import React, { Component } from 'react';
import { TabPanel } from 'react-tabs';

import CustomTab from 'containers/Tab';

import { TabData } from 'constants/tab';
// import Announcement from './Announcement';
import * as TabConstants from 'constants/tab';

import AppVersion from './AppVersion';
import Banner from './Banner';
import Event from './Event';
import News from './News';
// import Popup from './Popup';
// import Slider from './Slider';

class HomeSettings extends Component {
  state = {
    tabIndex: 0,
    subTabIndex: 0,
  };
  render() {
    return (
      <CustomTab
        selectedIndex={this.state.tabIndex}
        onGetIndex={(ind) => {
          this.setState({ tabIndex: ind }, () => {});
        }}
        wantIndex
        data={TabData.homeSetting.filter((d) => !d.hideTab)}
        tabHeaderClass="customerMaintab"
        className="moreTabScroll"
      >
        <div className="configurationWrapper pt-0">
          {/* <TabPanel>
            <Popup />
          </TabPanel> */}
          <TabPanel>
            <News />
          </TabPanel>
          <TabPanel>
            <Event />
          </TabPanel>
          {/* <TabPanel>
              <Announcement />
            </TabPanel> */}
          <TabPanel>
            <Banner />
          </TabPanel>

          {/* <TabPanel>
              <SendNotification />
            </TabPanel> */}
          {/* <TabPanel>
            <Slider />
          </TabPanel> */}

          <TabPanel>
            <CustomTab
              className="customerSubTab"
              data={TabConstants.TabData.appVersion[0]}
              title1="1"
              selectedIndex={this.state.subTabIndex}
              selectedClassName="none"
              sub={true}
              wantIndex
              onGetIndex={(ind) => {
                this.setState({ subTabIndex: ind });
              }}
              forceRenderTabPanel={true}
            >
              <TabPanel key="0">{this.state.subTabIndex === 0 && <AppVersion platform={1} />}</TabPanel>
              <TabPanel key="1">{this.state.subTabIndex === 1 && <AppVersion platform={2} />}</TabPanel>
            </CustomTab>
          </TabPanel>
        </div>
      </CustomTab>
    );
  }
}

export default HomeSettings;
