import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import ConstantApi from 'constants/CommonApi';

export const getAddNoteList = (request = {}, tab) =>
  UtilService.callApiAsync({
    request,
    ...ConstantApi.addNote.paginate,
    url: ConstantApi.addNote.paginate.url + `&tab=${tab}`,
  });

export const createAddNote = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.addNote.create, request, ...(!isEmpty(config) && { config }) });

export const updateAddNote = (id, request = {}) =>
  UtilService.callApiAsync({
    ...ConstantApi.addNote.update,
    url: ConstantApi.addNote.update.url + '/' + id,
    id,
    request,
  });

export const findAddNote = (id) => UtilService.callApiAsync(UtilService.withUrlParams(ConstantApi.addNote.view, [id]));

export const deleteAddNote = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.addNote.delete, request, ...(!isEmpty(config) && { config }) });
