import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, Formik } from 'formik';
import { cloneDeep } from 'lodash';
// import { find, remove, cloneDeep, findIndex, compact } from 'lodash';
import * as Yup from 'yup';

import TextArea from 'containers/TextArea';

import CircularProgress from 'components/CircularProgress';

import OpenNotification from 'constants/OpenNotifications';

// import { HeadingCalc } from './CommonPopupFunction';

import CommonApi from '../../constants/CommonApi';
import Messages from '../../constants/Messages';
import ListingTableWrapper from '../../containers/ListingTableWrapper';
import ListingWrapper from '../../containers/ListingWrapper';
import MainHeading from '../../containers/MainHeading';
import UtilService from '../../services/util';
import Table from '../Inventory/TableBack';

const currentType = 'commentpopup';

class CommentPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      stoneblock: false,
      gridHeight: 0,
      mainNote: '',
      notes: {},
      data: cloneDeep(this.props.checked),
      checked: this.props.checked,
      columns: this.props.columns,
    };
  }

  // componentDidUpdate() {
  //   if (this.state.checked.length === 0) {
  //     this.props.onClose();
  //   }
  // }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.checkedData.length !== this.state.checked.length) {
      this.setState({ checked: nextProps.checkedData });
    }
  }

  onAddComment = (values) => {
    if (this.props.client !== '') {
      this.setState({ loader: true });
      const self = this;
      const obj = {
        method: CommonApi.Inventory.diamondComment.method,
        url: CommonApi.Inventory.diamondComment.url,
        request: {
          userAccount: this.props?.client?.id,
          diamonds: this.state.data.map((d) => ({ diamond: d.id, remarks: values.remarks })),
        },
      };
      UtilService.callApi(obj, (err, data) => {
        if (data && data.code === 'OK') {
          self.props.onClose();
          if (!self.props.inDNA) {
            self.props.unCheckedDiamond();
          }
          OpenNotification({
            type: 'success',
            title: data.message,
          });
        }
        self.setState({ loader: false });
      });
    }
  };
  initialValues = {
    remarks: '',
  };

  validationSchema = Yup.object().shape({
    remarks: Yup.string().required(Messages.CommentRequire),
  });

  render() {
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={this.initialValues}
          validateOnBlur
          validationSchema={this.validationSchema}
          onSubmit={this.onAddComment}
        >
          {({ touched, errors, values, setFieldValue }) => (
            <Form>
              <div className="addNotePopup">
                <div className="d-flex align-items-center oneLineTotal">
                  <MainHeading className="mb-0 mr-40" title="Add Note" />
                  {/* {HeadingCalc(this.state.checked, 'note')} */}
                </div>
                <div className="popupHalfShow">
                  <ListingWrapper>
                    <ListingTableWrapper classList={['.oneLineTotal', '.sidebarAction']} initial={-15}>
                      {this.state.data && (
                        <Table
                          data={this.state.data}
                          columns={this.state.columns}
                          canSort={false}
                          currentType={currentType}
                          FilterOption={false}
                          // areAllChecked={true}
                          noheader
                          noGrp
                          nodots
                          noCheckBox
                        />
                      )}
                    </ListingTableWrapper>
                  </ListingWrapper>
                  <div className="singleStonePlaceOrderDetail mt-20 smallGrayScroll grayScroll d-block">
                    <div className="d-flex flex-wrap width-100 align-items-start">
                      <TextArea
                        label="Note *"
                        placeholder="Enter Note"
                        onChange={(e) => {
                          setFieldValue('remarks', e.target.value);
                        }}
                        value={values?.remarks}
                        error={touched?.remarks && errors?.remarks}
                      />
                    </div>
                  </div>
                </div>
                <div className="sidebarAction">
                  <button className="commonButton" type="submit">
                    Submit
                  </button>
                  <button className="commonOutine" onClick={this.props.onClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {this.state.loader && <CircularProgress className="fixLoader" />}
      </>
    );
  }
}

const mapStateToProps = ({ diamondData }) => {
  return { checkedData: diamondData.selectedRows[currentType] || [] };
};

export default connect(mapStateToProps, null)(CommentPopup);
