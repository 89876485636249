export const LAB_LINKS = {
  IGI: 'https://igi.org/verify.php?r=***',
  GSI: 'http://wg.gemscience.net/vr/veri.aspx',
  GIA: 'https://www.gia.edu/report-check?reportno=***',
  HRD: 'https://my.hrdantwerp.com/?record_number=***',
};

export const SORTABLE_COLUMNS = {
  stoneId: 'Packet No',
  shpNm: 'Shape',
  crt: 'Carat',
  colNm: 'Color',
  clrNm: 'Clarity',
  shape: 'Shape',
  color: 'Color',
  carat: 'Carat',
};
export const COLLAPSIBLE_ROW_HEADERS = false;
export const SELECT_ROW_ON_CLICK = true;
export const HIGHLIGHT_ROW_BY_LAB = true;

// prettier-ignore
export const ALT_COLUMNS = ['lbNm', 'crt', 'colNm', 'clrNm', 'cutNm', 'polNm', 'symNm', 'back', 'rap', 'fluNm'];
// prettier-ignore
export const BOLD_COLUMNS = ['amt', 'back', 'bidAmount', 'bidDiscount', 'bidPricePerCarat', 'brlncyNm', 'clrNm', 'colNm', 'crt', 'ctPr', 'cutNm', 'fluNm', 'polNm', 'prvBack', 'prvCtPr', 'rap', 'shdNm', 'shpNm', 'stoneId', 'symNm', 'vStnId'];
// prettier-ignore
export const TOTAL_COLUMNS = ['ctPr', 'amt', 'rap', 'crt', 'newDiscount', 'newPricePerCarat', 'newAmount', 'rapAvg', 'latestOfferAmt', 'buyAmt'];
// prettier-ignore
export const FILTER_COLUMNS = ['colNm', 'fluNm', 'shpNm', 'lbNm', 'clrNm', 'locNm', 'shdNm', 'cutNm', 'polNm', 'symNm', 'brlncyNm', 'blkTblNm', 'blkSdNm', 'wSdNm', 'wTblNm', 'opCrwnNm', 'opPavNm', 'opTblNm', 'eClnNm', 'girdleStr', 'cultNm', 'hANm']
// prettier-ignore
export const FLOAT_COLUMNS = ['amt', 'back', 'bidAmount', 'bidDiscount', 'bidPricePerCarat', 'cAng', 'cHgt', 'crt', 'ctPr', 'depPer', 'fnDis', 'grdlPer', 'height', 'length', 'lwrHal', 'newAmount', 'newDiscount', 'newPricePerCarat', 'offerAmount', 'offerDiscount', 'offerPricePerCarat', 'pAng', 'pHgt', 'prvBack', 'prvCtPr', 'rapAvg', 'ratio', 'strLn', 'tblPer', 'trackAmount', 'trackDiscount', 'trackPricePerCarat', 'width'];
// prettier-ignore
export const NOCHECK_COLUMNS = ['Details', 'Action', 'quote', 'expBack', 'hours', 'bidPricePerCarat', 'note', 'confirm', 'reject', 'FutureDisc', 'stockCount', 'vStnId', 'history', 'diff', 'discount', 'offerctpr', 'targetProgress'];
// prettier-ignore
export const ROUND_COLUMNS = ['amt', 'rap'];
// prettier-ignore
export const FONT_WEIGHT_COLUMNS = ['stoneId', 'vStnId', 'colNm', 'shpNm', 'crt', 'clrNm', 'shdNm', 'rap', 'back', 'ctPr', 'cutNm', 'polNm', 'symNm', 'brlncyNm', 'fluNm'];

export const PAGE_LIMIT = 250;
