import React from 'react';

import CommonBackButton from 'containers/CommonBackButton';
import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import MainHeading from 'containers/MainHeading';

import MagnifierImage from 'components/DiamondDetail/MagnifierImage';
import DiamondListingAction from 'components/DiamondListing/DiamondListingAction';

import { isArray, isEmpty } from 'util/utils';

import { diamondParameters as DiamondFields, FILE_URLS } from 'constants/Common';
import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

import closeredSvg from '../../assets/svg/close-red.svg';

// prettier-ignore
const FieldNameList = ['img',  'vStnId', 'stoneId', 'locNm', 'lbNm', 'rptNo', 'shpNm', 'crt', 'clrNm', 'colNm', 'shdNm', 'rap', 'back', 'ctPr', 'fluNm', 'cutNm', 'polNm', 'symNm', 'blkTblNm', 'blkSdNm', 'wSdNm', 'wTblNm', 'opTblNm', 'opCrwnNm', 'opPavNm', 'eClnNm', 'depPer', 'tblPer', 'length', 'width', 'height', 'cAng', 'cHgt', 'pAng', 'pHgt', 'girdleStr', 'cultNm', 'lsrInc', 'brlncyNm', 'hANm', 'kToSStr', 'lbCmt'];
const ExcludeFieldNameList = [];
const FieldMapEntries = FieldNameList.map((field) => [
  field,
  DiamondFields?.[field]?.desc || DiamondFields?.[field]?.name || `${field}`.toUpperCase(),
]);
const FieldMap = Object.fromEntries(FieldMapEntries);

const CompareHead = React.memo(({ handleClose, count }) => {
  return (
    <div className="d-flex flex-wrap compare-head">
      <CommonBackButton onClick={handleClose} />
      <MainHeading title={`Compare(${count})`} />
    </div>
  );
});

const CompareTable = React.memo(({ list, removeItem }) => {
  return (
    <table>
      <tbody>
        {Object.entries(list).map(
          ([field, values]) =>
            !ExcludeFieldNameList.includes(field) &&
            (field === 'img' ? (
              <tr>
                <td className="compareHead">Diamond Image</td>
                {values.map((value, index) => (
                  <td className="compareHead compareImage" key={list?.vStnId?.[index]}>
                    <MagnifierImage
                      className="diamondCompareImg"
                      src={FILE_URLS.img.replace('***', list?.vStnId?.[index])}
                    >
                      No Image Found
                    </MagnifierImage>
                    <a className="closeImage" href="!#" onClick={removeItem(list?.vStnId?.[index])}>
                      <img src={closeredSvg} alt="" />
                    </a>
                  </td>
                ))}
              </tr>
            ) : (
              <tr key={field}>
                <td className="compareHead">{FieldMap?.[field]}</td>
                {values.map((value, index) => (
                  <td key={index}>{isEmpty(value) ? '-' : value}</td>
                ))}
              </tr>
            )),
        )}
      </tbody>
    </table>
  );
});

function Compare({ handleClose, list }) {
  const [removed, setRemoved] = React.useState([]);

  React.useEffect(() => {
    if (!isArray(list) || isEmpty(list)) {
      OpenNotification({ type: 'error', title: Messages.Error.Required.Stones });
      handleClose();
    }

    if (list.length < 2) {
      OpenNotification({ type: 'error', title: Messages.Error.Required.MoreThanOneStone });
      handleClose();
    }
    if (list.length > 15) {
      OpenNotification({ type: 'error', title: Messages.Error.Required.MaxCompareStone });
      handleClose();
    }
  }, [list, handleClose]);

  const [formattedList, count] = React.useMemo(() => {
    const output = Object.fromEntries(FieldNameList.map((field) => [field, []]));
    FieldNameList.forEach((field) => {
      list
        .filter((stone) => !removed.includes(stone?.vStnId))
        .forEach((stone) => {
          output[field].push(isEmpty(stone?.[field]) ? undefined : stone?.[field]);
        });
    });
    const count = output?.vStnId?.length;
    return [output, count];
  }, [list, removed]);

  const removeStone = React.useCallback(
    (stoneId) => () => {
      count === 1 ? handleClose() : setRemoved((removed) => [...removed, stoneId]);
    },
    [count, handleClose],
  );

  return (
    <div className="compareMainPage">
      <CompareHead {...{ handleClose, count }} />
      <GridHeightContainer className="compareTable" subtractFrom=".compare-head" adjustment={-41}>
        <CompareTable list={formattedList} removeItem={removeStone} />
      </GridHeightContainer>
      <DiamondListingAction checkedData={list} ActionFalse={false} />
    </div>
  );
}

export default React.memo(Compare);
