import React from 'react';

import { Select } from 'antd';

import InlineList from 'components/List/InlineList';

import * as UserAPI from 'services/UserApi';

import * as hooks from 'util/hooks';
import { classNames, isArray, isEmpty } from 'util/utils';

import { LIST_LIMIT } from 'constants/Common';

function UserSelect(props) {
  const { label, required, filters, className, error, ...rest } = props;

  const [list, setList] = React.useState([]);
  const [loading, startLoading, stopLoading] = hooks.useLoading();

  const listRef = hooks.useAutoRef(list);

  const getList = React.useCallback(
    async (keyword) => {
      if (keyword?.length < 3) return setList([]);

      startLoading();
      const [, res] = await UserAPI.getUserList(
        {
          filter: {
            ...(filters && filters.type !== 'all' ? { type: filters.type } : {}),
            ...(filters && filters.isActive !== 'all' ? { isActive: filters.isActive } : {}),
          },
          page: 1,
          limit: LIST_LIMIT,
          search: { keyword, keys: ['firstName', 'companyName'] },
        },
        { loader: false },
      );

      if (res?.code === 'OK') setList(isArray(res?.data?.list) ? res?.data?.list : []);
      stopLoading();
    },
    [startLoading, stopLoading],
  );

  const getUserDetails = React.useCallback(async (userId) => {
    const [err, res] = await UserAPI.getUserDetails(userId);
    if (!err) setList((list) => [...list, res.data]);
  }, []);

  React.useEffect(() => {
    // react on value
    if (!isEmpty(rest?.value)) {
      // cast to array
      const list = listRef.current ?? [];
      const idList = rest?.mode !== 'default' ? rest?.value : [rest?.value];
      // iterate through ids and get entry if not available in list
      idList.forEach((id, index) => {
        const itemIsAvailable = list.find((details) => details?.id === id);
        if (!itemIsAvailable) setTimeout(() => getUserDetails(id), 10 * index);
      });
    }
  }, [getUserDetails, listRef, rest?.mode, rest?.value]);

  return (
    <div className={classNames([`formSelect from-group`, error && ' error-message', className])}>
      <label>
        {label ?? 'Select Customer'} {required && '*'}
      </label>
      <Select
        placeholder="Select Customer"
        notFoundContent={loading ? 'Searching...' : 'Type Client/Company name to Search'}
        onSearch={getList}
        filterOption={false}
        allowClear
        showArrow
        showSearch
        {...rest}
      >
        {!isEmpty(list) &&
          list.map((details) => (
            <Select.Option value={details.id} key={details.id}>
              <InlineList
                separator="&nbsp;|&nbsp;"
                list={[details?.companyName, `${details.firstName} ${details.lastName ? details.lastName : ''}`]}
              />
            </Select.Option>
          ))}
      </Select>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

UserSelect.propTypes = Select.propTypes;

export default UserSelect;
