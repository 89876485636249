import { getDefaultPermissions } from './_getDefaultPermissions';

export const BidShowPermissions = [
  {
    mainModule: 'BIDSHOW',
    module: 'APPOINTMENT',
    title: 'Bidconfig',
    url: '/bid',
    permissions: getDefaultPermissions(),
  },
];
