import React from 'react';
import Slider from 'react-slick';

import { STATUS_INDICATION } from 'components/DiamondListing/Status';

import { FILE_URLS, LAB_LINKS } from 'constants/Common';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

import cameraSvg from 'assets/svg/camera.svg';

const RecentlyView = (props) => {
  const settings = {
    dots: true,
    infinite: false,
    loop: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          // infinite:true,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {props.diamondList.map((diamond, index) => (
          <div className="diamondGridView" key={index}>
            <div className="daimondGridViewItem width-100">
              <div className="DiamondGridViewImg">
                <img
                  onError={(e) => {
                    e.target.src = cameraSvg;
                  }}
                  src={FILE_URLS.img.replace('***', diamond.vStnId)}
                  alt={FILE_URLS.img.replace('***', diamond.vStnId)}
                />
              </div>
              <div className="gridViewContent">
                <div className="gridLeftContent">
                  <h2>{diamond.shpNm}</h2>
                  <a
                    href={`${PREFIX_URL_WITH_SLASH}/diamond-detail/${diamond.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {diamond.vStnId}
                  </a>
                  <p>
                    {[
                      parseFloat(diamond.crt).toFixed(2),
                      diamond.isFcCol ? diamond.fcColNm : diamond.colNm,
                      diamond.clrNm,
                      diamond.cutNm,
                      diamond.polNm,
                      diamond.symNm,
                      diamond.fluNm,
                    ]
                      .filter((el) => {
                        return el && el;
                      })
                      .join(' ')}
                  </p>
                </div>
                <div className="gridRightContent">
                  <a
                    href="!#"
                    onClick={() => {
                      if (diamond.lbNm && diamond.rptNo) {
                        window.open(LAB_LINKS[diamond.lbNm.toUpperCase()].replace('***', diamond.rptNo));
                      }
                    }}
                  >
                    {diamond.lbNm}
                  </a>

                  <span>{parseFloat(diamond.amt).toFixed(2)}</span>
                  <span className="statusShow">
                    {STATUS_INDICATION[diamond.wSts]?.name}
                    <span
                      style={{ backgroundColor: STATUS_INDICATION[diamond.wSts]?.color }}
                      className={'statusIcon '}
                    ></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default RecentlyView;
