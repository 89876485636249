import { getDefaultPermissions } from './_getDefaultPermissions';

export const PricingPermissions = [
  {
    mainModule: 'PRICING',
    module: 'REVISE',
    title: 'Revise',
    url: '/pricing/revise',
    permissions: getDefaultPermissions(),
  },
];
