import { getDefaultPermissions } from './_getDefaultPermissions';

export const DashboardPermissions = [
  {
    mainModule: 'DASHBOARD',
    module: 'DASHBOARD',
    url: '/dashboard/home',
    title: 'Dashboard',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    module: 'ENQUIRY',
    url: '/dashboard/enquiry',
    title: 'Enquiry',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    module: 'INVENTORY',
    url: '/dashboard/inventory',
    title: 'Inventory',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'DASHBOARD',
    module: 'SEARCH',
    title: 'Search',
    url: '/dashboard/search',
    permissions: getDefaultPermissions(),
  },
];
