import { getDefaultPermissions } from './_getDefaultPermissions';

export const ReportPermissions = [
  {
    mainModule: 'REPORT',
    module: 'PAGE_TRACKING',
    title: 'Page Tracking',
    url: '/page-tracking',
    permissions: getDefaultPermissions(),
  },
];
