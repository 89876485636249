import React from 'react';

import { isEmpty } from 'util/utils';
import './title.less';

const MainHeading = ({ className, title, children, ...rest }) => (
  <h2 className={`mainHeadingTitle ${className}`} {...rest}>
    {isEmpty(children) ? title : children}
  </h2>
);

export default MainHeading;
