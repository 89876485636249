import React from 'react';

import { Select } from 'antd';

import clearFlagSvg from 'assets/svg/Todo/clear-flag.svg';
import highSvg from 'assets/svg/Todo/high.svg';
import lowFlagSvg from 'assets/svg/Todo/low-flag.svg';
import normalSvg from 'assets/svg/Todo/normal.svg';
import urgentSvg from 'assets/svg/Todo/urgent.svg';

import './prioritySelect.less';

export const PRIORITIES = { /* URGENT: 4, */ HIGH: 'HIGH', NORMAL: 'NORMAL', LOW: 'LOW', CLEAR: 'CLEAR' };

const PriorityImages = {
  URGENT: urgentSvg,
  HIGH: highSvg,
  NORMAL: normalSvg,
  LOW: lowFlagSvg,
  CLEAR: clearFlagSvg,
};

function PrioritySelect({ priority, setPriority }) {
  return (
    <Select value={priority} onChange={setPriority} dropdownClassName="priorityDropdown">
      {Object.entries(PRIORITIES).map(([name, value]) => (
        <Select.Option key={value} value={value}>
          <div className="priorityItem">
            <img className="priorityImg" src={PriorityImages[name]} alt="priorityImg" />
            <span className="priorityTitle">{name.toLowerCase()}</span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default PrioritySelect;
