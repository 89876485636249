import React, { Component } from 'react';

// import CustomTab from '../../containers/Tab';
// import { TabData } from '../../constants/tab';

import '../../styles/configuration.less';
// import { TabPanel } from 'react-tabs';

// import TableForm from './MatchPaireParameter/TableForm';
// import TableList from './MatchPaireParameter/TableList';

// import PlusIcon from '../../containers/PlusIcon';

class Configuration extends Component {
  constructor() {
    super();
    this.state = {
      list: true,
      tabIndex: 0,
    };
  }
  toggle = () => {
    this.setState({
      list: !this.state.list,
    });
  };
  render() {
    return (
      <div>
        {/* <CustomTab
          selectedIndex={this.state.tabIndex}
          wantIndex
          onGetIndex={(ind) => {
            this.setState({ tabIndex: ind }, () => {});
          }}
          moreTab
          data={TabData.Configuration.filter((d) => !d.hideTab)}
          tabHeaderClass="customerMaintab"
          className="moreTabScroll"
        >
          <div className="addButton" onClick={this.toggle.bind(this)}>
                <img src={require("../../assets/svg/plus-color.svg")} alt=""/>
                <span>New Space</span>
            </div>
          <PlusIcon onClick={this.toggle.bind(this)}></PlusIcon>
          <div className="configurationWrapper">
            <TabPanel>{this.state.list ? <TableList></TableList> : <TableForm></TableForm>}</TabPanel>
          </div>
        </CustomTab> */}
      </div>
    );
  }
}

export default Configuration;
