import { DOWNLOAD_EXCEL_STATUS } from 'constants/ActionTypes';

const initialState = { downloadExcelStatus: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_EXCEL_STATUS:
      return { ...state, downloadExcelStatus: action.payload };
    default:
      return state;
  }
};
