import React, { Component } from 'react';

import * as NewsAPI from 'services/NewsApi';

import NewsForm from './form';
import NewsList from './list';

class News extends Component {
  state = { list: true, detail: {} };

  toggle = () => this.setState({ list: !this.state.list, detail: {} });

  editDetails = async (editId) => {
    const [, res] = await NewsAPI.findNews(editId);
    if (res?.code === 'OK') {
      this.setState({ detail: res.data, list: !this.state.list });
    }
  };

  render() {
    return this.state.list ? (
      <NewsList onClick={this.toggle} editDetails={this.editDetails} />
    ) : (
      <NewsForm closeForm={this.toggle} update={this.state.detail} />
    );
  }
}

export default News;
