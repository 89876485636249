import { downloadExcelFile } from 'services/commonFunc';
import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import ConstantApi from 'constants/CommonApi';

export const getList = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.ProformaReport.paginate, request, ...(!isEmpty(config) && { config }) });

export const addInstruction = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.ProformaReport.instruction, request, ...(!isEmpty(config) && { config }) });

export const getSEReport = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Report.seReport, request, ...(!isEmpty(config) && { config }) });

/**
 * Function used to generate excel
 * @param {*} api
 * @param {*} request
 */
export const getExcelFile = async (api, request = {}, cb) => {
  const [err, res] = await UtilService.callApiAsync({
    ...api,
    request,
  });
  downloadExcelFile(err, res, cb);
};
