import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import API_ROUTES from 'constants/CommonApi';

// Cabin slot
export const getOfficeList = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.office.list, request, ...(!isEmpty(config) && { config }) });

export const createOffice = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.office.create, request, ...(!isEmpty(config) && { config }) });

export const getBranch = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.office.branch, request, ...(!isEmpty(config) && { config }) });

// Office View Schedule list
export const getOfficeViewList = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.officeView.list, request, ...(!isEmpty(config) && { config }) });

export const deleteOffice = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.office.destroy, request, ...(!isEmpty(config) && { config }) });

export const changeStatus = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.office.statusUpdate, request, ...(!isEmpty(config) && { config }) });
