import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import BackendDevComponent from 'components/BackendDevCompo/BackendDevCompo';
import { InventoryUser } from 'components/Inventory-user/InventoryUser';
import InventoryQuickSearch from 'components/Inventory/QuickSearch/InventoryQuickSearch';

import loadable from 'util/asyncComponent';

import { PREFIX_URL } from 'constants/CommonUrl';

const Documents = loadable(() => import('./Documents'));
// const Pricing = loadable(() => import('./Pricing'));
const Client = loadable(() => import('./Client'));
const Bidshow = loadable(() => import('./Bidshow'));
const Transaction = loadable(() => import('./Transaction'));
const Inventory = loadable(() => import('./Inventory'));
const Settings = loadable(() => import('./Settings'));
const EmailSync = loadable(() => import('./EmailSync'));
// const Todo = loadable(() => import('./Todo'));
// const Notifications = loadable(() => import('./Notifications'));
const NotificationsSetting = loadable(() => import('./NotificationsSetting'));
const ContactDirectory = loadable(() => import('./ContactDirectory'));
const EditProfile = loadable(() => import('./EditProfile'));
const Compare = loadable(() => import('./Compare'));
const Configuration = loadable(() => import('./Configuration'));
const HomeSettings = loadable(() => import('../components/Settings/HomeSettings'));
const DiscountSettings = loadable(() => import('../components/Settings/DiscountSettings'));
const CaratRange = loadable(() => import('../components/Inventory/Configuration/CaratRange'));
const XRAY = loadable(() => import('./Inventory/XRAY'));
const FTC = loadable(() => import('./Inventory/FTC'));
const Offer = loadable(() => import('./Offer'));
const ContactUs = loadable(() => import('./ContactUs'));
// const MyAccount = loadable(() => import('./MyAccount'));
const Banner = loadable(() => import('./Banner'));
const BannerUpsertForm = loadable(() => import('./Banner/BannerUpsertForm'));
const Location = loadable(() => import('./Location'));
const LocationUpsert = loadable(() => import('./Location/LocationUpsert'));
const Master = loadable(() => import('./Master'));
const SubMaster = loadable(() => import('./SubMaster'));
// const Product = loadable(() => import('./Product'));
const Account = loadable(() => import('./Account'));
const AccountUpsert = loadable(() => import('./Account/AccountUpsert'));
const User = loadable(() => import('./User'));
const RoleManager = loadable(() => import('./RoleManager'));
// const SizeMaster = loadable(() => import('./SizeMaster'));
const PageTrack = loadable(() => import('./PageTrack'));
// const Terms = loadable(() => import('components/TermsOfUse/TermsOfUse'));
const Country = loadable(() => import('./Country'));
const State = loadable(() => import('./State'));
const City = loadable(() => import('./City'));
const UploadHistory = loadable(() => import('./UploadHistory'));
const StockSummary = loadable(() => import('./StockSummary'));
const InvalidDiamond = loadable(() => import('./UploadHistory/InvalidDiamond'));
const GridColumn = loadable(() => import('./GridColumn'));
const DNA = loadable(() => import('components/DNA'));
const ViewImage = loadable(() => import('components/DNA/ViewImage'));
const Diamond = loadable(() => import('./Diamond'));
const Cart = loadable(() => import('./Cart'));
const Watchlist = loadable(() => import('./Watchlist'));
const Reminder = loadable(() => import('./Reminder'));
const Enquiry = loadable(() => import('./Enquiry'));
const EnquiryDetail = loadable(() => import('../components/Enquiry/EnquiryDetail'));
const Reports = loadable(() => import('./Reports'));
const Comment = loadable(() => import('./Comment'));
const ex = loadable(() => import('./GridColumn/ex'));
const ParcelMaster = loadable(() => import('./ParcelMaster'));
const Feedback = loadable(() => import('./Feedback'));
const FeaturedStone = loadable(() => import('./FeaturedStone'));
const Cabin = loadable(() => import('./Cabin'));
const Slot = loadable(() => import('./Slot'));
const Schedule = loadable(() => import('./Schedule'));
const Setting = loadable(() => import('./Setting'));
const NotificationSetting = loadable(() => import('./NotificationSetting'));
const NotificationUpsert = loadable(() => import('./NotificationSetting/NotificationUpsert'));
const settingTable = loadable(() => import('./Setting/settingTable'));
const Version = loadable(() => import('./Version'));
const Dashboard = loadable(() => import('./Dashboard'));
const Platform = loadable(() => import('./Platform'));
const GeneralSetting = loadable(() => import('./GeneralSetting'));
const Transport = loadable(() => import('./Transport'));
const Hospitality = loadable(() => import('./Hospitality'));
const Career = loadable(() => import('./Career'));
const SearchHistory = loadable(() => import('./SearchHistory'));
const HitCounter = loadable(() => import('./HitCounter'));
const SyncSetting = loadable(() => import('./SyncSetting'));
const FtpRequest = loadable(() => import('./FtpRequest'));
const ThemeSetup = loadable(() => import('./ThemeSetup'));
const UploadDiamonds = loadable(() => import('./UploadDiamonds'));
const DiamondDetail = loadable(() => import('../components/DiamondDetail'));
// const Todo = loadable(() => import('./Todo'));
const Report = loadable(() => import('./Report'));
const Activity = loadable(() => import('./Activity'));
const Demand = loadable(() => import('./Demand'));
const Trade = loadable(() => import('./Trade'));
const Notifications = loadable(() => import('./Notifications'));

export const RouteAdmin = PREFIX_URL;

function App({ match }) {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}${RouteAdmin}/document`} component={Documents} />
        <Route path={`${match.url}${RouteAdmin}/uploaddiamonds`} component={UploadDiamonds} />
        {/* <Route path={`${match.url}${RouteAdmin}/pricing`} component={Pricing} /> */}
        <Route path={`${match.url}${RouteAdmin}/client`} component={Client} />
        <Route path={`${match.url}${RouteAdmin}/bid`} component={Bidshow} />
        <Route path={`${match.url}${RouteAdmin}/transaction`} component={Transaction} />
        <Route path={`${match.url}${RouteAdmin}/inventory`} component={Inventory} />
        <Route path={`${match.url}${RouteAdmin}/setting`} component={Settings} />
        <Route path={`${match.url}${RouteAdmin}/activity`} component={Activity} />
        <Route path={`${match.url}${RouteAdmin}/demand`} component={Demand} />
        <Route path={`${match.url}${RouteAdmin}/report`} component={Report} />
        <Route path={`${match.url}${RouteAdmin}/trade`} component={Trade} />
        <Route path={`${match.url}${RouteAdmin}/notification`} component={Notifications} />
        <Route path={`${match.url}${RouteAdmin}/emailsync/email`} component={EmailSync} />
        <Route path={`${match.url}${RouteAdmin}/emailsync/call-log`} component={EmailSync} />
        <Route path={`${match.url}${RouteAdmin}/emailsync/meeting`} component={EmailSync} />
        <Route path={`${match.url}${RouteAdmin}/emailsync/calendar`} component={EmailSync} />
        {/* <Route path={`${match.url}${RouteAdmin}/todo`} component={Todo} /> */}
        {/* <Route path={`${match.url}${RouteAdmin}/notification`} component={Notifications} /> */}
        <Route path={`${match.url}${RouteAdmin}/setting/notifications`} component={NotificationsSetting} />
        <Route path={`${match.url}${RouteAdmin}/setting/contact-directory`} component={ContactDirectory} />
        <Route path={`${match.url}${RouteAdmin}/setting/profile`} component={EditProfile} />
        <Route path={`${match.url}${RouteAdmin}/setting/configuration`} component={Configuration} />
        <Route path={`${match.url}${RouteAdmin}/setting/home-setting`} component={HomeSettings} />
        <Route path={`${match.url}${RouteAdmin}/setting/discount-setting`} component={DiscountSettings} />
        <Route path={`${match.url}${RouteAdmin}/compare`} component={Compare} />
        {/* <Route path={match.url+RouteAdmin+'/inventory/carat-range`} component={CaratRange}/> */}
        <Route path={`${match.url}${RouteAdmin}/inventory/configuration/carat-range`} component={CaratRange} />
        <Route path={`${match.url}${RouteAdmin}/inventory/configuration`} component={Inventory} />
        <Route path={`${match.url}${RouteAdmin}/xray/:id`} component={XRAY} />
        <Route path={`${match.url}${RouteAdmin}/diamond-ftp/:id`} component={FTC} />
        <Route path={`${match.url}${RouteAdmin}/offer`} component={Offer} />
        <Route path={`${match.url}${RouteAdmin}/contact`} component={ContactUs} />
        <Route path={`${match.url}${RouteAdmin}/suggestion`} component={ContactUs} />
        {/* <Route path={match.url+RouteAdmin+'/profile`} component={MyAccount} /> */}
        <Route exact path={`${match.url}${RouteAdmin}/banner`} component={Banner} />
        <Route path={`${match.url}${RouteAdmin}/banner/upsert/:id?`} component={BannerUpsertForm} />
        <Route exact path={`${match.url}${RouteAdmin}/location`} component={Location} />
        <Route path={`${match.url}${RouteAdmin}/location/upsert/:id?`} component={LocationUpsert} />
        <Route path={`${match.url}${RouteAdmin}/master/main-master`} component={Master} />
        <Route path={`${match.url}${RouteAdmin}/master/sub-master/:id?`} component={SubMaster} />
        {/* <Route path={match.url + RouteAdmin + '/product/:id?`} component={Product} /> */}
        <Route path={`${match.url}${RouteAdmin}/account`} component={Account} />
        <Route path={`${match.url}${RouteAdmin}/account-details/:id?`} component={AccountUpsert} />
        <Route path={`${match.url}${RouteAdmin}/user`} component={User} />
        <Route path={`${match.url}${RouteAdmin}/rolemaster`} component={RoleManager} />
        {/* <Route path={match.url + RouteAdmin + '/size-master`} component={SizeMaster} /> */}
        <Route path={`${match.url}${RouteAdmin}/page-tracking`} component={PageTrack} />
        <Route path={`${match.url}${RouteAdmin}/country/:id?`} component={Country} />
        <Route path={`${match.url}${RouteAdmin}/state/:id?`} component={State} />
        <Route path={`${match.url}${RouteAdmin}/city/:id?/:countryId?`} component={City} />
        <Route path={`${match.url}${RouteAdmin}/upload-history`} component={UploadHistory} />
        <Route path={`${match.url}${RouteAdmin}/stock-summary`} component={StockSummary} />
        <Route path={`${match.url}${RouteAdmin}/uploaded-sheet-details/:id`} component={InvalidDiamond} />
        <Route path={`${match.url}${RouteAdmin}/gridcolumn`} component={GridColumn} />
        <Route exact path={`${match.url}${RouteAdmin}/dna/:id`} component={DNA} />
        <Route exact path={`${match.url}${RouteAdmin}/diamond-detail/:id`} component={DiamondDetail} />
        <Route exact path={`${match.url}${RouteAdmin}/viewImage/:type/:id`} component={ViewImage} />
        <Route path={`${match.url}${RouteAdmin}/stock`} component={Diamond} />
        <Route path={`${match.url}${RouteAdmin}/cart/:id?`} component={Cart} />
        <Route path={`${match.url}${RouteAdmin}/watchlist/:id?`} component={Watchlist} />
        <Route path={`${match.url}${RouteAdmin}/reminder/:id?`} component={Reminder} />
        <Route path={`${match.url}${RouteAdmin}/enquiry`} component={Enquiry} />
        <Route path={`${match.url}${RouteAdmin}/enquiry`} component={EnquiryDetail} />
        <Route path={`${match.url}${RouteAdmin}/enquiry/enquiry/confirm`} component={EnquiryDetail} />
        <Route path={`${match.url}${RouteAdmin}/enquiry/enquiry/hold`} component={EnquiryDetail} />
        <Route path={`${match.url}${RouteAdmin}/reports`} component={Reports} />
        <Route path={`${match.url}${RouteAdmin}/comment/:id?`} component={Comment} />
        <Route path={`${match.url}${RouteAdmin}/ex`} component={ex} />
        <Route path={`${match.url}${RouteAdmin}/parcel-master`} component={ParcelMaster} />
        <Route path={`${match.url}${RouteAdmin}/feedback`} component={Feedback} />
        <Route path={`${match.url}${RouteAdmin}/featured-stone`} component={FeaturedStone} />
        <Route path={`${match.url}${RouteAdmin}/cabin`} component={Cabin} />
        <Route path={`${match.url}${RouteAdmin}/slot`} component={Slot} />
        <Route path={`${match.url}${RouteAdmin}/schedule`} component={Schedule} />
        <Route path={`${match.url}${RouteAdmin}/setting`} component={Setting} />
        <Route path={`${match.url}${RouteAdmin}/notification-setting`} component={NotificationSetting} />
        <Route path={`${match.url}${RouteAdmin}/notification/upsert/:id?`} component={NotificationUpsert} />
        <Route path={`${match.url}${RouteAdmin}/settingtable`} component={settingTable} />
        <Route path={`${match.url}${RouteAdmin}/version-apk`} component={Version} />
        <Route path={`${match.url}${RouteAdmin}/report`} component={Dashboard} />
        <Route path={`${match.url}${RouteAdmin}/platform`} component={Platform} />
        <Route path={`${match.url}${RouteAdmin}/general-setting`} component={GeneralSetting} />
        <Route path={`${match.url}${RouteAdmin}/transport`} component={Transport} />
        <Route path={`${match.url}${RouteAdmin}/hospitality`} component={Hospitality} />
        <Route path={`${match.url}${RouteAdmin}/career`} component={Career} />
        <Route path={`${match.url}${RouteAdmin}/search-history`} component={SearchHistory} />
        <Route path={`${match.url}${RouteAdmin}/hit-counter`} component={HitCounter} />
        <Route path={`${match.url}${RouteAdmin}/sync-setting`} component={SyncSetting} />
        <Route path={`${match.url}${RouteAdmin}/dashboard`} component={Dashboard} />
        <Route path={`${match.url}${RouteAdmin}/ftp-request`} component={FtpRequest} />
        <Route path={`${match.url}${RouteAdmin}/theme-setup`} component={ThemeSetup} />
        <Route path={`${match.url}${RouteAdmin}/backend-dev`} component={BackendDevComponent} />
        <Route path={`${match.url}${RouteAdmin}/inventory-user`} component={InventoryUser} />
        <Route path={`${match.url}${RouteAdmin}/inventory-quick-search`} component={InventoryQuickSearch} />
        <Redirect from="*" to={`${match.url}${RouteAdmin}/dashboard`} />
      </Switch>
    </div>
  );
}

export default App;
