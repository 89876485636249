import React from 'react';

import { InputField } from 'containers/InputBlock';

function CourierAccountNo(props) {
  const [courierAccountNo, setCourierAccNo] = React.useState('');
  React.useEffect(() => {
    // initialCourierAccountNo it's when by default fill data and it' not editAble
    setCourierAccNo(props.initialCourierAccountNo);
  }, [props.initialCourierAccountNo]);
  return (
    <InputField
      className={props.initialCourierAccountNo ? 'disableBlock' : ''}
      placeholder="Courier Account No"
      label="Enter Courier Account No"
      onChange={(e) => {
        setCourierAccNo(e.target.value);
        props.setCourierAccNo(e.target.value);
      }}
      value={courierAccountNo}
    />
  );
}

export default React.memo(CourierAccountNo);
