import React, { Component } from 'react';

import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { uniq, trim } from 'lodash';

import LeftDatePicker from 'containers/LeftOption/DatePicker';
import LeftMultipleSelect from 'containers/LeftOption/MultiSelect';
import MainHeading from 'containers/MainHeading';

import CircularProgress from 'components/CircularProgress';

import { getBranch, getOfficeList } from 'services/officeApi';

import { isArray } from 'util/utils';

import AppointmentForm from './AppointmentForm';
import SlotItem from './SlotItem';

export const Appointemt = {
  location: [{ value: 'India', id: '234344343' }, { value: 'Spain' }, { value: 'Hong Kong' }, { value: 'Dubai' }],
  virtualType: [{ value: 'Phone Call' }, { value: 'Web Conferen' }],
};
class OfficeSidebar extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
      date: '',
      timedate: false,
      slots: [],
      bidSlots: [],
      clickSlot: '',
      apiSlot: '',
      loader: false,
      cabinId: undefined,
      cabinBranchList: [],
    };
  }
  onChange = (date) => this.setState({ date });
  bookAppointment = () => {
    this.setState({
      show: !this.state.show,
      timedate: false,
    });
  };
  bookAppointmentWithTime = () => {
    this.setState({
      timedate: !this.state.timedate,
      show: false,
    });
  };
  // ******************************
  componentDidMount() {
    this.getBranch();
  }
  getBranch = async () => {
    const [, res] = await getBranch();
    if (res.code === 'OK' && isArray(res.data?.list)) {
      this.setState({ cabinBranchList: res.data.list }, () => {
        this.getCabinWiseListing();
      });
    }
  };
  getWorkingDays = (workingDays) => {
    const workingDates = [];
    let date = moment(new Date());
    while (workingDays > 0) {
      date = date.add(1, 'days');
      if (date.isoWeekday() !== 7) {
        workingDays -= 1;
        workingDates.push(moment(date).toISOString());
      }
    }
    return workingDates;
  };
  getCabinWiseListing = async () => {
    //FIXME:For mount listing pass first Id
    const [, res] = await getOfficeList({
      sort: [{ end: 'ASC' }],
      cabin: this.state.cabinId ? this.state.cabinId : this.state.cabinBranchList?.[0]?.id,
    });
    if (res.code === 'OK' && res.data?.list) {
      const bidSlots = [];
      // const data = res.data?.list.forEach((ls) => {
      await res.data?.list.forEach((ls) => {
        //If in bid page
        const start = moment(ls.start).format('hh:mm A');
        const end = moment(ls.end).format('hh:mm A');
        const bidTime = start < '11:00 AM' && end > '03:00 PM';
        if (!bidTime) {
          bidSlots.push(ls);
        }
      });
      this.setState({ slots: uniq(res.data.list), bidSlots });
    }
  };

  render() {
    const finalSlots = this.props.bid ? this.state.bidSlots : this.state.slots;
    return (
      <div className="officeBookWrapper">
        <div className="officeBookLeft">
          <div className="officeDateListing">
            <div className="officeTopLeft">
              <LeftMultipleSelect
                valueKey="id"
                nameKey="name"
                label="Branch *"
                placeholder="Select Branch"
                selectValue={this.state.cabinBranchList}
                onChange={(cabinId) => {
                  this.setState({ cabinId, clickSlot: cabinId ? this.state.cabinSlot : cabinId }, () => {
                    this.getCabinWiseListing();
                  });
                }}
                allowClear
                value={this.state.cabinId}
              />
              <LeftDatePicker
                title="Date *"
                date={this.state.date ? this.state.date : ''}
                Singledate
                onDateChange={(dt) => this.setState({ date: moment(dt).toISOString(), clickSlot: '', apiSlot: '' })}
              />
            </div>
            <div className="cardListinOffice">
              {this.getWorkingDays(7).map((date, index) => (
                <div
                  onClick={() => this.setState({ date, clickSlot: '', apiSlot: '' })}
                  className={`cardListOfficeItem ${
                    moment(this.state.date).format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY') && `active`
                  }`}
                  key={index}
                >
                  <div className="cardListOfficeTop">
                    <div className="cardListOfficeDate"> {moment(date).format('ddd, MMM Do YYYY')}</div>
                    {/* <div className="cardListOfficeTotal">04</div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="officeBookRight">
          <div className="soltWrapper">
            <div className="soltBookLeft">
              <div className="soltBookTop">
                <div className="appoinmentOfficeRightTop">
                  <MainHeading title="Appointment" />
                  <span className="appointmentDate">
                    {this.state.date ? moment(this.state.date).format('ddd, MMM Do YYYY') : ''}
                  </span>
                </div>

                <div className="officeBookSolt smallScroll grayScroll">
                  <div className="officeBookSoltItem">
                    <div className="officeBookSoltTitle">Select Time</div>
                    <div className="officeBookSoltInner">
                      {finalSlots.map((d, index) => {
                        const start = d.start ? moment(d.start).format('hh:mm A') : '';
                        const end = d.end ? moment(d.end).format('hh:mm A') : '';
                        const display = `${start} - ${end}`;
                        const disabled =
                          moment(`${moment(this.state.date).format('YYYY-MM-DD')} ${end}`) < moment() ||
                          !this.state.cabinId ||
                          !this.state.date;
                        return (
                          <>
                            {trim(display) !== '-' ? (
                              <SlotItem
                                key={index}
                                onClick={() => this.setState({ clickSlot: display, apiSlot: d })}
                                time={display}
                                className={
                                  this.state.clickSlot === display ? `soltBook` : disabled ? `allReadyBook` : ''
                                }
                                tooltip={'Book Appointment'}
                              />
                            ) : (
                              ''
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="soltBookBottom smallScroll grayScroll">
                <div className="officeBookingArea">
                  <div className="officeBookingRight">
                    <AppointmentForm
                      buttonFasle
                      cabinId={this.state.cabinId}
                      setLoader={() => {
                        this.setState({ loader: !this.state.loader });
                      }}
                      apiSlot={this.state.apiSlot}
                      date={this.state.date}
                      checked={this.props.checked}
                      slot={this.state.clickSlot}
                      onClose={this.props.onClose}
                      client={this.props.client}
                      unCheckedDiamond={this.props.unCheckedDiamond}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.state.loader && <CircularProgress className="fixLoader" />}
            {/* //Right side lisitng */}
          </div>
        </div>
      </div>
    );
  }
}

export default OfficeSidebar;
