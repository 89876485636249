import React, { useEffect, useState } from 'react';

import { DatePicker, Checkbox } from 'antd';
import { Formik, Form } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import FileUpload from 'containers/FileUpload';
import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import InputBlock from 'containers/InputBlock';
import Heading from 'containers/MainHeading';
// import { PRIORITIES } from 'containers/PrioritySelect';
import SelectOption from 'containers/SelectOption';
import TextArea from 'containers/TextArea';

import PartyBuyerComponent from 'components/DiamondListing/PopupComponent/PartyBuyerComponent';

import * as SellerAPI from 'services/SellerApi';

import { useCompositeState } from 'util/hooks';
import { isArray, isEmpty } from 'util/utils';

import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';
import { COMMON_URL } from 'constants/CommonUrl';

import { AddCallLogActivity, updateCallLog } from '../../services/ActivityApi';

import '../../containers/CommonCollapse/collapse.less';

const SelectData = {
  Reminder: [{ name: '30 min before' }, { name: '1 hours before' }],
  CallType: [
    { key: 1, value: 'Phone Call' },
    { key: 2, value: 'Web Conference' },
  ],
  Company: [
    { key: 1, value: 'Coruscate' },
    { key: 2, value: 'Coruscate' },
  ],
};

const AddCallLog = ({ checked, onClose, details }) => {
  // const [priority, setPriority] = React.useState(PRIORITIES.URGENT);
  const [state, setState] = useCompositeState({
    sellers: [],
    isReminder: false,
  });

  const getSalesPerson = React.useCallback(async () => {
    const request = { filter: { isActive: true }, isDeleted: false };
    const [err, res] = await SellerAPI.getSellerList(request);
    const success = !err && res?.code === 'OK';

    if (success && isArray(res.data)) {
      setState({
        sellers: res.data.map(({ id, firstName, lastName }) => ({
          key: id,
          value: `${firstName}${lastName !== '_' ? ' ' + lastName : ''}`,
        })),
      });
    }
  }, []);

  React.useEffect(() => {
    getSalesPerson();
  }, [getSalesPerson]);

  React.useEffect(() => {
    setState({
      isReminder: details?.isReminder ?? false,
    });
  }, []);

  const initialValue = {
    userAccount: undefined,
    user: undefined,
    subject: undefined,
    callType: undefined,
    description: undefined,
    logOnDateTime: undefined,
    isReminder: undefined,
    reminderDateTime: undefined,
    reminderSubject: undefined,
    reminderShareTo: undefined,
    sellers: [],
    audioFile: undefined,
    type: 'CALL_LOG',
  };
  const [syncData, setSyncData] = useState(undefined);
  useEffect(() => {
    if (details) {
      let clientList = [];
      if (details.partyId && details.customerId) {
        const client = {
          id: details?.partyId?.id,
          companyName: details?.partyId?.companyName,
          user: {
            id: details?.customerId?.id,
            name: details?.customerId?.name,
            firstName: details?.customerId?.firstName,
            lastName: details?.customerId?.lastName,
          },
        };
        clientList = [client];
      }
      const editValue = {
        client: clientList,
        userAccount: details?.partyId?.id ?? undefined,
        user: details?.customerId?.id ?? undefined,
        subject: details?.subject ?? undefined,
        callType: details?.callType?.toString() ?? undefined,
        description: details?.description ?? undefined,
        logOnDateTime: details?.logOnDateTime ? moment(details?.logOnDateTime) : undefined,
        isReminder: details?.isReminder ?? false,
        reminderDateTime: details?.reminderDateTime ? moment(details?.reminderDateTime) : undefined ?? undefined,
        reminderSubject: details?.reminderSubject ?? undefined,
        reminderShareTo: details?.reminderShareTo?.map((data) => data.id) ?? [],
        sellers: [],
        audioFile: details?.audioFile ?? undefined,
        type: 'CALL_LOG',
      };
      setSyncData(editValue);
    }
  }, []);
  useEffect(() => {
    if (checked) {
      const editValue = {
        ...initialValue,
        subject: checked.map((data) => data.memoNo).toString() ?? undefined,
        // callType: '1' ?? undefined,
      };
      setSyncData(editValue);
    }
  }, []);

  const tempVal = {
    subject: Yup.string().required(Messages.Todo.Title).nullable(),
    userAccount: Yup.string().required(Messages.userAccountSelect).nullable(),
    user: Yup.string().required(Messages.userSelect).nullable(),
    callType: Yup.string().required(Messages.Todo.callType).nullable(),
    logOnDateTime: Yup.date().required(Messages.Todo.logOnDateTime).nullable(),
    // related: Yup.string().required(Messages.Todo.ReltedTo).nullable(),
    // assign: Yup.string().required('Please enter assigned member').nullable(),
    description: Yup.string().required(Messages.Todo.Description).nullable(),
    // priority: Yup.string().required(Messages.Todo.priority).nullable(),
    audioFile: Yup.string().required('Audio File is required'),
    reminderDateTime: Yup.mixed().when('isReminder', {
      is: (v) => v === true,
      then: (s) => s.required('please enter date'),
      otherwise: (s) => s.nullable(),
    }),
    reminderSubject: Yup.mixed().when('isReminder', {
      is: (v) => v === true,
      then: (s) => s.required('please enter Subject'),
      otherwise: (s) => s.nullable(),
    }),
  };
  if (state.isReminder) {
    tempVal.reminderShareTo = Yup.array().required('please enter the shared To detail');
  }
  const validationSchema = Yup.object().shape(tempVal);
  const submitValue = async (values) => {
    setState({ loader: true });

    const request = {
      subject: values.subject,
      partyId: values.userAccount,
      description: values.description,
      // reminderShareTo: values.reminderShareTo,
      callType: values.callType,
      isReminder: values.isReminder,
      reminderDateTime: values.isReminder === true ? values.reminderDateTime : '',
      reminderShareTo: values.isReminder === true ? values.reminderShareTo : [],
      reminderSubject: values.isReminder === true ? values.reminderSubject : '',
      logOnDateTime: values.logOnDateTime,
      customerId: values.user,
      audioFile: values.audioFile,
      type: values.type,
    };
    const [, res] = isEmpty(details) ? await AddCallLogActivity(request) : await updateCallLog(details.id, request);
    if (res?.code === 'OK' && res?.data) {
      setState({ loader: false });
      OpenNotification({ type: 'success', title: res.message });
      onClose();
    }
    // this.props.handleCancel();
    setState({ loader: false });
  };

  return (
    <Formik
      initialValues={syncData || initialValue}
      // values={details}
      enableReinitialize
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={submitValue}
    >
      {/* {({ handleChange, handleBlur, setValues, setFieldTouched, touched, errors, values }) => ( */}
      {({ handleBlur, setValues, touched, errors, values }) => (
        <Form>
          <Heading title={`${isEmpty(details) ? 'Add' : 'Edit'} Call Log`} className="mb-20 smallTitle p-0" />
          <GridHeightContainer subtractFrom=".mainHeadingTitle,.sidebarAction" adjustment={-38}>
            <PartyBuyerComponent
              error={(touched?.useAccount || touched?.user) && errors}
              setUserDetails={(data) => {
                setValues((oldValues) => ({
                  ...oldValues,
                  userAccount: data?.userAccount,
                  user: data?.user,
                }));
              }}
              initialData={{ ...values }}
            />
            <InputBlock
              placeholder="Subject"
              label="Subject *"
              value={values?.subject}
              onChange={(e) =>
                setValues((oldValues) => ({
                  ...oldValues,
                  subject: e.target.value,
                }))
              }
              onBlur={handleBlur}
              error={touched?.subject && errors?.subject}
            />
            <SelectOption
              value={values.callType}
              onChange={(value) => {
                setValues((oldValues) => ({
                  ...oldValues,
                  callType: value,
                }));
                // setState({ share: value });
              }}
              name="callType"
              label="Call Type *"
              placeholder="Select call type"
              selectValue={SelectData.CallType}
              error={touched?.callType && errors?.callType}
              // nameKey="name"
              // valueKey="id"
              showSearch
              // className="multiSelect w-100"
            />
            <TextArea
              value={values.description}
              onChange={(e) => {
                setValues((oldValues) => ({
                  ...oldValues,
                  description: e.target.value,
                }));
              }}
              label="Description"
              placeholder="Description"
              onBlur={handleBlur}
              error={touched?.description && errors?.description}
            />

            <div className="from-group inputBlock mb-15">
              <label className="commonLabel">Select Due Date & Time</label>
              <DatePicker
                value={values.logOnDateTime}
                format="YYYY-MM-DD HH:mm:ss"
                onChange={(value) => {
                  setValues((oldValues) => ({
                    ...oldValues,
                    logOnDateTime: value,
                  }));
                }}
                disabledDate={(current) => current && current < moment().startOf('day')}
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                onBlur={handleBlur}
                error={touched?.logOnDateTime && errors?.logOnDateTime}
              />
              {touched?.logOnDateTime && <div className="error">{errors?.logOnDateTime}</div>}
            </div>
            <div className="mb-20">
              <Checkbox
                checked={values.isReminder}
                onChange={(e) => {
                  setValues((values) => ({ ...values, isReminder: e.target.checked }));
                  setState({ isReminder: e.target.checked });
                }}
              />
              <span className="ml-5">Next Call Reminder</span>
            </div>
            <>
              {values.isReminder && (
                <>
                  <div className="from-group inputBlock mb-15">
                    <label className="commonLabel">Select Due Date & Time</label>
                    <DatePicker
                      value={values.reminderDateTime}
                      format="YYYY-MM-DD HH:mm:ss"
                      onChange={(value) =>
                        setValues((oldValues) => ({
                          ...oldValues,
                          reminderDateTime: value,
                        }))
                      }
                      disabledDate={(current) => current && current < moment().startOf('day')}
                      showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    />
                    {touched?.reminderDateTime && <div className="error">{errors?.reminderDateTime}</div>}
                  </div>
                  <InputBlock
                    error={touched?.reminderSubject && errors?.reminderSubject}
                    placeholder="Next Call Subject"
                    label="Subject"
                    value={values.reminderSubject}
                    onChange={(e) =>
                      setValues((oldValues) => ({
                        ...oldValues,
                        reminderSubject: e.target.value,
                      }))
                    }
                  />
                  <SelectOption
                    value={values.reminderShareTo}
                    onChange={(value) => {
                      setValues((oldValues) => ({
                        ...oldValues,
                        reminderShareTo: value,
                      }));
                      // setState({ share: value });
                    }}
                    name="reminderShareTo"
                    label="Share To"
                    placeholder="Select Member"
                    selectValue={state.sellers}
                    // onBlur={handleBlur}
                    error={errors?.reminderShareTo && errors?.reminderShareTo}
                    // nameKey="name"
                    // valueKey="id"
                    showSearch
                    mode="multiple"
                    className="multiSelect w-100"
                  />
                </>
              )}
            </>

            <FileUpload
              name="images"
              isValidate
              label="Choose Audio File"
              accept="Audio Files MP3"
              size="Audio Files"
              files={values?.audioFile}
              onChange={(audio) => setValues((values) => ({ ...values, audioFile: audio || '' }))}
              onBlur={handleBlur}
              error={touched?.audioFile && errors?.audioFile}
            />

            {values?.audioFile && (
              <span className="fileUploadedBlock mt-10">
                <audio controls>
                  <source src={`${COMMON_URL.BASE_URL}${values?.audioFile}`} type="audio/mp3" />
                </audio>
              </span>
            )}
          </GridHeightContainer>

          <div className="sidebarAction">
            <button className="commonButton" type="submit">
              {isEmpty(details) ? 'Add' : 'Update'} Call Log
            </button>
            <button className="commonOutine" type="reset" onClick={onClose}>
              Cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddCallLog;
