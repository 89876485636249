import React from 'react';

import { DatePicker } from 'antd';
import moment from 'moment';
import './leftOption.less';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
// const monthFormat = 'MM/YYY';
const LeftDatePicker = ({
  value,
  image,
  title,
  Singledate,
  singleDateTime,
  onChange,
  onDateChange,
  placeholder = 'Select Date',
  date,
}) => {
  const [dateTime, setDateTime] = React.useState(undefined);
  return (
    <div className="leftSelectItem">
      <div className="leftSelectTitle">
        {title && <h2>{title}</h2>}
        {image && <img src={image} alt="icon" className="reloadImg" />}
      </div>
      {Singledate ? (
        <DatePicker
          placeholder={placeholder}
          className="SelectDateRange"
          disabledDate={(current) => {
            return current <= moment().startOf('day') || moment(current).isoWeekday() === 7;
          }}
          onChange={onDateChange}
          format={dateFormat}
          value={date ? moment(date) : undefined}
        />
      ) : singleDateTime ? (
        <DatePicker
          value={dateTime}
          placeholder={placeholder}
          className="SelectDateRange"
          disabledDate={(current) => current && current < moment().startOf('day')}
          onChange={(value) => setDateTime(value)}
          onOk={onDateChange}
          format="YYYY-MM-DD HH:mm:ss"
          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
        />
      ) : (
        <RangePicker
          onChange={onChange}
          className="SelectDateRange"
          defaultValue={[
            value?.from ? moment(value?.from, dateFormat) : '',
            value?.to ? moment(value?.to, dateFormat) : '',
          ]}
          format={dateFormat}
        />
      )}
    </div>
  );
};
export default LeftDatePicker;
