import React from 'react';

import './datePicker.less';
import { DatePicker } from 'antd';
import moment from 'moment';

import classNames from 'util/classNames';
import { isEmpty, isFunction } from 'util/utils';

const DefaultDateFormat = 'DD-MM-YYYY HH:mm';

const isInPast = (date) => date < moment().startOf('day');
const isInFuture = (date) => date > moment().endOf('day');
const isLowerThan = (date, min) => date < moment(min).startOf('day');
const isHigherThan = (date, max) => date > moment(max).endOf('day');

const Datepicker = (props) => {
  const {
    title,
    dateFormat,
    date,
    noFuture,
    noPast,
    min,
    max,
    onChange,
    containerProps,
    utc,
    error,
    disabledDate,
    showTime,
    original, //to get date without set start and end of day
    value,
    isOriginal,
    ...restProps
  } = props;
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const getFormattedDate = React.useCallback(
    (date) => {
      return !isEmpty(date) ? moment(utc ? date.utc() : date).startOf('day') : undefined;
    },
    [utc],
  );

  const getFormattedOriginalDate = React.useCallback(
    (date) => {
      return !isEmpty(date) ? moment(utc ? date.utc() : date) : undefined;
    },
    [utc],
  );

  const handleChange = React.useCallback(
    ([from, to]) => {
      if (isFunction(onChange)) {
        !isOriginal
          ? onChange([getFormattedDate(from), getFormattedDate(to)])
          : onChange([getFormattedOriginalDate(from), getFormattedOriginalDate(to)]);
      }
    },
    [onChange, isOriginal, getFormattedDate, getFormattedOriginalDate],
  );

  React.useEffect(() => {
    // const [from, to] = restProps.value;
    if (!isOriginal) {
      setStartDate(getFormattedDate(value?.from));
      setEndDate(getFormattedDate(value?.to));
    } else {
      setStartDate(getFormattedOriginalDate(value?.from));
      setEndDate(getFormattedOriginalDate(value?.to));
    }
  }, [getFormattedDate, getFormattedOriginalDate, isOriginal, value]);

  const checkIfDisabled = React.useCallback(
    (date) =>
      (noPast && isInPast(date)) ||
      (noFuture && isInFuture(date)) ||
      (!isEmpty(min) && isLowerThan(date, min)) ||
      (!isEmpty(min) && isInPast(date, min)) ||
      (!isEmpty(max) && isHigherThan(date, max)),
    [max, min, noFuture, noPast],
  );

  return (
    <div className={classNames(['from-group', ' DatePickerBlock', error && 'error-message', restProps?.className])}>
      <label className="commonLabel">
        {!isEmpty(title) ? title : 'Select Date'} {restProps?.required && <span className="mandatory">*</span>}
      </label>
      <DatePicker.RangePicker
        showTime={showTime}
        onChange={handleChange}
        className="SelectDateRange"
        value={[startDate, endDate]}
        format={dateFormat || DefaultDateFormat}
        disabledDate={checkIfDisabled}
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
};
export default Datepicker;
