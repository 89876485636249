// import { utils } from '@amcharts/amcharts4/core';

import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

// import ConstantApi, API_ROUTES  from 'constants/CommonApi';
import ConstantApi from 'constants/CommonApi';
import API_ROUTES from 'constants/CommonApi';

export const createTrade = (request = {}) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Trade.create,
    request,
  });
};

export const getTradeList = (request, config) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Trade.getList,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getTradeDetail = (id = null, config) => {
  return UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.Trade.getDetail, [id]),
    ...(!isEmpty(config) && { config }),
  });
};

export const getTradeListPaginate = (request = null, config) => {
  return UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.Trade.getList, [request]),
    ...(!isEmpty(config) && { config }),
  });
};
export const geUserDetails = (request = null, config) => {
  return UtilService.callApiAsync({ ...API_ROUTES.User.paginate, request, ...(!isEmpty(config) && { config }) });
};

// Trade Appointment API

export const addTradeAppointment = (request = {}) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Trade.addTradeAppointment,
    request,
  });
};

export const getTradeAppointment = (request = null, config) => {
  return UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.Trade.getAppointmentList, [request]),
    ...(!isEmpty(config) && { config }),
  });
};

export const deleteTradeAppointment = (id = null, config) => {
  return UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.Trade.deleteAppointment, [id]),
    ...(!isEmpty(config) && { config }),
  });
};

export const viewTradeAppointment = (id = null, config) => {
  return UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.Trade.getAppointment, [id]),
    ...(!isEmpty(config) && { config }),
  });
};

export const updateAppointment = (id = null, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.Trade.updateAppointment, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });
export const updateDetails = (id = null, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.Trade.updateDetails, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const updateAppointmentStatus = (request = {}) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Trade.updateAppointmentStatus,
    request,
  });
};

// Trade Discussion API

export const addTradeDiscussion = (request = {}) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Trade.addTradeDiscussion,
    request,
  });
};

export const getTradeDiscussion = (request = null) => {
  return UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.Trade.paginateTradeDiscussion, [request]),
    // request,
  });
};

// Trade Visitor API
export const createTradeVisitor = (request = {}) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Trade.addTradeVisitor,
    request,
  });
};

export const getListDetails = (request, config) => {
  return UtilService.callApiAsync({ ...API_ROUTES.User.paginate, request, ...(!isEmpty(config) && { config }) });
};

export const destroyVisitorTrade = (id, config) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Trade.deleteTradeVisitor,
    url: API_ROUTES.Trade.deleteTradeVisitor.url + `${id}`,
    ...(!isEmpty(config) && { config }),
  });
};

export const updateVisitorStatus = (id, request) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Trade.updateTradeVisitor,
    url: API_ROUTES.Trade.updateTradeVisitor.url + `${id}`,
    id,
    request,
  });
}; // fetch country list

export const findVisitor = (request = {}) =>
  UtilService.callApiAsync({
    ...ConstantApi.Activity.view,
    url: ConstantApi.Trade.view.url + request,
  });

export const paginateCountryList = (request = {}) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Country.paginate,
    request,
  });
};

export const paginateStateList = (request = {}) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.State.paginate,
    request,
  });
};

export const paginateTradeVisitor = (tradeId, request = {}) => {
  return UtilService.callApiAsync({
    ...API_ROUTES.Trade.paginateTradeVisitor,
    url: API_ROUTES.Trade.paginateTradeVisitor.url + `?referenceId=${tradeId.tradeId}&referenceFrom=TRADE`,
    tradeId,
    request,
  });
};
