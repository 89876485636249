import { getDefaultPermissions } from './_getDefaultPermissions';

export const InventoryPermissions = [
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'OVERVIEW',
    title: 'Overview',
    url: '/inventory/single-stone/overview',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SEARCH',
    title: 'Search',
    url: '/inventory/single-stone/search',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'QUICK_SEARCH',
    title: 'Quick Search',
    url: '/inventory/single-stone/quick-search',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'NEW_ARRIVAL',
    title: 'New Arrival',
    url: '/inventory/single-stone/new-arrival',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'BID_TO_BUY',
    title: 'Bid To Buy',
    url: '/inventory/single-stone/bid-to-buy',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'UPCOMING',
    title: 'Upcoming',
    url: '/inventory/single-stone/upcoming',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SAVED_SEARCH',
    title: 'Saved Search',
    url: '/inventory/single-stone/saved-search',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'MY_DEMAND',
    title: 'My Demand',
    url: '/inventory/single-stone/my-demand',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'GENERALDEMAND',
    title: 'General Demand',
    url: '/inventory/single-stone/general-demand',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'MATCH_PAIR',
    title: 'Match Pair',
    url: '/inventory/single-stone/match-pair',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'SHOW_STONE_REPORT',
    title: 'Show Stone Report',
    url: '/inventory/single-stone/show-stone-report',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'SINGLE_STONE',
    module: 'QUOTE_DAY',
    title: 'Quote Day',
    url: '/inventory/single-stone/quote-day',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'ANALYTICS',
    module: 'ANALYTICS',
    title: 'Analytics',
    url: '/inventory/analytics',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    title: 'Sync With Global Inventory',
    module: 'SYNC_WITH_GLOBAL_INVENTORY',
    url: '/inventory/configuration/global-inventory',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CREATE_COLLECTIONS',
    title: 'Create Collections',
    url: '/inventory/configuration/create-collection',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'TERMS_DETAILS',
    title: 'Terms Details',
    url: '/inventory/configuration/terms-details',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'VOLUME_DISCOUNT',
    title: 'Volume Discount',
    url: '/inventory/configuration/volume-discount',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'FEATURE_STONE',
    title: 'Feature Stone',
    url: '/inventory/configuration/feature-stone',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CARAT_RANGE',
    title: 'Carat Range',
    url: '/inventory/configuration/carat-range',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CUT_GROUP',
    title: 'Cut Group',
    url: '/inventory/configuration/cut-group',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'GLOBAL_POLICY',
    title: 'Global Policy',
    url: '/inventory/configuration/global-policy',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CHECK_IMAGE',
    title: 'Check Image',
    url: '/inventory/configuration/check-image-status',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'BID_CONFIG',
    title: 'Bidconfig',
    url: '/inventory/configuration/big-config',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CERTIFICATE_UPLOAD',
    title: 'Certificate Upload',
    url: '/inventory/configuration/upload-typeIIA-cert',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'DIAMOND_COLUMN_SETTING',
    title: 'Diamond Column Setting',
    url: '/inventory/configuration/diamond-column-setting',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'DOWNLOAD_EXCEL',
    title: 'Download Excel',
    url: '/inventory/configuration/download-Excel',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'QUOTE_DAY',
    title: 'Quote Day',
    url: '/inventory/configuration/quote-day',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'INVENTORY',
    subModule: 'CONFIGURATION',
    module: 'CHECK_PACKET',
    title: 'Check Packet',
    url: '/inventory/configuration/check-packet',
    permissions: getDefaultPermissions(),
  },
];
