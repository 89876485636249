import React from 'react';

import { Select } from 'antd';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import { LiveStatus } from 'components/List/ListItem';

import * as AccountAPI from 'services/AccountApi';

import * as Hooks from 'util/hooks';
import { isArray, isBoolean, isEmpty, isFunction, isString } from 'util/utils';

import editSvg from 'assets/svg/edit.svg';
import resetSvg from 'assets/svg/reset.svg';

function CustomerDropdown({ value, onChange, loadInactive }) {
  const [list, setList] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [showSelected, setShowSelected] = Hooks.useBoolean();
  const [loading, startLoading, stopLoading] = Hooks.useLoading();

  const _init = React.useRef(true);
  const _selectedItem = React.useRef(selectedItem);
  React.useEffect(() => {
    _selectedItem.current = selectedItem;
  }, [selectedItem]);

  const _showSelected = React.useRef(showSelected);
  React.useEffect(() => {
    _showSelected.current = showSelected;
  }, [showSelected]);

  const getAccountList = React.useMemo(
    () =>
      debounce(async (keyword, loadInactive, startLoading, stopLoading) => {
        startLoading();
        const [, res] = await AccountAPI.getAccountsListNew(
          {
            page: 1,
            limit: 15,
            sort: [{ createdAt: 'DESC' }],
            filter: { isActive: !loadInactive },
            startWith: { keyword, keys: ['companyName', 'name', 'firstName', 'lastName', 'mobile', 'email'] },
          },
          { loader: false },
        );

        if (['E_BAD_REQUEST', 'OK'].includes(res?.code)) {
          setList(isArray(res?.data?.list) ? res?.data?.list : []);
        }
        stopLoading();
      }, 270),
    [],
  );

  const getList = React.useCallback(
    (keyword) => {
      setSelectedItem({});
      if (keyword?.length < 3) return setList([]);
      getAccountList(keyword, loadInactive, startLoading, stopLoading);
    },
    [getAccountList, loadInactive, startLoading, stopLoading],
  );

  const onSelectionChange = React.useCallback(
    (id) => {
      const selectedItem = !isEmpty(id) ? list?.find((item) => item?.id === id) : {};
      setSelectedItem(selectedItem);
      if (!isEmpty(selectedItem)) setShowSelected.true();
      if (isFunction(onChange)) onChange(selectedItem);
    },
    [list, onChange, setShowSelected],
  );

  React.useEffect(() => {
    if ((_init.current || !_showSelected.current) && isEmpty(value?.id)) return;
    if (_init.current) _init.current = false;
    // Set initial value from props
    if (_selectedItem?.id !== value?.id) {
      setList([value]); // set in list
      setSelectedItem(value); // saveToState
      setShowSelected.true(); // show selected item
    }
  }, [value, setShowSelected]);

  return (
    <div className="d-flex">
      {showSelected ? (
        <div className="clientDetail d-flex clientDetailDiamonList">
          {(isString(selectedItem?.companyName) || isBoolean(selectedItem?.isActive)) && (
            <div className="listdetailBox listClientName">
              <div className="d-flex align-items-center">
                <span className="listClientNameBox">{selectedItem?.companyName || ''}</span>
                <LiveStatus status={selectedItem.isActive ? 'online' : 'offline'} />
              </div>
            </div>
          )}
          {(isString(selectedItem?.firstName) || isString(selectedItem?.lastName)) && (
            <div className="listdetailBox listUserList">
              <div className="d-flex align-items-center">
                <span>
                  {selectedItem?.firstName} {selectedItem?.lastName}
                </span>
              </div>
            </div>
          )}
          {isString(selectedItem?.mobile) && (
            <div className="listdetailBox listUserList">
              <div className="d-flex align-items-center">
                <span>{selectedItem?.mobile}</span>
              </div>
            </div>
          )}
          {isString(selectedItem?.email) && (
            <div className="listdetailBox listUserList">
              <div className="d-flex align-items-center">
                <span>{selectedItem?.email}</span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Select
          className="commonSearchDropdown"
          value={selectedItem?.id}
          placeholder="Company Name | Name"
          notFoundContent={loading ? 'Searching...' : 'Type Company/Customer name to Search'}
          onChange={onSelectionChange}
          onSearch={getList}
          style={{ width: 300 }}
          filterOption={false}
          allowClear
          autoFocus
          showArrow
          showSearch
        >
          {isArray(list) &&
            list.map((details) => (
              <Select.Option value={details?.id} key={details?.id}>
                {!isEmpty(details?.companyName) && `${details.companyName}`}
                {details?.user?.firstName || details?.user?.lastName
                  ? ` | ${details?.user?.firstName} ${details?.user?.lastName ? details?.user?.lastName : ''}`
                  : ''}
              </Select.Option>
            ))}
        </Select>
      )}
      <div className="userDetailEdit">
        {showSelected && <img src={editSvg} alt="" onClick={setShowSelected.false} />}
        {!showSelected && selectedItem?.id && <img src={resetSvg} alt="" onClick={setShowSelected.true} />}
      </div>
    </div>
  );
}

CustomerDropdown.propTypes = {
  initialValue: PropTypes.object, // initialValue object
  onChange: PropTypes.func, // returns selected list item
  enableReinitialize: PropTypes.bool,
  loadDefault: PropTypes.bool,
  loadInactive: PropTypes.bool,
};

export default CustomerDropdown;
