import React from 'react';

import PropTypes from 'prop-types';

import Compare from 'routes/Compare';

import BottomAction from 'containers/BottomAction';

import { useBoolean } from 'util/hooks';

export function BottomCompareStoneButton({ ...props }) {
  return <BottomAction title="Compare Stone" {...props} />;
}

export function CompareStoneTable({ isOpen, list, handleClose }) {
  return isOpen && <Compare list={list} handleClose={handleClose} />;
}

function BottomCompareStone({ list }) {
  const [isOpen, setIsOpen] = useBoolean(false);

  return (
    <>
      <BottomCompareStoneButton onClick={setIsOpen.true} />
      <CompareStoneTable isOpen={isOpen} list={list} handleClose={setIsOpen.false} />
    </>
  );
}

BottomCompareStone.propTypes = {
  list: PropTypes.array,
};

export default React.memo(BottomCompareStone);
