import React from 'react';

import Image from 'containers/Image';

import classNames from 'util/classNames';
import { isEmpty, isString } from 'util/utils';

import { COMMON_URL } from 'constants/CommonUrl';

import iconPdf from 'assets/images/pdf.png';
import iconVideo from 'assets/images/video.svg';
import iconExcel from 'assets/svg/Document/excel.svg';
import iconClose from 'assets/svg/close-color.svg';

import './fileUploaded.less';

function FileUploaded(props) {
  const filePath = React.useMemo(() => {
    const path = props?.file?.absolutePath || props?.file;
    return isString(path) ? path : '';
  }, [props?.file]);

  const fileName = React.useMemo(() => (isEmpty(filePath) ? '' : filePath.split('/').pop()), [filePath]);

  const fileExtension = React.useMemo(() => fileName.split('.').pop(), [fileName]);

  const fileIcon = React.useMemo(() => {
    return ['pdf', 'PDF'].includes(`${fileExtension}`.toLowerCase())
      ? iconPdf
      : ['xls', 'XLS', 'xlsx', 'XLSX'].includes(`${fileExtension}`.toLowerCase())
      ? iconExcel
      : ['mkv', 'mp4', 'wmv'].includes(`${fileExtension}`.toLowerCase())
      ? iconVideo
      : `${COMMON_URL.BASE_URL}${filePath}`;
  }, [filePath, fileExtension]);

  return (
    <div className={classNames(['fileUploadedBlockItem', props?.size])}>
      <div className="fileUplodImg">
        <Image src={fileIcon} />
      </div>
      <div className="fileUplodContent">
        <p>{fileName}</p>
        {!props?.noDelete && (
          <div className="deleteIcon" onClick={props?.onDelete}>
            <img src={iconClose} alt="Remove file" />
          </div>
        )}
      </div>
    </div>
  );
}
export default FileUploaded;
