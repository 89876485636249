import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import API_ROUTES from 'constants/CommonApi';

/**
 * Fucntion used to get user list
 * @param {*} request
 */
export const getUserList = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.paginate, request, ...(!isEmpty(config) && { config }) });

export const getVisitorsList = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Visitor.paginate, request, ...(!isEmpty(config) && { config }) });

export const downloadUserExcel = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.downloadExcel, request, ...(!isEmpty(config) && { config }) });

/**
 * Function used to get user details
 * @param {*} id
 */
export const getUserDetails = (id = null, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.User.getDetail, [id]),
    ...(!isEmpty(config) && { config }),
  });

/**
 * Function used to update user details
 * @param {*} id
 * @param {*} request
 */
export const updateUserDetail = (id = null, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(API_ROUTES.User.update, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

/**
 *  Function used to save user details
 * @param {*} request
 */
export const storeUserDetail = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.Upsert, request, ...(!isEmpty(config) && { config }) });

/**
 *  Function used to save user details
 * @param {*} request
 */
export const createMany = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.createMany, request, ...(!isEmpty(config) && { config }) });

/**
 * Fucntion used to change password
 * @param {*} request
 */
export const resetPassword = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.User.ResetPassword, request, ...(!isEmpty(config) && { config }) });
