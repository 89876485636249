import React, { Component } from 'react';

import CircularProgress from 'components/CircularProgress';

import { createShowSelection } from 'services/CommonApi';

import { isEmpty } from 'util/utils';

import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

import QuoteDayComponent from './PopupComponent/QuoteDayComponent';

import { SHOW_SELECTION } from '../../constants/Common';
import CommonModal from '../../containers/CommonModal';

class QuoteDayPopup extends Component {
  state = {
    quoteDay: null,
    error: {},
    loader: false,
    checked: [],
  };
  onSaveQuote = async () => {
    const { quoteDay, error } = this.state;
    if (quoteDay === null) {
      error.quoteDay = Messages.quoteDaySelect;
      this.setState({ error });
    }
    if (isEmpty(error)) {
      const request = { quoteDay, vStnIds: this.props.checked.map((d) => d.vStnId), type: SHOW_SELECTION.QUOTE_DAY };
      this.setState({ loader: true });
      const [, res] = await createShowSelection(request);
      if (res.code === 'OK') {
        this.setState({ loader: false });
        this.props.handleCancel();
        // this.props.unCheckedDiamond();
        OpenNotification({ type: 'success', title: res.message });
      } else {
        this.props.handleCancel();
        this.setState({ loader: false });
      }
    }
  };

  render() {
    return (
      <>
        <CommonModal
          modalSize="sm-size"
          visible={true}
          handleOk={this.onSaveQuote}
          handleCancel={this.props.handleCancel}
          title="Add to Quote Day"
          footerShow
          submitTitle="Submit"
          cancelTitle="Cancel"
        >
          <div className="searchPopupCommon">
            <div className="">
              {this.props.quoteDay && (
                <QuoteDayComponent
                  removeError={() => {
                    delete this.state.error['quoteDay'];
                  }}
                  error={this.state.error}
                  setQuoteDetail={(data) => {
                    this.setState({ ...data });
                  }}
                />
              )}
            </div>
          </div>
          {this.state.loader && <CircularProgress className="fixLoader" />}
        </CommonModal>
      </>
    );
  }
}

export default QuoteDayPopup;
