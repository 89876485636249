import { getDefaultPermissions } from './_getDefaultPermissions';

export const DemandMainPermissions = [
  {
    mainModule: 'DEMANDMAIN',
    subModule: 'SUPPLIERDEMAND',
    module: 'GENERALDEMAND',
    title: 'General Demand',
    url: '/supplier/general-demand',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'DEMANDMAIN',
    subModule: 'SUPPLIERDEMAND',
    module: 'DIAMONDDEMAND',
    title: 'Diamond Demand',
    url: '/supplier/diamond-demand',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'DEMANDMAIN',
    subModule: 'CUSTOMER',
    module: 'GENERALDEMAND',
    title: 'General Demand',
    url: '/customer/general-demand',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'DEMANDMAIN',
    module: 'SUPPLIER',
    title: 'Supplier',
    url: '/supplier-demand',
    permissions: getDefaultPermissions(),
  },
];
