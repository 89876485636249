import React from 'react';

import { Select } from 'antd';
import PropTypes from 'prop-types';

import { LIMIT } from 'components/Inventory/TableBack';
import InlineList from 'components/List/InlineList';

import * as AccountAPI from 'services/AccountApi';

import classNames from 'util/classNames';
import * as hooks from 'util/hooks';
import { isArray, isEmpty, isFunction } from 'util/utils';

function AccountDropdown(props) {
  const { className, initialValue, value, required, label, error, hideUser, ledgerType, onChange, ...rest } = props;

  const [list, setList] = React.useState([]);
  const [loading, _setLoading] = React.useState(0);
  const [selectedItem, setSelectedItem] = React.useState({});

  const startLoading = () => _setLoading((n) => ++n);
  const stopLoading = () => _setLoading((n) => --n);

  const listRef = React.useRef(list);
  const selectedItemRef = React.useRef(selectedItem);

  React.useEffect(() => {
    listRef.current = list;
    selectedItemRef.current = selectedItem;
  });

  hooks.useEffectOnMount(() => {
    // Set initial `value` from props
    if (!isEmpty(initialValue?.id) && selectedItem?.id !== initialValue?.id) {
      setSelectedItem({ ...initialValue }); // saveToState
      setList([initialValue]); // set in list

      if (isFunction(onChange)) onChange({ ...initialValue });
    }
  });

  const getList = async (keyword) => {
    setSelectedItem({});
    if (keyword?.length < 3) return setList([]);

    startLoading();
    const [, res] = await AccountAPI.getAccountsList(
      {
        page: 1,
        limit: LIMIT,
        sort: [{ createdAt: 'DESC' }],
        startWith: { keyword, keys: ['companyName', 'name', 'firstName', 'lastName', 'mobile', 'email'] },
        ...(!isEmpty(ledgerType) && { ledgerType }),
      },
      { loader: false },
    );

    if (res?.code === 'OK') setList(isArray(res?.data?.list) ? res?.data?.list : []);
    stopLoading();
  };

  const onSelectionChange = (id) => {
    const selectedItem = !isEmpty(id) ? listRef?.current?.filter((item) => item?.id === id)?.[0] : null;
    setSelectedItem({ ...selectedItem });

    if (isFunction(onChange)) onChange(selectedItem);
  };

  return (
    <div className={classNames([`formSelect from-group`, className, error && ' error-message'])}>
      <label>
        {isEmpty(label) ? 'Company Name' : label}
        {required && <span className="required-ast">*</span>}
      </label>
      <Select
        defaultValue={undefined}
        value={list?.filter((item) => item.id === selectedItem?.id).length ? selectedItem?.id : undefined}
        placeholder={!isEmpty(rest?.placeholder) ? rest.placeholder : isEmpty(label) ? 'Select Company Name' : label}
        notFoundContent={loading ? 'Searching...' : 'Type Client/Company name to Search'}
        onChange={onSelectionChange}
        onSearch={getList}
        filterOption={false}
        allowClear
        showArrow
        showSearch
        {...rest}
      >
        {!isEmpty(list) &&
          list.map((details) => (
            <Select.Option value={details?.id} key={details?.id}>
              <InlineList
                separator="&nbsp;|&nbsp;"
                skipEmpty
                list={[
                  details?.companyName,
                  ...(!hideUser
                    ? [
                        details?.user?.firstName || details?.user?.lastName
                          ? `${details?.user?.firstName} ${details?.user?.lastName ? details?.user?.lastName : ''}`
                          : undefined,
                      ]
                    : []),
                ].filter((item) => !isEmpty(item))}
              />
            </Select.Option>
          ))}
      </Select>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

AccountDropdown.propTypes = {
  onChange: PropTypes.func, // returns selected list item
  initialValue: PropTypes.object, // initialValue object
};

export default AccountDropdown;
