import React, { useState, useEffect } from 'react';

import { Checkbox } from 'antd';

import FileUpload from 'containers/FileUpload';
import InputBlock from 'containers/InputBlock';
import SelectOption from 'containers/SelectOption';
import TextArea from 'containers/TextArea';

import { showNotification } from 'services/NotificationService';
import { storeMasterDetail, updateMasterDetail } from 'services/SubMasterApi';

import { isEmpty } from 'util/utils';

export const groupData = [
  { value: 'Round', key: 'Round' },
  { value: 'Princess', key: 'Princess' },
  { value: 'Emerald', key: 'Emerald' },
  { value: 'Sq. Emerald', key: 'Sq. Emerald' },
];

const Form = (props) => {
  let submitAction = 'save';
  const [state, setState] = useState({
    formData: {
      isActive: true,
      isWebVisible: true,
    },
    errors: {},
  });

  useEffect(() => {
    setState({ ...state.formData, formData: props.detail });
  }, [props.detail?.id]);

  const handleChange = (name, value) => {
    if (name === 'likeKeyWords') {
      value = value ? value.split(',') : [];
    }
    setState({ formData: { ...state.formData, [name]: value } });
  };

  const handleValidation = () => {
    let formIsValid = true;
    const errors = {};
    const data = { ...state.formData };
    if (!data['name']) {
      formIsValid = false;
      errors['name'] = 'Please enter name.';
    }
    if (!data['code']) {
      formIsValid = false;
      errors['code'] = 'Please enter code.';
    }

    setState({ ...state, errors });
    return formIsValid;
  };

  const formRef = React.createRef();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { ...state.formData };
    data.parentId = props.parentId;
    data.group = data.group || '';

    if (handleValidation()) {
      if (isEmpty(data.sortingSequence)) {
        delete data?.sortingSequence;
      }
      if (!props?.detail?.id) {
        const [, res] = await storeMasterDetail(data);
        showNotification(res);
      } else {
        data.updateId = props?.detail?.id;
        const [, res] = await updateMasterDetail(data);
        showNotification(res);
      }

      if (submitAction === 'continue') {
        if (isEmpty(props?.detail?.id)) {
          formRef.current.reset();
          setState({
            formData: {
              isActive: true,
              isWebVisible: true,
              isDefault: false,
            },
          });
        }
      } else {
        props.onClick();
      }
    }
  };

  const { errors } = state;

  return (
    <div className="masterWrapper">
      <form onSubmit={handleSubmit} ref={formRef}>
        <div className="masterCol">
          <InputBlock
            label="Name *"
            placeholder="Name"
            name="name"
            value={state?.formData?.name}
            onChange={(event) => {
              handleChange('name', event.target.value.replace(/[^\w\s\][_-]/gi, ''));
            }}
            error={errors?.name?.length > 0 && errors?.name}
          />

          <InputBlock
            label="Code *"
            placeholder="Code"
            name="code"
            value={state?.formData?.code}
            onChange={(event) => {
              handleChange('code', event.target?.value?.toUpperCase());
            }}
            error={errors?.code?.length > 0 && errors?.code}
            disabled={props?.detail?.id ? true : false}
          />

          <SelectOption
            selectValue={groupData}
            label="Group"
            placeholder="Select Group"
            name="group"
            value={state.formData && state.formData.group ? state.formData.group : 'Select Group'}
            onChange={(value) => {
              handleChange('group', value);
            }}
          />

          <TextArea
            label="Like Keywords"
            rows="3"
            placeholder="Like Keywords"
            name="likeKeyWords"
            value={state?.formData?.likeKeyWords}
            onChange={(event) => {
              handleChange('likeKeyWords', event.target.value);
            }}
          />

          <TextArea
            label="Description"
            placeholder="Description"
            name="description"
            value={state?.formData?.description}
            onChange={(event) => {
              handleChange('description', event.target.value);
            }}
          />

          <div className="from-group">
            <InputBlock
              label="Sequence"
              placeholder="Sequence"
              className="width-100"
              name="sortingSequence"
              value={state?.formData?.sortingSequence}
              type="number"
              onKeyPress={(evt) => {
                if (evt.which < 48 || evt.which > 57) {
                  evt.preventDefault();
                }
              }}
              onChange={(event) => {
                handleChange('sortingSequence', event.target.value >= 0 && event.target.value);
              }}
            />
            <div className="d-flex">
              <div className="checkBoxForm">
                <label>Active</label>
                <Checkbox
                  checked={state?.formData?.isActive}
                  name="isActive"
                  onChange={(event) => {
                    const formData = state.formData;
                    if (!event.target.checked) {
                      formData.isDefault = false;
                      setState({ formData: formData });
                    }
                    handleChange('isActive', event.target.checked);
                  }}
                />
              </div>
              <div className="checkBoxForm">
                <label>Display</label>
                <Checkbox
                  checked={state?.formData?.isWebVisible}
                  name="isWebVisible"
                  onChange={(event) => {
                    handleChange('isWebVisible', event.target.checked);
                  }}
                />
              </div>
              <div className="checkBoxForm">
                <label>Default</label>
                <Checkbox
                  checked={state?.formData?.isDefault}
                  name="isDefault"
                  onChange={(event) => {
                    handleChange('isDefault', state.formData?.isActive && event.target.checked);
                  }}
                />
              </div>
            </div>
          </div>

          <FileUpload
            files={[state?.formData?.image]}
            label="Choose File"
            onChange={(e) => handleChange('image', e || '')}
          />
        </div>
        <div className="configActionButton ml-20">
          {!props?.detail?.id && (
            <button className="commonButton" type="submit" onClick={() => (submitAction = 'continue')}>
              Save & Continue
            </button>
          )}
          <button className="commonButton" type="submit" onClick={() => (submitAction = 'save')}>
            Save
          </button>
          <button className="commonOutine" type="button" onClick={props.onClick}>
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
