import React, { useState } from 'react';

import CommonModal from 'containers/CommonModal';

import CircularProgress from 'components/CircularProgress';

import { downloadZip } from 'services/DiamondApi';

import './action.less';

import { isFunction } from 'util/utils';

const DownloadAction = (props) => {
  const [isCert, setIsCert] = useState(true);
  const [isImg, setIsImg] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isPlt, setIsPlt] = useState(false);
  const [isProp, setIsProp] = useState(false);
  // const [isFront, setIsFront] = useState(false);
  // const [isSide, setIsSide] = useState(false);
  // const [isHa, setIsHa] = useState(false);
  // const [isAsset, setIsAsset] = useState(false);
  // const [isLightImg, setIsLightImg] = useState(false);
  const [flag, setFlag] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isExcel, setIsExcel] = useState(false);

  const handleDownloadClick = () => {
    setLoader(true);
    let ids = props.checked.map((item) => item.vStnId);
    ids = ids.length ? ids : props.data.map((item) => item.vStnId);

    const obj = {
      filter: {
        vStnId: ids,
        isFcCol: false,
      },
      isExcel,
      img: isImg,
      certFile: isCert,
      mp4Video: isVideo,
      pltFile: isPlt,
      sideFile: false,
      // sideFile: isSide,
      frontFile: false,
      // frontFile: isFront,
      prpFile: isProp,
      zip: flag >= 1 || ids.length > 1,
    };

    if (!props.checked.length && props.seachFilterTag && props.seachFilterTag.searchData) {
      obj.filter = props.seachFilterTag.searchData;
    }
    if (props.tabPrintExcel === 'inOrder') {
      obj.orderDiamond = obj.filter;
      delete obj.filter;
    }

    downloadZip(obj, () => {
      setLoader(false);
      props.onClose();
      if (isFunction(props.unCheckedDiamond)) props.unCheckedDiamond();
    });
  };

  const DownloadOption = (props) => {
    return (
      <li
        onClick={() => {
          // handling one or more CHECKED TOGGLE
          if (props.id === true && flag > 0) {
            props.onChange(!props.id);
            setFlag(flag - 1);
          } else if (props.id === false) {
            props.onChange(!props.id);
            setFlag(flag + 1);
          }
        }}
        className={props.activeClass}
      >
        <div className="dropdownBottomItem">
          <span className="shareOptionLabel">{props.title}</span>
        </div>
      </li>
    );
  };

  return (
    <>
      <CommonModal className="md-size" title="Download" handleCancel={props.onClose} visible>
        <div>
          <div className="searchPopupCommon">
            <div className="shareOptionSet d-flex">
              <div className="shareOptionSetItem">
                <ul>
                  <DownloadOption
                    id={isCert}
                    onChange={setIsCert}
                    activeClass={isCert === true ? 'active' : ''}
                    title="Certificate"
                  />
                  {props.newArrivalExcel && (
                    <DownloadOption
                      id={isExcel}
                      onChange={setIsExcel}
                      activeClass={isExcel === true ? 'active' : ''}
                      title="Excel"
                    />
                  )}
                  <DownloadOption
                    id={isImg}
                    onChange={setIsImg}
                    activeClass={isImg === true ? 'active' : ''}
                    title="Main Image"
                  />
                  <DownloadOption
                    id={isVideo}
                    onChange={setIsVideo}
                    activeClass={isVideo === true ? 'active' : ''}
                    title="Video"
                  />
                  <DownloadOption
                    id={isPlt}
                    onChange={setIsPlt}
                    activeClass={isPlt === true ? 'active' : ''}
                    title="Plot"
                  />
                  <DownloadOption
                    id={isProp}
                    onChange={setIsProp}
                    activeClass={isProp === true ? 'active' : ''}
                    title="Prop"
                  />
                  {/* <DownloadOption
                    id={isFront}
                    onChange={setIsFront}
                    activeClass={isFront === true ? 'active' : ''}
                    title="Front"
                  />
                  <DownloadOption
                    id={isSide}
                    onChange={setIsSide}
                    activeClass={isSide === true ? 'active' : ''}
                    title="Side"
                  /> */}
                </ul>
              </div>
            </div>
            <div className="commonModalButton mt-20">
              <button className="fillButton" onClick={() => handleDownloadClick()}>
                Download
              </button>
              <button onClick={() => props.onClose()} className="outLineButton ml-5">
                Cancel
              </button>
            </div>
          </div>
        </div>
        {loader && <CircularProgress className="fixLoader" />}
      </CommonModal>
    </>
  );
};
export default DownloadAction;
