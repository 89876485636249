import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';

import { useCompositeState } from 'util/hooks';

import { FILE_URLS } from 'constants/Common';

import IFrame from './IFrame';
import MagnifierImage from './MagnifierImage';

import videoSvg from '../../assets/svg/diamondDetail/video.svg';

const DiamondDetailLeft = ({ vStnId, rptNo, setDownload }) => {
  // const [activeTab, setActiveTab] = useState('1');
  const Resource = React.useMemo(
    () => ({
      video: FILE_URLS.videoFile.replace('***', vStnId),
      mainImage: FILE_URLS.img.replace('***', vStnId),
      sideImage: FILE_URLS.side.replace('***', vStnId),
      frontImage: FILE_URLS.front.replace('***', vStnId),
      plotCerti: FILE_URLS.plotCerti.replace('***', rptNo),
      propCerti: FILE_URLS.propCerti.replace('***', rptNo),
      certFile: FILE_URLS.certFile.replace('***', rptNo),
    }),
    [vStnId, rptNo],
  );
  const [state, setState] = useCompositeState({
    mainImage: true,
    sideImage: true,
    frontImage: true,
    plotCerti: true,
    propCerti: true,
    certFile: true,

    slideCount: 6,
  });

  //FIXME:To load magnifierState state after loading image
  const [magnifierState, setMagnifierState] = useCompositeState({
    mainImage: false,
    sideImage: false,
    frontImage: false,
    plotCerti: false,
    propCerti: false,
    certFile: false,
  });

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);
  const setting = [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        // infinite:true,
        vertical: false,
      },
    },
  ];
  //Don't change this sequence

  const downloadOptionSet = React.useCallback(
    (index) => {
      const sequenceDownload = {
        mp4Video: true,
        img: state.mainImage,
        frontFile: state.frontImage,
        sideFile: state.sideImage,
        certFile: state.certFile,
        pltFile: state.plotCerti,
        prpFile: state.propCerti,
      };
      const sequenceDownloadFilter = Object.keys(sequenceDownload).filter(
        (key) => sequenceDownload[key] && sequenceDownload[key],
      );

      setDownload({ [sequenceDownloadFilter[index]]: true });
    },
    [setDownload, state],
  );

  return (
    <>
      <Slider
        vertical
        infinite={false}
        asNavFor={nav2}
        ref={slider1}
        slidesToShow={6}
        swipeToSlide
        focusOnSelect
        responsive={setting}
        className="smallSliderBlock"
      >
        <div className="smallSliderImage htmlVideo">
          <img src={videoSvg} alt="" />
        </div>

        {state.mainImage ? (
          <div className="smallSliderImage">
            <img
              onError={() => setState({ mainImage: false })}
              src={Resource.mainImage}
              alt="Main Image"
              onLoad={() => {
                setMagnifierState({ mainImage: true });
              }}
            />
          </div>
        ) : null}
        {state.frontImage ? (
          <div className="smallSliderImage">
            <img
              onError={() => setState({ frontImage: false })}
              src={Resource.frontImage}
              alt="Main Image"
              onLoad={() => {
                setMagnifierState({ frontImage: true });
              }}
            />
          </div>
        ) : null}
        {state.sideImage ? (
          <div className="smallSliderImage">
            <img
              onError={() => setState({ sideImage: false })}
              src={Resource.sideImage}
              alt="Side Image"
              onLoad={() => {
                setMagnifierState({ sideImage: true });
              }}
            />
          </div>
        ) : null}
        {state.certFile && (
          <div className="smallSliderImage">
            <img
              onError={() => setState({ certFile: false })}
              src={Resource.certFile}
              alt="Side Image"
              onLoad={() => {
                setMagnifierState({ certFile: true });
              }}
            />
          </div>
        )}
        {state.propCerti && (
          <div className="smallSliderImage">
            <img
              onError={() => setState({ propCerti: false })}
              src={Resource.propCerti}
              alt="Side Image"
              onLoad={() => {
                setMagnifierState({ propCerti: true });
              }}
            />
          </div>
        )}
        {state.plotCerti && (
          <div className="smallSliderImage">
            <img
              onError={() => setState({ plotCerti: false })}
              src={Resource.plotCerti}
              alt="Side Image"
              onLoad={() => {
                setMagnifierState({ plotCerti: true });
              }}
            />
          </div>
        )}
      </Slider>
      <Slider
        infinite={false}
        className="leftBigImage"
        asNavFor={nav1}
        ref={slider2}
        arrows={false}
        swipeToSlide
        beforeChange={(old, newI) => {
          downloadOptionSet(newI);
        }}
      >
        <div className="diamondDetailImageBox htmlViewImage">
          <IFrame src={Resource.video} />
        </div>
        {['mainImage', 'frontImage', 'sideImage', 'certFile', 'propCerti', 'plotCerti'].map(
          (key, i) =>
            magnifierState[key] && (
              <div key={i} className="diamondDetailImageBox">
                <MagnifierImage src={Resource[key]} />
              </div>
            ),
        )}
      </Slider>
    </>
  );
};

export default DiamondDetailLeft;
