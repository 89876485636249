import React from 'react';

import { Checkbox } from 'antd';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import FileUploadFormik from 'containers/FileUpload/FileUploadFormik';
import InputBlock from 'containers/InputBlock';
import SelectOption from 'containers/SelectOption';
import TextArea from 'containers/TextArea';

import { showNotification } from 'services/NotificationService';
import { storeMasterDetail, updateMasterDetail } from 'services/SubMasterApi';

import { isEmpty, isString } from 'util/utils';

const groupData = [
  { value: 'Round', key: 'Round' },
  { value: 'Princess', key: 'Princess' },
  { value: 'Emerald', key: 'Emerald' },
  { value: 'Sq. Emerald', key: 'Sq. Emerald' },
];

const userGroupData = [
  { value: 'ADMIN', key: 'ADMIN' },
  { value: 'CUSTOMER', key: 'CUSTOMER' },
  { value: 'SELLER', key: 'SELLER' },
];

const defaultValues = {
  name: '',
  code: '',
  webDisplay: '',
  group: undefined,
  sortingSequence: '',
  isActive: true,
  isWebVisible: true,
  isDefault: false,
  likeKeyWords: '',
  description: '',
  image: '',
};
class SubMasterForm extends React.Component {
  state = {
    saveContinue: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.detail && nextProps.detail?.id !== prevState.formData?.id) {
      return { formData: { ...prevState.formData, ...nextProps.detail } };
    }
    return true;
  }

  get initialValues() {
    const initialValues = { ...defaultValues };
    const { detail } = this.props;
    const values = Object.fromEntries(
      Object.entries(initialValues).map(([key, value]) => [key, isEmpty(detail?.[key]) ? value : detail?.[key]]),
    );
    if (isString(values?.image)) values.image = [{ absolutePath: values?.image }];

    return values;
  }

  submitForm = async (values, { setSubmitting, resetForm }) => {
    const { detail } = this.props;
    const { saveContinue } = this.state;

    values.parentId = this.props.parentId;
    values.parentCode = this.props.parentCode;
    values.group = values.group || '';
    const imageObject = values.image;
    values.image = values.image?.[0]?.absolutePath;

    if (isEmpty(values.sortingSequence)) delete values?.sortingSequence;
    if (!isEmpty(values.likeKeyWords) && isString(values.likeKeyWords))
      values.likeKeyWords = values?.likeKeyWords.split(',') ?? [];

    setSubmitting(true);
    const [err, res] = await (!isEmpty(detail?.id)
      ? updateMasterDetail({ ...detail, ...values, updateId: detail?.id })
      : storeMasterDetail(values));
    setSubmitting(false);

    !err && showNotification(res);
    if (res?.code === 'OK') {
      if (!err && isEmpty(detail?.id)) resetForm();
      if (!err && !saveContinue) this.props.onClick();
    } else values.image = imageObject;
  };

  render() {
    const { detail } = this.props;
    return (
      <div className="masterWrapper">
        <Formik
          initialValues={this.initialValues}
          validateOnChangedsd
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Name is required').typeError('Name must be a string'),
            code: Yup.string().required('Code is required').typeError('Code must be a string'),
            webDisplay: Yup.string().required('Display name is required').typeError('Display name must be a string'),
          })}
          onSubmit={this.submitForm}
        >
          {({ handleBlur, setFieldValue, setFieldTouched, submitForm, setValues, touched, errors, values }) => (
            <Form>
              <div className="masterCol">
                <InputBlock
                  label="Name *"
                  placeholder="Name"
                  name="name"
                  value={values?.name}
                  onChange={(event) => setFieldValue('name', event?.target?.value.replace(/[^\w\s\][_-]/gi, ''))}
                  onBlur={handleBlur}
                  error={touched?.name && errors?.name}
                />

                <InputBlock
                  label="Code *"
                  placeholder="Code"
                  name="code"
                  value={values?.code}
                  onChange={(event) => setFieldValue('code', event?.target?.value?.toUpperCase())}
                  onBlur={handleBlur}
                  error={touched?.code && errors?.code}
                  disabled={this.props?.detail?.id ? true : false}
                />

                <InputBlock
                  label="Web Display *"
                  placeholder="Web Display"
                  name="webDisplay"
                  value={values?.webDisplay}
                  onChange={(event) => setFieldValue('webDisplay', event?.target?.value)}
                  onBlur={handleBlur}
                  error={touched?.webDisplay && errors?.webDisplay}
                />

                <SelectOption
                  selectValue={this.props.parentCode !== 'USER_ROLES' ? groupData : userGroupData}
                  label="Group"
                  placeholder="Select Group"
                  name="group"
                  value={values?.group}
                  onChange={(value) => setFieldValue('group', value)}
                  onBlur={() => setFieldTouched('group')}
                  error={touched?.group && errors?.group}
                />

                <div className="from-group">
                  <InputBlock
                    label="Sequence"
                    placeholder="Sequence"
                    className="width-100"
                    name="sortingSequence"
                    onKeyPress={(evt) => {
                      if (evt.which < 48 || evt.which > 57) evt.preventDefault();
                    }}
                    type="number"
                    value={values?.sortingSequence}
                    onChange={(event) => setFieldValue('sortingSequence', event?.target?.value)}
                    onBlur={handleBlur}
                    error={touched?.sortingSequence && errors?.sortingSequence}
                  />
                </div>
                <div className="from-group">
                  <div className="d-flex">
                    <div className="checkBoxForm">
                      <label>Active</label>
                      <Checkbox
                        name="isActive"
                        checked={values?.isActive}
                        onChange={(event) => {
                          setFieldValue('isActive', event.target.checked);
                          if (!event.target.checked) setFieldValue('isDefault', false);
                        }}
                        onBlur={handleBlur}
                        error={touched?.isActive && errors?.isActive}
                      />
                    </div>
                    <div className="checkBoxForm">
                      <label>Display</label>
                      <Checkbox
                        name="isWebVisible"
                        checked={values?.isWebVisible}
                        onChange={(event) => {
                          setFieldValue('isWebVisible', event.target.checked);
                        }}
                        onBlur={handleBlur}
                        error={touched?.isWebVisible && errors?.isWebVisible}
                      />
                    </div>
                    <div className="checkBoxForm">
                      <label>Default</label>
                      <Checkbox
                        name="isDefault"
                        checked={values?.isDefault}
                        onChange={(event) => {
                          setFieldValue('isDefault', values?.isActive && event.target.checked);
                        }}
                        onBlur={handleBlur}
                        error={touched?.isDefault && errors?.isDefault}
                      />
                    </div>
                  </div>
                </div>
                <TextArea
                  label="Like Keywords"
                  placeholder="Like Keywords"
                  name="likeKeyWords"
                  value={values?.likeKeyWords}
                  onChange={(event) => setFieldValue('likeKeyWords', event?.target?.value)}
                  onBlur={handleBlur}
                  error={touched?.likeKeyWords && errors?.likeKeyWords}
                />
                <TextArea
                  label="Description"
                  placeholder="Description"
                  name="description"
                  value={values?.description}
                  onChange={(event) => setFieldValue('description', event?.target?.value)}
                  onBlur={handleBlur}
                  error={touched?.description && errors?.description}
                />
              </div>
              <div className="row ml-20 d-flex from-group">
                <FileUploadFormik
                  value={values?.image}
                  label="Choose File"
                  name="image"
                  onChange={(image) => setValues((values) => ({ ...values, image }))}
                  onBlur={handleBlur}
                  isValidate
                  accept="JPG,JPEG,PNG,GIF,SVG"
                  size="JPG,JPEG,PNG,GIF,SVG file allowed"
                  error={touched?.image && errors?.image}
                />
              </div>
              <div className="configActionButton ml-20">
                {isEmpty(detail?.id) && (
                  <button
                    className="commonButton"
                    type="button"
                    onClick={() => this.setState({ saveContinue: true }, submitForm)}
                  >
                    Save &amp; Continue
                  </button>
                )}
                <button
                  className="commonButton"
                  type="button"
                  onClick={() => this.setState({ saveContinue: false }, submitForm)}
                >
                  {isEmpty(detail?.id) ? 'Save' : 'Update'}
                </button>
                <button className="commonOutine" type="reset" onClick={this.props.onClick}>
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default SubMasterForm;
