import { Notify } from 'util/notify';
import { isFunction, isObject, isString } from 'util/utils';

/**
 * Notification
 *
 * @param {Array} response response
 * @param {Object} args antd notification args (overrides defaults)
 */
export const showNotification = (response, args = {}) => {
  const { type, ...options } = isObject(args) ? args : {};

  const hasError = response?.code !== 'OK';
  const notifyType = isString(type) && isFunction(Notify?.[type]) ? type : hasError ? 'error' : 'success';
  const message = response?.data?.message ?? response?.message ?? 'Something went wrong';

  return Notify[notifyType]({ message, ...options });
};
