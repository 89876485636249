import React from 'react';

import PropTypes from 'prop-types';

import FileUploaded from 'containers/FileUploaded';

import UtilService from 'services/util';

import classNames from 'util/classNames';
import { useLoading } from 'util/hooks';
import { isArray, isEmpty, isString } from 'util/utils';

import CommonApi from 'constants/CommonApi';
import OpenNotification from 'constants/OpenNotifications';

import fileuploadSvg from './fileupload.svg';

import uploadSvg from '../../assets/svg/upload.svg';

import './fileUpload.less';

export const MIME_TYPES = {
  'image/png': ['89504e47'],
  'image/jpeg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb', 'ffd8ffe2'],
  'image/gif': ['47494638'],
  'image/jpg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb'],
  'application/pdf': ['25504446'],
  'text/csv': ['73646673'],
  'application/vnd.ms-excel': ['504b0304'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['504b0304'],
  'video/x-matroska': ['1a45dfa3'],
  'video/mp4': ['00000020', '0000001c'],
  'video/x-ms-wmv': ['3026b275'],
};

function FileUploadFormik({ label, error, folder = 'image', noPreview, value = [], onChange, ...props }) {
  const [storedFromProps, setStoredFromProps] = React.useState(false);
  const [loading, startLoading, stopLoading] = useLoading(false);

  const allowedTypes = React.useMemo(() => {
    return isString(props?.accept) ? props.accept.split(',').map((str) => `${str}`.trim().toLowerCase()) : undefined;
  }, [props.accept]);

  const typeErrorMessage = React.useMemo(
    () =>
      `File format is not supported. Please upload ${
        isArray(allowedTypes) || isString(allowedTypes) ? allowedTypes.join(', ') : '.jpg, .jpeg, .png or .pdf'
      } file.`,
    [allowedTypes],
  );

  const uploadDocument = React.useCallback(
    (file) => {
      const fileReader = new FileReader();
      fileReader.onloadend = function (ev) {
        const arr = new Uint8Array(ev.target.result).subarray(0, 4);
        let header = '';
        let headerVal = '';
        arr.forEach((value, index) => {
          headerVal = arr[index];
          if (arr[index].constructor.name === 'Number' && arr[index] < 10) {
            headerVal = ('0' + arr[index]).slice(-2);
          }
          header += headerVal.toString(16);
        });

        if (!MIME_TYPES?.[file?.type] || (MIME_TYPES?.[file?.type] && !MIME_TYPES?.[file?.type].includes(header))) {
          return OpenNotification({ type: 'error', title: typeErrorMessage });
        }

        const request = new FormData();
        request.append('folder', folder);
        request.append('destination', folder);
        request.append('file', file);

        startLoading();
        UtilService.callApi({ ...CommonApi.File.Upload, request }, (err, res) => {
          if (res && res.code === 'OK') {
            if (isEmpty(res?.data?.files?.[0])) return;
            if (!storedFromProps) setStoredFromProps(true);
            props?.multiple
              ? onChange((files) => [...files, res?.data?.files?.[0]])
              : onChange([isEmpty(res?.data?.files?.[0]) ? '' : res?.data?.files?.[0]]);
          }
          stopLoading();
        });
      };
      fileReader.readAsArrayBuffer(file);
    },
    [folder, onChange, props?.multiple, startLoading, stopLoading, storedFromProps, typeErrorMessage],
  );

  const removeFile = React.useCallback(
    (index) => {
      if (!isArray(value)) return; // Return if not array
      if (index < 0 || index >= value.length) return; // return if index is invalid
      // remove from index
      value.splice(index, 1);
      // setState
      props?.multiple ? onChange([...value]) : onChange([]);
    },
    [value, props?.multiple, onChange],
  );

  const handleChange = React.useCallback(
    (ev) => {
      // Copy FileList to an Array
      const fileList = [...ev.target?.files];

      // Clear file input
      if (ev?.target?.value) ev.target.value = '';

      // Validate extension if specified
      if (props?.isValidate) {
        const validate = fileList.filter((file) => {
          const fileExtension = `${file.name}`.split('.').pop().toLowerCase();

          return !props?.accept
            ?.split(',')
            ?.map((extVal) => `${extVal}`.trim().toLowerCase())
            ?.toString()
            ?.includes(fileExtension);
        });
        // Notify and Return if invalid
        if (validate?.length) {
          return OpenNotification({ type: 'error', title: typeErrorMessage });
        }
      }

      // Upload files
      fileList.forEach(uploadDocument);
    },
    [props?.accept, props?.isValidate, typeErrorMessage, uploadDocument],
  );

  return (
    <>
      {props?.diamondUpload ? (
        <div className={`select-file ${props.className}`}>
          <input
            {...{ type: 'file', id: 'image', name: 'image', ...props, accept: props.fileType }}
            onChange={handleChange}
          />
          {!noPreview && isEmpty(value) && (
            <div className="upload_images_content">
              <img src={uploadSvg} alt="upload" />
              <p className="title">Select File</p>
              <p className="desc">Drop file here or Browse</p>
              {loading ? (
                <div className="message">Uploading Files...</div>
              ) : (
                error && <div className="error">{error}</div>
              )}
            </div>
          )}

          {!noPreview && isArray(value) && (
            <div className="fileUploadedBlock">
              {value.map(
                (file, index) =>
                  file && <FileUploaded size="smallSize" key={index} file={file} onDelete={() => removeFile(index)} />,
              )}
            </div>
          )}
        </div>
      ) : (
        <>
          <div className={`from-group mb-15 ${props.fullWidth}`}>
            <div
              className={classNames([
                'uploadeDocumentWrapper ',
                !loading && error && 'error-message-file',
                props?.className,
              ])}
            >
              <div className="uploadeDocument">
                <img src={fileuploadSvg} alt="file upload" />
              </div>
              <div className="uploadeTitle">{label ? label : 'Image'}</div>
              <input
                {...{ type: 'file', id: 'image', name: 'image', ...props, accept: props.fileType }}
                onChange={handleChange}
              />
            </div>
            {loading ? (
              <div className="message">Uploading Files...</div>
            ) : (
              error && <div className="error">{error}</div>
            )}
            {props?.size && <span className="sizeText">{props?.size}</span>}
          </div>

          {!noPreview && isArray(value) && (
            <div className="fileUploadedBlock">
              {value.map(
                (file, index) =>
                  file && <FileUploaded size="smallSize" key={index} file={file} onDelete={() => removeFile(index)} />,
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

FileUploadFormik.propTypes = {
  folder: PropTypes.string, // Folder to save files in
  error: PropTypes.string,
  label: PropTypes.string, // Input Label
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
};

export default React.memo(FileUploadFormik);
