import { isEmpty, isString } from './utils';

export const __cacheContainer__ = {};

/**
 * In-memory caching interface (for memoization)
 * @param {String} [prefix] optional Prefix for each keys stored by the instance
 */
export class CacheStore {
  constructor(prefix = '') {
    this.prefix = `${isString(prefix) && !isEmpty(prefix) ? prefix : Math.floor(Math.random() * Math.floor(50000))}`;
    // this.store = __cacheContainer__[this.prefix] = {};
    __cacheContainer__[this.prefix] = {};
    this.store = __cacheContainer__[this.prefix];
  }

  /**
   * Get cached value for the key
   * @param {String} key cache key
   */
  get(key) {
    return this.store?.[key];
  }

  /**
   * cache data
   * @param {String} key cache key
   * @param {*} data cache value
   */
  set(key, data) {
    this.store[key] = data;
    return data;
  }
}
