import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'util/classNames';
import 'styles/listingTop.less';

export const FilterTopContainer = React.memo(({ className, absolute, children, ...restProps }) => {
  return (
    <div
      className={classNames([
        'd-flex j-space-between filterTopMainBlock',
        absolute && 'selectStoneValueBlock',
        className,
      ])}
      {...restProps}
    >
      {children}
    </div>
  );
});
FilterTopContainer.propTypes = {
  className: PropTypes.string,
  absolute: PropTypes.bool,
  children: PropTypes.node,
};

export const FilterTopInnerField = React.memo(({ className, children, ...restProps }) => {
  return (
    <div className={classNames(['searchInnerFilterMain', className])} {...restProps}>
      {children}
    </div>
  );
});

FilterTopInnerField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
