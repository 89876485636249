import { memoize } from 'lodash';

import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import ConstantApi from 'constants/CommonApi';

export const InsertDiamondBlock = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Memo.Insert, request, ...(!isEmpty(config) && { config }) });

export const changeStatusDiamondBlock = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Memo.changeStatus, request, ...(!isEmpty(config) && { config }) });

export const getDiamondBlockMaster = (request = {}, config) =>
  UtilService.callApiAsync({
    ...ConstantApi.DiamondBlockMaster.paginate,
    request,
    ...(!isEmpty(config) && { config }),
  });

export const memoHoldPaginate = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Memo.paginate, request, ...(!isEmpty(config) && { config }) });

export const historyDataPaginate = (request = {}) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Order.paginate,
    request,
  });
};

export const memoizedHistoryDataPaginate = memoize(
  (...args) => historyDataPaginate(...args),
  (...args) => JSON.stringify(args),
);
