import React from 'react';

import SelectOption from 'containers/SelectOption';

import Storage from 'services/storage';

import { isFunction } from 'util/utils';

import { isValue } from '../CommonPopupFunction';

function PaymentTermsComponent(props) {
  const [state, _setState] = React.useState({
    terms: null,
    termsList: [],
    loading: false,
  });
  const setState = (object = {}) => {
    _setState((state) => ({ ...state, ...object }));
  };
  React.useEffect(() => {
    const masters = Storage.get('dn-master');
    const termsList = masters?.DAY_TERM?.map((item) => ({ ...item, value: item.name, key: item.id[0] }));
    // let terms = termsList[0]?.key;
    setState({ termsList });
  }, []);
  React.useEffect(() => {
    setState({ terms: props.initialPaymentTerms });
  }, [props.initialPaymentTerms]);
  return (
    <>
      <SelectOption
        placeholder="Select Payment Terms"
        value={isValue(state.terms)}
        selectValue={state.termsList}
        label={props.label || 'Payment Terms *'}
        onChange={(terms = null) => {
          if (isFunction(props.errorHandel)) props.errorHandel();
          props.setTermsDetails({ terms });
          setState({ terms });
        }}
        onSearch={() => {
          props.setTermsDetails({ terms: null });
          setState({ terms: null });
        }}
        error={props.error}
        disabled={props?.disabled}
      />
    </>
  );
}

export default React.memo(PaymentTermsComponent);
