import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Checkbox } from 'antd';
import { Formik, Form } from 'formik';
import _findIndex from 'lodash/findIndex';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import InputBlock from 'containers/InputBlock';
import Heading from 'containers/MainHeading';
import SelectOption from 'containers/SelectOption';

import CircularProgress from 'components/CircularProgress';
import BottomStickyButton from 'components/ShortCuts/BottomStickButton';
import AccountDropdown from 'components/User/AccountDropdown';

import * as AccountAPI from 'services/AccountApi';
import * as SellerAPI from 'services/SellerApi';
import * as MasterApi from 'services/SubMasterApi';
// import { getUserList } from 'services/UserApi';
import Storage from 'services/storage';

import OptionsUtils from 'util/OptionsUtils';
import classNames from 'util/classNames';
import { formatInlineList, isArray, isEmpty, isObject, isString } from 'util/utils';

import { KYC_STATUS, REGISTRATION_TYPES } from 'constants/Common';
// import { KYC_STATUS, REGISTRATION_TYPES, USERS_TYPE, CUSTOMER_TYPE } from 'constants/Common';

class ClientKYCDetail extends Component {
  static propTypes = {
    account: PropTypes.any,
    createAccount: PropTypes.func,
    updateAccount: PropTypes.func,
  };

  state = {
    groups: [],
    sellers: [],
    cities: [],
    states: [],
    countries: [],
    loading: true,
    saveContinue: false,
    roleList: [],
  };

  masters = undefined;
  broker = undefined;
  groups = [];
  location = [];
  kycStatus = [];
  location = [];
  india = {};
  //stay as it is untill USA inventory is not sync
  usa = undefined;
  bel = {};

  async masterList() {
    const [, res] = await MasterApi.getMastersList({
      filter: {
        parentCode: 'USER_ROLES',
        group: ['CUSTOMER'],
        isActive: true,
      },
    });

    if (isArray(res?.data)) {
      return new Promise((resolve) => this.setState({ roleList: res.data }, resolve));
    }
  }

  getLocation = async () => {
    const [, res] = await AccountAPI.getVendorLocation();
    if (isArray(res?.response?.account)) {
      res.response.account.forEach((data) => {
        if (data && data.vendorCode === 'KGK_INDIAN_INVENTORY') {
          this.india = data.id;
        }
        if (data && data.vendorCode === 'KGK_BELGIUM_INVENTORY') {
          this.bel = data.id;
        }
        if (data && data.vendorCode === 'KGK_USA_INVENTORY') {
          this.usa = data.id;
        }
      });
    }
  };

  getKycMaster = () => {
    this.masters = Storage.get('dn-master');
    this.groups = this.masters?.COMPANY_GROUP?.map((item) => ({ ...item, id: item.id[0] }));
  };

  initCountryStateCity = async () => {
    await this.getCountries();

    const countryId = this.props?.account?.country?.id;
    if (!isEmpty(countryId)) this.getStates(countryId);

    const stateId = this.props?.account?.state?.id;
    if (!isEmpty(stateId)) this.getCities(stateId);
  };

  getSalesPerson = async () => {
    const request = { filter: { isActive: true }, isDeleted: false };
    const [err, res] = await SellerAPI.getSellerList(request);
    const success = !err && res?.code === 'OK';

    if (success && isArray(res.data)) {
      this.setState({
        sellers: res.data.map(({ id, firstName, lastName }) => ({
          id,
          name: `${firstName}${lastName !== '_' ? ' ' + lastName : ''}`,
        })),
      });
    }
  };

  getCountries = async () => {
    const countries = await OptionsUtils.getCountryOptions();
    this.setState({ countries });
  };

  getStates = async (country) => {
    this.setState({ states: [] });
    const states = await OptionsUtils.getStateOptions(country);
    this.setState({ states });
  };

  getCities = async (state) => {
    this.setState({ cities: [] });
    const cities = await OptionsUtils.getCityOptions(state);
    this.setState({ cities });
  };

  handleMount = async () => {
    this.setState({ loading: true });
    await Promise.all([this.getSalesPerson(), this.initCountryStateCity(), this.masterList(), this.getLocation()]);
    this.getKycMaster();
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.handleMount();
  }

  submitForm = async (values, { setSubmitting, resetForm }) => {
    const { account } = this.props;

    const groups = account?.group || [];
    const groupIndex = _findIndex(groups, { isActive: true });
    const groupId = groupIndex > -1 ? groups[groupIndex].id : '';

    let group = [];
    if (values?.group && values?.group !== groupId) {
      group = (!isString(account?.group) && !isEmpty(account?.group) ? account?.group : [])?.map((item) => ({
        ...item,
        isActive: false,
      }));
      group.push({ id: values?.group, time: DateTime.local(), isActive: true });
    } else {
      group = groups;
    }

    if (account?.isApproved !== Number(values?.isApproved) && isArray(account?.kyc) && !isEmpty(account?.kyc)) {
      values.kyc = account.kyc.map((item) => {
        item.status = Number(values?.isApproved);
        return item;
      });
    }

    if (values.isSpam === true) values.isActive = false;

    values.approveStatusChanged = account?.isApproved !== values.isApproved;

    if (!isArray(values.mobiles)) values.mobiles = values.mobiles?.split(',').map((item) => item.trim());
    if (!isArray(values.emails)) values.emails = values.emails?.split(',').map((item) => item.trim());
    if (!isArray(values.faxes)) values.faxes = values.faxes?.split(',').map((item) => item.trim());
    values.broker = isObject(values.broker) ? values?.broker?.id : values.broker;

    values.sellerVId = [
      { vnd: this.india, seller: values.sellerInd ?? null },
      { vnd: this.usa, seller: values.sellerUsa ?? null },
      { vnd: this.bel, seller: values.sellerBel ?? null },
    ];

    setSubmitting(true);
    const [err, res] = await (isEmpty(account?.id)
      ? this.props.createAccount({ ...values, group, isVerified: values.isVerified ? 2 : 1 })
      : this.props.updateAccount(account?.id, { ...values, group, isVerified: values.isVerified ? 2 : 1 }));
    setSubmitting(false);

    if (!isEmpty(account?.id) && this.props?.filter?.isActive === 'spam' && values.isSpam !== account.isSpam) {
      this.props.getClientList();
    }

    if (isEmpty(account?.id)) {
      this.props.setClientList({ customerDetail: res?.data });
    }

    if (!err && res?.code === 'OK') {
      if (this.state.saveContinue) this.props.updateSubTabIndex();
      else {
        resetForm();
        this.props.onCancel();
      }
    }
  };

  get initialValues() {
    const account = { ...this.props.account };
    const userList = { ...this.props.userList };
    return {
      code: '',
      country: userList.country,
      state: userList.state,
      city: userList.city,
      vendorCode: account.vendorCode ?? account.companyName ?? '',
      name: account.name ?? userList.name ?? '',
      contactEmail: account.contactEmail ?? userList.email ?? '',
      contactMobile: account.contactMobile ?? userList.mobile ?? '',
      companyName: '',
      address: userList.address,
      emails: '',
      zipCode: '',
      isActive: false,
      isEmailVerified: userList.isEmailVerified ?? false,
      isSpam: false,
      isVerified: false,
      isApproved: 1,
      sellerInd: undefined,
      sellerUsa: undefined,
      sellerBel: undefined,
      //seller: undefined,
      group: undefined,
      broker: undefined,
      email: '',
      Website: '',
      mobiles: '',
      faxes: '',
      vendorLogo: '',
      registrationType: undefined,
      roles: userList.roles,
    };
  }

  getInitialValues = () => {
    const account = { ...this.props.account };
    this.broker = undefined;

    account.isVerified = account.isVerified === 2 ? true : false;
    const groups = account?.group || [];
    const groupIndex = _findIndex(groups, { isActive: true });
    account.group = groupIndex > -1 ? groups[groupIndex].id : '';
    account.code = account.code
      ? account.code
      : this.state.countries.find((item) => item.key === account?.country?.id)?.code;
    if (account?.sellerVId) {
      account.sellerVId.forEach((data) => {
        if (data && data.vnd && data.vnd.vendorCode === 'KGK_INDIAN_INVENTORY') {
          account.sellerInd = data.seller;
        }
        if (data && data.vnd && data.vnd.vendorCode === 'KGK_BELGIUM_INVENTORY') {
          account.sellerBel = data.seller;
        }
        if (data && data.vnd && data.vnd.vendorCode === 'KGK_USA_INVENTORY') {
          account.sellerUsa = data.seller;
        }
      });
    }
    // assign Ids to prepopulated fields
    const populatedFieldNames = ['country', 'state', 'city', 'sellerInd', 'sellerBel', 'sellerUsa'];
    populatedFieldNames.forEach((fieldName) => (account[fieldName] = account?.[fieldName]?.id));

    return Object.fromEntries(
      Object.entries(this.initialValues).map(([key, value]) => [key, isEmpty(account?.[key]) ? value : account?.[key]]),
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps?.account?.id !== this.props?.account?.id) this.handleMount();
  }

  validationSchema = () =>
    Yup.object().shape({
      companyName: Yup.string().trim().required('Please enter company name'),
      // vendorCode: Yup.string().trim().required('Please enter company code'),
      address: Yup.string().trim().required('Please enter address'),
      emails: Yup.array().of(Yup.string().email('Invalid Email Address')).required('Please enter emails'),
      mobiles: Yup.array().of(Yup.string()).required('Please enter phone no'),
      roles: Yup.array().of(Yup.string()).required('Please select Cutomer Roles.'),
      country: Yup.string().trim().required('Please selct country'),
      state: Yup.string().trim().required('Please select state'),
      city: Yup.string().trim().required('Please select city'),
      zipCode: Yup.string()
        .trim()
        .when('registrationType', {
          is: (v) => ['3', '4'].includes(v),
          then: (s) => s.min(6),
          otherwise: (s) => s.min(4),
        })
        .when('registrationType', {
          is: (v) => ['3', '4'].includes(v),
          then: (s) => s.max(6),
          otherwise: (s) => s.max(10),
        }),
      isActive: Yup.boolean(),
      isSpam: Yup.boolean(),
      //seller: Yup.string().required('Please select sales person'),
      group: Yup.string().required('Please select group'),
      // name: Yup.string().required('Please enter name'),
      // contactEmail: Yup.string().email('Please enter valid email').required('Please enter email'),
      // contactMobile: Yup.string().trim().required('Please enter phone no'),
      // Website: Yup.string().url('Invalid URL'),
      Website: Yup.string()
        .trim()
        .matches(
          /(www.)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Invalid URL!',
        ),
      faxes: Yup.string().trim(),
      // vendorLogo: Yup.string().trim().required('Please choose logo'),
    });



  render() {
    const { cities, states, countries, sellers, loading } = this.state;
    if (loading) return <CircularProgress />;

    return (
      <Formik
        enableReinitialize
        initialValues={this.getInitialValues()}
        validationSchema={this.validationSchema}
        validateOnChange
        onSubmit={this.submitForm}
      >
        {({
          handleChange,
          handleBlur,
          setValues,
          setFieldValue,
          setFieldTouched,
          submitForm,
          touched,
          errors,
          values,
        }) => (
          <Form>
            <div className={classNames(['kycFormMainBlock'])}>
              <div className="kycBlockWrapper mb-20">
                <InputBlock
                  name="companyName"
                  label="Company Name *"
                  placeholder="Company Name"
                  type="text"
                  value={values?.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.companyName && errors?.companyName}
                />
                <InputBlock
                  name="vendorCode"
                  label="Company Code"
                  placeholder="Company Code"
                  type="text"
                  value={values?.vendorCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.vendorCode && errors?.vendorCode}
                />
                <InputBlock
                  name="address"
                  label="Address *"
                  placeholder="Address"
                  type="text"
                  value={values?.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.address && errors?.address}
                />
                <InputBlock
                  name="emails"
                  label="Email *"
                  placeholder="Email"
                  type="text"
                  value={values?.emails}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (isString(value))
                      setValues((values) => ({
                        ...values,
                        emails: formatInlineList(value, { allowAppend: true }).split(','),
                      }));
                  }}
                  onBlur={handleBlur}
                  error={touched?.emails && errors?.emails}
                />
                <InputBlock
                  name="code"
                  label="Country Code"
                  placeholder="Code"
                  type="text"
                  value={values?.code}
                  onBlur={handleBlur}
                  disabled
                />
                <InputBlock
                  name="mobiles"
                  label="Phone no. *"
                  placeholder="Phone no."
                  type="text"
                  value={values?.mobiles}
                  onKeyPress={(evt) => {
                    if ((evt.which < 48 || evt.which > 57) && evt.which !== 44 && evt.which !== 32) {
                      evt.preventDefault();
                    }
                  }}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (isString(value))
                      setValues((values) => ({
                        ...values,
                        mobiles: formatInlineList(value, { allowAppend: true }).split(','),
                      }));
                  }}
                  onBlur={handleBlur}
                  error={touched?.mobiles && errors?.mobiles}
                />
                <InputBlock
                  name="zipCode"
                  label="Zip/Pin Code"
                  placeholder="Zip/Pin Code"
                  type="text"
                  value={values?.zipCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.zipCode && errors?.zipCode}
                />
                <SelectOption
                  value={countries?.filter((item) => item.key === values?.country).length ? values?.country : undefined}
                  onChange={(value) => {
                    this.getStates(value);
                    setValues((values) => ({
                      ...values,
                      city: undefined,
                      state: undefined,
                      country: value,
                      code: this.state.countries.find((item) => item.key === value)?.code,
                    }));
                  }}
                  onBlur={() => setFieldTouched('country', true)}
                  name="country"
                  error={errors?.country}
                  label="Country *"
                  placeholder="Select Country"
                  selectValue={countries}
                  showSearch
                />
                <SelectOption
                  value={states?.filter((item) => item.key === values?.state).length ? values?.state : undefined}
                  onChange={(value) => {
                    this.getCities(value);
                    setValues((values) => ({ ...values, state: value, city: undefined }));
                  }}
                  onBlur={() => setFieldTouched('state', true)}
                  name="state"
                  error={errors?.state}
                  label="State *"
                  placeholder="Select State"
                  selectValue={states}
                  showSearch
                />
                <SelectOption
                  value={cities?.filter((item) => item.key === values?.city).length ? values?.city : undefined}
                  onChange={(value) => setValues((values) => ({ ...values, city: value }))}
                  onBlur={() => setFieldTouched('city', true)}
                  name="city"
                  error={errors?.city}
                  label="City *"
                  placeholder="Select City"
                  selectValue={cities}
                  showSearch
                />
                <SelectOption
                  value={
                    sellers?.filter((item) => item.id === values?.sellerInd).length ? values?.sellerInd : undefined
                  }
                  onChange={(value) => setValues((values) => ({ ...values, sellerInd: value }))}
                  onBlur={() => setFieldTouched('sellerInd', true)}
                  name="sellerInd"
                  error={touched?.sellerInd && errors?.sellerInd}
                  label="Indian Inventory Sales Person "
                  placeholder="Select Sales Person"
                  selectValue={sellers}
                  nameKey="name"
                  valueKey="id"
                  showSearch
                />
                <SelectOption
                  value={
                    sellers?.filter((item) => item.id === values?.sellerUsa).length ? values?.sellerUsa : undefined
                  }
                  onChange={(value) => setValues((values) => ({ ...values, sellerUsa: value }))}
                  //onBlur={() => setFieldTouched('sellerUsa', true)}
                  name="sellerUsa"
                  //error={touched?.sellerUsa && errors?.sellerUsa}
                  label="USA Inventory Sales Person "
                  placeholder="Select Sales Person"
                  selectValue={sellers}
                  nameKey="name"
                  valueKey="id"
                  showSearch
                />
                <SelectOption
                  value={
                    sellers?.filter((item) => item.id === values?.sellerBel).length ? values?.sellerBel : undefined
                  }
                  onChange={(value) => setValues((values) => ({ ...values, sellerBel: value }))}
                  //onBlur={() => setFieldTouched('sellerBel', true)}
                  name="sellerBel"
                  //error={touched?.sellerBel && errors?.sellerBel}
                  label="Belgium Inventory Sales Person "
                  placeholder="Select Sales Person"
                  selectValue={sellers}
                  nameKey="name"
                  valueKey="id"
                  showSearch
                />
                {/* <SelectOption
                  value={sellers?.filter((item) => item.id === values?.seller).length ? values?.seller : undefined}
                  onChange={(value) => setValues((values) => ({ ...values, seller: value }))}
                  //onBlur={() => setFieldTouched('seller', true)}
                  name="seller"
                  //error={touched?.seller && errors?.seller}
                  label="Sales Person *"
                  placeholder="Select Sales Person"
                  selectValue={sellers}
                  nameKey="name"
                  valueKey="id"
                  showSearch
                /> */}
                {/* <SelectOption
                  value={
                    this.location?.filter((item) => item.id === values?.location).length ? values?.location : undefined
                  }
                  onChange={(value) => {
                    this.handleLocation(value);
                    setValues((values) => ({ ...values, location: value }));
                  }}
                  onBlur={() => setFieldTouched('location', true)}
                  name="location"
                  error={touched?.location && errors?.location}
                  label="Inventory Location *"
                  placeholder="Select Inventory Location"
                  selectValue={this.location}
                  nameKey="name"
                  valueKey="id"
                  showSearch
                /> */}
                <SelectOption
                  value={this.groups?.filter((item) => item.id === values?.group).length ? values?.group : undefined}
                  onChange={(value) => setValues((values) => ({ ...values, group: value }))}
                  onBlur={() => setFieldTouched('group', true)}
                  name="group"
                  error={touched?.group && errors?.group}
                  label="Group *"
                  placeholder="Select Group"
                  selectValue={this.groups}
                  nameKey="name"
                  valueKey="id"
                  showSearch
                />
                <AccountDropdown
                  initialValue={values?.broker}
                  value={values?.broker?.id}
                  placeholder="Broker"
                  label={`Broker ${this.props?.account?.hasBroker ? '(' + this.props?.account?.brokerInfo?.brokerName + ')' : ''
                    }`}
                  name="broker"
                  onChange={(broker) => {
                    setFieldValue('broker', broker);
                  }}
                  ledgerType="broker"
                />
                <SelectOption
                  value={
                    isEmpty(values?.registrationType) || values?.registrationType === 0
                      ? undefined
                      : `${values?.registrationType}`
                  }
                  onChange={(value) => setValues((values) => ({ ...values, registrationType: value || 0 }))}
                  onBlur={() => setFieldTouched('registrationType', true)}
                  name="registrationType"
                  error={touched?.registrationType && errors?.registrationType}
                  label="Registration Type"
                  selectValue={Object.entries(REGISTRATION_TYPES).map(([key, value]) => ({ key, value }))}
                  showSearch
                  placeholder="Select Registration Type"
                />
                <SelectOption
                  value={
                    KYC_STATUS?.filter((item) => item.key == values?.isApproved).length
                      ? `${values?.isApproved}`
                      : undefined
                  }
                  onChange={(value) => setFieldValue('isApproved', value || '1')}
                  onBlur={() => setFieldTouched('isApproved', true)}
                  name="isApproved"
                  error={touched?.isApproved && errors?.isApproved}
                  label="KYC Approve"
                  selectValue={KYC_STATUS}
                  showSearch
                  disabled={values?.isSpam === true}
                />
                <SelectOption
                  tabIndex="8"
                  placeholder="Select Customer Role"
                  label="Customer Role"
                  name="roles"
                  options={this.state.roleList.map((item) => ({
                    id: item?.id,
                    label: item?.webDisplay,
                    //selected: Boolean(item.isDefault),
                  }))}
                  value={values?.roles}
                  onChange={(value) => setFieldValue('roles', value)}
                  onBlur={() => setFieldTouched('roles', true)}
                  error={touched?.roles && errors?.roles}
                  mode="multiple"
                  className="multiSelect"
                />
                <div className="checkBoxForm">
                  <label>Spam</label>
                  <Checkbox
                    onChange={(e) => {
                      e.target.checked
                        ? setValues((values) => ({ ...values, isActive: false, isSpam: !values?.isSpam }))
                        : setValues((values) => ({ ...values, isSpam: !values?.isSpam }));
                    }}
                    checked={values?.isSpam}
                  />
                </div>
                <div className="checkBoxForm">
                  <label>Verified</label>
                  <Checkbox
                    onChange={() => setFieldValue('isVerified', !values?.isVerified)}
                    checked={values?.isVerified}
                    disabled={values?.isSpam === true}
                  />
                </div>
                <div className="checkBoxForm">
                  <label>Active</label>
                  <Checkbox
                    onChange={() => setFieldValue('isActive', !values?.isActive)}
                    checked={values?.isActive}
                    disabled={values?.isSpam === true}
                  />
                </div>
                <div className="checkBoxForm">
                  <label>Email Verified</label>

                  <Checkbox
                    onChange={() => setFieldValue('isEmailVerified', !values?.isEmailVerified)}
                    checked={values?.isEmailVerified}
                    disabled={values?.isSpam === true}
                  />
                </div>
                {/* <div className="checkBoxForm">
                  <label>KYC Approved?</label>
                  <Checkbox
                    onChange={() => setFieldValue('isApproved', !values?.isApproved)}
                    checked={values?.isApproved}
                  />
                </div> */}
              </div>
              <div className="d-flex j-space-between mb-20">
                <Heading className="smallTitle p-0" title="Contact Details" />
              </div>
              <div className="kycBlockWrapper mb-20 addRemoveinputBlock">
                <InputBlock
                  name="name"
                  label="Contact Person Name"
                  placeholder="Contact Person Name"
                  type="text"
                  value={values?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.name && errors?.name}
                />
                <InputBlock
                  name="contactEmail"
                  label="Email"
                  placeholder="Email"
                  type="text"
                  value={values?.contactEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.contactEmail && errors?.contactEmail}
                />
                <InputBlock
                  name="Website"
                  label="Website"
                  placeholder="Website"
                  type="text"
                  value={values?.Website}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.Website && errors?.Website}
                />
                <InputBlock
                  name="contactMobile"
                  label="Mobile Number"
                  onKeyPress={(evt) => {
                    if (evt.which < 48 || evt.which > 57) {
                      evt.preventDefault();
                    }
                  }}
                  placeholder="Mobile Number"
                  type="text"
                  value={values?.contactMobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.contactMobile && errors?.contactMobile}
                />
                {/* <InputBlock
                  name="faxes"
                  label="FAX Numbers"
                  placeholder="FAX Numbers"
                  type="text"
                  value={values?.faxes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.faxes && errors?.faxes}
                /> */}
              </div>
              {/* <div className="tabinnerTitle">Upload Document *</div>
              <FileUpload
                onChange={(value) => setFieldValue('vendorLogo', value)}
                onBlur={() => setFieldTouched('vendorLogo', true)}
                error={touched?.vendorLogo && errors?.vendorLogo}
                label="Choose Document"
                files={[values?.vendorLogo]}
              /> */}
            </div>
            <BottomStickyButton>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: true }, submitForm)}
              >
                {this.props.addAccount ? 'Add Account & Continue' : 'Save & Continue'}
              </button>
              {!this.props.addAccount && (
                <button
                  className="commonButton"
                  type="button"
                  onClick={() => this.setState({ saveContinue: false }, submitForm)}
                >
                  Save
                </button>
              )}
              <button className="commonOutine" type="reset" onClick={this.props.onCancel}>
                Cancel
              </button>
            </BottomStickyButton>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(
  ({ accountFilter }) => ({
    filter: accountFilter,
  }),
  {},
)(ClientKYCDetail);
