import React from 'react';

import Heading from 'containers/Heading';
import InputBlock from 'containers/InputBlock';
import SelectOption from 'containers/SelectOption';

const LoginLog = {
  customerName: [
    { key: 1, name: 'Coruscate' },
    { key: 2, name: 'Coruscate' },
  ],
  PermissionProfile: [
    { key: 1, name: 'Web Login' },
    { key: 2, name: 'Web Login 1' },
  ],
  IPAddress: [
    { key: 1, name: '135.235.024.04' },
    { key: 2, name: '135.235.024.04' },
  ],
};

export const LoginLogAdd = ({ closeForm }) => {
  const [assignee, setAssignee] = React.useState('Select Customer');
  const [assignee1, setAssignee1] = React.useState('Select Permission Profile');
  const [assignee2, setAssignee2] = React.useState('Select IP Address');
  return (
    <div className="mt-20">
      <div className="d-flex flex-wrap three-block justify-space-between">
        <SelectOption
          value={assignee}
          onChange={(value) => setAssignee(value)}
          name="Customer"
          label="Customer"
          placeholder="Customer"
          selectValue={LoginLog.customerName}
          nameKey="name"
          valueKey="id"
          showSearch
        />
        <SelectOption
          value={assignee1}
          onChange={(value) => setAssignee1(value)}
          name="Permission Profile"
          label="Permission Profile"
          placeholder="Permission Profile"
          selectValue={LoginLog.PermissionProfile}
          nameKey="name"
          valueKey="id"
          showSearch
        />
        <SelectOption
          value={assignee2}
          onChange={(value) => setAssignee2(value)}
          name="IP Address"
          label="IP Address"
          placeholder="IP Address"
          selectValue={LoginLog.IPAddress}
          nameKey="name"
          valueKey="id"
          showSearch
        />
      </div>
      <Heading title="Active Permission Count" className="popupInnerTitle mb-20" />
      <div className="d-flex mb-20">
        <div className="permissionSessionCount">
          <span>Website : </span>
          <span>1</span>
        </div>
        <div className="permissionSessionCount">
          <span>IOS : </span>
          <span>1</span>
        </div>
        <div className="permissionSessionCount">
          <span>Android : </span>
          <span>1</span>
        </div>
        <div className="permissionSessionCount">
          <span>Mobile : </span>
          <span>1</span>
        </div>
      </div>
      <Heading title="Permission Count" className="popupInnerTitle mb-20" />
      <div className="d-flex flex-wrap four-block justify-space-between">
        <InputBlock placeholder="WebSite" label="WebSite" />
        <InputBlock placeholder="IOS" label="IOS" />
        <InputBlock placeholder="Android" label="Android" />
        <InputBlock placeholder="Mobile" label="Mobile" />
      </div>
      <div className="width-100 d-flex mt-40">
        <button className="commonButton mr-10" type="submit" value="Submit">
          {/* {isEmpty(update?.id) ? 'Save' : 'Update'} */}
          Save
        </button>
        <button className="commonOutine" type="reset" onClick={closeForm}>
          Back
        </button>
      </div>
    </div>
  );
};
