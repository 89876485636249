import { getDefaultPermissions } from './_getDefaultPermissions';

export const SettingPermissions = [
  {
    mainModule: 'SETTING',
    module: 'NOTIFICATION',
    title: 'Notification',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    module: 'MY_ACCOUNT',
    title: 'My Account',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    module: 'TERMS_AND_CONDITIONS',
    title: 'Terms And Conditions',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'MASTER',
    title: 'Master',
    url: '/setting/master',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'SUB_MASTER',
    title: 'Sub Master',
    url: '/setting/submaster',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'CONTACT_DIRECTORY',
    title: 'Contact Directory',
    url: '/setting/contact-directory',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'HOME_SETTING',
    title: 'Home Settings',
    url: '/setting/home-setting',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SETTING',
    subModule: 'MY_SETTING',
    module: 'DISCOUNT_SETTING',
    title: 'Discount Settings',
    url: '/setting/discount-setting',
    permissions: getDefaultPermissions(),
  },
];
