import urlJoin from 'url-join';

import _capitalize from 'lodash/capitalize';

import { COMMON_URL } from './CommonUrl';
import { MENU } from './Menu';
import ProjectDetails from './ProjectDetails.json';

import topbannerPng from '../assets/images/login/top-banner.png';
import logo1Png from '../assets/images/logo1.png';
// const path = require('path');
export const LOCALE = 'en-US';
export const CURRENCY = 'USD';
export const TIMEZONE_IANA = 'Asia/Kolkata';
export const LUXON_FORMAT = { DATE: 'dd/LL/y', TIME: 'hh:mm a', DATE_TIME: 'dd/LL/y hh:mm a', DURATION: 'hh:mm:ss' };
export const FLOAT_PRECISION = 2;

export const LIST_LIMIT = 50;

export const ALLOW_ROW_SELECTION = true;
export const ALLOW_ROW_HIGHLIGHT_BY_LAB = true;
export const ALLOW_ROW_GROUP_TITLE_TOGGLE = false;

export const CALENDER_FIELD = [
  'SHOW',
  'INFO',
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export const USER_TYPE = {
  ADMIN: { SUPER: 1, GENERAL: 11 },
  PHARMACY: { ADMIN: 2, PHARMACIST: 21, LICENSED_TECHNICIAN: 22, INTERN: 23, SHIPPER: 24, RECEIVER: 25 },
  ORGANIZATION: { ADMIN: 3 },
  HOME: {
    ADMIN: 4,
    HOME_AREA: { ADMIN: 5, DOCTOR: 51, NURSE: 52, STAFF: 53, PATIENT: 54 },
  },
};

export const FILE_TYPES = {
  pdf: ['application/pdf'],
  image: ['image/jpeg', 'image/png', 'image/jpg'],
  json: ['application/json'],
};

export const VERSION_PLATFORM = { ANDROID: 1, IPHONE: 2 };

export const EVENT = {
  TYPE: { EVENT: '1', NEWS: '2', SHOW: '3', OTHER: '4', CSR: '5', POPUP: '6' },
};

export const FEATURE_STONE = {
  STONE_OF_DAY: 'stone_of_day',
  BEST_OF_KGK: 'best',
  EXCLUSIVE_STONE: 'exclusive',
  PAIR: 'pair',
  FEATURE_STONE: 'stone',
};

export const USERS_TYPE = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  SUB_USER: 3,
  PRIMARY: 4,
  PERMANENT_GUEST: 5,
  EMPLOYEE: 6,
  API_USER: 7,
  SELLER: 8,
  CUSTOMER: 9,
  SECONDARY: 10,
};

export const Employe_Type = { commonPER: 2, AdminPER: 1 };

export const PERMISSION_TYPE = { user: 'user', role: 'role' };

export const PARCEL_PRICE_OPERATION_TYPE = { SAVE_AS_NEW: '1', UPDATE: '2' };

export const PARCEL_PRICE_SHAPE = {
  ROUND: 'Round',
  PRINCESS: 'Princess',
  PEAR: 'Pear',
  OVAL: 'Oval',
  MARQUISE: 'Marquise',
};

export const NEWS = {
  DISPLAYON: { WEB: '1', APP: '2' },
  TYPE: { NOTIFICATION: '1', 'HELLO BAR': '2', 'TRADE SHOW': '3', OTHER: '4' },
};

export const BANNER_TYPES = {
  HOMEPAGE_ONE: 1,
  HOMEPAGE_TWO: 2,
  HOMEPAGE_THREE: 3,
  HOMEPAGE_FOUR: 4,
  HOMEPAGE_FIVE: 5,
  HOME_PAGE_EVENT: 9,
  NEWS_THUMBNAIL: 6,
  NEWS_DETAIL: 7,
  MY_ACCOUNT: 8,
};

export const BANNER_IMAGE_SIZE = {
  1: { desktop: '1150 * 564', ipad: '460 * 300', mobile: '384 * 250' },
  2: { desktop: '383 * 281', ipad: '154 * 150', mobile: '192 * 150' },
  3: { desktop: '383 * 281', ipad: '154 * 150', mobile: '192 * 150' },
  4: { desktop: '383 * 281', ipad: '154 * 150', mobile: '192 * 150' },
  5: { desktop: '383 * 281', ipad: '154 * 150', mobile: '192 * 150' },
  6: { desktop: '50 * 50', ipad: '50 * 50', mobile: '50 * 50' },
  7: { desktop: '250 * 250', ipad: '250 * 250', mobile: '250 * 250' },
  8: { desktop: '1110 * 350', ipad: '420 * 300', mobile: '379 * 250' },
};

export const BANNER_SEARCH_BAR_POSITION = {
  CENTER: 1,
  TOP: 2,
  DOWN: 3,
  LEFT: 4,
  RIGHT: 5,
};

export const PAGE_TRACKING_DEVICE = { WEB: 1, ANDROID: 2, IOS: 3 };

export const PAGE_TRACKING_ACTION = {
  OPEN: 'open',
  CLOSE: 'close',
  UPLOAD: 'upload',
  RESET: 'reset',
  SELECT: 'select',
  UNSELECT: 'unselect',
  CUSTOM: 'custom',
  EXISTING: 'existing',
  FANCY: 'fancy',
  WHITE: 'white',
  LIST: 'list',
  CLICK: 'click',
  PRINT: 'print',
  EXCEL: 'excel',
  GRID: 'grid',
  VIDEO: 'video',
  PICTURE: 'picture',
  CERTIFICATE: 'certificate',
  CHANGE: 'change',
  DOWNLOAD: 'download',
  FOCUS: 'focus',
  SEARCH: 'search',
  COMPLETE: 'complete',
};

export const PAGE_TRACKING_SECTION = {
  ADVANCE_SEARCH: 'AdvanceSearch',
  ADD_DEMAND: 'AddDemand',
  SAVE_SEARCH: 'SavedSearch',
  UPLOAD_EXCEL: 'UploadExcel',
  RESET_FILTER: 'ResetFilter',
  THREE_EX: 'ThreeEx',
  TWO_EX: 'TwoEx',
  THREE_VG: 'ThreeVg',

  CARAT_SIZE: 'CaratSize',
  COLOR: 'Color',
  DIAMONDS: 'Diamonds',
  MODIFY: 'Modify',
  STICKY_FILTER: 'StickyFilter',
  PLACE_ORDER: 'PlaceOrder',
  SHIPMENT: 'Shipment',
  ENQUIRY: 'Enquiry',
  UPDATE: 'Update',
  EXPORT: 'Export',
  EXPORT_SEND_EMAIL: 'Export-Send_Email',
  ADD_TO_CART: 'AddToCart',
  ADD_TO_WATCHLIST: 'AddToWatchList',
  COMMENT: 'Comment',
  REMINDER: 'Reminder',
  VIEW: 'View',
  FILTER: 'Filter',
  SETTING: 'Setting',
  TABLE_ADD_TO_CART: 'Table-AddToCart',
  TABLE_ADD_TO_WATCHLIST: 'Table-AddToWatchList',
  TABLE_DELETE: 'Table-Delete',
  TABLE_COMMENT: 'Table-Comment',
  TABLE_REMINDER: 'Table-Reminder',
  TABLE_DETAILS: 'Table-Details',
  EDIT: 'Edit',
  DELETE: 'Delete',
  SEARCH: 'Search',
  CHANGE_PASSWORD: 'ChangePassword',
  INVENTORY: 'Inventory',
  MY_CART: 'MyCart',
  CALCULATOR: 'Calculator',
  TRACK_SHIPMENT: 'TrackShipment',
  LOCALE: 'Locale',
  LOGIN: 'login',
  LOGOUT: 'logout',
};

export const PAGE_TRACKING_PAGE = {
  DIAMOND_SEARCH: 'DiamondSearch',
  FANCY_SEARCH: 'FancySearch',
  MY_DEMAND: 'MyDemand',
  MY_SAVE_SEARCH: 'MySavedSearch',
  SEARCH_RESULT: 'SearchResult',
  UPCOMING_DIAMOND: 'UpcomingDiamond',
  NEW_DIAMOND: 'NewDiamond',
  CART: 'MyCart',
  WATCHLIST: 'MyWatchlist',
  REMINDER: 'MyReminder',
  COMMENT: 'MyComment',
  ENQUIRY: 'MyEnquiry',
  DEMAND: 'MyDemand',
  ORDER: 'MyOrder',
  ACCOUNT: 'MyAccount',
  HEADER: 'Header',
  RECOMMENDED: 'Recommended',
  USER: 'user',
  HOME: 'Home',
  DRAWER: 'Drawer',
  VENDOR_DIAMOND: 'vendor-diamond',
  SHEET_UPLOAD_V1: 'sheet-upload-V1',
  PROFILE: 'profile',
};

export const BANNER_TYPES_IMAGE = {
  Image: 1,
  Text: 2,
};

export const TRACK_TYPES = {
  Cart: 1,
  Watchlist: 2,
  Request: 3,
  Offer: 4,
  Reminder: 5,
  Enquiry: 6,
  Shipment: 7,
  Comment: 99,
};

export const TRACK_TYPES_NAME = {
  1: 'Cart',
  2: 'Watchlist',
  3: 'Request',
  4: 'Offer',
  5: 'Reminder',
  6: 'Enquiry',
  7: 'Shipment',
  8: 'Comment',
  10: 'Confirm ',
  11: 'Bid',
  12: 'View Diamonds',
};
export const TRANSACTION = {
  HOLD: 1,
  MEMO: 2,
};
export const TRANSACTION_ENQUIRY = {
  CUSTOMER_WISE: 1,
  DATE_WISE: 2,
  DIAMONDLIST: 3,
  DATE_CUSTOMER: 4,
  COUNTRY_WISE: 5,
  SALES_PERSON_WISE: 6,
};

export const TRANSPORT_STATUS = { Pending: '1', Done: '2', Cancel: '3' };

export const HOSPITALITY_STATUS = { Pending: '0', approved: '1', Cancel: '3' };

export const UserPage_Type = { Pending: '1', Verified: '2', Cancel: '3', verifyLogin: '4' };

export const Sheet_Processing = {
  PENDING: '1',
  PROCESSING: '2',
  COMPLETED: '3',
  FAILED: '4',
  UPLOADING: '5',
  UPLOADING_COMPLETED: '6',
  UPLOADING_FAILED: '7',
  ALL: '8',
};

export const FTP_REQUEST = { PENDING: '1', ACCEPTABLE: '2', CANCEL: '3' };

export const Account_Detail_tabs = {
  CompanyDetail: '3',
  BasicInfo: '1',
  AddressDetail: '2',
  Users: '4',
  Ftp: '5',
};

export const LOGIN_PLATFORM = { Android: '2', IOS: '3', Web: '1' };

export const MasterAction = {
  color: 'color',
  clarity: 'clarity',
  Symmetry: 'Symmetry',
  shape: 'shape',
  fluorescence: 'fluorescence',
  Location: 'Location',
  SIZE: 'SIZE',
  Polish: 'Polish',
  cut: 'Cut',
};

export const diamondParameters = {
  shp: { key: 'shp', name: 'Shape', master: 'SHAPE' },
  carat: { key: 'carat', name: 'Carat Range', master: 'SIZE' },
  col: { key: 'col', name: 'Color', master: 'COLOR' },
  clr: { key: 'clr', name: 'Clarity', master: 'CLARITY' },
  flu: { key: 'flu', name: 'Fluorescence', master: 'FLUORESCENCE' },
  crt: { key: 'crt', name: 'Carat', master: 'COLOR' },
  shd: { key: 'shd', name: 'Tinge', master: 'SHADE' },
  lb: { key: 'lb', name: 'Lab', master: 'LAB' },
  cut: { key: 'cut', name: 'Cut', master: 'CUT' },
  pol: { key: 'pol', name: 'Polish', master: 'POLISH' },
  sym: { key: 'sym', name: 'Symmetry', master: 'SYMMETRY' },
  loc: { key: 'loc', name: 'Location', master: 'LOCATION' },
  inten: { key: 'inten', name: 'Intensity', master: 'INTENSITY' },
  ovrtn: { key: 'ovrtn', name: 'Overtone', master: 'OVERTONE' },
  blkInc: { key: 'blkInc', name: 'Black Table', master: 'BLACK_INCLUSION' },
  eCln: { key: 'eCln', name: 'Eye Clean', master: 'EYECLEAN' },
  mlk: { key: 'mlk', name: 'Milky', master: 'MILKEY' },
  hA: { key: 'hA', name: 'Heart and Arrow', master: 'H_AND_A' },
  rap: { key: 'rap', name: 'Rap Price' },
  ctPr: { key: 'ctPr', name: 'Price/Carat' },
  amt: { key: 'amt', name: 'Amount' },
  depPer: { key: 'depPer', name: 'Depth %' },
  tblPer: { key: 'tblPer', name: 'Table %' },
  grdl: { key: 'grdl', name: 'Girdle', master: 'GIRDLE' },
  pAng: { key: 'pAng', name: 'Pavilion Angle' },
  cAng: { key: 'cAng', name: 'Crown Angle' },
  cHgt: { key: 'cHgt', name: 'Crown Height' },
  pHgt: { key: 'pHgt', name: 'Pavilian Depth' },
  dmtrMn: { key: 'dmtrMn', name: 'Diameter Min' },
  dmtrMxn: { key: 'dmtrMxn', name: 'Diameter Max' },
  or: { key: 'or', name: 'Carat' }, // or has more props than caret
  ratio: { key: 'ratio', name: 'Ratio' },
  showSrNo: { key: 'showSrNo', name: 'Show ID' },
  kToSStr: { key: 'kToSStr', name: 'Key To Symbol', master: 'KEY_TO_SYMBOLS' },
  fcCol: { key: 'fcCol', name: 'Fancy Color', master: 'FANCY_COLOR' },
  isFcCol: { key: 'isFcCol', name: 'Has Fancy Color' },
  noBGM: { key: 'noBGM', name: 'No Brown, Green or Milky' },
  grdlCond: { key: 'grdlCond', name: 'Girdle Condition' },
  cultCond: { key: 'cultCond', name: 'Culet Condition' },
  cult: { key: 'cult', name: 'Culet', master: 'CULET' },
  opTbl: { key: 'opTbl', name: 'Open Inclusion Table', master: 'OPEN_INCLUSION_TABLE' },
  opPav: { key: 'opPav', name: 'Open Inclusion Pavilion', master: 'OPEN_INCLUSION_PAVILION' },
  opCrwn: { key: 'opCrwn', name: 'Open Inclusion Crown', master: 'OPEN_INCLUSION_CROWN' },
  keyToSymbol: { key: 'keyToSymbol', name: 'Key To Symbol', master: 'KEY_TO_SYMBOLS' },
  wTbl: { key: 'wTbl', name: 'White Inclusion Table', master: 'WHITE_INCLUSION_TABLE' },
  blkTbl: { key: 'blkTbl', name: 'Black Inclusion Table', master: 'BLACK_INCLUSION_TABLE' },
  blkSd: { key: 'blkSd', name: 'Black Inclusion Side', master: 'BLACK_INCLUSION_SIDE' },
  wSd: { key: 'wSd', name: 'White Inclusion Side', master: 'WHITE_INCLUSION_SIDE' },
  brlncy: { key: 'brlncy', name: 'Brilliancy', master: 'BRILLIANCY' },
  stoneId: { key: 'stoneId', name: 'Stone ID/ Report Number' },
  vnd: { key: 'vnd', name: 'Seller' },
  vStnId: { key: 'vStnId', name: 'Seller Stone Number' },
  length: { key: 'length', name: 'Length' },
  width: { key: 'width', name: 'Width' },
  height: { key: 'height', name: 'Depth' },
  counId: { key: 'counId', name: 'Location' },
  country: { key: 'country', name: 'Country' },
  shpNm: { key: 'shpNm', name: 'Shape' },
  lab: { key: 'lab', name: 'Lab' },
  colNm: { key: 'colNm', name: 'Color' },
  clrNm: { key: 'clrNm', name: 'Clarity' },
  lap: { key: 'lap', name: 'LAP%' },
  lapCt: { key: 'lapCt', name: 'LAP/CT' },
  lapAmt: { key: 'lapAmt', name: 'Lap Amt' },
  Sale: { key: 'Sale', name: 'Sale' },
  saleCt: { key: 'saleCt', name: 'Sale/CT' },
  saleAmt: { key: 'saleAmt', name: 'Sale Amt' },
  cutNm: { key: 'cutNm', name: 'Cut' },
  polNm: { key: 'polNm', name: 'Pol', desc: 'Polish' },
  symNm: { key: 'symNm', name: 'Sym', desc: 'Symmetry' },
  flo: { key: 'flo', name: 'Flo', desc: 'Fluorescence' },
  tb: { key: 'tb', name: 'TB%', desc: 'Table %' },
  dp: { key: 'dp', name: 'DP%', desc: 'Depth %' },
  msrmnt: { key: 'msrmnt', name: 'Measurement' },
  //
  rptNo: { name: 'Report No', key: 'rptNo' },
  lbNm: { name: 'Lab', key: 'lbNm' },
  shdNm: { name: 'Shade', key: 'shdNm' },
  fluNm: { name: 'Fluorescence', key: 'fluNm' },
  lbCmt: { name: 'Report Comments', key: 'lbCmt' },
  girdleStr: { name: 'Girdle', key: 'girdleStr' },
  cultNm: { name: 'Culet', key: 'cultNm' },
  lsrInc: { name: 'Laser Inc.', key: 'lsrInc', desc: 'Laser Inclusion' },
  blkTblNm: { name: 'Center Natts', key: 'blkTblNm' },
  blkSdNm: { name: 'Side Natts', key: 'blkSdNm' },
  wTblNm: { name: 'Center White', key: 'wTblNm' },
  wSdNm: { name: 'Side White', key: 'wSdNm' },
  opTblNm: { name: 'Table Open', key: 'opTblNm' },
  opCrwnNm: { name: 'Crown Open', key: 'opCrwnNm' },
  opPavNm: { name: 'Pavillion Open', key: 'opPavNm' },
  eClnNm: { name: 'Eye Clean', key: 'eClnNm' },
  hANm: { name: 'Heart & Arrow', key: 'hANm' },
  brlncyNm: { name: 'Brilliancy', key: 'brlncyNm' },
  type2Nm: { name: 'Type2 Cert', key: 'type2Nm' },
  locNm: { name: 'Country of Origin', key: 'locNm' },
  mines: { name: 'Rough Mine', key: 'mines' },
  //
  wSts: { name: 'Stage', key: 'wSts' },
  sSts: { name: 'Stage Status', key: 'sSts' },
  prcStg: { name: 'Price Stage', key: 'prcStg' },
  back: { name: 'Disc %', key: 'cDis' },
  cDis: { name: 'Dis %', key: 'cDis' },
  grdlPer: { name: 'Girdle %', key: 'grdlPer' },
  lowerhalf: { name: 'Lower Half', key: 'lowerhalf' },
  starlength: { name: 'Star Length', key: 'starlength' },
  kToSArr: { name: 'Key To Symbol', key: 'kToSArr' },
};

export const diamondparameters2 = [
  { key: 'stoneId', name: 'Stone Id' },
  { key: 'shapeId', name: 'Shape' },
];

export const SEARCH_HISTORY = { RECENT: 1, SAVE: 2, DEMAND: 3, VOICE_SEARCH: 6 };

export const OVERFLOW_COUNT = 1500000;
export const PAGES_PERMISSION = {
  // prettier-ignore
  '1': [1, 60, 61, 62, 63, 64, 65, 66, 67, 71, 72, 73, 74, 75, 76, 77, 78, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 120, 118, /* Appointment */ 119, /* Slot */ 107, 108, 109, 110, 111, 112, 113, 121, /* parcel-master */ 122, /* setting */ 123, /* notification-master */ 124, 125, 126, 127, 128, 129, 130, 131, 140, 141, 151, 162, 163, /* hit-counter */ 164, /* location */ 145, /* sync setting */ 146, /* upload history */ 147, /* stock-summary */ 148, /* ftp request */],
  3: [1],
  4: [1],
};

export const IMAGE_PATH = 'https://diamanti.s3.amazonaws.com/images/diamond/***.jpg';
export const VIDEO_PATH = 'https://diamanti.s3.amazonaws.com/video/Vision360.html?d=***';
export const CERTI_PATH = 'http://diamanti.s3.amazonaws.com/certificates/***.jpg';

export const BASE_URL = COMMON_URL.BASE_URL;
export const BASE_URL_FILE_UPLOAD = COMMON_URL.BASE_URL_FILE_UPLOAD;
export const BASE_SYNC_URL = COMMON_URL.BASE_SYNC_URL;
export const EXCELFILE_URL = COMMON_URL.EXCELFILE_URL;
export const BASE_DOWNLOAD_URL_NEW = process.env.REACT_APP_ASSETS_URL; // FOR HEROKU
// export const BASE_DOWNLOAD_URL_NEW = `https://assets.kgk.live/`; // FOR AWS INFRA
export const MEDIA_URL = 'https://s3.ap-south-1.amazonaws.com/kgkgroup';

export const DEFAULT_API_ERROR = 'Something went wrong. Please contact admin.';
export const ERR_MSG = 'Something went wrong.';

export const MIME_TYPES = {
  png: ['89504e47'],
  jpeg: ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb'],
  jpg: ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb'],
  pdf: ['25504446'],
  xlsx: ['504b0304', '504b34', '504b0506', '504b0708'],
  ods: ['504b0304', '504b34', '504b0506', '504b0708'],
  xls: ['d0cf11e0'],
  csv: ['rfc4180', '5372204e', '50412c53', '53746f6e'],
  mp4: ['00020'],
};

export const PROJECT_DETAILS = {
  NAME: ProjectDetails && ProjectDetails.projectName ? ProjectDetails.projectName : 'KGK',
  LOGO_1: ProjectDetails && ProjectDetails.logo1 ? BASE_URL + ProjectDetails.logo1 : logo1Png,
  LOGO_2: ProjectDetails && ProjectDetails.logo2 ? BASE_URL + ProjectDetails.logo2 : logo1Png,
  LOGO_3: ProjectDetails && ProjectDetails.logo3 ? BASE_URL + ProjectDetails.logo3 : logo1Png,
  LOGO_4: ProjectDetails && ProjectDetails.logo4 ? BASE_URL + ProjectDetails.logo4 : topbannerPng,
};

export const bussinessTypeArray = {
  // Polished_Dealer: 'Polished Dealer',
  // Jewellery_Manufacturer: 'Jewellery Manufacturer',
  // Diamond_Manufacturer: 'Diamond Manufacturer',
  // Retailer: 'Retailer',
  // Consumer: 'Consumer',
  'Independent Jeweler': 'Independent Jeweler',
  'Online Jewelry Store': 'Online Jewelry Store',
  'Diamond Dealer/ Broker': 'Diamond Dealer/ Broker',
  'Diamond Manufacturer / Cutter': 'Diamond Manufacturer / Cutter',
  'Jewelry Manufacturer': 'Jewelry Manufacturer',
  'Jewelry Retail Chain': 'Jewelry Retail Chain',
  'Pawn shop': 'Pawn shop',
  Appraiser: 'Appraiser',
  Designer: 'Designer',
  'Not in the Diamond / Jewelry Trade': 'Not in the Diamond / Jewelry Trade',
  'Gold Buyer': 'Gold Buyer',
  'Diamond Mining': 'Diamond Mining',
  Auctioneer: 'Auctioneer',
  'Trade Association': 'Trade Association',
  'Watch Dealer': 'Watch Dealer',
  'Finance / Banking': 'Finance / Banking',
  Investor: 'Investor',
  'Jewelry Appraiser': 'Jewelry Appraiser',
  'Jewelry Retailer': 'Jewelry Retailer',
  'Diamond and Pearl Dealer / Broker': 'Diamond and Pearl Dealer / Broker',
  'Colored Stone Dealer / Broker': 'Colored Stone Dealer / Broker',
  'Estate Jewelry Dealer / Broker': 'Estate Jewelry Dealer / Broker',
  Other: 'Other',
};

export const DIAMOND_SEARCH_TYPE = {
  RECENT: 1,
  SAVE: 2,
  DEMAND: 3,
  API_SEARCH: 4,
  PAIR_SEARCH: 5,
  VOICE_SEARCH: 6,
  LUCKY_SEARCH: 7,
  ARTICLE: 8,
  COLLECTION: 9,
  INVENTORY: 10,
  API_SAVED_SEARCH: 11,
};

export const INVENTORY_PLATFORMS = [
  { key: 1, value: 'RAPNET' },
  { key: 2, value: 'R2NET' },
  { key: 3, value: 'IDEX' },
  { key: 4, value: 'MARKET_DIAMONDS' },
  { key: 5, value: 'GET_DIAMONDS' },
];
export const INVENTORY_DIAMSTATUS = [
  { key: 'B', value: 'Bid To Buy' },
  { key: 'N', value: 'New Arrival' },
];

export const INVENTORY_DIAMPRESTATUS = [
  { key: 'B', value: 'Bid To Buy' },
  { key: 'N', value: 'New Arrival' },
];
export const INVENTORY_DIAMPOSTSTATUS = [
  { key: 'B', value: 'Bid To Buy' },
  { key: 'N', value: 'New Arrival' },
];
export const INVENTORY_TIMEZONE = [
  { key: 'CET', value: 'CET' },
  // { key: 'IST', value: 'IST' },
];
export const INVENTORY_CALCOLUMN = [
  { key: 'ctPr', value: 'CTPR' },
  { key: 'back', value: 'DISCOUNT' },
];
export const INVENTORY_RESULT_TYPE = [
  { key: 'AUTO', value: 'AUTO' },
  { key: 'MANUEL', value: 'MANUEL' },
];

export const BID_WEEK_DAYS = [
  { key: 'Sunday', value: 'Sunday' },
  { key: 'Monday', value: 'Monday' },
  { key: 'Tuesday', value: 'Tuesday' },
  { key: 'Wednesday', value: 'Wednesday' },
  { key: 'Thursday', value: 'Thursday' },
  { key: 'Friday', value: 'Friday' },
  { key: 'Saturday', value: 'Saturday' },
];

export const INVENTORY_BID = [
  { key: 'DAILY', value: 'DAILY' },
  { key: 'WEEKLY', value: 'WEEKLY' },
  // { key: 'MONTHLY', value: 'MONTHLY' },
];

export const DYNAMIC_BID_TYPE = [
  { key: 1, value: 'NEW_ARRIVAL' },
  { key: 2, value: 'BID_TO_BUY' },
];
export const INVENTORY_UPLOAD_METHODS = [
  { key: 1, value: 'API' },
  { key: 2, value: 'FTP' },
];

export const INVENTORY_SYNC_TIME = [
  { key: 15, value: '15 mins' },
  { key: 30, value: '30 mins' },
  { key: 60, value: '60 mins' },
];

export const INVENTORY_FILE_TYPES = [
  { key: '.csv', value: 'csv' },
  { key: '.xlsx', value: 'xlsx' },
];

export const ACCOUNT_TERM_TYPE = {
  GLOBAL: 1,
  GROUP: 2,
  INDIVIDUAL: 3,
  SHOW: 4,
};

export const BID_TYPE = [
  { value: 1, label: 'NEW_ARRIVAL' },
  { value: 2, label: 'BID_TO_BUY' },
];

export const DIAMOND_FEATURED_GROUP_TYPE = {
  PAIR: 'pair',
  STONE: 'stone',
  EXCLUSIVE: 'exclusive',
  BEST: 'best',
  STONE_OF_DAY: 'stone_of_day',
};

export const BANNER_TYPE = {
  HOMEPAGE: 1,
};
export const DIAMOND_BLOCK = {
  TYPE: {
    HOLD: 1,
    MEMO: 2,
  },
  STATUS: {
    PENDING: 1,
    APPROVED: 2,
    REJECT: 3,
    RELEASE: 4,
  },
  OFFER: {
    APPLIED: 1,
    ACCEPTED: 2,
    REJECTED: 3,
  },
};

export const ORDER_STATUS = {
  PENDING: 1,
  APPROVE: 2,
  REJECTED: 3,
  CANCELED: 4,
};
export const OFFICE_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  CANCELED: 4,
};
export const QUOTE_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
};

export const ACCOUNT_IS_VERIFIED = {
  1: 'PENDING',
  2: 'VERIFIED',
  3: 'CANCELED',
};

export const MEMO_STATUS = {
  PENDIND: 1,
  CONFIRMED: 2,
  REJECTED: 3,
  RELEASE: 4,
};

export const SHIP_MODE = [
  { value: 'Ship To Direct', key: 0 },
  { value: 'Ship To NY', key: 1 },
  { value: 'Ship To HK', key: 2 },
  { value: 'Ship to Dubai', key: 3 },
  { value: 'Ship to Antwerp', key: 4 },
  { value: 'Ship to Japan', key: 5 },
];

export const INVOICE_DATE = [
  { value: 'Today', key: 0 },
  { value: 'Tomorrow', key: 1 },
  { value: 'Later', key: 2 },
];

export const PROJECT_SETTINGS = {
  TYPE: {
    CM_CHARGES: 1,
    BANK_RATES: 2,
    MIN_MAX: 18,
  },
};

export const MEETING_TYPE = [
  { value: 'Phone Call', key: 1 },
  { value: 'Web Conference', key: 2 },
  { value: 'In Person', key: 3 },
];
export const LEDGER_TYPE = [
  { key: 'customer', value: 'Customer' },
  { key: 'broker', value: 'Broker' },
  { key: 'supplier', value: 'Supplier' },
  { key: 'associate office', value: 'Associate office' },
  { key: 'mfg unit', value: 'Mfg unit' },
  { key: 'individual', value: 'Individual' },
  { key: 'buyer', value: 'Buyer' },
];

export const OFFERSTATUS = {
  1: 'Pending',
  2: 'Accepted',
  3: 'Rejected',
};

export const TRANSACTION_HOLD_STATUS = ['H', 'N', 'I', 'CH', 'SH', 'EH', 'CX', 'SX', 'EX'];
export const TRANSACTION_HOLD_BNR2_STATUS = ['B', 'CB', 'SB', 'EB', 'J', 'CJ', 'SJ', 'EJ'];
export const TRANSACTION_MEMO_CONSIGNMENT_STATUS = ['M', 'C1', 'C2', 'C3', 'C4', 'C5', 'T', 'CM', 'EM', 'SM'];
export const TRANSACTION_MEMO_OFFICE_STATUS = ['O'];

export const FILE_URLS = {
  // img: 'https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/VISION360/imaged/***/still.jpg', // stone id
  img: 'https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/V360Mini5/imaged/***/still.jpg', // stone id
  certFile: 'https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/Cert/***.jpeg', //certificate No
  // videoFile: 'https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/VISION360/Vision360.html?d=***&autoPlay=1', //stone id
  videoFile: 'https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/V360Mini5/Vision360.html?d=***&autoPlay=1', //stone id

  side: 'https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/Side/***.Jpeg',
  front: 'https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/Front/***.Jpeg',
  plotCerti: 'https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/Plot/***PL.jpg',
  propCerti: 'https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/Prop/***PR.jpg',

  hAFile: 'https://s3.ap-south-1.amazonaws.com/kgkgroup/HeartImages/***.jpg', //Packet No
  arrowFile: 'https://s3.ap-south-1.amazonaws.com/kgkgroup/ArrowImages/***.jpg', //Packet No
  assetFile: 'https://s3.ap-south-1.amazonaws.com/kgkgroup/AssetScopeImages/***.jpg', // Packet No
  lightBlack: 'https://cdn.pndiamonds.com/stonevideos/StoneImage_04-01-2020/***/Office_Light_Black_BG.jpg',
};

export const SHOW_SELECTION = {
  SHOW: 1,
  QUOTE_DAY: 2,
};
export const REFERENCE_BY = [
  { value: 'Client Reference', key: 1 },
  { value: 'Sales Person', key: 2 },
];

export const KYC_STATUS = [
  { key: 1, value: 'Pending' },
  { key: 2, value: 'Approved' },
  { key: 3, value: 'Rejected' },
];

export const approveStatusLabel = {
  1: 'Pending',
  2: 'Approved',
  3: 'Rejected',
};

export const approveStatus = [
  { key: 1, value: 'Pending' },
  { key: 2, value: 'Approve' },
  { key: 3, value: 'Reject' },
];

export const DateTitle = {
  offer: 'Offer Expiry Date :',
  officeView: 'Office Visit Date :',
  hold: 'Hold Expiry Date :',
};

export const REGISTRATION_TYPES = {
  1: 'International',
  2: 'International Associate',
  3: 'Domestic',
  4: 'Domestic Associate',
};

export const CUSTOMER_TYPE = [
  { key: 1, value: 'Customer' },
  { key: 2, value: 'Lead' },
];

export const packetType = [
  { name: 'All', id: 0 },
  { name: 'HK', id: 1 },
  { name: 'USA', id: 2 },
];
export const CommonPrintExcel = {
  inNewArrival: {
    prcStg: 'NEW',
    filter: {
      wSts: 'B',
      sSts: { nin: ['P', 'D'] },
    },
  },
  inUpComing: { filter: { wSts: 'U', sSts: { nin: ['P', 'D'] } } },
};

export const HkUsaPacket = {
  stock: 0,
  sale: 1,
  hold: 2,
};

export const ReviseEmailType = {
  cart: '1',
  watchlist: '2',
};

export const DiamondUploadTypes = {
  add_update: '1',
  replace_all: '2',
};
export const HOLD_MEMO_BLOCK_SETTING = {
  HOLD: '5faa3e10fee4d616f95d9c42',
  MEMO: '5f9ff997acf27d10033f0171',
};

export const Office = {
  upComing: 1,
  expire: 2,
};
export const LAB_LINKS = {
  IGI: 'https://igi.org/verify.php?r=***',
  GSI: 'http://wg.gemscience.net/vr/veri.aspx',
  GIA: 'https://www.gia.edu/report-check?reportno=***',
  HRD: 'https://my.hrdantwerp.com/?id=34&record_number=***',
};

export const USER_TYPES_LIST = (() => {
  const replaceMap = { 8: 'SALES PERSON', 5: 'GUEST' };
  const entries = Object.entries(USERS_TYPE).map(([key, value]) => [value, replaceMap?.[value] ?? key]);
  return Object.fromEntries(entries);
})();

/////
const Data = [];
const masterChild = [];
MENU.forEach((menuItem) => {
  if (menuItem.children) {
    menuItem.children.forEach((subMenuItem) => {
      Data.push({
        module: subMenuItem.name,
        title: _capitalize(subMenuItem.name),
        id: subMenuItem.id,
        permissions: { insert: false, update: false, delete: false, view: false },
      });
      masterChild.push({ name: subMenuItem.name, id: subMenuItem.id, parentid: menuItem.id });
    });
  } else {
    Data.push({
      module: menuItem.name,
      title: _capitalize(menuItem.name),
      id: menuItem.id,
      permissions: { insert: false, update: false, delete: false, view: false },
    });
  }
});

Data.push(
  {
    module: 'sync',
    title: 'Sync Function',
    id: 201,
    permissions: { insert: false, update: false, delete: false, view: false },
  },
  {
    module: 'UserActive',
    title: 'User Active',
    id: 202,
    permissions: { insert: false, update: false, delete: false, view: false },
  },
  {
    module: 'UserResetpass',
    title: 'User ResetPassword',
    id: 203,
    permissions: { insert: false, update: false, delete: false, view: false },
  },
  {
    module: 'UserPagePermission',
    title: 'User Page PermissionIcon',
    id: 204,
    permissions: { insert: false, update: false, delete: false, view: false },
  },
);

export const SUPERADMINPERMISSION = Data;
export const MASTERCHILD = { child: masterChild };
