import React, { Component } from 'react';
import ScrollArea from 'react-scrollbar';

import _ from 'lodash';

import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import LeftMultipleSelect from 'containers/LeftOption/MultiSelect';

import Storage from 'services/storage';
import UtilService from 'services/util';

import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

import resetSvg from '../../../assets/svg/reset.svg';

const quickSearch = [
  {
    colors: [
      { id: 'D', name: 'DEF' },
      { id: 'E', name: 'DEF' },
      { id: 'F', name: 'DEF' },
      { id: 'G', name: 'GHI' },
      { id: 'H', name: 'GHI' },
      { id: 'I', name: 'GHI' },
      { id: 'J', name: 'J-' },
      { id: 'K', name: 'J-' },
      { id: 'L', name: 'J-' },
      { id: 'M', name: 'J-' },
      { id: 'N', name: 'J-' },
      { id: 'O', name: 'J-' },
      { id: 'P', name: 'J-' },
      { id: 'Q-R', name: 'J-' },
      { id: 'S-T', name: 'J-' },
      { id: 'U-V', name: 'J-' },
      { id: 'W-X', name: 'J-' },
      { id: 'Y-Z', name: 'J-' },
    ],
    clarity: [
      { id: 'LC', name: 'LC' },
      { id: 'FL', name: 'FL-IF' },
      { id: 'IF', name: 'FL-IF' },
      { id: 'VVS1', name: 'VVS' },
      { id: 'VVS2', name: 'VVS' },
      { id: 'VS1', name: 'VS' },
      { id: 'VS1-', name: 'VS' },
      { id: 'VS1+', name: 'VS' },
      { id: 'VS2', name: 'VS' },
      { id: 'VS2-', name: 'VS' },
      { id: 'VS2+', name: 'VS' },
      { id: 'SI1', name: 'SI' },
      { id: 'SI1-', name: 'SI' },
      { id: 'SI1+', name: 'SI' },
      { id: 'SI2', name: 'SI' },
      { id: 'SI2+', name: 'SI' },
      { id: 'SI2-', name: 'SI' },
      { id: 'SI3', name: 'SI' },
      { id: 'SI3-', name: 'SI' },
      { id: 'SI3+', name: 'SI' },
      { id: 'I1', name: 'I' },
      { id: 'I1-', name: 'I' },
      { id: 'I1+', name: 'I' },
      { id: 'I2', name: 'I' },
      { id: 'I2-', name: 'I' },
      { id: 'I2+', name: 'I' },
      { id: 'I3', name: 'I' },
      { id: 'I3-', name: 'I' },
      { id: 'I3+', name: 'I' },
    ],
  },
];

class InventoryQuickSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shape: [],
      caratSizeGroup: {},
      finalResult: [],
      searched0: '',
      searched1: '',
      searched2: '',
      caratsArr: [],

      colorIds: [],
      clarityIds: [],
      selectedShapes: [],
      selectedColor: [],
      selectedClars: [],
      selectedCarats: undefined,
    };
  }

  getData() {
    const hkw_master = Storage.get('dn-master');
    if (hkw_master) {
      this.setState(
        {
          shape: hkw_master.SHAPE,
          colors: hkw_master.COLOR,
          clars: hkw_master.CLARITY,
          caratSizeGroup: _.groupBy(hkw_master.SIZE, 'group'),
          selectedCarats: Object.keys(_.groupBy(hkw_master.SIZE, 'group')),
        },
        () => {
          this.selectedMaster([]);
        },
      );

      const colorGroup = _.groupBy(quickSearch[0].colors, 'name');
      const clarityGroup = _.groupBy(quickSearch[0].clarity, 'name');
      const carats = _.groupBy(hkw_master.SIZE, 'group');
      const colorIds = [];
      const clarityIds = [];
      const finalResult = [];
      const caratsArr = [];

      Object.keys(carats).forEach((crt) => {
        caratsArr.push({
          group: carats[crt][0]['group'],
          fromCarat: carats[crt][0]['fromCarat'],
          toCarat: carats[crt][0]['toCarat'],
        });
      });

      _.each(colorGroup, (val1) => {
        let arr = [];
        _.each(val1, (val2) => {
          _.each(hkw_master.COLOR, (clr) => {
            if (val2.id === clr.webDisplay) {
              arr = _.concat(arr, clr.id);
            }
          });
        });
        colorIds.push({ group: val1[0].name, Ids: arr });
      });

      _.each(clarityGroup, (val1) => {
        let arr = [];
        _.each(val1, (val2) => {
          _.each(hkw_master.CLARITY, (clar) => {
            if (val2.id === clar.webDisplay) {
              arr = _.concat(arr, clar.id);
            }
          });
        });
        clarityIds.push({ group: val1[0].name, Ids: arr });
      });

      _.each(colorIds, (val1) => {
        const clarity = [];
        _.each(clarityIds, (val2) => {
          const carat = [];
          _.each(carats, (val3) => {
            carat.push({
              caratName: val3[0].group,
              from: _.head(val3).fromCarat,
              to: _.last(val3).toCarat,
              count: 0,
            });
          });
          clarity.push({
            clarityName: val2.group,
            clarityIds: val2.Ids,
            carat: carat,
          });
        });
        finalResult.push({
          colorName: val1.group,
          colorIds: val1.Ids,
          clarity: clarity,
        });
      });

      this.setState({ finalResult, colorIds, clarityIds, caratsArr });
    }
  }

  componentDidMount() {
    this.getData();
  }

  selectedMaster = () => {
    const sizes = [];
    const self = this;
    const { selectedShapes, selectedColor, selectedClars, colorIds, clarityIds } = this.state;
    const finalColors = [];
    const finalClars = [];

    _.map(colorIds, (cc) => {
      if (_.includes(selectedColor, cc.group)) {
        finalColors.push(cc.Ids);
      }
    });

    _.map(clarityIds, (cc) => {
      if (_.includes(selectedClars, cc.group)) {
        finalClars.push(cc.Ids);
      }
    });

    _.each(this.state.caratSizeGroup, (size) => {
      if (_.includes(this.state.selectedCarats, size[0].group)) {
        sizes.push({
          id: size[0].group,
          from: _.head(size).fromCarat,
          to: _.last(size).toCarat,
        });
      }
    });

    const apiObj = {
      range: sizes,
      shp: selectedShapes,
      col: _.flatten(finalColors),
      clr: _.flatten(finalClars),
    };

    const api = '/admin/v1/diamond/quick-search';
    const objData = {
      method: 'post',
      url: api,
      request: apiObj,
    };

    const finalResult = self.state.finalResult;
    _.each(finalResult, (val1) => {
      _.each(val1.clarity, (val2) => {
        _.each(val2.carat, (val3) => {
          val3.count = 0;
        });
      });
    });

    UtilService.callApi(objData, (err, data) => {
      if (err) throw err;
      if (!data) return;
      let result = data.data.list;
      result = _.groupBy(result, 'col');
      _.each(result, (colorVal, colorKey) => {
        result[colorKey] = _.groupBy(colorVal, 'clr');
        _.each(result[colorKey], (clarityVal, clarityKey) => {
          result[colorKey][clarityKey] = _.groupBy(clarityVal, 'pointer');
          _.each(result[colorKey][clarityKey], (pointerVal, pointerKey) => {
            result[colorKey][clarityKey][pointerKey] = { count: 0 };
            _.each(pointerVal, (pointerVal) => {
              result[colorKey][clarityKey][pointerKey].count += pointerVal.count || 0;
              //console.log(pointerVal.count)
            });
          });
        });
      });

      // eslint-disable-next-line array-callback-return
      Object.keys(result).map((colorKey) => {
        _.each(finalResult, (val1) => {
          _.each(val1.colorIds, (clrId) => {
            if (clrId === colorKey) {
              _.each(val1.clarity, (clar) => {
                _.each(clar.clarityIds, (clarId) => {
                  // eslint-disable-next-line array-callback-return
                  Object.keys(result[colorKey]).map((clarKey) => {
                    if (clarKey === clarId) {
                      _.each(clar.carat, (car) => {
                        // eslint-disable-next-line array-callback-return
                        Object.keys(result[colorKey][clarKey]).map((caratKey) => {
                          if (caratKey === car.caratName) {
                            car.count += result[colorKey][clarKey][caratKey].count;
                          }
                        });
                      });
                    }
                  });
                });
              });
            }
          });
        });
      });

      self.setState({ finalResult: finalResult, loader: false, searched0: '', searched1: '', searched2: '' });
    });
  };

  handleSearch(caratFrom, caratTo, clar, color, index0, index1, index2) {
    const self = this;
    const apiObj = {
      shp: self.state.selectedShapes,
      col: color,
      or: [{ crt: { '>=': caratFrom, '<=': caratTo } }],
      clr: clar,
    };

    _.each(apiObj, (val, key) => {
      if (_.isArray(val) && val.length === 0) delete apiObj[key];
      else if (_.isArray(val)) apiObj[key] = val;
    });

    const obj = {
      filters: [apiObj],
      isNotReturnTotal: true,
      isReturnCountOnly: true,
    };
    const api = '/admin/v1/diamond/paginate';
    const objData = {
      method: 'post',
      url: api,
      request: obj,
      config: { loader: false },
    };

    UtilService.callApi(objData, (err, data) => {
      if (err) {
        if (err.data && err.data.message) {
          self.setState({ searched1: index1, searched2: index2, searched0: index0 });
        }
      }
      if (data && data.code === 'OK') {
        self.setState({ searched1: index1, searched2: index2, searched0: index0 });
        window.open(`${PREFIX_URL_WITH_SLASH}/inventory/single-stone/search?result=` + data.data[0].filter.id);
      }
    });
  }

  render() {
    const carats = Object.keys(this.state.caratSizeGroup);
    const { finalResult, selectedCarats } = this.state;

    return (
      <>
        <div className="userInv_header">
          <h2>Inventory Quick Search</h2>
        </div>
        <GridHeightContainer subtractFrom=".customerMaintab,.customerSubTab" className="quickSearchMainWrapper">
          <div className="quickSearcLeft">
            <div className="">
              <LeftMultipleSelect
                label="Select Shape"
                placeholder="Shape"
                mode="multiple"
                selectValue={this.state.shape}
                nameKey="name"
                valueKey="id"
                value={this.state.selectedShapes}
                onChange={(e) => this.setState({ selectedShapes: e }, () => this.selectedMaster())}
                image={resetSvg}
                onImageClick={() => this.setState({ selectedShapes: [] }, () => this.selectedMaster())}
              />
            </div>
            <div className="">
              <LeftMultipleSelect
                label="Select Color Range"
                placeholder="Color Range"
                selectValue={this.state.colorIds}
                nameKey="group"
                valueKey="group"
                mode="multiple"
                value={this.state.selectedColor}
                image={resetSvg}
                onChange={(e) => this.setState({ selectedColor: e }, () => this.selectedMaster())}
                onImageClick={() => this.setState({ selectedColor: [] }, () => this.selectedMaster())}
              />
            </div>
            <div className="">
              <LeftMultipleSelect
                label="Select Clarity Range"
                placeholder="Clarity Range"
                value={this.state.selectedClars}
                nameKey="group"
                valueKey="group"
                selectValue={this.state.clarityIds}
                mode="multiple"
                image={resetSvg}
                onChange={(e) => this.setState({ selectedClars: e }, () => this.selectedMaster())}
                onImageClick={() => this.setState({ selectedClars: [] }, () => this.selectedMaster())}
              />
            </div>
            <LeftMultipleSelect
              label="Select Pointer"
              placeholder="Pointer"
              value={
                selectedCarats && selectedCarats.length !== Object.keys(this.state.caratSizeGroup).length
                  ? selectedCarats
                  : undefined
              }
              nameKey="group"
              valueKey="group"
              selectValue={this.state.caratsArr}
              mode="multiple"
              image={resetSvg}
              onChange={(e) => {
                this.setState(
                  { selectedCarats: e.length > 0 ? _.sortBy(e) : Object.keys(this.state.caratSizeGroup) },
                  () => this.selectedMaster(),
                );
              }}
              onImageClick={() =>
                this.setState({ selectedCarats: Object.keys(this.state.caratSizeGroup) }, () => this.selectedMaster())
              }
            />
          </div>
          <GridHeightContainer subtractFrom=".customerMaintab,.customerSubTab" className="quickSearchRight">
            <div className="quickSearchTable">
              <ScrollArea
                speed={0.8}
                className="settingLeftScroll"
                contentClassName="content"
                smoothScrolling={Boolean}
              >
                <table>
                  <tbody>
                    <tr key="tr0">
                      <th scope="col" rowSpan={2} valign="middle" style={{ textAlign: 'center' }}>
                        Color
                      </th>
                      <th scope="col" rowSpan={2} valign="middle" style={{ textAlign: 'center' }}>
                        Clarity
                      </th>
                      <th colSpan={carats.length} scope="col" style={{ textAlign: 'center' }}>
                        Carat
                      </th>
                    </tr>
                    <tr className="rowback" key="tr1">
                      {_.map(selectedCarats, (val) => {
                        return (
                          <td key={val} style={{ textAlign: 'center' }}>
                            {val}
                          </td>
                        );
                      })}
                    </tr>
                    {Object.keys(finalResult).map((key, index0) => {
                      return finalResult[key].clarity.map((clar, index1) => {
                        return (
                          <tr key={index1 + 'intr'} className="quick-search-title-cell" style={{ textAlign: 'center' }}>
                            {index1 === 0 && (
                              <td
                                className="quick-search-title-cell"
                                style={{ textAlign: 'center' }}
                                rowSpan={finalResult[key].clarity.length}
                              >
                                {finalResult[key].colorName}
                              </td>
                            )}
                            <td className="quick-search-title-cell" style={{ textAlign: 'center' }}>
                              {clar.clarityName}
                            </td>

                            {clar.carat.map((carat, index2) => {
                              if (_.includes(selectedCarats, carat.caratName)) {
                                carat.selected = false;

                                if (
                                  this.state.searched2 === index2 &&
                                  this.state.searched1 === index1 &&
                                  this.state.searched0 === index0
                                )
                                  carat.selected = true;
                                return (
                                  <td
                                    key={carat + index2}
                                    onClick={this.handleSearch.bind(
                                      this,
                                      carat.from,
                                      carat.to,
                                      clar.clarityIds,
                                      finalResult[key].colorIds,
                                      index0,
                                      index1,
                                      index2,
                                    )}
                                    className={
                                      carat.count === 0
                                        ? 'quick-search-title-cell quick-search-carat-cells pointer-events-none'
                                        : carat.selected
                                        ? 'quick-search-title-cell quick-search-carat-cells active'
                                        : 'quick-search-title-cell quick-search-carat-cells'
                                    }
                                    style={{ textAlign: 'center' }}
                                  >
                                    {carat.count === 0 ? '-' : carat.count}
                                  </td>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </table>
              </ScrollArea>
            </div>
          </GridHeightContainer>
        </GridHeightContainer>
      </>
    );
  }
}

export default InventoryQuickSearch;
