import React from 'react';
import Magnifier from 'react-magnifier';

import { isFunction } from 'lodash';

import { useBoolean } from 'util/hooks';

const MagnifierImage = React.memo(({ children = 'No Image Found.', zoomFactor = 1, src, onError, ...props }) => {
  const [error, setError] = useBoolean(false);

  return (
    <>
      {error && <>{children}</>}
      {!error && (
        <Magnifier
          zoomFactor={zoomFactor}
          src={src}
          onError={() => {
            setError.toggle();
            if (isFunction(props.onError)) {
              props.onError();
            }
          }}
        />
      )}
    </>
  );
});

export default MagnifierImage;
