import React from 'react';

import { Select } from 'antd';

// import { useCustomRef } from 'util/hooks';
import { hasKey, isArray, isEmpty } from 'util/utils';

import './selectOption.less';

function SelectOption(props) {
  const {
    label,
    selectValue,
    options,
    nameKey = 'label',
    valueKey = 'id',
    className,
    error,
    required,
    ...rest
  } = props;

  const optionList = React.useMemo(() => {
    const list = options ?? selectValue;

    return isArray(list)
      ? list.map((option) => ({
          ...option,
          value: !hasKey(option, valueKey) ? `${option.key}` : `${option[valueKey]}`,
          label: !hasKey(option, nameKey) ? `${option.value}` : `${option[nameKey]}`,
        }))
      : [];
  }, [nameKey, options, selectValue, valueKey]);

  const optionListRef = React.useRef({ optionList, ...props });

  const value = React.useMemo(() => {
    return props.mode === 'multiple' ? props.value : optionList.find((option) => option.value === props.value)?.value;
  }, [optionList, props.mode, props.value]);

  React.useEffect(() => {
    optionListRef.current = { optionList, ...props };
  }, [optionList, props]);

  React.useEffect(() => {
    const { optionList, mode, onChange } = optionListRef.current;
    const defaultValues = optionList.filter((option) => option.selected).map((option) => option?.value);
    if (!isEmpty(defaultValues)) void onChange?.(mode === 'multiple' ? defaultValues : defaultValues?.[0]);
  }, []);

  return (
    <div className={`formSelect from-group ${className}` + (error ? ' error-message' : '')}>
      <label>
        {label} {required && <span className="required-ast">*</span>}
      </label>
      <Select
        getPopupContainer={(trigger) => trigger}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        allowClear
        showArrow
        showSearch
        {...rest}
        value={value}
      >
        {optionList.map((option, key) => (
          <Select.Option key={key} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default React.memo(SelectOption);
