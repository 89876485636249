import React from 'react';

import { head, isEmpty, pick, last, map, split } from 'lodash';
import moment from 'moment';

import SelectOption from 'containers/SelectOption';
import 'react-calendar/dist/Calendar.css';
import TextArea from 'containers/TextArea';

import { createOffice } from 'services/officeApi';

import { isObject } from 'util/utils';

import { MEETING_TYPE } from 'constants/Common';
import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

import { fetchPartyRelatedInfo, isValue } from '../CommonPopupFunction';
import PartyBuyerComponent from '../PopupComponent/PartyBuyerComponent';
import SellerComponent from '../PopupComponent/SellerComponent';

class AppointmentForm extends React.Component {
  state = {
    user: this.props.client?.user?.id ? this.props.client.user.id : null,
    userAccount: this.props.client?.id ? this.props.client.id : null,
    seller: isObject(this.props.client?.seller) ? this.props.client.seller?.id : null,
    sellerList: [],
    loading: false,
    error: {},
    client: !isEmpty(this.props.client) ? [this.props.client] : [],
    comment: '',
    virtualType: null,
  };

  errorHandel = (param) => {
    const { fields, isSendInvite } = param;
    const error = this.state.error;
    if (isSendInvite) {
      const data = pick(this.state, ['userAccount', 'user', 'virtualType']);
      Object.keys(data).forEach((d) => {
        if (isEmpty(data?.[d])) error[d] = Messages[`${d}Select`];
      });
    } else {
      fields.forEach((filed) => delete error[filed]);
    }
    this.setState({ error });
  };

  fetchClientOtherData = () => {
    const clientOtherData = fetchPartyRelatedInfo(this.state.client, this.state.user);
    this.setState({ ...this.state, ...clientOtherData });
  };

  onClickSendInvite = async () => {
    this.errorHandel({ isSendInvite: true });

    const { virtualType, user, userAccount, error, comment, seller } = this.state;
    const { slot, apiSlot, date, checked, cabinId } = this.props;
    if (!cabinId) return OpenNotification({ type: 'error', title: 'Please Select Branch.' });
    if (!date) return OpenNotification({ type: 'error', title: 'Please Select Date.' });
    if (!slot) return OpenNotification({ type: 'error', title: 'Please Select Slot.' });

    if (isEmpty(error)) {
      const start = moment(`${date} ${head(split(slot, ' - '))}`, 'YYYY-MM-DD HH:mm:ss A').toISOString();
      const end = moment(`${date} ${last(split(slot, ' - '))}`, 'YYYY-MM-DD HH:mm:ss A').toISOString();

      const obj = {
        companyId: userAccount,
        customerId: user,
        purpose: comment,
        seller,
        date: moment(date).toISOString(),
        type: 2,
        start: start,
        end: end,
        cabinSlot: [apiSlot],
        diamonds: map(checked, 'id'),
        meetingType: virtualType,
        cabin: cabinId,
      };
      this.props.setLoader();
      const [, res] = await createOffice(obj);
      if (res.code === 'OK') {
        this.props.setLoader();
        this.props.onClose();
        this.props.unCheckedDiamond();
        OpenNotification({
          type: 'success',
          title: res.message,
        });
      } else {
        this.props.setLoader();
      }
    }
  };
  render() {
    return (
      <div className="d-flex flex-wrap align-items-normal">
        <PartyBuyerComponent
          errorHandel={() => this.errorHandel({ fields: ['userAccount', 'user'] })}
          error={this.state.error}
          fetchClientOtherData={this.fetchClientOtherData}
          setUserDetails={(data) => {
            this.setState({ ...data });
          }}
          initialData={pick(this.state, ['client', 'userAccount', 'user'])}
        />
        <SellerComponent
          lebel="Salesman"
          errorHandel={() => this.errorHandel({ fields: ['seller'] })}
          error={this.state.error}
          setSellerDetails={(data) => {
            this.setState({ ...data });
          }}
          initialData={pick(this.state, ['seller'])}
        />

        <TextArea
          label="Comments"
          placeholder="Enter Comments"
          onChange={(e) => {
            this.setState({ comment: e.target.value });
          }}
          value={this.state.comment}
        />
        <div className="selectVirtualAction">
          <SelectOption
            placeholder="Select Appointment Type *"
            onChange={(virtualType = null) => {
              if (virtualType) this.errorHandel({ fields: ['virtualType'] });
              this.setState({ virtualType });
            }}
            onSearch={() => {
              this.setState({ virtualType: null });
            }}
            value={isValue(this.state.virtualType)}
            selectValue={MEETING_TYPE}
            error={this.state.error.virtualType}
          />
          <button onClick={this.onClickSendInvite}>Request Appointment</button>
        </div>
      </div>
    );
  }
}

export default AppointmentForm;
