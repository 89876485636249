import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';

import { Menu, Dropdown, Drawer, Tooltip } from 'antd';
import Cookies from 'js-cookie';
import moment from 'moment';

import ClientSidebar from 'components/ShortCuts/ClientDrawer';
import AddKYC from 'components/ShortCuts/KYC';
import UserDrawer from 'components/ShortCuts/UserDrawer';
import AddTrade from 'components/Trade/AddTrade';

import { PermissionService } from 'services/PermissionService';
import UtilService from 'services/util';

import { getMenuLinks } from 'constants/Menu';
import { THEME_TYPE_LITE } from 'constants/ThemeSetting';
// import { getPermittedInsertList, MAINMODULE, MODULES } from 'constants/permission';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';

import { ReactComponent as Trade } from '../../assets/svg/dashboard/email.svg';

import { ReactComponent as Account } from 'assets/svg/dashboard/account.svg';
import { ReactComponent as Client } from 'assets/svg/dashboard/addClient.svg';
import { ReactComponent as AddUser } from 'assets/svg/dashboard/addUser.svg';
import { ReactComponent as Appointment } from 'assets/svg/dashboard/appointment.svg';
import { ReactComponent as Cabin } from 'assets/svg/dashboard/cabin.svg';
import { ReactComponent as Dashboard } from 'assets/svg/dashboard/dashboard.svg';
import { ReactComponent as Diamond } from 'assets/svg/dashboard/diamond.svg';
import { ReactComponent as Enquiry } from 'assets/svg/dashboard/enquiry.svg';
import { ReactComponent as Feedback } from 'assets/svg/dashboard/feedback.svg';
import { ReactComponent as Master } from 'assets/svg/dashboard/master.svg';
import { ReactComponent as Media } from 'assets/svg/dashboard/media.svg';
import { ReactComponent as Bid, ReactComponent as Notification } from 'assets/svg/dashboard/notification.svg';
import { ReactComponent as ParcelMaster } from 'assets/svg/dashboard/parcel-master.svg';
import { ReactComponent as Price } from 'assets/svg/dashboard/price.svg';
import { ReactComponent as Pricing } from 'assets/svg/dashboard/pricing.svg';
import { ReactComponent as Reports } from 'assets/svg/dashboard/reports.svg';
import { ReactComponent as Setting } from 'assets/svg/dashboard/setting.svg';
import { ReactComponent as Stocksummary } from 'assets/svg/dashboard/stock-summary.svg';
import { ReactComponent as Stock } from 'assets/svg/dashboard/stock.svg';
import { ReactComponent as Todo } from 'assets/svg/dashboard/todo.svg';
import { ReactComponent as Transactions } from 'assets/svg/dashboard/transactions.svg';
import { ReactComponent as User } from 'assets/svg/dashboard/user.svg';
import { ReactComponent as QuickSearch } from 'assets/svg/dashboard/quick-search.svg';
// import { ReactComponent as KYC } from 'assets/svg/dashboard/Kyc.svg';

const Icons = {
  Dashboard: <Dashboard />,
  Master: <Master />,
  Account: <Account />,
  User: <User />,
  Diamond: <Diamond />,
  Stock: <Stock />,
  Setting: <Setting />,
  Enquiry: <Enquiry />,
  Price: <Price />,
  Feedback: <Feedback />,
  Media: <Media />,
  Cabin: <Cabin />,
  Stocksummary: <Stocksummary />,
  Appointment: <Appointment />,
  ParcelMaster: <ParcelMaster />,
  Transactions: <Transactions />,
  Notification: <Notification />,
  Reports: <Reports />,
  Pricing: <Pricing />,
  Todo: <Todo />,
  Trade: <Trade />,
  Bid: <Bid />,
  QuickSearch: <QuickSearch />,
};

const SidebarContent = (props) => {
  const [state, setState] = useState({
    collapsed: false,
    client: false,
    user: false,
    trade: false,
    sales: false,
    purchase: false,
    bid: false,
    kyc: false,
  });
  // const [addBid, setAddBid] = useState(
  //   getPermittedInsertList(MAINMODULE.BIDSHOW, MODULES.BIDSHOW.APPOINTMENT.NAME)?.length || 0,
  // );
  // const [addTrade, setAddTrade] = useState(
  //   Boolean(getPermittedInsertList(MAINMODULE.TRADESHOW, MODULES.TRADESHOW.APPOINTMENT.NAME)?.length) || 0,
  // );
  const greenShade = '#6bc950';
  const yellowShade = '#ffc600';
  const redShade = '#c95050';
  const defaultShade = '#c7c7c7';
  const [syncRes, setSyncRes] = useState([]);
  const [indSync, setIndSync] = useState({});
  const [belSync, setBelSync] = useState({});
  const [indStatus, setIndStatus] = useState(defaultShade);
  const [belStatus, setBelStatus] = useState(defaultShade);

  const stateBasedOnPermission = () => {
    return {
      links: getMenuLinks(),
      addKyc: PermissionService.getPermission('shortcuts.client_kyc')?.allow,
      addUser: PermissionService.getPermission('shortcuts.user')?.allow,
    };
  };

  useEffect(() => {
    setState({ ...state, ...stateBasedOnPermission() });
    return () => {
      PermissionService.events.updateTree.listen(() => setState({ ...state, ...stateBasedOnPermission() }));
    };
  }, []);

  // const openClientDrawer = () => setState({ ...state, client: true });
  const openUserDrawer = () => setState({ ...state, user: true });
  // const openTrade = () => setState({ ...state, trade: true });
  // const addInvUser = () => setState({ ...state, trade: true });
  const openBid = () => setState({ ...state, bid: true });
  // const openSalesDrawer = () => setState({ ...state, sales: true });
  // const openPurchaseDrawer = () => setState({ ...state, purchase: true });
  const openKYCDrawer = () => setState({ ...state, kyc: true });
  const closeDrawers = () =>
    setState({ ...state, trade: false, client: false, user: false, sales: false, purchase: false, kyc: false });

  const addInventoryRoute = `${PREFIX_URL_WITH_SLASH}/inventory-user`;

  const getShortcutsMenuOverlay = () => (
    <Menu className="shortCutMenu">
      <h2>Add</h2>
      <Menu.Divider />
      {state.addKyc && (
        <Menu.Item key="1" onClick={openKYCDrawer}>
          <Client />
          Clients / KYC
        </Menu.Item>
      )}
      {state.addUser && (
        <Menu.Item key="2" onClick={openUserDrawer}>
          <AddUser />
          User
        </Menu.Item>
      )}
      {/* {addTrade && (
        <Menu.Item key="3" onClick={openTrade}>
          <AddUser />
          Trade Show
        </Menu.Item>
      )} */}

      <NavLink to={addInventoryRoute}>
        <Menu.Item key="3">
          <AddUser />
          Add Inventory User
        </Menu.Item>
      </NavLink>

      {state.addBid && (
        <Menu.Item key="4" onClick={openBid}>
          <AddUser />
          Bid Show
        </Menu.Item>
      )}
    </Menu>
  );

  const fetchSyncRes = useCallback(async () => {
    const token = await Cookies.get('token');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    };
    const apiRes = await UtilService.callApiAsync({ method: 'post', url: 'admin/v1/dashboard/sync-info', config });
    const data = await apiRes?.filter((item) => {
      if (typeof item === 'object' && item !== null) {
        return item;
      } else {
        return null;
      }
    });
    // console.log('api data', data?.[0]?.data);

    setSyncRes(data?.[0]?.data);
  }, []);

  const setMinutes = async (time) => {
    const now = moment(new Date()); //todays date
    const end = moment(time); // another date
    const duration = moment.duration(now.diff(end));
    const minutes = duration.asMinutes().toString();
    // return minutes;
    return Math.trunc(minutes);
  };

  useMemo(async () => {
    if (Object.keys(indSync).length > 0) {
      //const minutes = await setMinutes(indSync.endDateTime);
      // console.log('indSync', indSync);
      if (!indSync?.response?.flag) {
        // console.log('setting red clr ind',minutes);
        setIndStatus(redShade);
      } else {
        // if (minutes <= '10') {
        //   console.log('setting green clr ind', minutes);
        setIndStatus(greenShade);
        // } else if (minutes > '10' && minutes < '20') {
        //   console.log('setting yellow clr ind', minutes);
        //   setIndStatus(yellowShade);
        // } else if (minutes >= '20') {
        //   console.log('setting red clr ind', minutes);
        //   setIndStatus(redShade);
        // }
      }
    }
  }, [indSync]);

  useMemo(async () => {
    if (Object.keys(belSync).length > 0) {
      // console.log('belSync', belSync);
      setBelStatus(belSync.response.statusColor);
      // if (!belSync?.response?.flag) {
      //   // console.log('setting red clr ind',minutes);
      //   setBelStatus(redShade);
      // } else {
      //   setBelStatus(greenShade);
      // }
      //const minutes = await setMinutes(belSync.endDateTime);

      // console.log('belSync', belSync);
      //if (!belSync?.response?.flag) {
      //   // console.log('setting red clr belgium');
      //   setBelStatus(redShade);
      // }
      // if (minutes <= '10') {
      //   // console.log('setting green clr belgium');
      //   setBelStatus(greenShade);
      // } else if (minutes > '10' && minutes < '20') {
      //   // console.log('setting yellow clr belgium');
      //   setBelStatus(yellowShade);
      // } else if (minutes >= '20') {
      //   // console.log('setting red clr belgium');
      //   setBelStatus(redShade);
      // }
    }
  }, [belSync]);

  useMemo(() => {
    syncRes?.length > 0 &&
      syncRes.forEach((r) => {
        if (r?.locNm === 'IND') {
          //console.log('indian data', r);
          //setIndStatus(r.response.statusColor);
          setIndSync(r);
        } else {
          //console.log('belgium data', r);
          // setBelStatus(r.response.statusColor);
          setBelSync(r);
        }
      });
  }, [syncRes]);

  useEffect(() => {
    fetchSyncRes();
    setInterval(() => {
      fetchSyncRes();
    }, 300000);
  }, []);

  const themeType = THEME_TYPE_LITE;

  return (
    <React.Fragment>
      <div className="gx-sidebar-content">
        <SidebarLogo />
        <ScrollArea
          speed={0.8}
          className="settingLeftScroll area "
          // className="settingLeftScroll area sideBarScroll"
          contentClassName="content"
          smoothScrolling
        >
          <Menu mode="inline" theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}>
            {state.links?.map?.((menu) => (
              <Menu.Item
                className={props.location?.pathname.includes(menu?.path) ? 'ant-menu-item-selected' : ''}
                key={menu?.path && menu.path.replace('/', '')}
              >
                <NavLink to={menu?.path}>
                  {Icons?.[menu?.svg]}
                  <span className="vertical-menu">{menu?.name}</span>
                </NavLink>
              </Menu.Item>
            ))}
          </Menu>
        </ScrollArea>
      </div>
      <div className="gx-sidebar-content">
        <Tooltip title="Sync [Every 5 Min] | Green : Synced, Yellow : Syncing, Red : Failed" placement="right">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#c7c7c7',
              cursor: 'pointer',
              padding: '0px 0px 20px 0px',
            }}
          >
            <span
              style={{
                padding: '0px 0px 5px 0px',
              }}
            >
              Sync Status
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'end',
              }}
            >
              BEL
              <span
                style={{
                  display: 'block',
                  borderRadius: '50%',
                  borderStyle: 'solid',
                  margin: '0px 0px 0px 15px',
                  borderColor: 'black',
                  height: 20,
                  width: 20,
                  backgroundColor: belStatus,
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'end',
              }}
            >
              IND
              <span
                style={{
                  display: 'block',
                  borderRadius: '50%',
                  borderStyle: 'solid',
                  margin: '0px 0px 0px 15px',
                  borderColor: 'black',
                  height: 20,
                  width: 20,
                  backgroundColor: indStatus,
                }}
              />
            </div>
          </div>
        </Tooltip>
      </div>
      <div className="gx-sidebar-content">
        {(state.addKyc || state.addUser) && (
          <Menu mode="inline">
            <Dropdown
              overlay={getShortcutsMenuOverlay}
              trigger={['click']}
              overlayClassName="shortCutPopup"
              placement="bottomCenter"
            >
              <Menu.Item>
                <svg
                  id="Capa_1"
                  enableBackground="new 0 0 512 512"
                  height="512"
                  viewBox="0 0 512 512"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path d="m446.605 124.392-119.997-119.997c-2.801-2.802-6.624-4.395-10.608-4.395h-210c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45v-332c0-4.09-1.717-7.931-4.395-10.608zm-115.605-73.179 68.787 68.787h-53.787c-8.271 0-15-6.729-15-15zm75 430.787h-300c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h195v75c0 24.813 20.187 45 45 45h75v317c0 8.271-6.729 15-15 15z" />
                    <path d="m346 212h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                    <path d="m346 272h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                    <path d="m346 332h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                    <path d="m286 392h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                  </g>
                </svg>
                <span className="vertical-menu">Shortcuts</span>
              </Menu.Item>
            </Dropdown>
          </Menu>
        )}
      </div>

      <UserProfile />
      <Drawer
        onClose={closeDrawers}
        visible={state.client}
        wrapClassName="diamondListinSidebar lg-size shortCutForm"
        destroyOnClose
      >
        {state.client && <ClientSidebar />}
      </Drawer>
      <Drawer
        onClose={closeDrawers}
        visible={state.user}
        wrapClassName="diamondListinSidebar lg-size shortCutForm"
        destroyOnClose
      >
        {state.user && <UserDrawer closeDrawer={closeDrawers} />}
      </Drawer>
      <Drawer
        onClose={closeDrawers}
        visible={state.trade}
        wrapClassName="diamondListinSidebar lg-size shortCutForm"
        destroyOnClose
      >
        <AddTrade closeDrawer={closeDrawers} />
      </Drawer>
      <Drawer
        title={false}
        onClose={closeDrawers}
        visible={state.kyc}
        wrapClassName="diamondListinSidebar xl-size shortCutForm"
        destroyOnClose
      >
        {state.kyc && <AddKYC selectedIndex={0} onCancel={closeDrawers} addAccount />}
      </Drawer>
    </React.Fragment>
  );
};

export default withRouter(SidebarContent);

// import React, { Component } from 'react';
// import { NavLink, withRouter } from 'react-router-dom';
// import ScrollArea from 'react-scrollbar';

// import { Menu, Dropdown, Drawer } from 'antd';

// import ClientSidebar from 'components/ShortCuts/ClientDrawer';
// import AddKYC from 'components/ShortCuts/KYC';
// import UserDrawer from 'components/ShortCuts/UserDrawer';
// import AddTrade from 'components/Trade/AddTrade';

// import { PermissionService } from 'services/PermissionService';

// import { getMenuLinks } from 'constants/Menu';
// import { THEME_TYPE_LITE } from 'constants/ThemeSetting';

// import { ReactComponent as AddUser } from 'assets/svg/dashboard/addUser.svg';
// import { ReactComponent as Todo } from 'assets/svg/dashboard/todo.svg';
// import { ReactComponent as Trade } from '../../assets/svg/dashboard/email.svg';

// import { ReactComponent as Bid } from 'assets/svg/dashboard/notification.svg';
// import { getPermittedInsertList, MAINMODULE, MODULES } from 'constants/permission';
// import SidebarLogo from './SidebarLogo';
// import UserProfile from './UserProfile';
// import { ReactComponent as Account } from 'assets/svg/dashboard/account.svg';
// import { ReactComponent as Client } from 'assets/svg/dashboard/addClient.svg';
// import { ReactComponent as Appointment } from 'assets/svg/dashboard/appointment.svg';
// import { ReactComponent as Cabin } from 'assets/svg/dashboard/cabin.svg';
// import { ReactComponent as Dashboard } from 'assets/svg/dashboard/dashboard.svg';
// import { ReactComponent as Diamond } from 'assets/svg/dashboard/diamond.svg';
// import { ReactComponent as Enquiry } from 'assets/svg/dashboard/enquiry.svg';
// import { ReactComponent as Feedback } from 'assets/svg/dashboard/feedback.svg';
// import { ReactComponent as Master } from 'assets/svg/dashboard/master.svg';
// import { ReactComponent as Media } from 'assets/svg/dashboard/media.svg';
// import { ReactComponent as Notification } from 'assets/svg/dashboard/notification.svg';
// import { ReactComponent as ParcelMaster } from 'assets/svg/dashboard/parcel-master.svg';
// import { ReactComponent as Price } from 'assets/svg/dashboard/price.svg';
// import { ReactComponent as Pricing } from 'assets/svg/dashboard/pricing.svg';
// import { ReactComponent as Reports } from 'assets/svg/dashboard/reports.svg';
// import { ReactComponent as Setting } from 'assets/svg/dashboard/setting.svg';
// import { ReactComponent as Stocksummary } from 'assets/svg/dashboard/stock-summary.svg';
// import { ReactComponent as Stock } from 'assets/svg/dashboard/stock.svg';
// import { ReactComponent as Transactions } from 'assets/svg/dashboard/transactions.svg';
// import { ReactComponent as User } from 'assets/svg/dashboard/user.svg';
// // import { ReactComponent as KYC } from 'assets/svg/dashboard/Kyc.svg';

// const Icons = {
//   Dashboard: <Dashboard />,
//   Master: <Master />,
//   Account: <Account />,
//   User: <User />,
//   Diamond: <Diamond />,
//   Stock: <Stock />,
//   Setting: <Setting />,
//   Enquiry: <Enquiry />,
//   Price: <Price />,
//   Feedback: <Feedback />,
//   Media: <Media />,
//   Cabin: <Cabin />,
//   Stocksummary: <Stocksummary />,
//   Appointment: <Appointment />,
//   ParcelMaster: <ParcelMaster />,
//   Transactions: <Transactions />,
//   Notification: <Notification />,
//   Reports: <Reports />,
//   Pricing: <Pricing />,
//   Todo: <Todo />,
//   Trade: <Trade />,
//   Bid: <Bid />,
// };

// class SidebarContent extends Component {
//   constructor(props) {
//     super(props);
//     this.addTrade = Boolean(getPermittedInsertList(MAINMODULE.TRADESHOW, MODULES.TRADESHOW.APPOINTMENT.NAME)?.length);
//     this.addBid = Boolean(getPermittedInsertList(MAINMODULE.BIDSHOW, MODULES.BIDSHOW.APPOINTMENT.NAME)?.length);
//     this.state = {
//       collapsed: false,
//       client: false,
//       user: false,
//       trade: false,
//       sales: false,
//       purchase: false,
//       bid: false,
//       kyc: false,
//       ...this.stateBasedOnPermission,
//     };

//     this.unlisten = PermissionService.events.updateTree.listen(() => this.setState({ ...this.stateBasedOnPermission }));
//   }

//   get stateBasedOnPermission() {
//     return {
//       links: getMenuLinks(),
//       addKyc: PermissionService.getPermission('shortcuts.client_kyc')?.allow,
//       addUser: PermissionService.getPermission('shortcuts.user')?.allow,
//     };
//   }

//   componentWillUnmount() {
//     void this.unlisten?.();
//   }

//   openClientDrawer = () => this.setState({ client: true });
//   openUserDrawer = () => this.setState({ user: true });
//   openTrade = () => this.setState({ trade: true });
//   openBid = () => this.setState({ bid: true });
//   openSalesDrawer = () => this.setState({ sales: true });
//   openPurchaseDrawer = () => this.setState({ purchase: true });
//   openKYCDrawer = () => this.setState({ kyc: true });
//   closeDrawers = () =>
//     this.setState({ trade: false, client: false, user: false, sales: false, purchase: false, kyc: false });

//   getShortcutsMenuOverlay = () => (
//     <Menu className="shortCutMenu">
//       <h2>Add</h2>
//       <Menu.Divider />
//       {this.state.addKyc && (
//         <Menu.Item key="1" onClick={this.openKYCDrawer}>
//           <Client />
//           Clients / KYC
//         </Menu.Item>
//       )}
//       {this.state.addUser && (
//         <Menu.Item key="2" onClick={this.openUserDrawer}>
//           <AddUser />
//           User
//         </Menu.Item>
//       )}
//       {/* {this.addTrade && (
//         <Menu.Item key="3" onClick={this.openTrade}>
//           <AddUser />
//           Trade Show
//         </Menu.Item>
//       )} */}
//       {this.state.addBid && (
//         <Menu.Item key="4" onClick={this.openBid}>
//           <AddUser />
//           Bid Show
//         </Menu.Item>
//       )}
//     </Menu>
//   );
//   const [syncRes, setSyncRes] = useState([]);
//   const [indSync, setIndSync] = useState({});
//   const [belSync, setBelSync] = useState({});
//   const [indStatus, setIndStatus] = useState(greenShade);
//   const [belStatus, setBelStatus] = useState(greenShade);

//   const [syncTimeLessThan10, setGreen] = useState(false);
//   const [syncTimelessThanbetwwen10and15, setOrange] = useState(false);
//   const [syncTimeGreaterThan15, setRed] = useState(false);

//   const fetchSyncRes = useCallback(async () => {
//     let apiRes = await UtilService.callApiAsync({ method: 'get', url: 'admin/v1/dashboard/sync-info' });
//     let data = await apiRes?.filter((item) => {
//       if (typeof item === 'object' && item !== null) return item;
//     });
//     setSyncRes(data?.[0]?.data);
//   }, []);

//   const setMinutes = async (time) => {
//     let now = moment(new Date()); //todays date
//     let end = moment(time); // another date
//     let duration = moment.duration(now.diff(end));
//     let minutes = duration.asMinutes().toString();
//     return minutes;
//   };

//   useMemo(async () => {
//     if (Object.keys(indSync).length > 0) {
//       let minutes = await setMinutes(indSync.endDateTime);
//       console.log('minutes', typeof minutes, minutes, minutes > '10');
//       if (minutes <= '10') {
//         setIndStatus(greenShade);
//       } else if (minutes > '10') {
//         setIndStatus(yellowShade);
//       } else if (minutes >= '20') {
//         setIndStatus(redShade);
//       }
//     }
//   }, [indSync]);
//   useMemo(async () => {
//     if (Object.keys(belSync).length > 0) {
//       let minutes = await setMinutes(belSync.endDateTime);
//       console.log('minutes', typeof minutes, minutes, minutes > '10');
//       if (minutes <= '10') {
//         setBelStatus(greenShade);
//       } else if (minutes > '10') {
//         setBelStatus(yellowShade);
//       } else if (minutes >= '20') {
//         setBelStatus(redShade);
//       }
//     }
//   }, [belSync]);

//   useMemo(() => {
//     syncRes?.length > 0 &&
//       syncRes.forEach((r) => {
//         if (r?.locNm === 'IND') {
//           setIndSync(r);
//         } else {
//           setBelSync(r);
//         }
//       });
//   }, [syncRes]);

//   useEffect(() => {
//     fetchSyncRes();
//     setTimeout(() => {
//       fetchSyncRes();
//     }, 300000);
//   }, []);
//   render() {
//     const themeType = THEME_TYPE_LITE;
//     return (
//       <React.Fragment>
//         <div className="gx-sidebar-content">
//           <SidebarLogo />
//           <ScrollArea
//             speed={0.8}
//             className="settingLeftScroll area sideBarScroll"
//             contentClassName="content"
//             smoothScrolling
//           >
//             <Menu mode="inline" theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}>
//               {this.state.links?.map?.((menu) => (
//                 <Menu.Item
//                   className={this.props.location?.pathname.includes(menu?.path) ? 'ant-menu-item-selected' : ''}
//                   key={menu?.path && menu.path.replace('/', '')}
//                 >
//                   <NavLink to={menu?.path}>
//                     {Icons?.[menu?.svg]}
//                     <span className="vertical-menu">{menu?.name}</span>
//                   </NavLink>
//                 </Menu.Item>
//               ))}
//             </Menu>
//           </ScrollArea>
//         </div>
//         <div className="gx-sidebar-content"> <Tooltip
//                       title="SYNC STATUS | GREEN : SYNCED , YELLOW : IN SYNC, RED : SYNC FAILED"
//                       placement="bottom"
//                     >
//                       <div
//                         style={{
//                           display: 'flex',
//                           flexDirection: 'row',
//                           flexWrap: 'wrap',
//                           justifyContent: 'center',
//                           alignItems: 'center',
//                           color: '#fff',
//                         }}
//                       >
//                         <div
//                           style={{
//                             display: 'flex',
//                             flexDirection: 'row',
//                             flexWrap: 'wrap',
//                             justifyContent: 'center',
//                             alignItems: 'end',
//                           }}
//                         >
//                           BEL
//                           <span
//                             style={{
//                               display: 'block',
//                               borderRadius: '50%',
//                               borderStyle: 'solid',
//                               margin: '0px 0px 0px 15px',
//                               borderColor: 'black',
//                               height: 20,
//                               width: 20,
//                               backgroundColor: belStatus,
//                             }}
//                           />
//                         </div>
//                         <span
//                           style={{
//                             padding: '0px 15px',
//                           }}
//                         >
//
//                           |
//                         </span>
//                         <div
//                           style={{
//                             display: 'flex',
//                             flexDirection: 'row',
//                             flexWrap: 'wrap',
//                             justifyContent: 'center',
//                             alignItems: 'end',
//                           }}
//                         >
//                           IND
//                           <span
//                             style={{
//                               display: 'block',
//                               borderRadius: '50%',
//                               borderStyle: 'solid',
//                               margin: '0px 0px 0px 15px',
//                               borderColor: 'black',
//                               height: 20,
//                               width: 20,
//                               backgroundColor: indStatus,
//                             }}
//                           />
//                         </div>
//                       </div>
//                     </Tooltip></div>
//         <div className="gx-sidebar-content">
//           {(this.state.addKyc || this.state.addUser) && (
//             <Menu mode="inline">
//               <Dropdown
//                 overlay={this.getShortcutsMenuOverlay}
//                 trigger={['click']}
//                 overlayClassName="shortCutPopup"
//                 placement="bottomCenter"
//               >
//                 <Menu.Item>
//                   <svg
//                     id="Capa_1"
//                     enableBackground="new 0 0 512 512"
//                     height="512"
//                     viewBox="0 0 512 512"
//                     width="512"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <g>
//                       <path d="m446.605 124.392-119.997-119.997c-2.801-2.802-6.624-4.395-10.608-4.395h-210c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45v-332c0-4.09-1.717-7.931-4.395-10.608zm-115.605-73.179 68.787 68.787h-53.787c-8.271 0-15-6.729-15-15zm75 430.787h-300c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h195v75c0 24.813 20.187 45 45 45h75v317c0 8.271-6.729 15-15 15z" />
//                       <path d="m346 212h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
//                       <path d="m346 272h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
//                       <path d="m346 332h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
//                       <path d="m286 392h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
//                     </g>
//                   </svg>
//                   <span className="vertical-menu">Shortcuts</span>
//                 </Menu.Item>
//               </Dropdown>
//             </Menu>
//           )}
//         </div>
//         <UserProfile />
//         <Drawer
//           onClose={this.closeDrawers}
//           visible={this.state.client}
//           wrapClassName="diamondListinSidebar lg-size shortCutForm"
//           destroyOnClose
//         >
//           {this.state.client && <ClientSidebar />}
//         </Drawer>
//         <Drawer
//           onClose={this.closeDrawers}
//           visible={this.state.user}
//           wrapClassName="diamondListinSidebar lg-size shortCutForm"
//           destroyOnClose
//         >
//           {this.state.user && <UserDrawer closeDrawer={this.closeDrawers} />}
//         </Drawer>
//         <Drawer
//           onClose={this.closeDrawers}
//           visible={this.state.trade}
//           wrapClassName="diamondListinSidebar lg-size shortCutForm"
//           destroyOnClose
//         >
//           <AddTrade closeDrawer={this.closeDrawers} />
//         </Drawer>
//         <Drawer
//           title={false}
//           onClose={this.closeDrawers}
//           visible={this.state.kyc}
//           wrapClassName="diamondListinSidebar xl-size shortCutForm"
//           destroyOnClose
//         >
//           {this.state.kyc && <AddKYC selectedIndex={0} onCancel={this.closeDrawers} addAccount />}
//         </Drawer>
//       </React.Fragment>
//     );
//   }
// }

// export default withRouter(SidebarContent);
