import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { useTable } from 'react-table';

import editSvg from '../../../assets/svg/edit.svg';
import delteSvg from '../../../assets/svg/delet.svg';
import plusSvg from '../../../assets/svg/plus.svg';

// import { Tooltip } from 'antd';

const data = [
  {
    srno: '1',
    name: 'Web Login',
    permission: 'Website',
  },
  {
    srno: '2',
    name: 'User',
    permission: 'IOS',
  },
  {
    srno: '3',
    name: 'Client',
    permission: 'Android',
  },
  {
    srno: '4',
    name: 'Web Login',
    permission: 'Mobile',
  },
  {
    srno: '5',
    name: 'Web Login',
    permission: 'Mobile',
  },
];
class LoginLogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: false,
      form: true,
      smallLeft: true,
    };
  }
  toggle = () => {
    this.setState({
      tab: true,
    });
  };
  toggle1 = () => {
    this.setState({
      form: false,
    });
  };
  closeForm = () => {
    this.setState({
      form: true,
    });
  };
  toggle = () => {
    this.setState({
      smallLeft: !this.state.smallLeft,
    });
  };
  render() {
    // const { location } = this.props;
    // const userListPath = location.pathname === '/admaadmin/user/user-list/list';
    // const userFormPath = location.pathname === '/admaadmin/user/user-list/form';
    const columns = [
      {
        Header: 'SR Number',
        width: '50px',
        accessor: 'srno',
      },
      {
        Header: 'Code',
        accessor: 'code',
        Cell: () => <span>W042DL</span>,
      },
      {
        Header: 'Company Name',
        accessor: 'name',
        Cell: () => <span>Coruscate</span>,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: () => <span>coruscate@gmail.com</span>,
      },
      {
        Header: 'IP Address',
        accessor: 'ipaddress',
        Cell: () => <span>135.235.024.04</span>,
      },
      {
        Header: 'Login Status',
        accessor: 'active',
        Cell: () => (
          <div className="activeStatus">
            <span>Active</span>
          </div>
        ),
      },
      {
        Header: 'Logged In at',
        accessor: 'loggedinat',
        Cell: () => <span>21-12-2018 04:03 PM </span>,
      },
      {
        Header: 'Logged Out at',
        accessor: 'loggedoutat',
        Cell: () => <span>09-02-2021 09:58 AM </span>,
      },
      {
        Header: 'Permissions',
        accessor: 'permission',
        Cell: ({ row }) => <span onClick={this.toggle1.bind(this)}>{row.original.permission}</span>,
      },
      {
        Header: 'Action',
        Cell: () => (
          <div className="actionButton j-flex-start">
            <div className="actionAction">
              <img src={editSvg} alt="edit" />
            </div>
            <div className="actionAction">
              <img src={delteSvg} alt="delete" />
            </div>
          </div>
        ),
      },
    ];
    const Table = ({ columns, data }) => {
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
      });
      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th {...column.getHeaderProps()} width="100px" key={i}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, i) => {
                      return (
                        <td width="100px" {...cell.getCellProps()} key={i}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      );
    };
    return (
      //  <div className="configurationInnerSubTb">
      <>
        <>
          <div className="addFormIcon" onClick={this.props.onClick}>
            <img src={plusSvg} alt="" />
          </div>
          <div className="contactDirectoryWrapper">
            <div className="contactListMain">
              <div className="contactList">
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </>
      </>
      //  </div>
    );
  }
}

export default withRouter(LoginLogList);
