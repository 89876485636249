import React, { useState } from 'react';
import Slider from 'react-slick';

import { useCompositeState } from 'util/hooks';

import MagnifierImage from './MagnifierImage';
import NoDataFoundComponent from './NoDataFoundComponent';

import noImgSvg from 'assets/svg/NoImg.svg';
import cameraSvg from 'assets/svg/camera.svg';

function DiamondSliderImage(props) {
  const [state, setState] = useCompositeState({ mainImage: true, lightImage: false, sliderImageCount: 2 });

  //FIXME:If LightImage come than lightImage set to true
  const [bottomNav, setBottomNav] = useState();
  const [topNav, setTopNav] = useState();

  const handleImgError = React.useCallback(
    (key) => (e) => {
      setState({ sliderImageCount: state.sliderImageCount - 1, [key]: false });
      e.target.src = noImgSvg;
    },
    [setState, state.sliderImageCount],
  );

  return (
    <div className="diamondImagePopup">
      <Slider asNavFor={bottomNav} infinite={false} ref={setTopNav} lazyLoad="true" arrows={false} swipeToSlide>
        <div className="diamondDetailImageBox">
          <MagnifierImage src={props.image1}>
            <NoDataFoundComponent className="nodatFoundImage" src={cameraSvg} title="No Image Found" />
          </MagnifierImage>
        </div>
        {state.lightImage && (
          <div className="diamondDetailImageBox">
            <MagnifierImage src={props.image2}>
              <NoDataFoundComponent className="nodatFoundImage" src={cameraSvg} title="No Image Found" />
            </MagnifierImage>
          </div>
        )}
      </Slider>
      <Slider
        asNavFor={topNav}
        infinite={false}
        ref={setBottomNav}
        className="smallSliderBlock smallSliderBlockimage"
        lazyLoad="ondemand"
        slidesToShow={4}
        focusOnSelect
        swipeToSlide
      >
        {state.mainImage && (
          <div className="smallSliderImage">
            <img onError={handleImgError('mainImage')} src={props.image1} alt="" />
          </div>
        )}
        {state.lightImage && (
          <div className="smallSliderImage">
            <img onError={handleImgError('lightImage')} src={props.image2} alt="" />
          </div>
        )}
      </Slider>
    </div>
  );
}

export default DiamondSliderImage;
