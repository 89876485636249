const Messages = {
  CommentRequire: 'Please Enter Note.',
  FilterSelect: 'Please Select Filter.',

  // MemoPopupModal
  userAccountSelect: 'Please select Party.',
  userSelect: 'Please select Buyer.',
  sellerSelect: 'Please Select Salesman.',
  shippingCountrySelect: 'Please Select Shipping Country.',
  shippingCitySelect: 'Please Select Shipping City.',
  stageSelect: 'Please Select Stage.',
  assistantSellerSelect: 'Please Select Assistant Salesman.',
  shipModeSelect: 'Please Select ShipMode.',
  brokerSelect: 'Please Enter Broker.',
  shippingChargeRequire: 'Please Enter Shipping Charge.',
  trackNoRequire: 'Please Enter Tracking No.',
  logisticRequire: 'Please Select Logistic.',
  virtualTypeSelect: 'Please Select Appointment Type.',
  // HoldPopUp
  clientNameSelect: 'Please Select Client Name.',
  validTillDateSelect: 'Please Select Hold Date Time.',
  holdTimeRequire: 'Please Enter Hold Time.',

  // confirm stone
  billTypeSelect: 'Please Select Bill Type.',
  sourceOfSaleSelect: 'Please Select Source Of Sale.',
  courierNameSelect: 'Please Select Courier Name.',
  invoiceDateSelect: 'Please Select Invoice Date',
  invoiceDateTypeSelect: 'Please Select Invoice Date',

  // offer message
  sameClientSelect: 'Please Select Similar Customer Diamond.',
  ExpireDateRequire: 'Please Enter Expire Date.',
  validityDaysRequire: 'Please Enter Validity.',
  termsSelect: 'Please Select Payment Terms.',
  // quoteDay
  quoteDaySelect: 'Please Select Quote Title.',
  // showStone
  referenceBySelect: 'Please Select Reference By.',
  showNameSelect: 'Please Select Show Name.',
  // Assignee: 'Please enter assignee.',

  Todo: {
    subject: 'Please enter Title.',
    Description: 'Please enter description.',
    Priority: 'Please enter priority.',
    DueDateTime: 'Please enter Due Date and Time.',
    Assignee: 'Please enter Assignee',
    RelatedTo: 'Please enter value.',
    Duedate: 'Please enter duedate.',
    logOnDateTime: 'please enter reminder date & time',
    callType: 'Please select the callType',
    Sellers: 'Please enter sellers.',
    buyerID: 'Please enter customer',
    Path: 'Please upload the file',
    reminder: 'Please enter Reminder.',
    reminderShareTo: 'please enter Reminder',
    share: 'Please enter share.',
    // Title: 'Please enter Title.',
    Title: 'Please enter Subject.',
    successMessage: 'To do is added successfully.',
  },
  Visitor: {
    firstName: 'Please enter firstname',
    lastName: 'Please enter lastname',
    email: 'Please enter email',
    username: 'Please enter username',
    companyName: 'Please enter company name',
    companyType: 'Please enter company type',
    address: 'Please enter address',
    country: 'Please enter country',
    state: 'Please enter state',
    city: 'Please enter city',
    pinCode: 'Please enter pincode',
    mobile: 'Please enter mobile',
    natureOfOrg: 'Please enter organization type',
  },
  Target: {
    targetName: 'Please Enter Target Name.',
    totalTarget: 'Please Enter Total Target.',
    distributedTarget: 'Please Enter Distributed Target.',
  },

  Error: {
    Required: {
      Entry: 'Please Select at least one entry',
      Company: 'Please Select Company Name.',
      Stones: 'Please Select Diamond.',
      MoreThanOneStone: 'Please select minimum two diamonds to compare.',
      MemoAndHoldStone: 'Sorry! Unable to perform action at this time. The selected diamond(s) is in hold/memo.',
      MaxCompareStone: 'Maximum 15 stones can be compared.',
    },
    NotFound: {
      Stones: 'No Stones Found',
    },
  },
};

export default Messages;
