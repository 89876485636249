import React from 'react';
import './title.less';

const Heading = (props) => {
  return (
    <h2
      className={`mainHeadingTitle ${props.className ? props.className : null}`}
      style={props.style ? props.style : null}
    >
      {props.title}
    </h2>
  );
};
export default Heading;
