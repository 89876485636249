import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Form, Input } from 'antd';

// import { useLoading } from 'util/hooks';
import IntlMessages from 'util/IntlMessages';

// import CircularProgress from 'components/CircularProgress';

import { PROJECT_DETAILS } from 'constants/Common';

import { showNotification } from 'services/NotificationService';
import 'styles/signin/signin.less';
import { forgotPassword } from 'services/CommonApi';

import { history } from 'util/history';

import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

function ForgotPassword({ form }) {
  // const [loading, startLoading, stopLoading] = useLoading(false);
  const { getFieldDecorator } = React.useMemo(() => form, [form]);
  // const [loading, setLoading] = React.useState(false);

  const handleSubmit = React.useCallback(
    async (event) => {
      try {
        event.preventDefault();

        const [, values] = await new Promise((r) => form.validateFields((err, values) => r([err, values])));

        const [err, res] = await forgotPassword({ username: values.email });

        if (!err) {
          showNotification(res);
          form.resetFields();
          history.push('/');
        }
      } catch (error) {
        // OpenNotification({ type: 'error', title: ?.message });
      }
    },
    [form],
  );
  return (
    <div className="adma-login-wrap">
      {/* {loading && <CircularProgress className="fixLoader" />} */}
      <div className="adma-login-container">
        <div className="adma-login-top-cover-banner">
          <div className="adma-login-top-banner">
            {/* <img className="adma-login-top-banner" src={PROJECT_DETAILS.LOGO_4} /> */}
            <div className="admin-login-position">
              <div>
                <h3>Admin Forgot</h3>
                <div className="text-center">
                  <Link>Log in to your Account</Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="admin-login-position">
              <div>
                <h3>Admin Login</h3>
                <div className="text-center">
                  <Link>Log in to your Account</Link>
                </div>
              </div>
            </div> */}
          {/* <img className="adma-login-top-banner" src="../assets/images/login/top-banner.png" alt="" /> */}
        </div>
        <div className="loginpage forget-pwd">
          <div className="adma-login-main-content">
            <div className="adma-app-login-content">
              <div className="adma-logo-content-bg">
                {/* <img src={PROJECT_DETAILS.LOGO_1} /> */}
                <h2 className="login-title">
                  Forgot Your Password ?
                  <span> Don't worry. Recovering the password is easy. Just tell us the email.</span>
                </h2>
              </div>
              <Form layout="vertical" onSubmit={handleSubmit} className="gx-login-form gx-form-row0">
                {getFieldDecorator('email', {
                  rules: [
                    { type: 'email', message: 'The input is not valid E-mail!' },
                    { required: true, message: 'Please input your E-mail!' },
                  ],
                })(<Input type="email" placeholder="E-Mail Address" />)}
                <div className="submit-btn d-flex mt-20">
                  <Button type="primary" className="adma-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.send" />
                  </Button>
                  <Link to={`${PREFIX_URL_WITH_SLASH}/signin`}>
                    <span className="gx-signup-form-forgot gx-link">Back to login</span>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
          <div className="login-right-section">
            {/* <video autoPlay loop muted>
              <source src={require('assets/images/FSDLogoSight.mp4')}></source>
            </video> */}
            {/* <div className="media">
                <div className="media-left">
                  <img src={require('assets/images/price-guarantee.svg')} alt="price-guarantee" />
                </div>
                <div className="media-body">
                  <h3 className="serives-name">Best Prices Guarantee</h3>
                  <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default WrappedForgotPasswordForm;
