import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Layout } from 'antd';

import classNames from 'util/classNames';

import * as ThemeSetting from 'constants/ThemeSetting';

import SidebarContent from './SidebarContent';

import 'styles/sidebar.less';

// export function Sidebar() {
function Sidebar() {
  const themeType = useSelector(({ settings }) => settings?.themeType);
  const navStyle = useSelector(({ settings }) => settings?.navStyle);
  const location = useLocation();

  const drawerStyle = React.useMemo(() => {
    switch (navStyle) {
      case ThemeSetting.NAV_STYLE_FIXED:
        return '';

      case ThemeSetting.NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return 'gx-mini-sidebar gx-mini-custom-sidebar';

      case ThemeSetting.NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return 'gx-custom-sidebar';

      case ThemeSetting.NAV_STYLE_MINI_SIDEBAR:
        return 'gx-mini-sidebar';

      case ThemeSetting.NAV_STYLE_DRAWER:
        return 'gx-collapsed-sidebar';

      default:
        return 'gx-collapsed-sidebar';
    }
  }, [navStyle]);

  if (location.pathname.includes('/dna/')) return null;

  return (
    <Layout.Sider
      className={classNames([
        'gx-app-sidebar hkSidebarMenu customTheme',
        drawerStyle,
        themeType !== ThemeSetting.THEME_TYPE_SEMI_DARK && 'gx-layout-sider-dark',
      ])}
      theme={themeType === ThemeSetting.THEME_TYPE_LITE ? 'lite' : 'dark'}
    >
      <SidebarContent />
    </Layout.Sider>
  );
}

export default Sidebar;
