import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

import _ from 'lodash';

import sortingupSvg from '../../assets/svg/InventoryResult/sortingup-theme.svg';
import sortingupGraySvg from '../../assets/svg/InventoryResult/sortingup-gray.svg';
import sortingdownSvg from '../../assets/svg/InventoryResult/sortingdown-theme.svg';
import sortingdownGraySvg from '../../assets/svg/InventoryResult/sortingdown-gray.svg';
import sortinglightSvg from '../../assets/svg/InventoryResult/sorting-light.svg';
// const _ = require('lodash');

const SortingFilterDropdownItem = (props) => {
  const DND_ITEM_TYPE = 'row';
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // move row
      const r1 = props.sort[dragIndex];
      let sortee = _.cloneDeep(props.sort);
      sortee = [...sortee.slice(0, dragIndex), ...sortee.slice(dragIndex + 1, sortee.length)];
      sortee = [...sortee.slice(0, hoverIndex), r1, ...sortee.slice(hoverIndex, sortee.length)];
      props.moverow(sortee);
      // move row
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index: props.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  preview(drop(dropRef));
  drag(dragRef);

  // const item = props.sort.filter((el) => el.hasOwnProperty(props.para));
  const item = props.sort.filter((el) => Object.prototype.hasOwnProperty.call(el, props.para));

  const asc = item.length && item[0][props.para] === 'ASC';
  const desc = item.length && item[0][props.para] === 'DESC';

  return (
    <div ref={dropRef} style={{ opacity }} className="llSortingTitleItem">
      <span ref={dragRef} className="sqvanceImage">
        <img src={sortinglightSvg} alt="icon" />
      </span>
      <span className="selectSortingTitle">{props.title}</span>
      <div className="shortingRoundInner" onClick={() => props.sortClick(props.para)}>
        <img src={asc ? sortingupSvg : sortingupGraySvg} alt="icon" />
        <img src={desc ? sortingdownSvg : sortingdownGraySvg} alt="icon" />
      </div>
    </div>
  );
};

export default SortingFilterDropdownItem;
