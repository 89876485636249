import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import API_ROUTES from 'constants/CommonApi';

export const getOrderList = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Order.paginate, request, ...(!isEmpty(config) && { config }) });

export const statusChange = (request = {}, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Order.statusChange, request, ...(!isEmpty(config) && { config }) });
