import { store } from 'appRedux/store';
import { TabActions } from 'appRedux/reducers/TabIssueReducer/TabReducer';

import * as AuthActions from './actions/Auth';
import * as CommonActions from './actions/Common';

const getActionsList = () => [
  CommonActions.fetchStart,
  TabActions.resetList,
  AuthActions.signoutUserSuccess,
  CommonActions.fetchSuccess,
];

export const resetStore = () => {
  getActionsList().forEach((action) => {
    store.dispatch(action());
  });
};
