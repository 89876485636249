import React, { Component } from 'react';

import { Popconfirm } from 'antd';
import { DateTime } from 'luxon';

import CommonSearch from 'containers/CommonSearch';
import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import ListingWrapper from 'containers/ListingWrapper';
import PlusIcon from 'containers/PlusIcon';
import StatusBlock from 'containers/StatusBlock';

import { showNotification } from 'services/NotificationService';
import { findMasterDetail, updateMasterDetail } from 'services/SubMasterApi';

import { getModuleFromUrl } from 'constants/permission';

import Form from './Form';

import deleteSvg from '../../../assets/svg/delet.svg';
import editSvg from '../../../assets/svg/edit.svg';
import Table from '../../Inventory/TableBack';

class SubMasterList extends Component {
  state = {
    tab: false,
    form: true,
    smallLeft: true,
    detail: null,
  };

  toggle = () => this.setState({ tab: true });

  toggleForm = () => this.setState({ form: !this.state.form, detail: {} }, () => this.props.getSubMastersList());

  closeForm = () => this.setState({ form: true });

  toggle = () => this.setState({ smallLeft: !this.state.smallLeft });

  editMasterDetail = async (editId) => {
    const [, res] = await findMasterDetail(editId);

    if (res?.code === 'OK') {
      this.setState({ detail: res.data, form: false });
    }
  };

  deleteMasterDetail = async (deleteId) => {
    const [, res] = await updateMasterDetail({
      updateId: deleteId,
      isDeleted: true,
    });

    showNotification(res);
    if (res.code === 'OK') {
      this.props.getSubMastersList();
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps?.parentId !== this.props?.parentId) this.closeForm();
  }

  updateActiveStatus = async (id, status) => {
    const [err, res] = await updateMasterDetail({ updateId: id, isActive: status });
    !err && showNotification(res);
    return !err && res?.code === 'OK';
  };

  render() {
    const columns = [
      { Header: 'Name', accessor: 'name', sort: 'name' },
      { Header: 'Code', accessor: 'code' },
      { Header: 'Web Display', accessor: 'webDisplay' },
      {
        Header: 'Group',
        accessor: 'group',
        Cell({ row }) {
          return <span>{row.original?.group || '-'}</span>;
        },
      },
      {
        Header: 'Sequence',
        accessor: 'sortingSequence',
        sort: 'sortingSequence',
        Cell({ row }) {
          return <span>{row.original?.sortingSequence || '-'}</span>;
        },
      },
      {
        Header: 'Status',
        accessor: 'active',
        Cell: ({ row }) => {
          const [isActive, setIsActive] = React.useState(row.original.isActive);
          return (
            <div
              onClick={async () => {
                const newIsActive = !isActive;
                const success = await this.updateActiveStatus(row.original.id, newIsActive);
                success && setIsActive(newIsActive);
              }}
            >
              <StatusBlock status={isActive ? 'active' : 'InActive'} title={isActive ? 'Active' : 'Inactive'} />
            </div>
          );
        },
      },
      {
        Header: 'Created By',
        accessor: 'createdby',
        Cell: ({ row }) => (
          <div className="lastEditUser">
            <div className="lastEditUserName">
              <span className="userName">{row.original?.createdBy?.name}</span>
            </div>
            <div className="userName mt-5">{DateTime.fromISO(row.original.createdAt).toLocaleString()}</div>
          </div>
        ),
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <div className="actionButton">
            {getModuleFromUrl().update && (
              <div className="actionAction" onClick={() => this.editMasterDetail(row.original.id)}>
                <img src={editSvg} alt="edit.svg" />
              </div>
            )}
            <Popconfirm
              placement="left"
              title={`Are you sure you want to delete this Sub Master?`}
              okText={`Delete`}
              onConfirm={() => this.deleteMasterDetail(row.original.id)}
            >
              <div className="actionAction">
                <img src={deleteSvg} alt="delet.svg" />
              </div>
            </Popconfirm>
          </div>
        ),
      },
    ].map((x) => ({ ...x, id: x.accessor }));

    return (
      <>
        {this.state.form ? (
          <ListingWrapper>
            <div className="d-flex j-flex-end pt-10 subMasterTop">
              {this.props.updateSequence && (
                <button className="commonButton mr-10" type="button" onClick={this.props.updateMasterSequence}>
                  Update Sequence
                </button>
              )}
              <CommonSearch placeholder="Search" handlechange={this.props.handleSearch} />
            </div>
            {getModuleFromUrl().insert && <PlusIcon onClick={this.toggleForm.bind(this)} />}
            <GridHeightContainer
              className="searchResultTable subMasterTable"
              subtractFrom=".subMasterHeader,.subMasterTop"
              adjustment={-2}
            >
              <Table
                moverow={this.props.moverow}
                loading={this.props.loading}
                columns={columns}
                data={this.props.list}
                handleSort={this.props.handleSort}
                FilterOption={false}
                initialSort={this.props.initialSort}
                nocheck
                dragDrop
              />
            </GridHeightContainer>
          </ListingWrapper>
        ) : (
          <Form
            onClick={this.toggleForm.bind(this)}
            detail={this.state.detail}
            parentId={this.props.parentId}
            parentCode={this.props.parentCode}
          />
        )}
      </>
    );
  }
}

export default SubMasterList;
