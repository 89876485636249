import Storage from 'services/storage';

import { isArray } from 'util/utils';

const getCurrencyTypes = () => Storage.get('dn-master')?.CURRENCY;

const getCurrencyTypeOptions = () => {
  const CurrencyTypes = getCurrencyTypes();
  return isArray(CurrencyTypes)
    ? CurrencyTypes.map((currency) => ({ key: currency?.id?.[0], value: currency.name }))
    : [];
};

export default {
  getCurrencyTypes,
  getCurrencyTypeOptions,
};
