import React, { useCallback, useState } from 'react';

// import { CopyOutlined } from '@ant-design/icons';
import { DownOutlined } from '@ant-design/icons';
import {
  // Modal,
  Table,
  // Col,
  // Card,
  // DatePicker,
  // Row,
  // Typography,
  // Container,
  Button,
  Tooltip,
  Input,
  Dropdown,
  Menu,
} from 'antd';
// import axios from 'axios';
import moment from 'moment';

import { getAllDetailsOfDiamond } from 'services/DiamondApi';
// import { getAllDetailsOfDiamond, getAllDiamonds } from 'services/DiamondApi';
//import { Dropdown, DropdownButton } from 'react-bootstrap';

const headingCssStyle = {
  padding: 20,
  backgroundColor: '#cccccc',
  verticalAlign: 'baseline',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '30px 0px 30px 0px',
};

const flexColumnStyle = {
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
};
const diamTrackColumns = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'status',
    dataIndex: 'status',
    key: 'status',
    // render: (vStnId) => (
    //   <Tooltip placement="topLeft" title={vStnId}>
    //     {vStnId}
    //   </Tooltip>
    // ),
  },
  {
    title: 'memoNo',
    dataIndex: 'memoNo',
    key: 'memoNo',
  },
  {
    title: 'trackType',
    dataIndex: 'trackType',
    key: 'trackType',
    //onFilter: (value, ) => record.name.indexOf(value) === 0,
  },
  {
    title: 'sSts',
    dataIndex: 'sSts',
    key: 'sSts',
  },
  {
    title: 'isDeleted',
    dataIndex: 'isDeleted',
    key: 'isDeleted',
    render: (text) => String(text),
  },
  {
    title: 'createdAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
  },
  {
    title: 'updatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    //sorter: (a, b) => a.updatedAt - b.updatedAt,
    //sortDirections: ['descend'],
    defaultSortOrder: 'descend',
    sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
  },
  // {
  //   title: 'Action',
  //   dataIndex: '',
  //   key: 'x',
  //   render: () => <Button>Edit</Button>,
  // },
];
const diamRawDataColumns = [
  {
    title: 'lotid',
    dataIndex: 'lotid',
    key: 'lotid',
  },
  {
    title: 'lotstatus',
    dataIndex: 'lotstatus',
    key: 'lotstatus',
  },
  {
    title: 'type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'location',
    dataIndex: 'location',
    key: 'location',
  },

  // {
  //   title: 'Action',
  //   dataIndex: '',
  //   key: 'x',
  //   render: () => <Button>Edit</Button>,
  // },
];
const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'vStnId',
    dataIndex: 'vStnId',
    key: 'vStnId',
    // render: (vStnId) => (
    //   <Tooltip placement="topLeft" title={vStnId}>
    //     {vStnId}
    //   </Tooltip>
    // ),
  },
  {
    title: 'locNm',
    dataIndex: 'locNm',
    key: 'locNm',
  },
  {
    title: 'wSts',
    dataIndex: 'wSts',
    key: 'wSts',
    //onFilter: (value, ) => record.name.indexOf(value) === 0,
  },
  {
    title: 'sSts',
    dataIndex: 'sSts',
    key: 'sSts',
  },
  {
    title: 'isDeleted',
    dataIndex: 'isDeleted',
    key: 'isDeleted',
    render: (text) => String(text),
  },
  {
    title: 'createdAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
  },
  {
    title: 'updatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    //sorter: (a, b) => a.updatedAt - b.updatedAt,
    //sortDirections: ['descend'],
    defaultSortOrder: 'descend',
    sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
  },
  // {
  //   title: 'Action',
  //   dataIndex: '',
  //   key: 'x',
  //   render: () => <Button>Edit</Button>,
  // },
];
const webStatus = [
  {
    label: 'A : available',
    key: '1',
    wSts: 'A',
  },
  {
    label: 'C : confirm',
    key: '2',
    wSts: 'C',
  },
  {
    label: 'N : new arrival',
    key: '3',
    wSts: 'N',
  },
  {
    label: 'B : bid',
    key: '4',
    wSts: 'B',
  },
  {
    label: 'H : hold',
    key: '5',
    wSts: 'H',
  },
  // {
  //   label: 'NA : not available hide',
  //   key: '6',
  //   wSts: 'NA',
  // },
  // {
  //   label: 'K : kgk collection',
  //   key: '7',
  //   wSts: 'K',
  // },
  // {
  //   label: 'U : upcoming',
  //   key: '8',
  //   wSts: 'U',
  // },
  {
    label: 'D : delete',
    key: '9',
    wSts: 'D',
  },
  {
    label: 'M : memo',
    key: '10',
    wSts: 'M',
  },
  // {
  //   label: 'E : exclusive',
  //   key: '11',
  //   wSts: 'E',
  // },
];
// const changeWebStatus = (key) => {
//   //console.log(`Click on item ${key}`);
// };
// const rowSelection = {
//   //type: 'checkbox',
//   // onChange: (e) => handleCheckbox(e),
//   selections: [
//     {
//       key: 'odd',
//       text: 'Select Odd Row',
//       // onSelect: (changableRowKeys) => {
//       //   let newSelectedRowKeys = [];
//       //   newSelectedRowKeys = changableRowKeys.filter((_, index) => {
//       //     if (index % 2 !== 0) {
//       //       return false;
//       //     }
//       //     return true;
//       //   });
//       //   setSelectedRowKeys(newSelectedRowKeys);
//       // },
//     },
//     {
//       key: 'even',
//       text: 'Select Even Row',
//       // onSelect: (changableRowKeys) => {
//       //   let newSelectedRowKeys = [];
//       //   newSelectedRowKeys = changableRowKeys.filter((_, index) => {
//       //     if (index % 2 !== 0) {
//       //       return true;
//       //     }
//       //     return false;
//       //   });
//       //   setSelectedRowKeys(newSelectedRowKeys);
//       // },
//     },
//   ],
// };

const formatNumber = (value) => new Intl.NumberFormat().format(value);
const NumericInput = (props) => {
  const { value, onChange } = props;
  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      onChange(inputValue);
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/0*(\d+)/, '$1'));
  };
  const title = value ? <span>{value !== '-' ? formatNumber(Number(value)) : '-'}</span> : 'Input a number';
  return (
    <Tooltip title={title} placement="topLeft">
      <Input
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Stock No. / LOTID / vStnId"
        size="large"
        onPressEnter={
          value === '' || value.length < 6
            ? () => {
                alert('enter valid id');
              }
            : props.fetchDiamond
        }
      />
    </Tooltip>
  );
};

const BackendDevComponent = () => {
  const [allData, setAllData] = useState({});
  const [input, setInput] = useState('');
  // const [input, setInput] = useState('428833954');

  const fetchDiamond = useCallback(async () => {
    const request = {
      diamondId: input,
    };
    let data = [];
    const apiRes = await getAllDetailsOfDiamond(request);
    if (apiRes?.length) {
      data = await apiRes?.filter((item) => {
        if (typeof item === 'object' && item !== null) {
          return item;
        } else {
          return null;
        }
      });
    }
    if (data?.length > 0 && data?.[0]?.code === 'OK') await setAllData(data?.[0]?.data);
  }, []);

  // const handleInput = (e) => {
  //   const val = e?.target?.value;
  //   if (val !== '' || val !== undefined) setInput(val);
  //   else setInput('');
  //   // await Promise.all([setLowerLmt(upperLmt), setUpperLmt(100 + upperLmt)]);
  // };

  const onSearch = async () => {
    // await Promise.all([setLowerLmt(upperLmt), setUpperLmt(100 + upperLmt)]);
    fetchDiamond();
  };

  // console.log('allData', allData);

  return (
    <>
      <div style={flexColumnStyle}>
        <h2>Please Enter Diamond Stock No. / LOTID / vStnId</h2>
        <div>
          <NumericInput
            value={input}
            onChange={setInput}
            fetchDiamond={() => {
              fetchDiamond();
            }}
          />
        </div>
        <Button
          style={{
            marginTop: 20,
          }}
          type="primary"
          onClick={() => {
            onSearch();
          }}
          size="large"
          disabled={input === '' || input.length < 6 ? true : false}
        >
          Search
        </Button>

        {Object.keys(allData)?.length > 0 &&
          Object.entries(allData).map(([key, value]) => {
            return (
              value !== null &&
              value !== undefined && (
                <>
                  {key === 'diamDetails' && (
                    <div
                      style={{
                        margin: '20px 0px 0px 0px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Dropdown
                        overlay={
                          <Menu>
                            {webStatus.map((item) => (
                              <Menu.Item key={item.key}>
                                <a
                                  href="!#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    //console.log('status chaged', item);
                                  }}
                                >
                                  {item.label.toUpperCase()}
                                </a>
                              </Menu.Item>
                            ))}
                          </Menu>
                        }
                      >
                        <a href="!#" onClick={(e) => e.preventDefault()}>
                          CHANGE DIAMOND STATUS
                          <DownOutlined />
                        </a>
                      </Dropdown>
                    </div>
                  )}
                </>
              )
            );
          })}
      </div>
      {Object.keys(allData)?.length > 0 ? (
        <>
          <div style={flexColumnStyle}>
            {Object.entries(allData).map(([key, value]) => {
              return (
                value !== null &&
                value !== undefined && (
                  <>
                    <div style={headingCssStyle}>
                      <h2
                        style={{
                          margin: 0,
                        }}
                      >
                        {key === 'diamDetails'
                          ? 'DIAMOND DETAILS FROM DIAMOND TABLE (ON WEBSITE)'
                          : key === 'diamHistory'
                          ? 'DIAMOND HISTORY FROM DIAMOND_HISTORY TABLE'
                          : key === 'diamMemoDetails'
                          ? 'DIAMOND ORDER DETAILS FROM MEMO_DETAIL TABLE (ON WEBSITE)'
                          : key === 'diamTrackDetails'
                          ? 'DIAMOND TRACK DETAILS FROM DIAMOND_TRACK TABLE'
                          : key === 'diamRawData'
                          ? 'DIAMOND RAW DATA FROM API'
                          : ''}
                      </h2>
                      {key === 'diamDetails' && (
                        <>
                          <h4
                            style={{
                              margin: 0,
                            }}
                          >
                            DIAMOND_WEB_STATUS:
                          </h4>
                          <h4
                            style={{
                              margin: 0,
                            }}
                          >
                            A : available | C : confirm | M :memo | NA : not available hide | U : upcoming | B : bid | H
                            : hold | N : new arrival | K : kgk collection | U : upcoming | E: exclusive | D : delete
                          </h4>
                        </>
                      )}
                      {key === 'diamRawData' && (
                        <>
                          <h4
                            style={{
                              margin: 0,
                            }}
                          >
                            LOTSTATUS:
                          </h4>
                          <h4
                            style={{
                              margin: 0,
                            }}
                          >
                            STOCK : A | STOCK-NULL : A | STOCK-N : N | STOCK-B : B | STOCK-E : E | STOCK-U : U | STOCK-O
                            : A | STOCK-K : K | PACK : H | PACK-O : H | DELETE : D | INVOICE : D | STOCK : A | N : N | B
                            : B | E : E | U : U | MEMO-NULL : D | MEMO-N : D | MEMO-E : D | MEMO-K : D | MEMO-B : D |
                            PACK-NULL : D | PACK-N : D| PACK-B : D | PACK-E : D| PACK-K : D | INVOICE-NULL : D |
                            INVOICE-B : D | INVOICE-N : D | INVOICE-E : D | INVOICE-K : D
                          </h4>
                        </>
                      )}
                      {key === 'diamTrackDetails' && (
                        <>
                          <h4
                            style={{
                              margin: 0,
                            }}
                          >
                            DIAMOND_TRACK_TYPE:
                          </h4>
                          <h4
                            style={{
                              margin: 0,
                            }}
                          >
                            CART : 1 | WATCHLIST : 2 | REQUEST : 3 | OFFER : 4 | REMINDER : 5 | INQUIRY : 6 | SHIPMENT :
                            7 | SHARE : 8 | INTERESTED : 9 | CONFIRM : 10 | BID : 11 | VIEW : 12
                          </h4>
                        </>
                      )}
                    </div>
                    {value?.length > 0 ? (
                      <Table
                        width={800}
                        //className="gx-table-responsive"
                        dataSource={value}
                        columns={key === 'diamTrackDetails' ? diamTrackColumns : columns}
                        // rowKey={(e) => {
                        //   console.log(e);
                        // }}
                        // onRowClick={(e) => {
                        //   onNextClick();
                        // }}
                        //pagination={{ pageSize: 100 }}
                        //rowSelection={rowSelection}
                      />
                    ) : value?.details?.length > 0 ? (
                      <Table
                        width={800}
                        //className="gx-table-responsive"
                        dataSource={key === 'diamRawData' ? value?.details : value}
                        columns={
                          key === 'diamRawData'
                            ? diamRawDataColumns
                            : key === 'diamTrackDetails'
                            ? diamTrackColumns
                            : columns
                        }
                        // rowKey={(e) => {
                        //   console.log(e);
                        // }}
                        // onRowClick={(e) => {
                        //   onNextClick();
                        // }}
                        //pagination={{ pageSize: 100 }}
                        //rowSelection={rowSelection}
                      />
                    ) : (
                      <div>
                        <h1>NO {key} DATA FROM API</h1>
                      </div>
                    )}
                  </>
                )
              );
            })}
          </div>
        </>
      ) : (
        <div
          style={{
            padding: 20,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <h1>NO DATA FROM API RIGHT NOW</h1>
        </div>
      )}
    </>
  );
};
export default BackendDevComponent;
