import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import PropTypes from 'prop-types';

import classNames from 'util/classNames';

import loaderSvg from 'assets/images/kgk.svg';

function ConnectedProgress({ loader }) {
  return loader ? (
    <div className={classNames([`loader`, `fixLoader`])}>
      <img src={loaderSvg} alt="loader" />
    </div>
  ) : null;
}

ConnectedProgress.propTypes = {
  loader: PropTypes.bool,
};

export default compose(
  connect(({ loader }) => ({
    loader: Boolean(loader?.loading),
  })),
)(ConnectedProgress);
