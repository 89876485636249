import React from 'react';
import { useSelector } from 'react-redux';

// import DisableBlock from 'containers/DisableBlock';
import SelectOption from 'containers/SelectOption';

import { isFunction } from 'util/utils';

import { USERS_TYPE } from 'constants/Common';

import { getSeller, isValue } from '../CommonPopupFunction';

function SellerComponent(props) {
  const loginUser = useSelector((state) => state.auth?.authUser);
  const loginUserTypeCheck = [USERS_TYPE.SELLER, USERS_TYPE.MASTER_SALES].includes(loginUser?.type);
  const [state, _setState] = React.useState({
    seller: null,
    sellerList: [],
    loading: false,
  });
  const setState = (object = {}) => {
    _setState((state) => ({ ...state, ...object }));
  };
  React.useEffect(() => {
    if (!loginUserTypeCheck) {
      setState(props.initialData);
    }
  }, [props?.initialData?.sellerList, props?.initialData?.seller]);

  React.useEffect(() => {
    if (loginUserTypeCheck) {
      const data = { seller: loginUser?.id, sellerList: [{ value: loginUser?.name, key: loginUser?.id }] };
      setState(data);
      props.setSellerDetails(data);
    }
  }, [loginUser]);
  React.useEffect(() => {
    if (!loginUserTypeCheck)
      getSeller((sellerList) => {
        setState({ sellerList });
      });
  }, []);

  return (
    <SelectOption
      className={loginUserTypeCheck ? 'disableBlock' : ''}
      placeholder={props?.placeholder || `Select Sales Person`}
      value={
        loginUserTypeCheck
          ? loginUser?.id
          : state.sellerList?.filter((item) => item.key === isValue(state.seller)).length
          ? isValue(state.seller)
          : undefined
      }
      selectValue={loginUserTypeCheck ? [{ value: loginUser?.name, key: loginUser?.id }] : state.sellerList}
      label={props?.label || 'Sales Person *'}
      onChange={(seller) => {
        if (isFunction(props.errorHandel)) props.errorHandel({ fields: ['seller'] });
        props.setSellerDetails({ seller });
        setState({ seller });
      }}
      onSearch={() => {
        props.setSellerDetails({ seller: null });
        setState({ seller: null });
      }}
      error={props.error?.seller}
    />
  );
}

export default React.memo(SellerComponent);
