import { getDefaultPermissions } from './_getDefaultPermissions';

export const NotificationPermissions = [
  {
    mainModule: 'NOTIFICATION',
    module: 'BULK_EMAIL',
    title: 'Bulk Email',
    url: '/notification/bulk-email',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'NOTIFICATION',
    module: 'NEW',
    title: 'New',
    url: '/notification/new',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'NOTIFICATION',
    module: 'CLEARED',
    title: 'Cleared',
    url: '/notification/seen',
    permissions: getDefaultPermissions(),
  },
];
