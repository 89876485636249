import { isEmpty } from 'util/utils';

const initialState = {
  request: {
    page: 1,
    limit: 15,
    search: undefined,
  },
  list: [],
  count: 0,
  selectedId: undefined,
  selected: undefined,
};

export const SyncEmailActionTypes = {
  SetList: '@sync-email/set-list',
  SetSelectedId: '@sync-email/set-selected-id',
  SetSelected: '@sync-email/set-selected',
  SetPagination: '@sync-email/set-pagination',
  SetSearch: '@sync-email/set-search',
  Reset: '@sync-email/reset',
};

export const SyncEmailActions = {
  setList: (list, count) => ({ type: SyncEmailActionTypes.SetList, payload: { list, count } }),
  setSelectedId: (selectedId) => ({ type: SyncEmailActionTypes.SetSelectedId, payload: { selectedId } }),
  setSelected: (selected) => ({ type: SyncEmailActionTypes.SetSelected, payload: { selected } }),
  setPagination: (page, limit) => ({ type: SyncEmailActionTypes.SetPagination, payload: { page, limit } }),
  setSearch: (search) => ({ type: SyncEmailActionTypes.SetSearch, payload: { search } }),
  reset: () => ({ type: SyncEmailActionTypes.Reset }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SyncEmailActionTypes.SetList: {
      const list = payload?.list ?? state?.list;
      const count = payload?.count ?? state?.count;
      const selectedId = list?.[0]?.id ?? undefined;
      return { ...state, count, list, selectedId };
    }

    case SyncEmailActionTypes.SetSelectedId: {
      const selectedId = payload?.selectedId ?? state?.selectedId;
      return { ...state, selectedId };
    }

    case SyncEmailActionTypes.SetSelected: {
      const selected = payload?.selected ?? state?.selected;
      return { ...state, selected };
    }

    case SyncEmailActionTypes.SetPagination: {
      const request = { ...state.request };
      request.page = payload?.page ?? request.page;
      request.limit = payload?.limit ?? request.limit;
      return { ...state, request };
    }

    case SyncEmailActionTypes.SetSearch: {
      const request = { ...state.request };
      request.search = isEmpty(payload?.search) ? undefined : payload?.search;
      return { ...state, request };
    }

    case SyncEmailActionTypes.Reset:
      return { ...initialState };

    default:
      return state;
  }
};
