import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Popover, Modal, Row, Col, notification } from 'antd';

import { SettingsRoutes } from 'routes/Settings';

import Image from 'containers/Image';

import { PermissionService } from 'services/PermissionService';
import Storage from 'services/storage';
import UtilService from 'services/util';

import { Notify } from 'util/notify';
import { capitalize, isNotEmpty } from 'util/utils';

import { BASE_URL } from 'constants/Common';
import ConstantApi from 'constants/CommonApi';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

import { userSignOut } from 'appRedux/actions/Auth';

class UserProfile extends Component {
  state = {
    showModal: false,
    newpassword: '',
    currentpassword: '',
    confirmpassword: '',
    user: {},
    errors: { newpassword: '', confirmpassword: '', currentpassword: '' },
    loader: false,
    popup: true,
  };

  unregister = () => {};

  componentDidMount() {
    this.setState({ user: Storage.get('user') });
    this.unregister = Storage.listen('user', ({ user }) => {
      this.setState({ user: { ...this.state.user, ...user } });
    });
  }

  componentWillUnmount() {
    this.unregister();
  }

  cancel = () => {
    this.setState({ showModal: false, confirmpassword: '', newpassword: '', currentpassword: '', errors: {} });
  };

  handleValidation() {
    const values = {
      newpassword: this.state.newpassword,
      confirmpassword: this.state.confirmpassword,
      currentpassword: this.state.currentpassword,
    };
    const errors = {};
    let formIsValid = true;
    if (!values.newpassword) {
      formIsValid = false;
      errors['newpassword'] = 'Please enter new password';
    }
    if (values.confirmpassword && values.confirmpassword !== values.newpassword) {
      formIsValid = false;
      errors['confirmpassword'] = 'Password not matched';
    } else if (!values.confirmpassword) {
      formIsValid = false;
      errors['confirmpassword'] = 'Please enter confirm password';
    }
    this.setState({ errors });
    return formIsValid;
  }

  ok = () => {
    const blank = [];
    const invalid = [];
    const obj = ConstantApi.ResetPassword.paginate;
    obj.request = {
      ConfirmPassword: this.state.confirmpassword,
      currentPassword: this.state.currentpassword,
      newPassword: this.state.newpassword,
    };
    if (this.handleValidation()) {
      UtilService.callApi(obj, (err, data) => {
        if (data.code === 'OK') {
          Notify.success({ message: data?.message });
          this.setState({ newpassword: '', confirmpassword: '', currentpassword: '', showModal: false, errors: {} });
        } else {
          this.setState({ newpassword: '', confirmpassword: '', currentpassword: '' });
        }
      });
    } else {
      if (blank.length > 0) {
        const blankFields = blank.map((f, i) => <span key={i}>{f + (i !== blank.length - 1 ? ', ' : '')}</span>);
        notification.error({ message: 'Required fields', description: blankFields });
      }
      if (invalid.length > 0) {
        const blankFields = invalid.map((f, i) => <span key={i}>{f + (i !== invalid.length - 1 ? ', ' : '')}</span>);
        notification.error({ message: 'Invalid fields', description: blankFields });
      }
    }
  };

  handleOnChange = (title) => (e) => {
    if (title === 'newpassword') this.setState({ newpassword: e.target.value });
    if (title === 'confirmpassword') this.setState({ confirmpassword: e.target.value });
    if (title === 'currentpassword') this.setState({ currentpassword: e.target.value });
  };

  hideShow = () => this.setState({ popup: false }, () => setTimeout(() => this.setState({ popup: true }), 120));

  render() {
    const { newpassword, confirmpassword, currentpassword, user } = this.state;
    const allowSettings = PermissionService.getPermission('setting')?.allow;
    const allowAccount = PermissionService.getPermission('setting.my_account')?.allow;
    const allowTerms = PermissionService.getPermission('setting.terms_and_conditions')?.allow;

    const userMenuOptions = (
      <ul className="gx-user-popover profileMenuHover" onClick={this.hideShow}>
        <a className="profileMenuProfile" href={PREFIX_URL_WITH_SLASH + '/setting/profile'}>
          <div className="userProfileLeft">
            <Image src={BASE_URL + user?.photoId} />
          </div>
          <div>{capitalize([user?.firstName, user?.lastName].filter(isNotEmpty).join(' '))}</div>
        </a>
        {allowSettings && (
          <li>
            <NavLink to={SettingsRoutes.root}>My Settings</NavLink>
          </li>
        )}
        {allowAccount && (
          <li>
            <NavLink to={SettingsRoutes.profile}>My Account</NavLink>
          </li>
        )}
        {allowTerms && (
          <li>
            <NavLink to={SettingsRoutes.terms}>Terms And Conditions</NavLink>
          </li>
        )}
        <li onClick={() => this.setState({ loader: true }, () => this.props.userSignOut())}>
          <a>Logout</a>
        </li>
      </ul>
    );

    return (
      <div>
        {this.state.popup && (
          <Popover
            placement="topRight"
            overlayClassName="profilePopupOver"
            content={userMenuOptions}
            trigger={['click']}
          >
            <div className="userProfile">
              <div className="userProfileLeft">
                <Image bgDark src={BASE_URL + user?.photoId} />
              </div>
            </div>
          </Popover>
        )}
        {this.state.showModal && (
          <Modal
            visible={this.state.showModal}
            title={'Reset Password'}
            okText={'Reset Password'}
            onOk={this.ok}
            onCancel={this.cancel}
          >
            <form autoComplete="off">
              <Row>
                <Col lg={24} className="reset-password-box mb-20">
                  <div className="form-control">
                    <label className="d-block">
                      Current Password
                      <span className="required" style={{ color: currentpassword === '' ? 'red' : 'green' }}>
                        *
                      </span>
                    </label>
                    <input
                      type="password"
                      id="currentpassword"
                      value={currentpassword ?? ''}
                      onChange={this.handleOnChange('currentpassword')}
                    />
                    <span style={{ color: 'red' }}>{this.state.errors['currentpassword']}</span>
                  </div>
                </Col>
                <Col lg={24} className="reset-password-box mb-20">
                  <div className="form-control">
                    <label className="d-block">
                      New Password
                      <span className="required" style={{ color: newpassword === '' ? 'red' : 'green' }}>
                        *
                      </span>
                    </label>
                    <input
                      type="password"
                      id="newpassword"
                      value={newpassword ?? ''}
                      onChange={this.handleOnChange('newpassword')}
                    />
                    <span style={{ color: 'red' }}>{this.state.errors['newpassword']}</span>
                  </div>
                </Col>
                <Col lg={24} className="reset-password-box mb-20">
                  <div className="form-control">
                    <label className="d-block">
                      Confirm Password
                      <span className="required" style={{ color: confirmpassword === '' ? 'red' : 'green' }}>
                        *
                      </span>
                    </label>
                    <input
                      type="password"
                      id="confirmpassword"
                      value={confirmpassword ?? ''}
                      onChange={this.handleOnChange('confirmpassword')}
                    />

                    <span style={{ color: 'red' }}>{this.state.errors['confirmpassword']}</span>
                  </div>
                </Col>
              </Row>
            </form>
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(
  ({ auth }) => ({
    user: auth.authUser,
  }),
  { userSignOut },
)(UserProfile);
