import React from 'react';

import './staticContent.less';
import Heading from 'containers/Heading';

// import StaticHeader from '../StaticHeader';
import { termData } from './termData';

const TermsOfUse = () => {
  return (
    <>
      {/* <StaticHeader /> */}
      <div className="staticBoxMain">
        <div className="statiContentBox">
          <div className="staticContentWrapper mb-0">
            <div className="staticList">
              <Heading
                title="Terms & Conditions"
                className="staticMainTitle text-center mb-30"
                style={{ marginBottom: '30px' }}
              />
              <p>
                This is a legally binding contract (hereinafter referred to as “Agreement”), By And Between User(s)
                (hereinafter referred as “User” or “Users” or “You” which expression shall, unless it be repugnant to
                the context or meaning thereof, be deemed to mean and include his/her/their heirs, executors,
                administrators and legal representatives its successors, as the case may be), i.e. any person, who
                accesses and/or uses internet resource www.kgk.live, including, without limitation, all its associated
                sub-domains, mobile applications, if any, and all accompanying or associated data, applications,
                utilities or interface available or provided now and in future (collectively referred to as “Website”)
                or any Services (defined hereinafter) provided by KGK Jewels Online DMCC, a company with limited
                liability incorporated under the relevant laws of Dubai Multi Commodities Centre Authority, and having
                its registered offices at Unit no. 1222, DMCC Business Centre, Level No. 1, Jewellery & Gemplex 3,
                Dubai, United Arab Emirates
              </p>
              <p>
                AND KGK Jewels Online DMCC (hereinafter referred to as the “Company” or “Us” or “We”). and each of which
                are hereinafter referred to as a “Party” and both of which are herein referred to as the “Parties”.
              </p>
            </div>
          </div>
          {termData.map((pri, key) => {
            return (
              <div className="staticContentWrapper" key={key}>
                <Heading title={pri.title} className="popupInnerTitle" />
                <div className="staticList">
                  <ul>
                    {pri.content.map((subDesc) => {
                      if (subDesc.title) {
                        return (
                          <>
                            <Heading title={subDesc.title} className="popupInnerTitle" />
                            <ul>
                              {subDesc.desc.map((des, key) => {
                                return (
                                  <li
                                    style={{ paddingLeft: '40px' }}
                                    dangerouslySetInnerHTML={{ __html: des }}
                                    key={key}
                                  />
                                );
                              })}
                            </ul>
                          </>
                        );
                      }
                      return subDesc.desc.map((des, key) => {
                        return <li dangerouslySetInnerHTML={{ __html: des }} key={key} />;
                      });
                    })}
                  </ul>
                </div>
              </div>
            );
          })}

          <div className="staticList">
            <p>
              <strong>
                USER UNDERSTAND AND ACKNOWLEDGE THAT BY CHECKING THE “AGREE/ I AGREE” TAB/BUTTON ON THE WEBSITE, FOR
                THIS AGREEMENT AND/OR ANY OTHER FORM(S) OR AGREEMENT PRESENTED TO YOU ON THE WEBSITE, OR BY USING ANY
                SERVICES, YOU ARE AGREEING TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, AND THAT SUCH ACTION /
                CONFIRMATION CONSTITUTES A LEGAL SIGNATURE RESULTING IN A BINDING CONTRACT BETWEEN YOU AND THE COMPANY.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
