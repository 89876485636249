import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import ConstantApi from 'constants/CommonApi';

export const getNewsList = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.News.paginate, request, ...(!isEmpty(config) && { config }) });

export const storeNews = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.News.add, request, ...(!isEmpty(config) && { config }) });

export const updateNews = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(ConstantApi.News.update, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const deleteNews = (id, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(ConstantApi.News.delete, [id]),
    ...(!isEmpty(config) && { config }),
  });

export const findNews = (id, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(ConstantApi.News.edit, [id]),
    ...(!isEmpty(config) && { config }),
  });
