import React from 'react';

import { Checkbox } from 'antd';

import { hasKey } from 'util/utils';

function Action(props) {
  const { handleChange, moduleName, module } = props;

  const permission = React.useMemo(() => {
    return module ?? {};
  }, [module]);

  const [fields, defaultFields] = React.useMemo(() => {
    let fields = [
      { name: 'All', permitKey: 'all' },
      { name: 'View', permitKey: 'view' },
      { name: 'Add', permitKey: 'insert' },
      { name: 'Update', permitKey: 'update' },
      { name: 'Delete', permitKey: 'delete' },
      { name: 'Upload', permitKey: 'uploadExcel' },
      { name: 'Download', permitKey: 'downloadExcel' },
      { name: 'Mail', permitKey: 'mailExcel' },
      { name: 'Print', permitKey: 'printPDF' },
    ];

    const defaultFields = fields.map((o) => o?.permitKey);

    if (hasKey(permission, 'modify')) fields.push({ name: 'Modify', permitKey: 'modify' });
    if (hasKey(permission, 'approve')) fields.push({ name: 'Approve', permitKey: 'approve' });
    if (hasKey(permission, 'reject')) fields.push({ name: 'Reject', permitKey: 'reject' });
    if (hasKey(permission, 'cancel')) fields.push({ name: 'Cancel', permitKey: 'cancel' });
    if (hasKey(permission, 'unHold')) fields.push({ name: 'UnHold', permitKey: 'unHold' });

    if (moduleName === 'HIDE_GRID_COLUMNS')
      fields = Object.keys(permission).map((item) => ({ name: item, permitKey: item }));

    return [fields, defaultFields];
  }, [permission, moduleName]);

  return (
    <div className="d-flex align-items-center j-space-between permissionAction">
      {fields.map((field, index) => {
        return (
          <div key={field?.name ?? index} className="d-flex align-items-center j-space-between permissionActionList">
            <Checkbox
              checked={permission.all || permission[field.permitKey]}
              onChange={(e) => handleChange?.({ checked: e.target.checked, permitKey: field.permitKey })}
            />
            {!defaultFields.includes(field.permitKey) && <h2>{field.name}</h2>}
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(Action);
