import React from 'react';

import classNames from 'util/classNames';

import checkWhiteSvg from 'assets/svg/check-white.svg';

const colorList = Array(24)
  .fill()
  .map((el, i) => ++i);

const ColorCircle = React.memo(({ id, selected, ...props }) => {
  const children = React.useMemo(() => selected && <img src={checkWhiteSvg} alt="selected" />, [selected]);
  return (
    <div className={classNames(['colorItem', `color${id}`, selected && 'selected'])} {...props}>
      {children}
    </div>
  );
});

const ColorPicker = React.memo(({ onChange, selected }) => {
  const handleClick = React.useCallback((id) => () => onChange(id), [onChange]);

  return (
    <div className="colorTopWrapper">
      {colorList.map((id) => (
        <ColorCircle key={`${id}`} id={`${id}`} selected={`${selected}` === `${id}`} onClick={handleClick(`${id}`)} />
      ))}
    </div>
  );
});

ColorPicker.propTypes = {};

export default ColorPicker;
