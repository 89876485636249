import { getDefaultPermissions } from './_getDefaultPermissions';

export const ClientPermissions = [
  {
    mainModule: 'CLIENT',
    module: 'DASHBOARD',
    title: 'Overview',
    url: '/client/overview',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'GENERAL',
    title: 'General',
    url: '/client/kyc/basic',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'BUSINESS_DETAILS',
    title: 'Business Details',
    url: '/client/kyc/business-detail',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'BANKS',
    title: 'Banks',
    url: '/client/kyc/bank-info',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'BRANCH',
    title: 'Branch',
    url: '/client/kyc/branch',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'USER',
    title: 'User',
    url: '/client/kyc/user',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'REFERENCES',
    title: 'References',
    url: '/client/kyc/references-detail',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'SETTING',
    title: 'Setting',
    url: '/client/kyc/setting',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'KYC',
    module: 'TERMS_DETAIL',
    title: 'Terms Details',
    url: '/client/kyc/terms-detail',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'HOLD',
    module: 'CONFIRMED',
    title: 'Confirmed',
    url: '/client/hold/confirmed',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'HOLD',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/client/hold/rejected',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'MEMO',
    module: 'CONFIRMED',
    title: 'Confirmed',
    url: '/client/memo/confirmed',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'MEMO',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/client/memo/rejected',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'CART',
    title: 'Cart',
    url: '/client/transactions/cart',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'WATCHLIST',
    title: 'Watchlist',
    url: '/client/transactions/watchlist',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'NOTES',
    title: 'Notes',
    url: '/client/transactions/notes',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'PURCHASEHISTORY',
    title: 'PURCHASEHISTORY',
    url: '/client/transactions/purchase-history',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ORDER',
    module: 'PENDING',
    title: 'Pending',
    url: '/client/order/pending',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ORDER',
    module: 'APPROVED',
    title: 'Approved',
    url: '/client/order/approved',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ORDER',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/client/order/rejected',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ORDER',
    module: 'CANCELLED',
    title: 'Cancelled',
    url: '/client/order/cancelled',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'OFFER',
    module: 'PENDING',
    title: 'Pending',
    url: '/client/offer/pending',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'OFFER',
    module: 'APPROVED',
    title: 'Approved',
    url: '/client/offer/approved',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'OFFER',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/client/offer/rejected',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'TRANSACTION',
    module: 'OFFICE_VIEW',
    title: 'Office View',
    url: '/client/transactions/officeview',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'OFFICE',
    module: 'PENDING',
    title: 'Pending',
    url: '/client/office/pending',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'OFFICE',
    module: 'APPROVED',
    title: 'Approved',
    url: '/client/office/approved',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'OFFICE',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/client/office/rejected',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'SEARCH',
    module: 'SAVED_SEARCH',
    title: 'Saved Search',
    url: '/client/search/saved-search',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'SEARCH',
    module: 'DEMAND',
    title: 'Demand',
    url: '/client/search/demand',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'SEARCH',
    module: 'GENERALDEMAND',
    title: 'General Demand',
    url: '/client/search/general-demand',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'SEARCH',
    module: 'RECENT_SEARCH',
    title: 'Recent Search',
    url: '/client/search/recent-search',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ACTIVITY',
    module: 'TODO',
    title: 'Task',
    url: '/client/activity/task',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ACTIVITY',
    module: 'CALL_LOG',
    title: 'Call log',
    url: '/client/activity/call-log',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ACTIVITY',
    module: 'EMAIL',
    title: 'Email',
    url: '/client/activity/email',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ACTIVITY',
    module: 'NOTES',
    title: 'Notes',
    url: '/client/activity/notes',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ACTIVITY',
    module: 'PAGE_TRACKING',
    title: 'PageTracking',
    url: '/client/activity/page-tracking',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ANALYTICS',
    module: 'ANALYTICS',
    title: 'Analytics',
    url: '/client/analytics',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'RATING',
    module: 'RATING',
    title: 'Rating',
    url: '/client/rating',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'SUGGESTED_STOCK',
    module: 'SUGGESTED_STOCK',
    title: 'Suggested Stock',
    url: '/client/suggested-stock',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'CLIENT',
    subModule: 'ACCURATE_STOCK',
    module: 'ACCURATE_STOCK',
    title: 'Accurate Stock',
    url: '/client/accurate-stock',
    permissions: getDefaultPermissions(),
  },
];
