import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Checkbox } from 'antd';
import { cloneDeep } from 'lodash';

import ListingTableWrapper from 'containers/ListingTableWrapper';
import ListingWrapper from 'containers/ListingWrapper';
import Heading from 'containers/MainHeading';

import Table from 'components/Inventory/TableBack';

import { downloadExcelFile } from 'services/DiamondApi';

import { useCompositeState } from 'util/hooks';
import { isArray, isEmpty, isNotEmpty } from 'util/utils';

import OpenNotification from 'constants/OpenNotifications';

import SendEmailPopup from './Actions/SendEmailPopup';
import { getBankRate, showFinalCalc } from './CommonPopupFunction';
import { getExtraPer } from './SelectStone';

const currentType = 'finalpopup';

function FinalCalculations({ checkDiamondStatus, client, isShowConfirmStone, onClose, onConfirmStone, ...props }) {
  const [email, sendEmail] = useState('');
  const [state, setState] = useCompositeState({
    data: cloneDeep(props?.checked),
    columns: [],
    diamond: [],
    cmCharges: null,
    cmChecked: false,
  });

  const checkedData = useSelector((state) => state.diamondData.selectedRows[currentType]);
  const checked = state.data.filter((el) => (isArray(checkedData) ? checkedData.find(({ id }) => id === el?.id) : []));

  useEffect(() => {
    if (!isArray(checkedData)) return;
    if (!isEmpty(checkedData)) {
      getBankRate('CM_CHARGES', (data) => {
        const [diamondValues] = showFinalCalc(checkedData, client?.accountTerm, data, true);
        setState({ diamond: diamondValues });
      });
    } else {
      onClose();
    }
  }, [checkedData, client?.accountTerm, onClose, setState]);

  useEffect(() => {
    const _columns = props.columns;
    const index = _columns.findIndex(({ id }) => id === 'rap') || 1;
    const columns = [
      ..._columns.slice(0, index + 1).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
      _columns.find(({ id }) => id === 'back'),
      _columns.find(({ id }) => id === 'ctPr'),
      _columns.find(({ id }) => id === 'amt'),
      {
        Header: () => 'Final Disc(' + parseFloat(getExtraPer(client?.accountTerm)).toFixed(2) + '%)',
        accessor: 'fnBack',
        id: 'fnBack',
        Cell({ row }) {
          return <>{parseFloat(row.original.calcDiscount || row.original.back).toFixed(2)}</>;
        },
      },
      {
        Header: 'Final Rate',
        accessor: 'fnCtpr',
        id: 'fnCtpr',
        Cell({ row }) {
          return <>{parseFloat(row.original.calcPricePerCarat || row.original.ctPr).toFixed(2)}</>;
        },
      },
      {
        Header: 'Final Value',
        accessor: 'fnAmt',
        id: 'fnAmt',
        Cell({ row }) {
          return <>{parseFloat(row.original.calcAmount || row.original.amt).toFixed(2)}</>;
        },
      },
      ..._columns
        .slice(index + 1, _columns.length)
        .filter((el) => !['fnBack', 'fnCtpr', 'fnAmt', 'back', 'amt', 'ctPr'].includes(el.id)),
    ];
    setState({ columns: columns.filter(isNotEmpty) });
  }, [client?.accountTerm, setState, props.columns]);

  return (
    <>
      <div>
        <Heading className="popupInnerTitle" title="Final Calculation" />
        <div className=" popupHalfShow">
          <ListingWrapper>
            <ListingTableWrapper subtractFrom=".mainHeadingTitle,.sidebarAction">
              <Table
                data={state.data}
                columns={state.columns}
                currentType={currentType}
                canSort={false}
                FilterOption={false}
                areAllChecked
                liveStatus
                noheader
                noDots
                noGrp
              />
            </ListingTableWrapper>
          </ListingWrapper>
          <div className="singleStonePlaceOrderDetail">
            <div className="mt-10 finalCalDetail">
              <Checkbox checked={state.cmChecked} onChange={() => setState({ cmChecked: !state.cmChecked })}>
                CM Charge
              </Checkbox>
              <div className="DiamondDetailPopup mt-10">
                {state.diamond.length &&
                  state.diamond.map((x) => (
                    <div key={x.id} className="DiamondDetailPopupItem">
                      <span>{x.title}</span>
                      <span>
                        {x.title === 'Final Net Value' && !state.cmChecked && x.value.search(/[+]/) > 0
                          ? x.value.slice(0, x.value.search(/[+]/))
                          : x.value}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="sidebarAction">
            {!isShowConfirmStone && (
              <button className="commonButton" onClick={() => checkDiamondStatus() && onConfirmStone()}>
                Confirm Stone
              </button>
            )}
            <button className="commonButton" onClick={onClose}>
              Cancel Stone
            </button>
            <button
              className="commonButton"
              onClick={() =>
                downloadExcelFile({ id: checked.map((item) => item.id) }, () => {
                  onClose();
                })
              }
            >
              Excel Export
            </button>
            <button
              className="commonButton"
              onClick={() => {
                if (checked.length) sendEmail('stock');
                else OpenNotification({ type: 'error', title: 'Please select any stone(s).' });
              }}
            >
              Email Stock
            </button>
            <button
              className="commonButton"
              onClick={() => {
                if (checked.length) sendEmail('img');
                else OpenNotification({ type: 'error', title: 'Please select any stone(s).' });
              }}
            >
              Email Pic
            </button>
          </div>
        </div>
      </div>
      <SendEmailPopup
        sendEmail={email}
        onCancel={() => {
          sendEmail('');
          onClose();
        }}
        ids={isArray(checked) && checked.map(({ id }) => id)}
        img={email === 'img'}
        isStock={email === 'stock'}
      />
    </>
  );
}

export default React.memo(FinalCalculations);
