import React from 'react';

import InlineList from 'components/List/InlineList';

import { isEmpty } from 'util/utils';
// import { isEmpty, typeOf } from 'util/utils';

const ClientName = (props) => {
  const { user } = props;
  if ((!user && typeof user !== 'object') || (!isEmpty(user) && typeof user !== 'object')) return <></>;

  if (user) user.firstName = user?.firstName ? user.firstName : '-';
  user.lastName = user?.lastName ? user.lastName : '-';
  const fullName =
    user?.firstName.charAt(0).toUpperCase() +
    user?.firstName.slice(1).toLowerCase() +
    '' +
    user?.lastName.charAt(0).toUpperCase() +
    user?.lastName.slice(1).toLowerCase();

  const companyName = user?.account?.companyName
    ? user.account.companyName
    : user?.companyName
    ? user.companyName
    : '-';
  return (
    <InlineList
      skipEmpty
      separator=" | "
      list={[
        !isEmpty(companyName) ? (
          <a href="!#" className="tableLink">
            {companyName}
          </a>
        ) : undefined,
        fullName,
      ]}
    />
  );
};
export default ClientName;
