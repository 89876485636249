import urlJoin from 'url-join';

import { last } from 'lodash';
// import _each from 'lodash/each';
// import _uniq from 'lodash/uniq';

import { downloadExcel, downloadExcelZip, printPdf } from 'services/CommonApi';
import Storage from 'services/storage';
import UtilService from 'services/util';

import { isArray, isEmpty, isFunction, isString } from 'util/utils';

import { CommonPrintExcel, USERS_TYPE } from 'constants/Common';
import ConstantApi from 'constants/CommonApi';
import { COMMON_URL } from 'constants/CommonUrl';
import OpenNotification from 'constants/OpenNotifications';

import { setDownloadExcelStatus } from 'appRedux/actions';

import { SearchService } from './SearchService';
import urljoin from 'urljoin';

export const sendPricingEmail = (request = {}) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Pricing.sendEmail,
    request,
  });
};

// Inventory > Single Stone > Overview > Stock Summary
export const getStockSummary = () => {
  return UtilService.callApiAsync({
    ...ConstantApi.StockSummary.list,
  });
};

// Diamond
export const getWithoutDiamondList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Inventory.imageCheck,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const placeOrderHandel = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondConfirm.Request,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

// Diamond track
export const getGridColumnList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Gridcolumn.list, request, ...(!isEmpty(config) && { config }) });
};

export const saveGridColumnList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Gridcolumn.update, request, ...(!isEmpty(config) && { config }) });
};

/**
 * Function used to get saved search list
 * @param {*} request
 */
export const savedSearchList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Diamond.search, request, ...(!isEmpty(config) && { config }) });
};

/**
 * Function used to get total diamond count
 * @param {*} filter
 * @param {*} cb
 */
export const getCount = (filter, cb) => {
  const searchFilter = SearchService.getSearchFilter(filter);

  UtilService.callApi(
    {
      ...ConstantApi.Diamond.paginate,
      request: {
        isNotReturnTotal: true,
        isReturnCountOnly: true,
        isSkipSave: true,
        filters: [searchFilter],
      },
      config: { loader: false },
    },
    (err, res) => cb(res?.data?.[0]?.count),
  );
};

export const getCountAsync = (...args) => new Promise((resolve) => getCount(...args, resolve));

// Diamond track
export const getDiamondTrackList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondTrack.paginate,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getDiamondCommentList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Inventory.diamondCommentPaginate,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getDiamondBidList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.DiamondBid.paginate, request, ...(!isEmpty(config) && { config }) });
};

export const getDiamondTrackRemove = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.DiamondTrack.delete, request, ...(!isEmpty(config) && { config }) });
};

export const getDiamondTrackChangeStatus = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondTrack.changeStatus,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getDiamondTrackUpdateStatus = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondTrack.statusUpdate,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const createDiamondTrack = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.DiamondTrack.create, request, ...(!isEmpty(config) && { config }) });
};

// Transaction Hold/Memo
export const getTransactionHoldMemo = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Memo.paginate, request, ...(!isEmpty(config) && { config }) });
};

//End Diamond track

//Diamond excel

export const diamondFtc = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Inventory.ftc, request, ...(!isEmpty(config) && { config }) });
};

export const sendEmailAttachment = (request = {}) => {
  // let request = { ...oldRequest, isSendEmail: true };
  //set new changes in api
  return UtilService.callApiAsync({
    ...ConstantApi.Inventory.exportExcel,
    request,
  });
};

export const sendXRayEmailAttachment = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.Inventory.xRayMail, request, ...(!isEmpty(config) && { config }) });
};

export const downloadExcelFile = async (request = {}, cb) => {
  const [err, res] = await UtilService.callApiAsync({
    ...ConstantApi.Inventory.exportExcel,
    request,
  });
  if (err || res?.code !== 'OK' || !isString(res?.data?.data)) return;

  // const server = COMMON_URL.EXCELFILE_URL;
  // const filePath = server + res?.data?.data;
  const filePath = urlJoin(COMMON_URL.EXCELFILE_URL, res?.data?.data);
  window.open(filePath, 'Download');
  if (isFunction(cb)) cb(true);
};

/**
 * Function used to download pdf file
 * @param {*} request
 */
export const downloadPdfFile = async (diamond, requestUrl, filter = {}, moduleParam, cb) => {
  if (isFunction(cb)) cb(true);
  if (CommonPrintExcel[moduleParam]) {
    const user = Storage.get('user');
    if (user?.type != USERS_TYPE.SELLER) delete CommonPrintExcel[moduleParam]?.filter?.['sSts'];
  }
  const request = {
    ...(isArray(diamond) && diamond?.length && moduleParam != 'inOrder'
      ? { id: diamond?.map((o) => o.id) }
      : moduleParam === 'inOrder'
      ? { orderDiamond: diamond?.map((o) => o.id) }
      : {}),
    ...(!isEmpty(filter) ? { filter } : {}),
    ...(CommonPrintExcel[moduleParam] ? CommonPrintExcel[moduleParam] : {}),
  };
  // console.log('filter', request);
  if (!isEmpty(requestUrl)) {
    ['page', 'limit'].map((d) => delete requestUrl?.request[d]);
  }
  const [err, res] =
    isEmpty(requestUrl) || (isArray(diamond) && diamond?.length) || !isEmpty(filter)
      ? await printPdf(request)
      : await requestUrl?.urlMethod({ ...requestUrl?.request, isPdf: true });

  if (err || res?.code !== 'OK' || !isString(res?.data)) {
    if (isFunction(cb)) cb(false);
    return;
  }

  // const filePath = COMMON_URL.EXCELFILE_URL + res?.data;
  const filePath = urlJoin(COMMON_URL.EXCELFILE_URL, res?.data);
  window.open(filePath, 'Download');
  if (isFunction(cb)) cb(false);
};

export const downloadZip = async (request = {}, cb, config) => {
  const [err, res] = await UtilService.callApiAsync({
    ...ConstantApi.Inventory.downloadZip,
    request,
    ...(!isEmpty(config) && { config }),
  });
  if (!err && res && res.code === 'OK' && !isEmpty(res?.data)) {
    try {
      let filePath = '';
      // const filePath = COMMON_URL.EXCELFILE_URL + res?.data;
      if (isArray(res.data)) {
        res.data.forEach((url) => window.open(urlJoin(COMMON_URL.EXCELFILE_URL, url), '_blank'));
      } else {
        filePath = urlJoin(COMMON_URL.EXCELFILE_URL, res?.data);
        window.open(filePath, 'Download');
      }

      const xhr = new XMLHttpRequest();
      xhr.open('GET', filePath, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (xhr.statusText === 'OK' || xhr.status === 200) {
          const urlCreator = window.URL || window.webkitURL;
          const Url = urlCreator.createObjectURL(this.response);
          const tag = document.createElement('a');
          tag.href = Url;
          tag.download = isArray(res?.data) ? last(res?.data?.[0]?.split('/')) : last(res.data?.split('/'));
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
        } else {
          OpenNotification({
            type: 'error',
            title: 'Resource Not Found.',
          });
        }
        if (isFunction(cb)) cb();
      };
      xhr.send();
    } catch (error) {
      if (isFunction(cb)) cb();
      return error;
    }
  } else {
    OpenNotification({
      type: 'error',
      title: 'Resource Not Found.',
    });
    if (isFunction(cb)) cb();
  }
  //  window.open(filePath, 'Download');
};
/**
 * Function used to download file
 * @param {*} request
 * @param {*} cb
 */
export const downloadFile = async (request = {}, cb) => {
  const [err, res] = await UtilService.callApiAsync({
    ...ConstantApi.Inventory.downloadFile,
    request,
  });

  if (err && res?.code !== 'OK') return;
  try {
    // const server = COMMON_URL.EXCELFILE_URL;
    // const file_path = server + res.data.path;
    const file_path = urlJoin(COMMON_URL.EXCELFILE_URL, res.data.path);
    const xhr = new XMLHttpRequest();
    xhr.open('GET', file_path, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = request.fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  } catch (error) {
    return error;
  }
  if (isFunction(cb)) cb();
};

export const downloadVideo = (apiObj) => {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', apiObj.path, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');
      tag.href = imageUrl;
      tag.target = '_blank';
      tag.download = apiObj.fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.onerror = (error) => alert(error);
    xhr.send();
  } catch (error) {
    return error;
  }
};

export const downloadCertificate = async (request = {}) => {
  const [err, res] = await UtilService.callApiAsync({
    ...ConstantApi.Inventory.downloadCertificate,
    request,
  });
  if (err && res?.code !== 'OK') return;

  try {
    // const server = COMMON_URL.EXCELFILE_URL;
    // const filePath = server + res.data;
    const filePath = urlJoin(COMMON_URL.EXCELFILE_URL, res.data);
    const xhr = new XMLHttpRequest();
    xhr.open('GET', filePath, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const url = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');
      tag.href = url;
      tag.download = request.fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  } catch (error) {
    return error;
  }
};

export const downloadZipExcel = async (
  diamond,
  requestUrl,
  filter = {},
  moduleParam,
  cb,
  // inOffer = false,
  isAllDiamond = false,
) => {
  const request = {
    ...(isArray(diamond) && diamond?.length && moduleParam != 'inOrder'
      ? { id: diamond?.map((o) => o.id) }
      : moduleParam === 'inOrder'
      ? { orderDiamond: diamond?.map((o) => o.id) }
      : {}),
    ...(!isEmpty(filter) ? { filter } : {}),
    ...(CommonPrintExcel[moduleParam] ? CommonPrintExcel[moduleParam] : {}),
  };

  if (isAllDiamond) {
    request.isAllDiamond = true;
  }

  const [err, res] = !isEmpty(filter)
    ? await downloadExcelZip(request)
    : await requestUrl?.urlMethod({ ...requestUrl?.request, isExcel: true });

  if (err) {
    setDownloadExcelStatus(false);
  }
  // const filePath = COMMON_URL.EXCELFILE_URL + res?.data?.data;
  const filePath = urlJoin(COMMON_URL.EXCELFILE_URL, res?.data?.data);

  if (isAllDiamond && res?.code === 'OK') {
    window.open(filePath, 'download');
    return true;
  } else if (isFunction(cb)) cb(false);
};

export const downloadDiamondExcel = async (
  diamond,
  requestUrl,
  filter = {},
  moduleParam,
  cb,
  inOffer = false,
  isAllDiamond = false,
) => {
  if (isFunction(cb)) cb(true);
  if (CommonPrintExcel[moduleParam]) {
    const user = Storage.get('user');

    if (user?.type != USERS_TYPE.SELLER) delete CommonPrintExcel[moduleParam]?.filter?.['sSts'];
  }
  const request = {
    ...(isArray(diamond) && diamond?.length && moduleParam != 'inOrder'
      ? { id: diamond?.map((o) => o.id) }
      : moduleParam === 'inOrder'
      ? { orderDiamond: diamond?.map((o) => o.id) }
      : {}),
    ...(!isEmpty(filter) ? { filter } : {}),
    ...(CommonPrintExcel[moduleParam] ? CommonPrintExcel[moduleParam] : {}),
  };

  if (inOffer) {
    request.fromOffer = true;
  }

  if (isAllDiamond) {
    request.isAllDiamond = true;
  }
  if (!isEmpty(requestUrl)) {
    ['page', 'limit'].map((d) => delete requestUrl?.request[d]);
  }
  const [err, res] =
    isEmpty(requestUrl) || (isArray(diamond) && diamond?.length) || !isEmpty(filter)
      ? await downloadExcel(request)
      : await requestUrl?.urlMethod({ ...requestUrl?.request, isExcel: true });

  if (
    (err && !isAllDiamond) ||
    (res?.code !== 'OK' && !isAllDiamond) ||
    (!isString(res?.data?.data) && !isAllDiamond)
  ) {
    if (isFunction(cb)) cb(false);
    return;
  }
  const filePath = urlJoin(COMMON_URL.EXCELFILE_URL, res?.data?.data);
  // const filePath = COMMON_URL.EXCELFILE_URL + res?.data?.data;

  window.open(filePath, 'Download');

  if (isAllDiamond && res?.code === 'OK') {
    return true;
  } else if (isFunction(cb)) cb(false);
};

export const getOfficeList = (request = {}, config) => {
  return UtilService.callApiAsync({ ...ConstantApi.office.paginate, request, ...(!isEmpty(config) && { config }) });
};

export const uploadDiamondsExcel = (request = {}) => {
  return UtilService.callApiAsync({ ...ConstantApi.Diamond.upload, request });
};

export const diamondSearchUpsert = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Demand.Upsert, request, ...(!isEmpty(config) && { config }) });

export const diamondSearchPartialUpsert = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Demand.PartialUpsert, request, ...(!isEmpty(config) && { config }) });

export const diamondHitCounter = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Diamond.hitCounter, request, ...(!isEmpty(config) && { config }) });

export const getDynamicDiamondBidList = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondBid.dynamicBid,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const declareBidWinner = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondBid.winner,
    request,
    ...(!isEmpty(config) && { config }),
  });
};
export const deleteBid = (request = {}, config) => {
  return UtilService.callApiAsync({
    ...ConstantApi.DiamondBid.delete,
    request,
    ...(!isEmpty(config) && { config }),
  });
};
export const getAllDetailsOfDiamond = (request = {}) => {
  const config = {
    headers: { 'content-type': 'application/json' },
  };
  return UtilService.callApiAsync({
    method: 'post',
    url: '/admin/v1/diamond/backenddev',
    request,
    ...(!isEmpty(config) && { config }),
  });
};
