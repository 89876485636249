import { cloneDeep, uniq } from 'lodash';

import { isArray, isObject, isEmpty, hasKey, pruneEmpty } from 'util/utils';

export class SearchService {
  static getSearchFilter(input = {}) {
    let options = cloneDeep(input);
    if (isEmpty(options) || !isObject(options)) return {};

    Object.entries(options).forEach(([key, val]) => {
      if (isArray(val)) {
        isEmpty(val) ? delete options[key] : (options[key] = uniq(val));
      }
      if (isObject(val)) {
        if (Object.keys(val).length === 2 && hasKey(val, '<=') && hasKey(val, '>=')) {
          if (isEmpty(val['<=']) || isEmpty(val['>='])) delete options[key];
        }
      }
    });

    if (!isArray(options.or)) options.or = [];

    // if (!isEmpty(options.lb)) {
    //   // options.or.push({ lbIds: options.lb });
    //   // options.or.push({ lb: options.lb });
    //   // delete options.lb;
    // }

    const hasStoneId = options.or.some((param) => !isEmpty(param?.vStnId ?? param?.rptNo));
    if (hasStoneId) {
      const wSts = !isEmpty(options.wSts) ? options.wSts : undefined;
      const sSts = !isEmpty(options.sSts) ? options.sSts : undefined;
      options = {
        sSts,
        wSts,
        or: options.or.filter((item) => !isEmpty(item?.vStnId ?? item?.rptNo)),
      };
    }

    const output = pruneEmpty(options);
    return output;
  }
}
