import React from 'react';
import './status.less';

const StatusBlock = (props) => (
  <div className={`statusBlock ${props.status}`}>
    <span>{props.title}</span>
  </div>
);

export default StatusBlock;
