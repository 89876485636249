import React from 'react';

import { Popover } from 'antd';

import { useCompositeState } from 'util/hooks';

import { FILE_URLS } from 'constants/Common';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

import MagnifierImage from './MagnifierImage';

import cameraSvg from '../../assets/svg/camera.svg';
import closeSvg from '../../assets/svg/close.svg';
import infoSvg from '../../assets/svg/info.svg';

const detailShow = [
  { name: 'Diamond Image', key: 'vStnId' },
  {
    name: 'Price',
    key: 'amt',
    isRoundOff: true,
  },
  {
    name: 'Difference',
    key: 'amt',
    isRoundOff: true,
  },
  {
    name: 'Carat',
    key: 'crt',
    isRoundOff: true,
  },
  {
    name: 'Cut',
    key: 'cutNm',
  },
  {
    name: 'Color',
    key: 'colNm',
  },
  {
    name: 'Clarity',
    key: 'clrNm',
  },
  {
    name: '',
    key: 'viewDetails',
  },
];
const DiamondDetailCompare = ({ diamondDetails, mainDiamondAmt }) => {
  const [state, setState] = useCompositeState({
    Price: false,
    Carat: false,
    Cut: false,
    Color: false,
    Clarity: false,
  });

  return (
    <div className="compareMainPage">
      <div className="compareTable">
        <table>
          {detailShow.map((detail, index) => (
            <tr key={index}>
              <td className="compareTableHead">
                {['Price', 'Carat', 'Cut', 'Color', 'Clarity'].includes(detail.name) && (
                  <span className="d-flex j-space-between align-items-center">
                    {detail.name}
                    <Popover
                      overlayClassName="detailPopover"
                      content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, "
                      title={
                        <div className="d-flex j-space-between align-items-center">
                          <h2>{detail.name}</h2>
                          <img onClick={() => setState({ [detail.name]: !state[detail.name] })} src={closeSvg} alt="" />
                        </div>
                      }
                      trigger="click"
                      visible={state[detail.name]}
                      onVisibleChange={() => setState({ [detail.name]: !state[detail.name] })}
                    >
                      <img className="infomTootip" src={infoSvg} alt="" />
                    </Popover>
                  </span>
                )}
                {['Diamond Image', 'Difference'].includes(detail.name) && (
                  <span className="d-flex j-space-between align-items-center">{detail.name}</span>
                )}
              </td>
              {diamondDetails.map((diamond, index) => (
                <>
                  {detail.name !== 'Difference' && (
                    <td key={index} className={`compareHead ${detail?.name === 'Diamond Image' ? 'compareImage' : ''}`}>
                      {detail.name === 'Diamond Image' && (
                        <MagnifierImage
                          className="diamondCompareImg"
                          onError={(e) => {
                            e.target.src = cameraSvg;
                          }}
                          src={FILE_URLS.img.replace('***', diamond[detail.key])}
                          alt="No Image"
                        >
                          No Image Found
                        </MagnifierImage>
                      )}
                      {['Price', 'Carat', 'Cut', 'Color', 'Amount', 'Clarity'].includes(detail.name) &&
                        (detail?.isRoundOff ? parseFloat(diamond[detail.key]).toFixed(2) : diamond[detail.key] || '-')}
                      {detail.key === 'viewDetails' && (
                        <a
                          className="linkText"
                          // eslint-disable-next-line react/jsx-no-target-blank
                          target="_blank"
                          href={`${PREFIX_URL_WITH_SLASH}/diamond-detail/${diamond.id}`}
                          rel="noreferrer"
                        >
                          View Detail
                        </a>
                      )}
                    </td>
                  )}
                  {detail.name === 'Difference' && (
                    <td className={diamond.amt - mainDiamondAmt <= 0 ? 'greenColor' : 'redColor'}>
                      {`${diamond.amt - mainDiamondAmt <= 0 ? '' : '+'}${parseFloat(
                        diamond.amt - mainDiamondAmt,
                      ).toFixed(2)}`}
                    </td>
                  )}
                </>
              ))}
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};
export default DiamondDetailCompare;
