import React, { useState } from 'react';

import SelectOption from 'containers/SelectOption';

// import { getQuoteDay } from 'services/CommonApi';

import { isFunction } from 'util/utils';

import { getQuoteDayList, isValue } from '../CommonPopupFunction';

function QuoteDayComponent(props) {
  const [state, _setState] = useState({ quoteDayList: [], quoteDay: null, loading: false });
  const setState = (object = {}) => {
    _setState((state) => ({ ...state, ...object }));
    props.setQuoteDetail(object);
  };
  React.useEffect(() => {
    getQuoteDayList((quoteDayList) => {
      setState({ quoteDayList, quoteDay: props.quoteDayList ? quoteDayList[0]?.key : null });
    });
  }, []);
  return (
    <SelectOption
      className={props.className}
      placeholder="Select Quote Title"
      value={isValue(state.quoteDay)}
      selectValue={state.quoteDayList}
      label={props.isNotLabel ? '' : 'Select Quote Title *'}
      onChange={(quoteDay = null) => {
        if (quoteDay && isFunction(props.removeError)) props.removeError();
        setState({ quoteDay });
      }}
      error={props.error?.quoteDay}
    />
  );
}

export default React.memo(QuoteDayComponent);
