import React from 'react';

import { find, groupBy, isEmpty, pick } from 'lodash';
import moment from 'moment';

import { LIMIT } from 'components/Inventory/TableBack';

import { getAccountsList } from 'services/AccountApi';
import { getBroker, getCities, getCountries, getQuoteDay, getRateSettings, getuserTerms } from 'services/CommonApi';
import { downloadPdfFile, getDiamondTrackUpdateStatus } from 'services/DiamondApi';
import { statusChange } from 'services/OrderApi';
import { getSellerList } from 'services/SellerApi';
import { getTradeList } from 'services/TradeApi';
import { getUserList, getVisitorsList } from 'services/UserApi';
import Storage from 'services/storage';

import { isFunction, isNotEmpty, isObject } from 'util/utils';

import { DIAMOND_BLOCK, ORDER_STATUS, PROJECT_SETTINGS } from 'constants/Common';
import { DIAMOND_BID } from 'constants/Inventory';
import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

import { calculate, formatNumber, newDiamondPrice, quoteCalculation } from './SelectStone';

export const nameFormat = (firstName = '', lastName = '') => {
  return `${firstName} ${lastName}`;
};

export const getSeller = async (cb) => {
  const request = { filter: { isActive: true }, isDeleted: false };
  const [, res] = await getSellerList(request);
  if (res?.code === 'OK' && res?.data) {
    const sellerList = res.data.map((d) => ({ value: nameFormat(d.firstName, d.lastName), key: d.id }));
    cb(sellerList);
  } else {
    cb([]);
  }
};

export const getCountryData = async (cb) => {
  const [, res] = await getCountries({});
  if (res?.code === 'OK' && res?.data?.list) {
    const countryList = res.data.list.map((d) => ({ value: d.name, key: d.id }));
    cb(countryList);
  } else {
    cb([]);
  }
};
export const getCityData = async (cb, country) => {
  const [, res] = await getCities({ country });
  if (res?.code === 'OK' && res?.data?.list) {
    const cityList = res.data.list.map((d) => ({ value: d.name, key: d.id }));
    cb(cityList);
  } else {
    cb([]);
  }
};

export const getClientData = async (cb, keyword, userId = null) => {
  if (keyword?.length < 3) {
    cb({ userAccount: null, user: null, accountList: [], userList: [], loading: false });
    return false;
  }
  cb({ loading: true });
  const user = Storage.get('user');
  const request = {
    ...(userId ? { filter: { user: userId } } : {}),
    page: 1,
    filter: { isActive: true },
    limit: LIMIT,
    isDeleted: false,
    startWith: {
      keys: ['companyName', 'name', 'firstName', 'lastName', 'mobile'],
      keyword: keyword,
    },
    sort: [
      {
        createdAt: 'DESC',
      },
    ],
  };
  if (user?.type === 8) {
    request.filter = { seller: user.id };
  }
  const [, res] = await getAccountsList(request);

  if (res?.code === 'OK' && res?.data?.list) {
    cb({
      loading: false,
      // client: res.data.list,
      accountList: res.data.list,
    });
  } else {
    cb({ userAccount: null, user: null, accountList: [], userList: [], loading: false });
  }
};

export const getUserClientData = async (cb, keyword = null, account = null) => {
  if (keyword?.length < 3 && keyword != null) {
    cb({ loading: false });

    return false;
  }
  cb({ loading: true });
  // let user = Storage.get('user');

  const request = {
    filter: { isActive: true, ...(account ? { account } : {}) },
    page: 1,
    limit: 15,
    search: {
      ...(keyword ? { keyword } : {}),
      keys: ['companyName', 'name', 'username', 'email'],
    },
    sort: [
      {
        name: 'ASC',
      },
    ],
  };
  // if (user?.type === 8) {
  //   request.filter = { seller: user.id };
  // }
  const [, res] = await getUserList(request);

  if (res?.code === 'OK' && res?.data?.list) {
    cb({
      loading: false,
      //   client: res.data.list,
      userList: res.data.list,
      // accountList: res.data.list,
    });
  } else {
    cb({ userAccount: null, user: null, userAccountList: [], userList: [], loading: false });
  }
};
export const getBrokerData = async (cb, keyword) => {
  if (keyword?.length < 3) {
    cb({ brokerList: [] });
    return false;
  }
  const [, res] = await getBroker({
    page: 1,
    limit: LIMIT,
    search: {
      keys: ['companyName'],
      keyword: keyword,
    },
    sort: [
      {
        createdAt: 'DESC',
      },
    ],
  });
  if (res?.code === 'OK' && res?.data) {
    const brokerList = res.data.map((d) => ({ value: d.companyName, key: d.id }));
    cb(brokerList);
  }
};

export const fetchPartyRelatedInfo = (client, mapId) => {
  //For party related other information fill
  const clientDetail = find(client, (d) => d?.id === mapId || d?.account?.id === mapId || d?.user?.id === mapId);
  const data = pick(clientDetail, ['city', 'country', 'seller', 'broker', 'brokerInfo']);
  return {
    ...(data.city ? { shippingCity: data.city } : {}),
    ...(data.country ? { shippingCountry: data.country } : {}),
    ...(isObject(clientDetail?.broker)
      ? {
          broker: clientDetail?.broker?.id,
          brokerList: [
            { ...clientDetail?.broker, value: clientDetail?.broker?.companyName, key: clientDetail?.broker?.id },
          ],
        }
      : {}),
    ...(!isEmpty(clientDetail?.seller)
      ? {
          seller: clientDetail?.seller?.id,
          // sellerList: [
          //   {
          //     ...data.seller,
          //     value: clientDetail?.seller?.name,
          //     key: clientDetail?.seller?.id,
          //   },
          // ],
        }
      : {}),
  };
};

export const isValue = (value) => {
  //for set place holder value
  return value ? value : undefined;
};

export const getFullClientData = async (cb, keyword) => {
  if (keyword?.length < 3) {
    cb({ userAccount: null, user: null, userAccountList: [], userList: [], loading: false });
    return false;
  }
  cb({ loading: true });
  const request = {
    page: 1,
    limit: LIMIT,
    startWith: {
      keys: ['companyName', 'name', 'firstName', 'lastName', 'mobile'],
      keyword: keyword,
    },
    sort: [
      {
        createdAt: 'DESC',
      },
    ],
  };
  const user = Storage.get('user');
  if (user.type === 8) {
    request.filter = { seller: user.id };
  }
  const [, res] = await getAccountsList(request);

  if (res?.code === 'OK' && res?.data?.list) {
    cb({ loading: false });
    const clientList = res.data.list.map((d) => ({
      value: `${d.companyName} ${d?.user?.firstName || d?.user?.lastName ? `|` : ''} ${
        d?.user?.firstName ? d.user.firstName : ''
      } ${d?.user?.lastName ? d.user.lastName : ''}`,
      key: d?.user?.id,
      ...d,
    }));
    cb({ clientList, loading: false });
  } else {
    cb({ userAccount: null, user: null, userAccountList: [], userList: [], loading: false });
  }
};

export const fetchClientTerms = async (cb, client, checkedData, qt = false) => {
  let newPricedRows;
  let userTerms;
  if (client) {
    const [, res] = await getuserTerms({ user: client });
    if (res?.data) {
      userTerms = res.data;
      newPricedRows = checkedData.map((x) => newDiamondPrice(x, userTerms, qt));
    }
  } else {
    userTerms = null;
    newPricedRows = checkedData.map((x) => newDiamondPrice(x, {}, qt));
  }
  cb({ newPricedRows, userTerms });
};

export const getVisitorAndAttendeesData = async (cb, keyword = null) => {
  if (keyword?.length < 3 && keyword != null) {
    cb({ visitorList: [] });
    return false;
  }
  cb({ loading: true });

  const [, res] = await getVisitorsList({
    page: 1,
    limit: LIMIT,
    search: {
      keys: ['name'],
      keyword: keyword,
    },
    sort: [
      {
        createdAt: 'DESC',
      },
    ],
  });
  if (res?.code === 'OK' && res?.data?.user) {
    const visitorList = res.data.user.map((d) => ({ value: d.name, key: d.id }));
    cb({ visitorList, loading: false });
  }
};

export const getTradeShowName = async (cb, keyword = null) => {
  if (keyword?.length < 3 && keyword != null) {
    cb({ tradeList: [] });
    return false;
  }
  const [, res] = await getTradeList({
    page: 1,
    limit: LIMIT,
    search: {
      keys: ['name'],
      keyword: keyword,
    },
    sort: [
      {
        createdAt: 'DESC',
      },
    ],
  });
  if (res?.code === 'OK' && res?.data?.list) {
    const tradeList = res.data.list.map((d) => ({ value: d.name, key: d.id }));
    cb(tradeList);
  }
};

export function HeadingCalc(checked, inTab = null) {
  let quoteSum;
  const sum = calculate(checked);
  if (inTab === 'quoteApprove') {
    quoteSum = quoteCalculation(checked);
  }
  return (
    <div className="DiamondDetailPopup">
      <div className="DiamondDetailPopupItem">
        <span>CT. :</span>
        <span>{parseFloat(sum.total_carat).toFixed(2)}</span>
      </div>
      <div className="DiamondDetailPopupItem">
        <span>Disc% :</span>
        <span>{parseFloat(sum.final_discount).toFixed(2)}</span>
      </div>
      <div className="DiamondDetailPopupItem">
        <span>Price/Ct :</span>
        <span>{formatNumber(parseFloat(sum.final_price).toFixed(2))}</span>
      </div>
      {['note', 'orderApprove', 'quoteApprove', 'order', 'showStone', 'bid'].includes(inTab) && (
        <div className="DiamondDetailPopupItem">
          <span>Total Value :</span>
          <span>{formatNumber(parseFloat(sum.final_value).toFixed(2))}</span>
        </div>
      )}
      {inTab === 'quoteApprove' && (
        <>
          <div className="DiamondDetailPopupItem">
            <span>Discount Different : </span>
            <span>{parseFloat(quoteSum.discountDifferent).toFixed(2)}</span>
          </div>
          <div className="DiamondDetailPopupItem">
            <span>Offer : </span>
            <span>{parseFloat(quoteSum.offer).toFixed(2)}</span>
          </div>
          <div className="DiamondDetailPopupItem">
            <span>Offer value : </span>
            <span>{parseFloat(quoteSum.offerValue).toLocaleString(2)}</span>
          </div>
        </>
      )}
    </div>
  );
}

export function showFinalCalc(checked = [], terms = null, cmCharge = null, inFinalCal = false) {
  const newPricedRows = checked.map((x) => newDiamondPrice(x, terms, inFinalCal));
  if (cmCharge) {
    let charge = 0;
    newPricedRows.map((line) => {
      if (line.isCm && line.isCm === 'ELIG') {
        const found = cmCharge.filter((el) => el.from <= line.crt && (el.to ? el.to >= line.crt : true));
        if (found.length) charge += found[0].fee;
      }
      return true;
    });
    cmCharge = parseFloat(charge).toFixed(2);
  }
  const summation = calculate(newPricedRows);

  const diamond = {
    totalCarat: parseFloat(summation.total_carat).toFixed(2),
    avgDisc: parseFloat(summation.final_discount).toFixed(2),
    finalAvgDisc: parseFloat(summation.final_term_discount).toFixed(2),
    finalNetRate: `$ ${formatNumber(parseFloat(summation.final_rate).toFixed(2))}`,
    netValue: `$ ${formatNumber(parseFloat(summation.final_value).toFixed(2))}`,
    finalNetValue: `$  ${formatNumber(parseFloat(summation.final_net_value).toFixed(2))}`,
  };

  const diamondValues = [
    { id: 1, title: 'Total Carats', value: diamond.totalCarat },
    { id: 2, title: 'Final Net Rate', value: diamond.finalNetRate },
    { id: 3, title: 'Avg. Discount', value: diamond.avgDisc },
    { id: 4, title: 'Final Avg Discount', value: diamond.finalAvgDisc },
    { id: 5, title: 'Net Value', value: diamond.netValue },
    {
      id: 6,
      title: 'Final Net Value',
      value: diamond.finalNetValue + (cmCharge > 0 ? ' + $' + cmCharge + ' (CM Charges)' : ''),
    },
  ];
  return [diamondValues, newPricedRows];
}

export const quoteActionHandel = async (cb, checked, btnName) => {
  const status = {
    approve: DIAMOND_BLOCK.OFFER.ACCEPTED,
    reject: DIAMOND_BLOCK.OFFER.REJECTED,
  };
  const request = {
    id: checked.map((d) => d.blockId),
    offerStatus: status[btnName],
  };
  const [, res] = await getDiamondTrackUpdateStatus(request);
  if (res.code === 'OK') {
    cb('success');
    OpenNotification({ type: 'success', title: res.message });
  } else {
    cb('error');
  }
};
export const checkClientName = (diamond, cb = null) => {
  const userId = groupBy(diamond, 'user.id');
  if (diamond.length === 1 || Object.keys(userId).length === 1) {
    if (cb !== null) cb({ client: diamond[0]?.user });
    return true;
  } else {
    OpenNotification({ type: 'error', title: Messages.sameClientSelect });
    return false;
  }
};

export function getStoneInfo(props_row) {
  const params = [
    props_row.vStnId || props_row.stoneId,
    props_row.shpNm,
    parseFloat(props_row.crt).toFixed(2),
    props_row.isFcCol ? props_row.fcColNm : props_row.colNm,
    props_row.clrNm,
    props_row.cutNm,
    props_row.polNm,
    props_row.symNm,
    props_row.fluNm,
  ].filter((el) => {
    return el && el;
  });
  return params;
}

export const getBankRate = async (rateType, cb) => {
  const [, res] = await getRateSettings({
    filter: {
      type: PROJECT_SETTINGS.TYPE[rateType],
    },
  });
  if (res.code === 'OK' && res.data) {
    cb(res.data.data);
  }
};

export const handlePrint = (diamond, cb) => {
  if (!diamond) diamond = [];
  const request = { filter: {} };
  const selectedIds = diamond.map((o) => o.id);
  if (!isEmpty(selectedIds)) {
    request.id = selectedIds;
    downloadPdfFile(request, cb);
  } else {
    if (isFunction(cb)) cb();
    OpenNotification({ type: 'error', title: 'Please select stone(s).' });
  }
};
export const getQuoteDayList = async (cb) => {
  const request = {
    isDeleted: false,
    sort: [{ name: 'ASC' }],
  };
  const [, res] = await getQuoteDay(request);
  if (res.code === 'OK' && res.data?.list?.length) {
    const quoteDayList = res.data.list.map((d) => ({
      value: d?.name,
      key: d?.id,
    }));
    cb(quoteDayList);
  }
};

function getBidStartTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(11, 'hours');
  else return moment().startOf('day').add(15, 'hours');
}

function getBidEndTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(14, 'hours').add(59, 'minutes');
  else return moment().startOf('day').add(10, 'hours').add(59, 'minutes');
}

export function getBidType() {
  let bidType = 0;
  if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) && moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)) {
    bidType = DIAMOND_BID.TYPE.OPEN;
  } else if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) || moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)) {
    bidType = DIAMOND_BID.TYPE.BLIND;
  }
  return bidType;
}

export const orderHandel = async (cb, checked, btnName, otherRequestData = {}) => {
  const status = {
    approve: ORDER_STATUS.APPROVE,
    reject: ORDER_STATUS.REJECTED,
    cancel: ORDER_STATUS.CANCELED,
  };

  const diamonds = checked.map((stone) => stone?.diamondId).filter(isNotEmpty);
  const [, res] = await statusChange({
    ...otherRequestData,
    id: checked[0].orderId,
    status: status[btnName],
    diamonds,
  });
  if (res.code === 'OK') {
    cb('success');
    OpenNotification({ type: 'success', title: res.message });
  } else {
    cb('error');
  }
};

export const MemoAndOfferCalc = ({ data }) => {
  const totalSum = [
    {
      name: 'Pieces',
      key: 'total_pieces',
    },
    {
      name: 'Cts',
      key: 'total_carat',
    },
    {
      name: 'Rap Price',
      key: 'final_rapaport',
    },
    {
      name: 'Avg Disc',
      key: 'final_discount',
    },
    {
      name: 'Total Ct/Pr',
      key: 'final_price',
    },
    {
      name: 'Amount',
      key: 'final_value',
    },
  ];
  return (
    <div className="DiamondDetailPopup">
      {totalSum.map((sum) => (
        <div key={sum.key} className="DiamondDetailPopupItem">
          <span>{sum.name} : </span>
          <span>{formatNumber(parseFloat(data[sum.key]).toFixed(2))}</span>
        </div>
      ))}
    </div>
  );
};
