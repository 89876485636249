import { find, invert, cloneDeep } from 'lodash';

import Storage from 'services/storage';

import { isNotEmpty } from 'util/utils';

import { MENU } from './Menu';
import { getDefaultPermissions } from './defaultPermissions/_getDefaultPermissions';
import { ActivitiesPermissions } from './defaultPermissions/activities.permission';
import { BidShowPermissions } from './defaultPermissions/bidshow.permission';
import { ClientPermissions } from './defaultPermissions/client.permission';
import { DashboardPermissions } from './defaultPermissions/dashboard.permission';
import { DemandMainPermissions } from './defaultPermissions/demandmain.permission';
import { InventoryPermissions } from './defaultPermissions/inventory.permission';
import { NotificationPermissions } from './defaultPermissions/notification.permission';
import { PricingPermissions } from './defaultPermissions/pricing.permission';
import { ReportPermissions } from './defaultPermissions/report.permission';
import { SettingPermissions } from './defaultPermissions/setting.permission';
import { ShortcutsPermissions } from './defaultPermissions/shortcuts.permission';
import { TradeShowPermissions } from './defaultPermissions/tradeshow.permission';
import { TransactionPermissions } from './defaultPermissions/transaction.permission';
import { UserPermissions } from './defaultPermissions/user.permission';

export const USER_PERMISSION = [
  { module: 'DASHBOARD', title: 'Dashboard', permissions: getDefaultPermissions() },
  { module: 'SEARCH_DIAMOND', title: 'Search Diamond', permissions: getDefaultPermissions() },
  { module: 'QUICK_SEARCH', title: 'Quick Search', permissions: getDefaultPermissions() },
  { module: 'FANCY_SEARCH', title: 'Fancy Search', permissions: getDefaultPermissions() },
  { module: 'SAVE_SEARCH', title: 'Save Search', permissions: getDefaultPermissions() },
  { module: 'LAYOUT', title: 'Search Layout', permissions: getDefaultPermissions() },
  { module: 'DEMAND', title: 'Demand', permissions: getDefaultPermissions() },
  { module: 'ENQUIRY', title: 'Enquiry', permissions: getDefaultPermissions() },
  { module: 'SEARCH_LIST', title: 'Search List', permissions: getDefaultPermissions() },
  { module: 'COMPARE', title: 'Compare', permissions: getDefaultPermissions() },
  { module: 'MATCH_PAIR', title: 'Match Pair', permissions: getDefaultPermissions() },
  { module: 'BID', title: 'BID IT', permissions: getDefaultPermissions() },
  { module: 'BID_TO_BUY', title: 'Bid To Buy', permissions: getDefaultPermissions() },
  { module: 'NEW_ARRIVAL', title: 'New Arrival', permissions: getDefaultPermissions() },
  { module: 'EXCLUSIVE', title: 'Exclusive', permissions: getDefaultPermissions() },
  { module: 'UPCOMING', title: 'Upcoming Diamonds', permissions: getDefaultPermissions() },
  { module: 'CART', title: 'Cart', permissions: getDefaultPermissions() },
  { module: 'HOLD', title: 'Hold', permissions: getDefaultPermissions() },
  { module: 'MEMO', title: 'Memo', permissions: getDefaultPermissions() },
  { module: 'REMOVE_FROM_RAPNET', title: 'Remove From Rapnet', permissions: getDefaultPermissions() },
  { module: 'WATCHLIST', title: 'Watchlist', permissions: getDefaultPermissions() },
  { module: 'NOTES', title: 'Notes', permissions: getDefaultPermissions() },
  { module: 'CONFIRM_STONE', title: 'Confirm Stone', permissions: getDefaultPermissions() },
  { module: 'SHIPMENT', title: 'Shipment', permissions: getDefaultPermissions() },
  { module: 'QUOTE_DAY', title: 'Quote Day', permissions: getDefaultPermissions() },
  { module: 'APPOINTMENT', title: 'Appointment', permissions: getDefaultPermissions() },
  { module: 'PRINT', title: 'Print', permissions: getDefaultPermissions() },
  { module: 'QUOTE', title: 'Quote ', permissions: getDefaultPermissions() },
  { module: 'NOTIFICATIONS', title: 'Notifications', permissions: getDefaultPermissions() },
  { module: 'MY_BID', title: 'My Bid', permissions: getDefaultPermissions() },
  { module: 'OFFICE', title: 'Office', permissions: getDefaultPermissions() },
  { module: 'FINAL_CALCULATIONS', title: 'Final Calculations', permissions: getDefaultPermissions() },
  { module: 'SHARE_VIA_MAIL', title: 'Share via mail', permissions: getDefaultPermissions() },
  { module: 'SHARE_VIA_WHATSAPP', title: 'Share via wtsap', permissions: getDefaultPermissions() },
  { module: 'SHARE_VIA_SKYPE', title: 'Share via skype', permissions: getDefaultPermissions() },
  { module: 'DOWNLOAD', title: 'Download', permissions: getDefaultPermissions() },
  { module: 'NOTIFICATIONS', title: 'Notification', permissions: getDefaultPermissions() },
  { module: 'REMINDER', title: 'Reminder', permissions: getDefaultPermissions() },
  { module: 'MADE_IN_BOTSWANA', title: 'Made in botswana', permissions: getDefaultPermissions() },
  { module: 'SUGGESTED_STOCK', title: 'Suggested stock', permissions: getDefaultPermissions() },
  { module: 'HIDE_GRID_COLUMNS', title: 'Hide Grid Columns', permissions: { org: false, ftc: false, mines: false } },
];

export const PERMISSION = [
  ...DashboardPermissions,
  ...InventoryPermissions,
  ...ClientPermissions,
  ...TransactionPermissions,
  ...PricingPermissions,
  ...NotificationPermissions,
  ...UserPermissions,

  ...ShortcutsPermissions,
  ...SettingPermissions,

  ...ActivitiesPermissions,
  ...BidShowPermissions,
  ...DemandMainPermissions,
  ...ReportPermissions,
  ...TradeShowPermissions,
];

export const MODULES = {
  TRANSACTION: {
    OFFER: {
      NAME: 'OFFER',
      MODULE: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
      },
    },
    ORDER: {
      NAME: 'ORDER',
      MODULE: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
        CANCELLED: 'CANCELLED',
      },
    },
    // ENQUIRY: {
    //   NAME: 'ENQUIRY',
    //   MODULE: {
    //     CART: 'CART',
    //     WATCHLIST: 'WATCHLIST',
    //     NOTES: 'NOTES',
    //     SEEN_STONES: 'SEEN_STONES',
    //   },
    // },
    OFFICE_VIEW: {
      NAME: 'OFFICE_VIEW',
      MODULE: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
      },
    },
    BID: {
      NAME: 'BID',
      MODULE: {
        RUNNING: 'RUNNING',
        HISTORY: 'HISTORY',
        RESULTS: 'RESULTS',
        MYCLIENTBID: 'MYCLIENTBID',
      },
    },
    HOLD: {
      NAME: 'HOLD',
      MODULE: {
        CONFIRMED: 'CONFIRMED',
        REJECTED: 'REJECTED',
      },
    },
    MEMO: {
      NAME: 'MEMO',
      MODULE: {
        CONFIRMED: 'CONFIRMED',
        REJECTED: 'REJECTED',
      },
    },
    CART: {
      NAME: 'CART',
    },
    NOTES: {
      NAME: 'NOTES',
    },
    WATCHLIST: {
      NAME: 'WATCHLIST',
    },
    SE_REPORT: {
      NAME: 'ECS_REPORT',
      MODULE: {
        E: 'E',
        C: 'C',
        S: 'S',
      },
    },
    PROFORMA_REPORT: {
      NAME: 'PROFORMA_REPORT',
      MODULE: {
        OK: 'OK',
        PENDING: 'PENDING',
      },
    },
  },
  CLIENT: {
    DASHBOARD: {
      NAME: 'DASHBOARD',
    },
    KYC: {
      NAME: 'KYC',
      MODULE: {
        GENERAL: 'GENERAL',
        BUSINESS: 'BUSINESS_DETAILS',
        BRANCH: 'BRANCH',
        BANKS: 'BANKS',
        USER: 'USER',
        REFERENCES: 'REFERENCES',
        SETTING: 'SETTING',
        TERMDETAILS: 'TERMS_DETAIL',
      },
    },
    // ENQUIRY: {
    //   NAME: 'ENQUIRY',
    //   MODULE: {
    //     HOLD: 'HOLD',
    //     MEMO: 'MEMO',
    //     DEMAND: 'DEMAND',
    //   },
    // },
    HOLD: {
      NAME: 'HOLD',
      MODULE: {
        CONFIRMED: 'CONFIRMED',
        REJECTED: 'REJECTED',
      },
    },
    MEMO: {
      NAME: 'MEMO',
      MODULE: {
        CONFIRMED: 'CONFIRMED',
        REJECTED: 'REJECTED',
      },
    },
    TRANSACTION: {
      NAME: 'TRANSACTION',
      MODULE: {
        CART: 'CART',
        NOTES: 'NOTES',
        PURCHASEHISTORY: 'PURCHASEHISTORY',
        WATCHLIST: 'WATCHLIST',
        OFFICE_VIEW: 'OFFICE_VIEW',
        SEEN_STONES: 'SEEN_STONES',
      },
    },
    ORDER: {
      NAME: 'ORDER',
      MODULE: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
        CANCELLED: 'CANCELLED',
      },
    },
    OFFER: {
      NAME: 'OFFER',
      MODULE: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
      },
    },
    OFFICE: {
      NAME: 'OFFICE',
      MODULE: {
        PENDING: 'PENDING',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
      },
    },
    SEARCH: {
      NAME: 'SEARCH',
      MODULE: {
        SAVEDSEARCH: 'SAVED_SEARCH',
        DEMAND: 'DEMAND',
        GENERALDEMAND: 'GENERALDEMAND',
        RECENT: 'RECENT_SEARCH',
      },
    },
    ACTIVITY: {
      NAME: 'ACTIVITY',
      MODULE: {
        TODO: 'TODO',
        CALLLOG: 'CALL_LOG',
        EMAIL: 'EMAIL',
        NOTES: 'NOTES',
        Page_Tracking: 'Page_Tracking',
      },
    },
    ANALYTICS: {
      NAME: 'ANALYTICS',
    },
    RATING: {
      NAME: 'RATING',
    },
    SUGGESTED_STOCK: {
      NAME: 'SUGGESTED_STOCK',
    },
    ACCURATE_STOCK: {
      NAME: 'ACCURATE_STOCK',
    },
  },
  PRICING: {
    REVISE: {
      NAME: 'REVISE',
    },
  },
  NOTIFICATIONS: {
    BULK_EMAIL: {
      NAME: 'BULK_EMAIL',
    },
    NEW: {
      NAME: 'NEW',
    },
    SEEN: {
      NAME: 'CLEARED',
    },
  },
  INVENTORY: {
    SINGLE_STONE: {
      NAME: 'SINGLE_STONE',
      MODULE: {
        OVERVIEW: 'OVERVIEW',
        SEARCH: 'SEARCH',
        QUICKSEARCH: 'QUICK_SEARCH',
        NEWARRIVAL: 'NEW_ARRIVAL',
        BIDTOBUY: 'BID_TO_BUY',
        UPCOMING: 'UPCOMING',
        SAVEDSEARCH: 'SAVED_SEARCH',
        DEMAND: 'MY_DEMAND',
        GENERALDEMAND: 'GENERALDEMAND',
        MATCHPAIR: 'MATCH_PAIR',
        SHOWSTONEREPORT: 'SHOW_STONE_REPORT',
        QUOTEDAY: 'QUOTE_DAY',
      },
    },
    ANALYTICS: {
      NAME: 'ANALYTICS',
    },
    CONFIGURATION: {
      NAME: 'CONFIGURATION',
      MODULE: {
        SYNC_WITH_GLOBAL_INVENTORY: 'SYNC_WITH_GLOBAL_INVENTORY',
        CREATE_COLLECTIONS: 'CREATE_COLLECTIONS',
        TERMS_DETAILS: 'TERMS_DETAILS',
        BID_CONFIG: 'BID_CONFIG',
        VOLUME_DISCOUNT: 'VOLUME_DISCOUNT',
        FEATURE_STONE: 'FEATURE_STONE',
        CARAT_RANGE: 'CARAT_RANGE',
        CUT_GROUP: 'CUT_GROUP',
        GLOBAL_POLICY: 'GLOBAl_POLICY',
        CHECK_IMAGE: 'CHECK_IMAGE',
        CERTIFICATE_UPLOAD: 'CERTIFICATE_UPLOAD',
        DIAMOND_COLUMN_SETTING: 'DIAMOND_COLUMN_SETTING',
        DOWNLOAD_EXCEL: 'DOWNLOAD_EXCEL',
        QUOTE_DAY: 'QUOTE_DAY',
        CHECK_PACKET: 'CHECK_PACKET',
      },
    },
  },
  DASHBOARD: {
    DASHBOARD: {
      NAME: 'DASHBOARD',
    },
    ENQUIRY: {
      NAME: 'ENQUIRY',
    },
    INVENTORY: {
      NAME: 'INVENTORY',
    },
    SEARCH: {
      NAME: 'SEARCH',
    },
  },
  USER: {
    OVERVIEW: {
      NAME: 'OVERVIEW',
    },
    ACTIONS: {
      NAME: 'ACTIONS',
    },
    PERFORMANCE: {
      NAME: 'PERFORMANCE',
    },
    CONFIGURATION: {
      NAME: 'CONFIGURATION',
      MODULE: {
        CHANGE_PASSOWRD: 'CHANGE_PASSOWRD',
        BASIC_DETAILS: 'BASIC_DETAILS',
        PERMISSIONS: 'PERMISSIONS',
      },
    },
    BEHAVIOUR: {
      NAME: 'BEHAVIOUR',
      MODULE: {
        LOGIN_LOG: 'LOGIN_LOG',
        ACTIVITY: 'ACTIVITY',
      },
    },
  },
  REPORT: {
    Page_Tracking: {
      NAME: 'Page_Tracking',
    },
  },
  ACTIVITIES: {
    TodoList: {
      NAME: 'Todo',
    },
    CallLog: {
      NAME: 'CallLog',
    },
    EMAIL: {
      NAME: 'EMAIL',
    },
    TARGET: {
      NAME: 'TARGET',
    },
    NOTES: {
      NAME: 'NOTES',
      MODULE: {
        DUE: 'DUE',
        UPCOMING: 'UPCOMING',
        ARCHIVED: 'ARCHIVED',
      },
    },
    APPOINTMENT: {
      NAME: 'APPOINTMENT',
      MODULE: {
        PENDING: 'PENDING',
        COMPLETE: 'COMPLETE',
        CANCEL: 'CANCEL',
        REOPEN: 'REOPEN',
      },
    },
  },
  DEMANDMAIN: {
    SUPPLIER: {
      NAME: 'SUPPLIER',
    },
    SUPPLIERDEMAND: {
      NAME: 'SUPPLIERDEMAND',
      MODULE: {
        DIAMONDDEMAND: 'DIAMONDDEMAND',
        GENERALDEMAND: 'GENERALDEMAND',
      },
    },
  },
  TRADESHOW: {
    APPOINTMENT: {
      NAME: 'APPOINTMENT',
      MODULE: {
        PENDING: 'PENDING',
        COMPLETED: 'COMPLETED',
        CANCEL: 'CANCEL',
        REOPEN: 'REOPEN',
      },
    },
    DISCUSSION: {
      NAME: 'DISCUSSION',
    },
    VISITOR: {
      NAME: 'VISITOR',
    },
    Details: {
      NAME: 'Details',
    },
  },
  BIDSHOW: {
    APPOINTMENT: {
      NAME: 'APPOINTMENT',
      MODULE: {
        PENDING: 'PENDING',
        COMPLETED: 'COMPLETED',
        CANCEL: 'CANCEL',
        REOPEN: 'REOPEN',
      },
    },
    DISCUSSION: {
      NAME: 'DISCUSSION',
    },
    VISITOR: {
      NAME: 'VISITOR',
    },
  },
  SHORTCUT: {
    USER: {
      NAME: 'USER',
    },
    KYC: {
      NAME: 'CLIENT_KYC',
    },
  },
  SETTING: {
    // MASTER: {
    //   NAME: 'MASTER',
    // },
    // SUBMASTER: {
    //   NAME: 'SUB_MASTER',
    // },
    // HOME_SETTING: {
    //   NAME: 'HOME_SETTING',
    // },
    // CONTACT_DIRECTORY: {
    //   NAME: 'CONTACT_DIRECTORY',
    // },
    ACCOUNT: {
      NAME: 'MY_ACCOUNT',
    },
    NOTIFICATION: {
      NAME: 'NOTIFICATION',
    },
    TERMS: {
      NAME: 'TERMS_AND_CONDITIONS',
    },
    MY_SETTING: {
      NAME: 'MY_SETTING',
      MODULE: {
        MASTER: 'MASTER',
        SUB_MASTER: 'SUB_MASTER',
        HOME_SETTING: 'HOME_SETTING',
        DISCOUNT_SETTING: 'DISCOUNT_SETTING',
        CONTACT_DIRECTORY: 'CONTACT_DIRECTORY',
      },
    },
  },
};

export const MAINMODULE = {
  TRANSACTION: 'TRANSACTION',
  PRICING: 'PRICING',
  NOTIFICATIONS: 'NOTIFICATION',
  INVENTORY: 'INVENTORY',
  DASHBOARD: 'DASHBOARD',
  USER: 'USER',
  DEMANDMAIN: 'DEMANDMAIN',
  REPORT: 'REPORT',
  CLIENT: 'CLIENT',
  TODO: 'TODO',
  ACTIVITIES: 'ACTIVITIES',
  SHORTCUT: 'SHORTCUTS',
  SETTING: 'SETTING',
  TRADESHOW: 'TRADESHOW',
  BIDSHOW: 'BIDSHOW',
};

export function getLoginPermission() {
  let permit = Storage.get('permission') || [];
  if (permit.filter((x) => x.mainModule && x.mainModule).length === 0) permit = [];
  if (!permit.length) permit = cloneDeep(PERMISSION);

  permit.forEach((val) => {
    if (!val.subModule && val.mainModule) {
      const fst = val.mainModule.split('_')[0];
      const main = invert(MAINMODULE)[val.mainModule] ? val.mainModule : invert(MAINMODULE)[fst] ? fst : val.mainModule;
      const mod = val.module;
      const sub = val.subModule
        ? val.subModule
        : invert(MAINMODULE)[fst] && val.mainModule.includes('_')
        ? val.mainModule.split('_').slice(1).join('_')
        : val.module;

      val.mainModule = main;
      val.subModule = sub;
      const obj = PERMISSION.filter((el) => el.mainModule === main && el.subModule === sub && el.module === mod);
      if (obj && obj[0]) {
        val.url = obj[0].url;
      }
    }
  });

  return permit;
}

export function getPermittedMainModuleList() {
  const PermissionData = getLoginPermission();
  let MenuData = [];
  if (PermissionData) {
    MENU.forEach((val) => {
      const modules = PermissionData.filter((el) => el?.mainModule === val.permit)
        .map((x) => x.permissions.view || x.permissions.all)
        .filter((el) => el && el);
      if (modules.length) MenuData.push(val);
    });
  } else {
    MenuData = MENU;
  }
  return MenuData;
}

export function getPermittedSubModuleList(mainModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData?.filter((el) => el?.mainModule === mainModule)
    .filter((x) => x.permissions.view || x.permissions.all)
    .map((x) => x.subModule || x.module);
  return MenuData;
}

export function getPermittedModuleList(mainModule, subModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.subModule === subModule)
    .filter((x) => x.permissions.view || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function getPermittedInsertList(mainModule, subModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.subModule === subModule)
    .filter((x) => x.permissions.insert || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function getPermittedInsertListFirst(mainModule, subModule, module) {
  const PermissionData = getLoginPermission();
  let MenuData = subModule
    ? find(PermissionData, { mainModule, subModule })
    : find(PermissionData, { mainModule, module });
  if (MenuData) {
    MenuData = MenuData.permissions.insert || MenuData.permissions.all ? [MenuData.subModule] : [];
  } else {
    MenuData = [];
  }
  return MenuData;
}

export function getPermittedUpdateListFirst(mainModule, subModule, module) {
  const PermissionData = getLoginPermission();
  let MenuData = find(PermissionData, { mainModule, subModule, module });
  if (MenuData) {
    MenuData = MenuData.permissions.update || MenuData.permissions.all ? [MenuData.subModule] : [];
  } else {
    MenuData = [];
  }
  return MenuData;
}

export function getPermittedDeleteFirst(mainModule, subModule, module) {
  const PermissionData = getLoginPermission();
  let MenuData = subModule
    ? find(PermissionData, { mainModule, subModule })
    : find(PermissionData, { mainModule, module });
  if (MenuData) {
    MenuData = MenuData ? (MenuData.permissions.delete || MenuData.permissions.all ? [MenuData.subModule] : []) : [];
  } else {
    MenuData = [];
  }
  return MenuData;
}

export function getPermittedExportList(mainModule, subModule) {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.mainModule === mainModule && el.subModule === subModule)
    .filter((x) => x.permissions.downloadExcel || x.permissions.all)
    .map((x) => x.module);
  return MenuData;
}

export function getModuleFromUrl() {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url && window.location.pathname.includes(el.url));
  const permit = MenuData[0] ? MenuData[0].permissions : {};
  if (permit.all) Object.keys(permit).forEach((key) => void (permit[key] = true));
  return permit;
}

export function downloadPermit() {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url && window.location.pathname.includes(el.url))
    .map((x) => x.permissions.downloadExcel || x.permissions.all)
    .filter(isNotEmpty);
  return Boolean(MenuData?.[0]);
}

export function printPermit() {
  const PermissionData = getLoginPermission();
  const MenuData = PermissionData.filter((el) => el.url && window.location.pathname.includes(el.url))
    .map((x) => x.permissions.printPDF || x.permissions.all)
    .filter(isNotEmpty);
  return Boolean(MenuData?.[0]);
}
