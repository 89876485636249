import React from 'react';
import { connect } from 'react-redux';

import { Checkbox } from 'antd';
import { Formik, Form, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import FileUpload from 'containers/FileUpload';
import InputBlock from 'containers/InputBlock';
import Heading from 'containers/MainHeading';
import SelectOption from 'containers/SelectOption';

import BottomStickyButton from 'components/ShortCuts/BottomStickButton';

import * as AccountAddressAPI from 'services/AccountAddressApi';
import { showNotification } from 'services/NotificationService';

import OptionsUtils from 'util/OptionsUtils';
import { isArray, isEmpty } from 'util/utils';

import { AccountAddressActions } from 'appRedux/reducers/AccountAddress';

class BranchForm extends React.Component {
  static propTypes = {
    account: PropTypes.any, // account object with id
    onClose: PropTypes.func, // function to close modal
  };

  state = {
    cities: [],
    states: {},
    countries: {},
    addresses: [],
    deleteIds: [],
    loading: 0,
  };

  startLoading = () => this.setState(({ loading }) => ({ loading: loading + 1 }));
  stopLoading = () => this.setState(({ loading }) => ({ loading: loading - 1 }));

  addressTypes = [
    { key: 'BILLING', value: 'Billing' },
    { key: 'BUYER', value: 'Buyer' },
    { key: 'HOME', value: 'Home' },
    { key: 'OTHER', value: 'Other' },
    { key: 'SHIPPING', value: 'Shipping' },
  ];

  validationSchema = Yup.object().shape({
    addresses: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().email('Please enter valid email').required('Please enter email'),
        phone: Yup.string().required('Please enter phone'),
        // fax: Yup.string(),
        addressType: Yup.string().required('Please select address type'),
        street: Yup.string().trim().required('Please enter street'),
        zipCode: Yup.string()
          .trim()
          .required('Please enter zip code')
          .when('registrationType', {
            is: () => ['3', '4'].includes(this?.props?.account?.registrationType),
            then: (s) => s.min(6, 'Zipcode must be atleast 6 characters'),
            otherwise: (s) => s.min(4, 'Zipcode must be atleast 4 characters'),
          })
          .when('registrationType', {
            is: () => ['3', '4'].includes(this?.props?.account?.registrationType),
            then: (s) => s.max(6, 'Zipcode must be atmost 6 characters'),
            otherwise: (s) => s.max(10, 'Zipcode must be atmost 10 characters'),
          }),
        city: Yup.string().required('Please select city'),
        state: Yup.string().required('Please select state'),
        country: Yup.string().required('Please select country'),
        Remark: Yup.string(),
        isDefault: Yup.boolean().typeError('Invalid value'),
        // addressProof: Yup.string().typeError('Invalid value'),
      }),
    ),
  });

  initialValues = {
    addresses: [
      {
        email: '',
        phone: '',
        // fax: '',
        addressType: undefined,
        street: '',
        zipCode: '',
        country: undefined,
        state: undefined,
        city: undefined,
        Remark: '',
        isDefault: false,
        addressProof: undefined,
      },
    ],
  };

  getInitialValues = () => {
    const storedList = this.props?.list?.[this.props?.account?.id];

    const data = isArray(storedList) &&
      !isEmpty(storedList) && {
        addresses: storedList?.map((address) =>
          Object.fromEntries(
            Object.entries(address).map(([name, value]) => [name, isEmpty(value?.id) ? value : value?.id]),
          ),
        ),
      };

    return Object.fromEntries(
      Object.entries(this.initialValues).map(([name, value]) => [name, isEmpty(data?.[name]) ? value : data[name]]),
    );
  };

  getCountries = async () => {
    this.startLoading();
    const countries = await OptionsUtils.getCountryOptions();
    this.setState({ countries });
    this.stopLoading();
  };

  getStates = async (country) => {
    // if (this.state.states?.[country]?.length > 0) return;
    this.startLoading();
    const states = await OptionsUtils.getStateOptions(country);
    this.setState({ states: { ...this.state.states, [country]: states } });
    this.stopLoading();
  };

  getCities = async (state) => {
    // if (this.state.cities?.[state]?.length > 0) return;
    this.startLoading();
    const cities = await OptionsUtils.getCityOptions(state);
    this.stopLoading();
    this.setState({ cities: { ...this.state.cities, [state]: cities } });
  };

  initCountryStateCity = async () => {
    await this.getCountries();
    isArray(this.props?.list?.[this.props?.account?.id]) &&
      this.props.list[this.props?.account?.id].forEach(({ country, state }) => {
        if (!isEmpty(country?.id)) this.getStates(country?.id);
        if (!isEmpty(state?.id)) this.getCities(state?.id);
      });
  };

  submitForm = async (values, { setSubmitting, resetForm }) => {
    const { account } = this.props;

    values.addresses = values.addresses.map((address) => ({ account: account?.id, ...address }));

    if (!isEmpty(this.state.deleteIds)) {
      const [, res] = await AccountAddressAPI.deleteAccountAddress({ id: this.state.deleteIds });
      if (res?.code !== 'OK') return;
    }

    setSubmitting(true);
    const [err, res] = await AccountAddressAPI.upsertAccountAddress({ ...values });
    setSubmitting(false);

    if (!err && res?.code === 'OK') {
      this.props.getAccountAddressList(account?.id);
      if (this.state.saveContinue) this.props.updateSubTabIndex();
      else {
        resetForm();
        this.props.onCancel();
      }
    }
    !err && showNotification(res);
  };

  componentDidMount() {
    this.initCountryStateCity();
    this.props?.account?.id && this.props.getAccountAddressList(this.props?.account?.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.account?.id !== this.props?.account?.id) {
      this.initCountryStateCity();
      this.props?.account?.id && this.props.getAccountAddressList(this.props?.account?.id);
    }
    if (this.props.list !== prevProps.list) {
      this.initCountryStateCity();
    }
  }

  handleSaveAndContinue = (action, submitForm) => () => this.setState({ saveContinue: action }, submitForm);

  render() {
    const initialValues = this.getInitialValues();

    return (
      !this.props?.loading && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={this.validationSchema}
          validateOnChange
          onSubmit={this.submitForm}
        >
          {({
            handleChange,
            handleBlur,
            setFieldValue,
            setValues,
            setFieldTouched,
            submitForm,
            touched,
            errors,
            values,
          }) => (
            <Form>
              <div className="kycFormMainBlock">
                <Heading className="smallTitle p-0 mb-20" title="Branch Details"></Heading>
                <FieldArray
                  name="addresses"
                  render={(fieldArrayHelpers) =>
                    values?.addresses?.map((address, index) => {
                      const namePrefix = `addresses.${index}.`;
                      const _touched = touched?.addresses?.[index];
                      const _errors = errors?.addresses?.[index];

                      return (
                        <>
                          <div className="kycBlockWrapper formMainSubBlock mb-20 pb-0" key={address?.id}>
                            <InputBlock
                              type="text"
                              label={`Email *`}
                              placeholder={`Email`}
                              name={`${namePrefix}email`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={address?.email}
                              error={_touched?.email && _errors?.email}
                            />
                            <InputBlock
                              type="text"
                              label={`Phone *`}
                              placeholder={`Phone`}
                              name={`${namePrefix}phone`}
                              onBlur={handleBlur}
                              onKeyPress={(evt) => {
                                if (
                                  evt.which != 8 &&
                                  evt.which != 0 &&
                                  (evt.which < 48 || evt.which > 57) &&
                                  evt.which != 45
                                ) {
                                  evt.preventDefault();
                                }
                              }}
                              onChange={handleChange}
                              value={address?.phone}
                              error={_touched?.phone && _errors?.phone}
                            />
                            {/* <InputBlock
                              type="text"
                              label={`Fax`}
                              placeholder={`Fax`}
                              name={`${namePrefix}fax`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={address?.fax}
                              error={_touched?.fax && _errors?.fax}
                            /> */}
                            <SelectOption
                              label={`Address Type *`}
                              placeholder={`Address Type`}
                              name={`${namePrefix}addressType`}
                              selectValue={this.addressTypes}
                              onBlur={() => setFieldTouched(`${namePrefix}addressType`, true)}
                              onChange={(value) => setFieldValue(`${namePrefix}addressType`, value)}
                              value={address?.addressType}
                              error={_touched?.addressType && _errors?.addressType}
                              showSearch
                            />
                            <InputBlock
                              type="text"
                              label={`Street *`}
                              placeholder={`Street`}
                              name={`${namePrefix}street`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={address?.street}
                              error={_touched?.street && _errors?.street}
                            />
                            <InputBlock
                              type="text"
                              label={`Zip Code *`}
                              placeholder={`Zip Code`}
                              name={`${namePrefix}zipCode`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={address?.zipCode}
                              error={_touched?.zipCode && _errors?.zipCode}
                            />
                            <SelectOption
                              label={`Country *`}
                              placeholder={`Country`}
                              name={`${namePrefix}country`}
                              selectValue={this.state.countries}
                              onBlur={() => setFieldTouched(`${namePrefix}country`, true)}
                              onChange={(value) => {
                                this.getStates(value);
                                setFieldValue(`${namePrefix}city`, undefined);
                                setFieldValue(`${namePrefix}state`, undefined);
                                setFieldValue(`${namePrefix}country`, value);
                              }}
                              value={address?.country}
                              error={_touched?.country && _errors?.country}
                              showSearch
                            />
                            <SelectOption
                              label={`State *`}
                              placeholder={`State`}
                              name={`${namePrefix}state`}
                              selectValue={this.state.states?.[address?.country] || []}
                              onBlur={() => setFieldTouched(`${namePrefix}state`, true)}
                              onChange={(value) => {
                                this.getCities(value);
                                setFieldValue(`${namePrefix}city`, undefined);
                                setFieldValue(`${namePrefix}state`, value);
                              }}
                              value={
                                this.state.states?.[address?.country]?.filter((item) => item.key === address?.state)
                                  .length
                                  ? address?.state
                                  : undefined
                              }
                              error={_touched?.state && _errors?.state}
                              showSearch
                            />
                            <SelectOption
                              label={`City *`}
                              placeholder={`City`}
                              name={`${namePrefix}city`}
                              selectValue={this.state.cities?.[address?.state] || []}
                              onBlur={() => setFieldTouched(`${namePrefix}city`, true)}
                              onChange={(value) => setFieldValue(`${namePrefix}city`, value)}
                              value={
                                this.state.cities?.[address?.state]?.filter((item) => item.key === address?.city).length
                                  ? address?.city
                                  : undefined
                              }
                              error={_touched?.city && _errors?.city}
                              showSearch
                            />
                            <InputBlock
                              type="text"
                              label={`Remark`}
                              placeholder={`Remark`}
                              name={`${namePrefix}Remark`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={address?.Remark}
                              error={_touched?.Remark && _errors?.Remark}
                            />
                            <div className="checkBoxForm">
                              <label>Is Main Branch?</label>
                              <Checkbox
                                name={`${namePrefix}isDefault`}
                                onBlur={() => setFieldTouched(`${namePrefix}isDefault`, address?.isDefault)}
                                onChange={() => {
                                  const newValue = !address?.isDefault;
                                  // if (newValue) {
                                  setValues({
                                    ...values,
                                    addresses: values.addresses.map((field) => ({ ...field, isDefault: false })),
                                  });
                                  setFieldValue(`${namePrefix}isDefault`, newValue);
                                  // }
                                }}
                                error={_touched?.isDefault && _errors?.isDefault}
                                checked={address?.isDefault}
                              />
                            </div>
                            <div style={{ minWidth: '100%' }}>
                              <FileUpload
                                onChange={(value) => setFieldValue(`${namePrefix}addressProof`, value)}
                                onBlur={() => setFieldTouched(`${namePrefix}addressProof`, true)}
                                error={_touched?.addressProof && _errors?.addressProof}
                                label="Choose Document"
                                files={[address?.addressProof]}
                              />
                            </div>
                          </div>
                          <div className="kycNewBlockAdd">
                            <button
                              className="commonButton"
                              type="button"
                              role="button"
                              onClick={() => fieldArrayHelpers.push({ ...this.initialValues?.addresses?.[0] })}
                            >
                              Add
                            </button>
                            {values.addresses?.length > 1 && (
                              <button
                                className="commonButton ml-5"
                                type="button"
                                role="button"
                                onClick={() => {
                                  if (values.addresses?.length > 1) {
                                    fieldArrayHelpers.remove(index);
                                    this.setState((pstate) => pstate.deleteIds.push(address?.id));
                                  }
                                }}
                              >
                                Remove
                              </button>
                            )}
                          </div>
                        </>
                      );
                    })
                  }
                />
              </div>
              <BottomStickyButton className={!this.props?.account?.id && 'disableBlock'}>
                <button
                  className="commonButton"
                  type="button"
                  onClick={() => this.setState({ saveContinue: true }, submitForm)}
                >
                  Save & Continue
                </button>
                <button
                  className="commonButton"
                  type="button"
                  onClick={() => this.setState({ saveContinue: false }, submitForm)}
                >
                  Save
                </button>
                <button className="commonOutine" type="reset" onClick={this.props.onCancel}>
                  Cancel
                </button>
              </BottomStickyButton>
            </Form>
          )}
        </Formik>
      )
    );
  }
}

export default connect(
  ({ accountAddress }) => ({
    ...accountAddress,
  }),
  {
    getAccountAddressList: AccountAddressActions.getAccountAddressList,
  },
)(BranchForm);
