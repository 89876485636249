const initialState = { loading: 0 };

export const LoaderActionTypes = {
  Start: '@loader/start',
  Stop: '@loader/stop',
  Adjust: '@loader/adjust',
  Reset: '@loader/reset',
};

export const LoaderActions = {
  start: (payload) => ({ type: LoaderActionTypes.Start, payload }),
  stop: (payload) => ({ type: LoaderActionTypes.Stop, payload }),
  adjust: (payload) => ({ type: LoaderActionTypes.Adjust, payload }),
  reset: () => ({ type: LoaderActionTypes.Reset }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LoaderActionTypes.Start: {
      let { loading } = state;
      return { ...state, loading: ++loading };
    }

    case LoaderActionTypes.Stop: {
      let { loading } = state;
      return { ...state, loading: loading < 1 ? 0 : --loading };
    }

    case LoaderActionTypes.Adjust: {
      const { loading } = state;
      const { count } = payload ?? {};
      return { ...state, loading: loading + count < 1 ? 0 : loading + count };
    }

    case LoaderActionTypes.Reset: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
};
