import React from 'react';

import { Popconfirm } from 'antd';
import PropTypes from 'prop-types';

import { isEmpty, isFunction } from 'util/utils';

import svgDelete from 'assets/svg/savedSearch/delete.svg';
import svgEdit from 'assets/svg/savedSearch/edit.svg';
import svgSearch from 'assets/svg/savedSearch/search.svg';

export const LiveStatus = (props) => <span className={`liveStatus ${props.status || 'offline'}`} />;

export const ListItemAction = ({ icon, ...props }) => (
  <div className="list-item-action savedSearchActionItem" {...props}>
    <img src={icon} alt="edit" />
  </div>
);
ListItemAction.propTypes = {
  icon: PropTypes.any,
};

export const ListItemTitle = ({ title, secondaryTitle }) => (
  <>
    <strong>{title}</strong> <span>{secondaryTitle}</span>
  </>
);
ListItemTitle.propTypes = {
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
};

const ListItem = (props) => {
  const { header, body, onEdit, onDelete, onSearch, deleteModule } = props;

  return (
    <div className="list-item savedSearchItem">
      {header && (
        <div className="list-item-header savedSearchTop">
          {header?.title && <span className="list-item-title savedTitle">{header.title}</span>}
          {header?.info && <span className="list-item-info customerName">{header.info}</span>}
          {(isFunction(onEdit) || isFunction(onDelete) || isFunction(onSearch)) && (
            <span className="list-item-actions savedSearchAction">
              {isFunction(onEdit) && <ListItemAction icon={svgEdit} alt="edit" onClick={onEdit} />}
              {isFunction(onDelete) && (
                <Popconfirm
                  placement="left"
                  title={`Are you sure you want to delete this ${!isEmpty(deleteModule) ? deleteModule : 'Search'}?`}
                  okText={`Delete`}
                  onConfirm={() => onDelete()}
                >
                  <span>
                    <ListItemAction icon={svgDelete} alt="delete" />
                  </span>
                </Popconfirm>
              )}
              {isFunction(onSearch) && <ListItemAction icon={svgSearch} alt="search" onClick={onSearch} />}
            </span>
          )}
        </div>
      )}
      {body && <div className="list-item-body savedSearchBottom">{body}</div>}
    </div>
  );
};

ListItem.propTypes = {
  header: PropTypes.object,
  body: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSearch: PropTypes.func,
};

export default ListItem;
