import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import isEqual from 'lodash/isEqual';
import xorWith from 'lodash/xorWith';
import moment from 'moment-timezone';

import ListingTableWrapper from 'containers/ListingTableWrapper';
import ListingWrapper from 'containers/ListingWrapper';

import DiamondListingAction from 'components/DiamondListing/DiamondListingAction';
import SelectStone from 'components/DiamondListing/SelectStone';
import CustomerDropdown from 'components/List/CustomerDropdown';
import { FilterTopContainer, FilterTopInnerField } from 'components/List/FilterTop';
import ListFooter from 'components/List/ListFooter';
import Pagination from 'components/List/Pagination';

// import { getDate } from 'services/commonFunc';
import { groupingArray } from 'services/groupingArray';
import UtilService from 'services/util';

import { useCompositeState } from 'util/hooks';
import { isEmpty, queryStringToObject } from 'util/utils';

import { USERS_TYPE } from 'constants/Common';
import CommonApi from 'constants/CommonApi';

import Table, { LIMIT } from '../TableBack';
import { fetchcolumnAsync } from '../inventoryFunctions';

const currentType = 'upcomingList';

export const getState = (additionalState = {}) => ({
  client: null,
  data: [],
  page: 1,
  limit: LIMIT,
  sum: {},
  count: 0,
  loading: false,
  DefaultData: [],
  seachFilterTag: {},
  ...additionalState,
});

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

const UpcomingDiamond = (props) => {
  const [state, setState] = useCompositeState(getState({ seachFilterTag: {} }));
  const [columns, setColumns] = useState([]);

  const sortRef = useRef([{ inDt: 'ASC' }]);
  const dispatch = useDispatch();

  const loginUser = useSelector((state) => state.auth?.authUser);

  const fetchcolumn = React.useCallback(async () => {
    const fetchedColumns = await fetchcolumnAsync();
    const columns = fetchedColumns.filter((column) => !['inDt'].includes(column.id));
    setColumns(columns);
  }, []);
  const fetch = useCallback(async () => {
    setState({ loading: true, data: [], count: 0, sum: {} });
    const client = props?.client?.user?.id || state?.client;
    const user = !isEmpty(client) ? [client] : undefined;
    const sort = sortRef.current;
    const diamondSearchId = queryStringToObject()?.result;

    const requestConf = {
      ...CommonApi.Diamond.paginate,
      request: {
        page: state?.page,
        limit: state?.limit,
        isUpcoming: true,
        ...(!isEmpty(user) && { user }),
        ...(!isEmpty(sort) && { sort }),
        filters: [
          {
            wSts: 'U',
            inDt: { '<=': moment().add(7, 'days').endOf('day').toISOString() },
            ...(diamondSearchId && { diamondSearchId }),
            ...(loginUser?.type === USERS_TYPE.SELLER && { sSts: { nin: ['P', 'D'] } }),
          },
        ],
      },
    };
    const [, res] = await UtilService.callApiAsync(requestConf);
    if (res?.code === 'OK') {
      const rows = res?.data?.[0]?.diamonds;
      const sum = res?.data?.[0]?.total?.[0];
      const count = res?.data?.[0]?.count;
      const seachFilterTag = res?.data?.[0]?.filter;

      const data = rows.map(({ inDt, ...diamond }) => ({
        ...diamond,
        // userDate: getDate(inDt),
        userDate: moment(inDt).format('DD/MM/YYYY H A ') + moment.tz('Asia/Calcutta').zoneAbbr(),
      }));
      const grouppedData = groupingArray(data, 'userGroupHold', undefined, 'userDate');
      setState({ data: grouppedData, DefaultData: data, sum, count, seachFilterTag });
    }
    setState({ loading: false });
  }, [setState, props?.client?.user?.id, state?.client, state?.page, state?.limit, loginUser?.type]);
  const handleSort = useCallback(
    (currentSort) => {
      if (currentSort.length != sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [fetch],
  );

  const clearAllSelectedRows = React.useCallback(() => dispatch({ type: 'RESET_SELECTED_ROWS' }), [dispatch]);
  const onPaginationChange = React.useCallback((page, limit) => setState({ page, limit }), [setState]);
  const onClientChange = React.useCallback((client) => setState({ client: client?.user?.id }), [setState]);

  useEffect(() => {
    fetchcolumn();
  }, [fetchcolumn]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <ListingWrapper>
      <SelectStone sum={state.sum} currentType={currentType} />
      <FilterTopContainer>
        <FilterTopInnerField />
        <CustomerDropdown onChange={onClientChange} />
      </FilterTopContainer>
      <ListingTableWrapper subtractFrom=".customerMaintab,.customerSubTab,.diamondListingAction,.filterTopMainBlock,.botoomStickyBar">
        <Table
          data={state.data}
          columns={columns}
          loading={state.loading}
          handleSort={handleSort}
          currentType={currentType}
          initialSort={sortRef.current}
        />
      </ListingTableWrapper>
      {!isEmpty(state?.data) && state?.count > 0 && (
        <ListFooter contentSpaceBetween>
          <DiamondListingAction
            {...state}
            {...props}
            columns={columns}
            ActionFalse={false}
            share
            download
            printList
            compareStone
            mailList
            noContainer
            clearAll={clearAllSelectedRows}
            fetch={fetch}
            currentType={currentType}
            modifySearch
          />
          <Pagination
            page={state?.page}
            limit={state?.limit}
            count={state?.count}
            length={state?.data?.length}
            onPaginationChange={onPaginationChange}
          />
        </ListFooter>
      )}
    </ListingWrapper>
  );
};

export default connect(
  ({ ...reducers }) => ({
    ...reducers,
  }),
  {},
)(React.memo(UpcomingDiamond));
