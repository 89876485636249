import axios from 'axios';

import OpenNotification from 'constants/OpenNotifications';
import { COMMON_URL } from 'constants/CommonUrl';

export const InventoryUserApi = async (request = {}) => {
  const config = {
    headers: { 'content-type': 'application/json' },
  };
  const url = `${COMMON_URL.BASE_URL}sync/InvUser/createuser`;
  let res = {};
  await axios
    .post(url, request, config.headers)
    .then(({ data }) => {
      res = data;
    })
    .catch((error) => {
      if (error?.response?.data) {
        OpenNotification({ type: 'error', title: error.response.data?.message });
      } else {
        OpenNotification({ type: 'error', title: 'Something bad happend' });
      }
    });

  return res;
};

export const showInventoryUser = async (request = {}) => {
  const config = {
    headers: { 'content-type': 'application/json' },
  };
  const url = `${COMMON_URL.BASE_URL}sync/InvUser/inventoryUsers`;
  let res = {};
  await axios
    .post(url, request, config.headers)
    .then(({ data }) => {
      res = data.data.users;
    })
    .catch((error) => {
      if (error?.response?.data) {
        OpenNotification({ type: 'error', title: error.response.data?.message });
      } else {
        OpenNotification({ type: 'error', title: 'Something bad happend' });
      }
    });

  return res;
};
