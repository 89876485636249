import { getDefaultPermissions } from './_getDefaultPermissions';

export const TransactionPermissions = [
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFER',
    module: 'PENDING',
    title: 'Pending',
    url: '/transaction/offer/pending',
    permissions: getDefaultPermissions({ modify: true, approve: true, reject: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFER',
    module: 'APPROVED',
    title: 'Approved',
    url: '/transaction/offer/approved',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFER',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/transaction/offer/rejected',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ORDER',
    module: 'PENDING',
    title: 'Pending',
    url: '/transaction/order/pending',
    permissions: getDefaultPermissions({ approve: true, reject: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ORDER',
    module: 'APPROVED',
    title: 'Approved',
    url: '/transaction/order/approved',
    permissions: getDefaultPermissions({ cancel: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ORDER',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/transaction/order/rejected',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'ORDER',
    module: 'CANCELLED',
    title: 'Cancelled',
    url: '/transaction/order/cancelled',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'CART',
    module: 'CART',
    title: 'Cart',
    url: '/transaction/cart',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'NOTES',
    module: 'NOTES',
    title: 'Notes',
    url: '/transaction/notes',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'WATCHLIST',
    module: 'WATCHLIST',
    title: 'Watchlist',
    url: '/transaction/watchList',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFICE_VIEW',
    module: 'PENDING',
    title: 'Pending',
    url: '/transaction/office-view/pending',
    permissions: getDefaultPermissions({ approve: true, reject: true }),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFICE_VIEW',
    module: 'APPROVED',
    title: 'Approved',
    url: '/transaction/office-view/approved',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'OFFICE_VIEW',
    module: 'REJECTED',
    title: 'Rejected',
    url: '/transaction/office-view/rejected',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'BID',
    module: 'RUNNING',
    title: 'Running',
    url: '/running',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'BID',
    module: 'RESULTS',
    title: 'Results',
    url: '/results',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'BID',
    module: 'HISTORY',
    title: 'History',
    url: '/history',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRANSACTION',
    subModule: 'BID',
    module: 'MYCLIENTBID',
    title: 'My Client Bid',
    url: '/my-client-bid',
    permissions: getDefaultPermissions(),
  },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'HOLD',
  //   module: 'CONFIRMED',
  //   title: 'Confirmed',
  //   url: '/transaction/hold/confirmed',
  //   permissions: getDefaultPermissions({ unHold: true }),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'HOLD',
  //   module: 'REJECTED',
  //   title: 'Rejected',
  //   url: '/transaction/hold/rejected',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'MEMO',
  //   module: 'CONFIRMED',
  //   title: 'Confirmed',
  //   url: '/transaction/memo/confirmed',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'MEMO',
  //   module: 'REJECTED',
  //   title: 'Rejected',
  //   url: '/transaction/memo/rejected',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'ECS_REPORT',
  //   module: 'E',
  //   title: 'E',
  //   url: '/transaction/report/e',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'ECS_REPORT',
  //   module: 'C',
  //   title: 'C',
  //   url: '/transaction/report/c',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'ECS_REPORT',
  //   module: 'S',
  //   title: 'S',
  //   url: '/transaction/report/s',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'PROFORMA_REPORT',
  //   module: 'OK',
  //   title: 'Ok',
  //   url: '/transaction/proforma-report/ok',
  //   permissions: getDefaultPermissions(),
  // },
  // {
  //   mainModule: 'TRANSACTION',
  //   subModule: 'PROFORMA_REPORT',
  //   module: 'PENDING',
  //   title: 'Pending',
  //   url: '/transaction/proforma-report/pending',
  //   permissions: getDefaultPermissions(),
  // },
];
