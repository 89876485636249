import React from 'react';

import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';

import classNames from 'util/classNames';
import { isArray, isString } from 'util/utils';

const style = { height: '100vh' };

function ListingTableWrapper({ allClasses, classList, className, ...restProps }) {
  const substractFrom = React.useMemo(() => {
    if (isString(classList)) return classList;
    if (isArray(classList)) return classList.join(',');
    if (isArray(allClasses)) return allClasses.map((className) => `.${className}`).join(',');
  }, [allClasses, classList]);

  return (
    <GridHeightContainer
      subtractFrom={substractFrom}
      className={classNames([`searchResultTable`, className])}
      style={style}
      {...restProps}
    />
  );
}

export default ListingTableWrapper;
