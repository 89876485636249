import { getDefaultPermissions } from './_getDefaultPermissions';

export const UserPermissions = [
  {
    mainModule: 'USER',
    module: 'OVERVIEW',
    title: 'Users',
    url: '/user/overview',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'USER',
    module: 'ACTIONS',
    title: 'Actions',
    url: '/user/actions',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'USER',
    module: 'PERFORMANCE',
    title: 'Performance',
    url: '/user/performance',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'USER',
    subModule: 'CONFIGURATION',
    module: 'BASIC_DETAILS',
    title: 'Basic Details',
    url: '/user/configuration/user-infomation',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'USER',
    subModule: 'CONFIGURATION',
    module: 'PERMISSIONS',
    title: 'Permissions',
    url: '/user/configuration/permissions',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'USER',
    subModule: 'CONFIGURATION',
    module: 'CHANGE_PASSOWRD',
    title: 'Change Password',
    url: '/user/configuration/change-password',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'USER',
    subModule: 'BEHAVIOUR',
    module: 'ACTIVITY',
    title: 'Activity',
    url: '/user/user-behaviour/activity',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'USER',
    subModule: 'BEHAVIOUR',
    module: 'LOGIN_LOG',
    title: 'Login Log',
    url: '/user/user-behaviour/login-log',
    permissions: getDefaultPermissions(),
  },
];
