// import { LIMIT } from 'components/Inventory/TableBack';

// import { isArray } from 'util/utils';

import { USERS_TYPE } from 'constants/Common';

const getInitialState = () => ({
  //data: {
  //   count: 0,
  //   clientList: [],
  //   detailShow: true,
  //   listShow: false,
  //   visible: true,
  //   show: true,
  //   customerDetail: null,
  //   filter: { page: 1, limit: 15 },
  //   clientStates: [],
  //   clientCities: [],
  //   startWith: { keyword: null, keys: ['companyName', 'name', 'firstName', 'lastName', 'mobile'] },
  // },
  tradeList: {
    filter: {
      type: [USERS_TYPE.SUPER_ADMIN, USERS_TYPE.ADMIN, USERS_TYPE.SELLER],
    },
    page: 1,
    limit: 15,
    data: [],
    count: 0,
    activeData: {},
    startWith: { keyword: null, keys: ['companyName', 'name', 'firstName', 'lastName', 'mobile'] },
  },
});

const initialState = { ...getInitialState() };

export const TradeActionTypes = {
  FETCH_TRADE_LIST: 'FETCH_TRADE_LIST',
  RESET_TAB: 'RESET_TAB',
};

export const TradeActions = {
  fetchTradeList: (payload) => ({ type: TradeActionTypes.FETCH_TRADE_LIST, payload }),
  resetList: () => ({ type: TradeActionTypes.RESET_TAB }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TradeActionTypes.FETCH_TRADE_LIST:
      return { ...state, tradeList: { ...state.tradeList, ...payload } };

    case TradeActionTypes.RESET_TAB:
      return { ...getInitialState() };

    default:
      return state;
  }
};
