import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';

import { history } from 'util/history';

import AccountAddress from './AccountAddress';
import AccountFilter from './AccountFilter';
import Auth from './Auth';
import ClientDetail from './ClientDetail';
import Common from './Common';
import LoaderReducer from './LoaderReducer';
import Settings from './Settings';
import SyncEmailReducer from './SyncEmailReducer';
import AdminInventryExcel from './AdminInventryExcel';
import TabReducer from './TabIssueReducer/TabReducer';
import TermsDetail from './TermsDetail';
import Trade from './Trade';
import User from './User';
import UserFilter from './UserFilter';
import diamond from './diamond';
import permission from './permission';

const reducers = combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  commonData: Common,
  permission,
  client: ClientDetail,
  tab: TabReducer,
  accountAddress: AccountAddress,
  user: User,
  accountFilter: AccountFilter,
  userFilter: UserFilter,
  diamondData: diamond,
  termsDetail: TermsDetail,
  loader: LoaderReducer,
  trade: Trade,
  syncEmail: SyncEmailReducer,
  AdminInventryExcel,
});

export default reducers;
