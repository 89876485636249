import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { isEmpty } from 'util/utils';

import { DiamondActions } from 'appRedux/reducers/diamond';

export function getExtraPer(terms) {
  let extraPer = terms && terms.extraPer ? terms.extraPer : 0;
  if (extraPer < 0) extraPer = extraPer * -1;
  return extraPer;
}
export const formatNumber = (num) => {
  return !isEmpty(num) ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
};

export function calculate(dataList) {
  const sum_total = {
    total_pieces: 0,
    total_carat: 0,
    total_avg_rap: 0,
    final_discount: 0,
    final_price: 0,
    final_value: 0,
    total_rapaport: 0,
    avg_discount: 0,
    final_net_value: 0,
    final_rate: 0,
    final_term_discount: 0,
    avg_term_discount: 0,
    bid_value: 0,
    final_bid_price: 0,
    final_bid_discount: 0,
  };
  const total = {
    fancy_total: { ...sum_total },
    non_fancy_total: { ...sum_total },
  };
  total.fancy_total.isFancy = 1;
  total.non_fancy_total.isFancy = 0;
  dataList.forEach((lst) => {
    const use_total_key = lst.isFancy ? 'fancy_total' : 'non_fancy_total';
    total[use_total_key].total_pieces += 1;
    total[use_total_key].total_carat += lst.crt && parseFloat(lst.crt) ? parseFloat(lst.crt) : 0;
    lst.rap_avg = lst.rap ? parseFloat(lst.rap) * parseFloat(lst.crt || 0) : 0;
    total[use_total_key].total_avg_rap += lst.rap_avg;
    total[use_total_key].total_rapaport += lst.rap ? parseFloat(lst.rap) : 0;
    total[use_total_key].final_value += lst.amt || 0;
    total[use_total_key].final_net_value += lst.calcAmount || 0;
    total[use_total_key].bid_value += lst.bidAmount || 0;
  });
  total.fancy_total.final_price =
    total.fancy_total.final_value && total.fancy_total.total_carat
      ? total.fancy_total.final_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_price =
    total.non_fancy_total.final_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_value / total.non_fancy_total.total_carat
      : 0;
  total.fancy_total.final_bid_price =
    total.fancy_total.bid_value && total.fancy_total.total_carat
      ? total.fancy_total.bid_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_bid_price =
    total.non_fancy_total.bid_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.bid_value / total.non_fancy_total.total_carat
      : 0;
  total.fancy_total.final_rate =
    total.fancy_total.final_net_value && total.fancy_total.total_carat
      ? total.fancy_total.final_net_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_rate =
    total.non_fancy_total.final_net_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_net_value / total.non_fancy_total.total_carat
      : 0;
  total.fancy_total.total_avg_rap = total.fancy_total.total_avg_rap
    ? total.fancy_total.total_avg_rap / total.fancy_total.total_carat
    : 0;
  total.non_fancy_total.total_avg_rap = total.non_fancy_total.total_avg_rap
    ? total.non_fancy_total.total_avg_rap / total.non_fancy_total.total_carat
    : 0;

  total.fancy_total.final_discount =
    total.fancy_total.final_price && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_price / total.fancy_total.total_avg_rap) * -100
      : 0;
  total.non_fancy_total.final_discount =
    total.non_fancy_total.final_price && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_price / total.non_fancy_total.total_avg_rap) * -100
      : 0;

  total.fancy_total.final_bid_discount =
    total.fancy_total.final_bid_price && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_bid_price / total.fancy_total.total_avg_rap) * -100
      : 0;
  total.non_fancy_total.final_bid_discount =
    total.non_fancy_total.final_bid_price && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_bid_price / total.non_fancy_total.total_avg_rap) * -100
      : 0;
  total.fancy_total.final_term_discount =
    total.fancy_total.final_rate && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_rate / total.fancy_total.total_avg_rap) * -100
      : 0;
  total.non_fancy_total.final_term_discount =
    total.non_fancy_total.final_rate && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_rate / total.non_fancy_total.total_avg_rap) * -100
      : 0;

  const allTotal = {
    total_pieces: total.fancy_total.total_pieces + total.non_fancy_total.total_pieces,
    total_carat: total.fancy_total.total_carat + total.non_fancy_total.total_carat,
    final_rapaport: total.fancy_total.total_avg_rap + total.non_fancy_total.total_avg_rap,
    final_discount: total.fancy_total.final_discount
      ? total.fancy_total.final_discount
      : total.non_fancy_total.final_discount,
    final_bid_discount: total.fancy_total.final_bid_discount
      ? total.fancy_total.final_bid_discount
      : total.non_fancy_total.final_bid_discount,
    final_value: total.fancy_total.final_value + total.non_fancy_total.final_value,
    bid_value: total.fancy_total.bid_value + total.non_fancy_total.bid_value,
    final_price:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_value + total.non_fancy_total.final_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    final_bid_price:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.bid_value + total.non_fancy_total.bid_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    total_rapaport: total.fancy_total.total_rapaport + total.non_fancy_total.total_rapaport,
    final_net_value: total.fancy_total.final_net_value + total.non_fancy_total.final_net_value,
    final_rate:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_net_value + total.non_fancy_total.final_net_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    final_term_discount: total.fancy_total.final_term_discount
      ? total.fancy_total.final_term_discount
      : total.non_fancy_total.final_term_discount,
  };
  allTotal.avg_discount = allTotal.final_rapaport ? allTotal.final_price / allTotal.final_rapaport : 0;
  allTotal.avg_term_discount = allTotal.final_rapaport ? allTotal.final_rate / allTotal.final_rapaport : 0;
  return allTotal;
}
export function newDiamondPrice(diamond, terms = {}, qt = false) {
  const extraPer = terms && terms.extraPer ? terms.extraPer : 0;
  // if (!extraPer) {
  //   ['pricePerCarat', 'calcPricePerCarat', 'calcDiscount', 'calcAmount'].forEach((key) => delete diamond[key]);
  // }
  // const rapPer = 0 //terms && terms.rapPer ? terms.rapPer : 0
  const quotePer = qt ? Number(parseFloat(diamond.finalquote * -1).toFixed(2)) : 0;
  if (extraPer || quotePer) {
    const pricePerCarat = ['bid'].includes(qt)
      ? diamond.bidPricePerCarat
      : quotePer
      ? Number(parseFloat(diamond.rap - (diamond.rap * quotePer) / 100).toFixed(2))
      : diamond.ctPr;
    diamond.calcPricePerCarat = Number(
      parseFloat(pricePerCarat - (pricePerCarat * Math.abs(extraPer)) / 100).toFixed(2),
    );
    diamond.calcDiscount = Number(parseFloat((1 - diamond.calcPricePerCarat / diamond.rap) * 100 * -1).toFixed(2));
    diamond.calcAmount = Number(parseFloat(diamond.calcPricePerCarat * diamond.crt).toFixed(2));
  } else {
    const pricePerCarat = diamond.ctPr;
    diamond.calcPricePerCarat = Number(parseFloat(pricePerCarat).toFixed(2));
    diamond.calcDiscount = Number(parseFloat((1 - diamond.calcPricePerCarat / diamond.rap) * 100 * -1).toFixed(2));
    diamond.calcAmount = Number(parseFloat(diamond.calcPricePerCarat * diamond.crt).toFixed(2));
  }
  return diamond;
}
export const quoteCalculation = (diamondList) => {
  const newDiamond = {
    oldDiscount: 0,
    newDiscount: 0,
    offerValue: 0,
  };

  diamondList.forEach((data) => {
    newDiamond.oldDiscount += data.back * data.crt;
    newDiamond.newDiscount +=
      (data.bargainTrack?.[0] ? data.bargainTrack[0].trackDiscount : data.newDiscount) * data.crt;
    newDiamond.offerValue += (data.bargainTrack?.[0] ? data.bargainTrack[0].trackAmount : data.newAmount) * data.crt;
  });

  const { total_carat } = calculate(diamondList);
  newDiamond.oldDiscount = newDiamond.oldDiscount / total_carat;
  newDiamond.newDiscount = newDiamond.newDiscount / total_carat;
  newDiamond.offer = newDiamond.newDiscount;
  newDiamond.offerValue = newDiamond.offerValue / total_carat;
  newDiamond.discountDifferent = newDiamond.newDiscount - newDiamond.oldDiscount;
  return newDiamond;
};

const SelectStone = ({ sum, currentType, bidModule }) => {
  const dispatch = useDispatch();
  const clearAll = React.useCallback(() => dispatch(DiamondActions.resetSelectedRows()), [dispatch]);
  const checked = useSelector((state) => state?.diamondData?.selectedRows?.[currentType] || []);

  const total = React.useMemo(() => calculate(checked), [checked]);
  const calcList = [
    { label: 'Pieces', check: total.total_pieces || 0, sum: sum?.pieces || sum?.count || 0 },
    {
      label: 'Cts',
      check: parseFloat(total?.total_carat || 0).toFixed(2),
      sum: parseFloat(sum?.totalCarat || 0).toFixed(2),
    },
    {
      label: 'Rap Price',
      check: parseFloat(total?.final_rapaport || 0).toFixed(2),
      sum: parseFloat(sum?.rapAvg || 0).toFixed(2),
    },
    {
      label: 'Avg Disc',
      check: parseFloat(total?.final_discount || 0).toFixed(2),
      sum: parseFloat(sum?.avgDiscount || 0).toFixed(2),
    },
    {
      label: 'Total Ct/Pr',
      check: Math.round(total?.final_price || 0),
      sum: Math.round(sum?.totalPricePerCarat || 0),
    },
    {
      label: 'Amount',
      check: Math.round(total?.final_value || 0),
      sum: Math.round(sum?.totalAmount || 0),
    },
  ];
  const bidCalcList = [
    {
      label: 'Avg Bid Disc',
      check: parseFloat(total?.final_bid_discount || 0).toFixed(2),
      sum: parseFloat(sum?.avgBidDiscount || 0).toFixed(2),
    },
    {
      label: 'Total Bid Ct/Pr',
      check: Math.round(total?.final_bid_price || 0),
      sum: Math.round(sum?.totalBidPricePerCarat || 0),
    },
    {
      label: 'Bid Amount',
      check: Math.round(total?.bid_value || 0),
      sum: Math.round(sum?.totalBidAmount || 0),
    },
  ];

  const value = bidModule ? [...calcList, ...bidCalcList] : [...calcList];

  return (
    !isEmpty(checked) && (
      <div className="selectStoneValueBlock">
        {value.map((item, index) => (
          <div className="selectStoneValueItem" key={index}>
            <span className="selectStoneLabel">{item.label} : </span>
            <span className="selectStopnValue">
              <span className="redColor">{formatNumber(item.check)}</span>/{formatNumber(item.sum)}
            </span>
          </div>
        ))}
        <div className="searchStoneClose" onClick={clearAll}>
          Clear all
        </div>
      </div>
    )
  );
};

export default React.memo(SelectStone);
