import { Action } from 'services/action';

import { hasKey, isArray, isString, logWarn } from 'util/utils';

const encode = (value) => btoa(JSON.stringify(value));
const decode = (value) => JSON.parse(atob(value));

/**
 * Storage class
 * Transforms and stores data in localStorage
 * @note: Keep all methods static (so they can be accessed directly)
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
class Storage {
  static updateKeyEvent = new Action('@storage/key-updated');

  /**
   * Store
   * @param {String} key
   * @param {*} value
   */
  static set(key, value) {
    try {
      localStorage.setItem(key, encode(value));
      Storage.updateKeyEvent.emit({ [key]: value });
      return true;
    } catch (error) {
      logWarn(error);
    }
  }

  /**
   * Retrieve
   * @param {String} key
   */
  static get(key) {
    try {
      return decode(localStorage.getItem(key));
    } catch (error) {
      logWarn(error);
      return null;
    }
  }

  /**
   * Remove values by key
   * @param {String|Array} keys String or Array of string
   */
  static delete(keys) {
    keys = isArray(keys) ? keys : isString(keys) ? [keys] : [];
    keys.forEach((key) => {
      try {
        localStorage.removeItem(key);
        Storage.updateKeyEvent.emit({ [key]: undefined });
      } catch (error) {
        logWarn(error);
      }
    });
  }

  static deleteAll(except = ['dn-up', 'dn-r-up']) {
    const storageKeys = Object.keys(localStorage);
    except.forEach((key) => storageKeys.splice(storageKeys.indexOf(key), 1));
    Storage.delete(storageKeys);
  }

  static listen(key, callback) {
    const unlisten = Storage.updateKeyEvent.listen((event) => {
      if (!hasKey(event?.detail, key)) return;
      callback({ [key]: event?.detail?.[key] });
    });
    return unlisten;
  }
}
export default Storage;

const addListeners = () => {
  window.__Storage = Storage;
};
addListeners();

void module?.hot?.accept?.(['./storage.js'], () => addListeners());
