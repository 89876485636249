export const PREFIX_URL = 'kgk';
export const PREFIX_URL_WITH_SLASH = `/${PREFIX_URL}`;
export const MEDIA_URL = 'https://s3.ap-south-1.amazonaws.com/kgkgroup';

// new url
export const BACKEND_URL = process.env.REACT_APP_API_URL;
export const COMMON_URL = {
  BASE_URL: BACKEND_URL,
  BASE_SYNC_URL: BACKEND_URL,
  // EXCELFILE_URL: 'https://assets.kgk.live/',
  EXCELFILE_URL: process.env.REACT_APP_ASSETS_URL,
};

COMMON_URL.FRONT_SITE_URL = process.env.REACT_APP_FRONT_SITE_URL;
COMMON_URL.BASE_URL_FILE_UPLOAD = BACKEND_URL;
