import React from 'react';

import { Radio, Row, Col, Checkbox } from 'antd';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// import FileUpload from 'containers/FileUpload';
import FileUploadFormik from 'containers/FileUpload/FileUploadFormik';

import ListFooter from 'components/List/ListFooter';

// import { showNotification } from 'services/NotificationService';

// import { isEmpty } from 'util/utils';

import { DiamondUploadTypes } from 'constants/Common';

import '../../styles/settings.less';
// import { uploadDiamondsExcel } from '../../services/DiamondApi';

const defaultValues = {
  type: '1',
  file: '',
  isEmailSend: false,
};

const validationSchema = Yup.object().shape({
  type: Yup.number()
    // .oneOf([DiamondUploadTypes.add_update, DiamondUploadTypes.replace_all], 'Invalid Type')
    .required('Type is required'),
  file: Yup.string().trim().required('File is required'),
  isEmailSend: Yup.boolean().nullable(),
});

const UploadDiamond = () => {
  const submitForm = React.useCallback(async (values, { resetForm }) => {
    // setSubmitting(true);
    // const [err, res] = await uploadDiamondsExcel(values);
    // setSubmitting(false);

    // if (!err) {
    //   showNotification(res);
    // }
    resetForm();
  }, []);

  return (
    <Formik initialValues={defaultValues} validateOnChange validationSchema={validationSchema} onSubmit={submitForm}>
      {({ handleBlur, setValues, touched, errors, values }) => (
        <Form>
          <div className="uploadDiamonds">
            <div className="headeTitle">
              <h2>Upload Diamonds</h2>
            </div>
            <Row type="flex" gutter={16}>
              <Col md={12} sm={12} span={6}>
                <FileUploadFormik
                  className="d-flex align-items-center justify-content-center"
                  diamondUpload
                  fullWidth="width-auto"
                  name="file"
                  isValidate
                  accept="XLS,XLSX,CSV"
                  size="XLS,XLSX,CSV file allowed"
                  fileType="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  value={values?.file}
                  onChange={(file) => setValues((values) => ({ ...values, file: file || '' }))}
                  onBlur={handleBlur}
                  error={touched?.file && errors?.file}
                />
              </Col>
              <Col md={12} sm={12} span={6}>
                <div className="rdoOption">
                  <Radio.Group
                    onChange={(event) => setValues((values) => ({ ...values, type: event.target.value }))}
                    defaultValue={DiamondUploadTypes.add_update}
                    value={values?.type}
                  >
                    <div className="rdoBtn">
                      <Radio value={DiamondUploadTypes.add_update}></Radio>
                      <div className="content">
                        <h3 className="subTitle">Add &amp; Update</h3>
                        <p className="desc">
                          New lots will be added and existing lots will be updated. Lots not included will remain
                          untouched.
                        </p>
                      </div>
                    </div>
                    <div className="rdoBtn">
                      <Radio value={DiamondUploadTypes.replace_all}></Radio>
                      <div className="content">
                        <h3 className="subTitle">Replace All</h3>
                        <p className="desc">Lots not included in the upload will be deleted.</p>
                      </div>
                    </div>
                  </Radio.Group>
                  {touched?.type && errors?.type && <div className="error mt-5">{errors?.type}</div>}
                </div>
              </Col>
            </Row>
            <div className="mt-20">
              <Checkbox onChange={(event) => setValues((values) => ({ ...values, isEmailSend: event.target.checked }))}>
                Send an upload report to my email
              </Checkbox>
            </div>
            <ListFooter className="homeSettingfooter">
              <span style={{ margin: 'auto' }}>
                <button className="commonButton mr-10" type="submit">
                  Save
                </button>
                <button className="commonOutine" type="reset">
                  Reset
                </button>
              </span>
            </ListFooter>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UploadDiamond;
