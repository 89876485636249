import React from 'react';

import Modal from 'containers/CommonModal';

import { isFunction, returnIfNotEmpty } from 'util/utils';

import eyeGray from 'assets/svg/InventoryResult/view.svg';

function OfferDetailsCell({ row }) {
  const [detailsVisible, setDetailsVisibility] = React.useState(false);
  const showDetails = React.useCallback(
    (e) => (isFunction(e.preventDefault) && e.preventDefault(), setDetailsVisibility(true)),
    [setDetailsVisibility],
  );
  const hideDetails = React.useCallback(() => setDetailsVisibility(false), [setDetailsVisibility]);

  const details = React.useMemo(() => {
    return {
      preferShippingCompany: row?.preferShippingCompany,
      courierAccountNo: row?.courierAccountNo,
    };
  }, [row]);

  return (
    <div className="modal-details">
      <a href="#" onClick={showDetails}>
        <img src={eyeGray} alt="Offer Details" style={{ display: 'inline', height: '15px', width: '15px' }} />
      </a>
      <Modal
        className="md-size"
        visible={detailsVisible}
        onCancel={hideDetails}
        title="Offer Details"
        footer={null}
        destroyOnClose
        noDefaults
        centered
      >
        <div className="detailShow d-flex flex-wrap">
          <div className="detail separator">
            <span>Prefer Shipping Company: </span>
            <span>{returnIfNotEmpty(details.preferShippingCompany?.webDisplay, '-')}</span>
          </div>
          <div className="detail separator">
            <span>Courier Account No: </span>
            <span>{returnIfNotEmpty(details.courierAccountNo, '-')}</span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

OfferDetailsCell.propTypes = {};

export default React.memo(OfferDetailsCell);
