import React, { Component } from 'react';

import { Form, Formik } from 'formik';
import { find } from 'lodash';
// import moment from 'moment';
import * as Yup from 'yup';

// import DatePickerInput from 'containers/DatePickerInput';
import InputBlock from 'containers/InputBlock';
import ListingTableWrapper from 'containers/ListingTableWrapper';
import ListingWrapper from 'containers/ListingWrapper';
import MainHeading from 'containers/MainHeading';
import SelectOption from 'containers/SelectOption';

import CircularProgress from 'components/CircularProgress';
import Table from 'components/Inventory/TableBack';
import { fetchcolumnAsync } from 'components/Inventory/inventoryFunctions';

import { InsertDiamondBlock } from 'services/DiamondBlockApi';
import Storage from 'services/storage';

import { Notify } from 'util/notify';
import { isArray, isEmpty, isNumber, isNumeric, isObject } from 'util/utils';

import { DIAMOND_BLOCK, HOLD_MEMO_BLOCK_SETTING } from 'constants/Common';
import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

import { MemoAndOfferCalc } from './CommonPopupFunction';
import PartyBuyerComponent from './PopupComponent/PartyBuyerComponent';
import SellerComponent from './PopupComponent/SellerComponent';
import { calculate } from './SelectStone';
// import { calculate, formatNumber } from './SelectStone';

import TextArea from '../../containers/TextArea';

const OfferConsts = {
  MAX_DISCOUNT_VALUE: 0,
  MIN_DISCOUNT_VALUE: -70,
  RELATIVE_DISCOUNT_DIFFERENCE: -5,
};

const CellInput = function CellInput({ ...props }) {
  return (
    <span className="tableInput offerPrice">
      <input {...props} />
    </span>
  );
};
class MemoPopup extends Component {
  state = {
    data: [],
    columns: [],
    loading: 0,
    values: {},
    loader: false,
    updatedData: [],
    logisticList: [],
  };

  startLoading = () => this.setState(({ loading }) => ({ loading: ++loading }));
  stopLoading = () => this.setState(({ loading }) => ({ loading: --loading }));

  getMasterData = async () => {
    this.startLoading();
    const masters = Storage.get('dn-master');
    this.setState({
      logisticList: masters?.LOGISTIC?.map((item) => ({ ...item, value: item.name, key: item.id[0] })),
    });

    this.stopLoading();
  };

  calculateDiscount = (row, values, updatedField) => {
    const { vStnId, rap, crt } = row;
    let pricePerCarat = values?.pricePerCarat;
    let memoDis = values?.memoDis;
    let memoAmt = values?.memoAmt;

    if (updatedField === 'memoDis') {
      memoDis = parseFloat(Number(memoDis)).toFixed(2);
      pricePerCarat = parseFloat(Number(rap) * (1 + Number(memoDis) / 100)).toFixed(0);
      memoAmt = parseFloat(Number(pricePerCarat) * Number(crt)).toFixed(2);
    }

    if (updatedField === 'pricePerCarat') {
      pricePerCarat = parseFloat(Math.abs(Number(values?.pricePerCarat))).toFixed(2);
      memoDis = parseFloat(100 * (Number(pricePerCarat) / Number(rap) - 1)).toFixed(2);
      memoAmt = parseFloat(Number(pricePerCarat) * Number(crt)).toFixed(2);
    }

    this.setState(
      {
        values: {
          ...this.state.values,
          [vStnId]: {
            memoDis: isNumber(Number(memoDis)) ? Number(memoDis) : undefined,
            pricePerCarat: isNumber(Number(pricePerCarat)) ? Number(pricePerCarat) : undefined,
            memoAmt: isNumber(Number(memoAmt)) ? Math.round(Number(memoAmt)) : undefined,
          },
        },
      },
      () => this.setUpdatedData(vStnId),
    );
  };

  setUpdatedData = (vStnId) => {
    const index = this.state.updatedData.findIndex((stone) => stone.vStnId === vStnId);
    const updatedData = [...this.state.updatedData];
    updatedData[index] = {
      ...this.state.updatedData?.[index],
      back: this.state.values?.[vStnId]?.memoDis ?? this.state.updatedData?.[index]?.back,
      ctPr: this.state.values?.[vStnId]?.pricePerCarat ?? this.state.updatedData?.[index]?.ctPr,
      amt: this.state.values?.[vStnId]?.memoAmt ?? this.state.updatedData?.[index]?.amt,
    };
    this.setState({ updatedData: [...updatedData] });
  };
  setCellValue = ({ row, name, value }) => {
    const { vStnId } = row;
    const state = this.state;
    if (!isObject(this.state.values[vStnId])) state.values[vStnId] = {};
    state.values[vStnId][name] = value;
    this.calculateDiscount(row, state.values[vStnId], name);
  };

  getColumns = async () => {
    this.startLoading();
    const _columns = await fetchcolumnAsync();
    const columns = [
      ..._columns.slice(0, 14),
      {
        Header: 'Memo Dis.',
        accessor: 'memoDis',
        id: 'memoDis',
        Cell: ({ row }) => {
          const name = 'memoDis';
          const originalValue = Number(parseFloat(row?.original?.back).toFixed(2));
          const updatedValue = this.state.values[row?.original?.vStnId]?.[name];
          const minAllowedValue = originalValue + OfferConsts.RELATIVE_DISCOUNT_DIFFERENCE;

          const [value, setValue] = React.useState(
            isNumber(Number(updatedValue))
              ? parseFloat(updatedValue).toFixed(2)
              : isNumber(Number(originalValue))
              ? parseFloat(originalValue).toFixed(2)
              : '0.00',
          );

          React.useEffect(() => {
            if (isNumber(updatedValue)) setValue(parseFloat(updatedValue).toFixed(2));
          }, [updatedValue]);

          const handleChange = React.useCallback((e) => {
            const number = e?.target?.value;
            if (isNumeric(number)) setValue(number);
          }, []);

          const handleBlur = React.useCallback(() => {
            let number = Number(value);
            number = isNumber(number) ? number : originalValue; // if not number - reset to original
            if (number < minAllowedValue) {
              Notify.error({ message: `Cannot allow discount less than ${minAllowedValue}%` });
              number = originalValue;
            }
            this.setCellValue({ row: row?.original, name, value: number });
            setValue(number);
          }, [minAllowedValue, originalValue, row?.original, value]);

          const handleKeyDown = React.useCallback(
            (e) => {
              if (e.keyCode !== 13) return;
              e.preventDefault();
              handleBlur();
            },
            [handleBlur],
          );

          return (
            <CellInput
              type="text"
              name={name}
              value={value}
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          );
        },
      },
      {
        Header: 'Memo Pr/Ct.',
        accessor: 'pricePerCarat',
        id: 'pricePerCarat',
        Cell: ({ row }) => {
          const name = 'pricePerCarat';
          const originalValue = Number(row.original?.ctPr);
          const updatedValue = Number(this.state.values[row?.original?.vStnId]?.[name]);
          const originalDis = Number(row.original?.back || this.state.values[row?.original?.vStnId]?.memoDis);
          const minAllowedDis = originalDis + OfferConsts.MIN_DISCOUNT_DIFFERENCE;

          const [value, setValue] = React.useState(
            isNumber(Number(updatedValue))
              ? parseFloat(updatedValue).toFixed(2)
              : isNumber(Number(originalValue))
              ? parseFloat(originalValue).toFixed(2)
              : '0.00',
          );

          React.useEffect(() => {
            if (isNumber(updatedValue)) setValue(parseFloat(updatedValue).toFixed(2));
          }, [updatedValue]);

          const handleChange = (e) => {
            const number = e?.target?.value;
            if (isNumeric(number)) setValue(number);
          };

          const handleBlur = () => {
            let number = Number(value);
            number = isNumber(number) ? (number < 0 ? number + number * 2 : number) : originalValue; // if negative number - convert to positive
            const memoDis = Number(
              parseFloat(100 * (parseFloat(number) / parseFloat(row?.original?.rap) - 1)).toFixed(2),
            );

            if (memoDis < minAllowedDis) {
              Notify.error({ message: `Cannot allow discount less than ${Math.abs(minAllowedDis)}%` });
              number = originalValue;
            }

            this.setCellValue({ row: row?.original, name, value: number });
            setValue(number);
          };

          const handleKeyDown = (e) => {
            if (e.keyCode !== 13) return;
            e.preventDefault();
            handleBlur();
          };

          return (
            <CellInput
              type="text"
              name={name}
              value={value}
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          );
        },
      },
      {
        Header: 'Memo Amt.',
        accessor: 'memoAmt',
        id: 'memoAmt',
        Cell: ({ row }) => {
          const name = 'memoAmt';
          const value = this.state.values[row?.original?.vStnId]?.[name] ?? row?.original?.amt;
          return <CellInput type="text" value={value} name={name} disabled />;
        },
      },
      ..._columns.slice(14),
    ].filter(({ id }) => !find(columns, { id }));

    this.setState({ columns });
    this.stopLoading();
  };

  onApplyMemo = async (values) => {
    this.setState({ loader: true });
    Object.keys(values).forEach((key) => {
      if (isEmpty(values[key]) || key === 'client') {
        delete values[key];
      }
    });
    const request = {
      ...values,
      blockType: DIAMOND_BLOCK.TYPE.MEMO,
      blockSetting: HOLD_MEMO_BLOCK_SETTING.MEMO,
      diamonds: this.state.data.map(({ id, vStnId, vnd, hours, ...stone }) => ({
        vnd,
        vStnId,
        diamond: id,
        newDiscount: this.state.values?.[vStnId]?.memoDis || stone.back,
        newPricePerCarat: this.state.values?.[vStnId]?.pricePerCarat || stone.ctPr,
        newAmount: this.state.values?.[vStnId]?.memoAmt || stone.amt,
      })),
    };

    const [, res] = await InsertDiamondBlock(request);
    if (res?.code === 'OK' && res?.data) {
      this.setState({ loader: false });
      OpenNotification({ type: 'success', title: res.message });
      //  errorMessage(res, 'memo');

      this.props.changesStatus();
    }
    this.props.handleCancel();

    this.setState({ loader: false });
  };

  tableProps = {
    sort: [],
    canSort: [],
    filterArray: {},
    selectedFilterArray: {},
    FilterOption: false,
  };

  initialValues = {
    userAccount: this.props.client?.id,
    user: this.props.client?.user?.id,
    validityDays: undefined,
    terms: undefined,
    // client: this.props.client ? [this.props.client] : [],
    seller: this.props.client?.seller?.id,
    shippingCharge: null,
    trackNo: null,
    logistic: undefined,
    comment: '',
    ...(this.props.client && { client: [this.props.client] }),
  };

  validationSchema = Yup.object().shape({
    userAccount: Yup.string().required(Messages.userAccountSelect).nullable(),
    user: Yup.string().required(Messages.userSelect).nullable(),
    remarks: Yup.string(),
    validityDays: Yup.number().required(Messages.validityDaysRequire).nullable(),
    shippingCharge: Yup.string().required(Messages.shippingChargeRequire).nullable(),
    trackNo: Yup.string().required(Messages.trackNoRequire).nullable(),
    logistic: Yup.string().required(Messages.logisticRequire).nullable(),
  });

  async componentDidMount() {
    await this.getColumns();
    this.getMasterData();
    if (!isArray(this.props.checked)) return;
    this.setState({ data: [...this.props.checked], updatedData: [...this.props.checked] });
  }

  render() {
    const sum = calculate(this.state.updatedData);
    return (
      <Formik
        enableReinitialize
        initialValues={this.initialValues}
        validateOnBlur
        validationSchema={this.validationSchema}
        onSubmit={this.onApplyMemo}
      >
        {({ handleBlur, setValues, touched, errors, values }) => (
          <Form>
            <div className="d-flex align-items-center oneLineTotal">
              <MainHeading className="mb-0 mr-40" title={`Apply Memo (${this.state?.data?.length})`} />
              <MemoAndOfferCalc data={sum} />
            </div>

            <div className="popupHalfShow">
              <ListingWrapper>
                <ListingTableWrapper classList={['.mainHeadingTitle', '.sidebarAction']} initial={30}>
                  <Table
                    columns={this.state.columns}
                    data={this.state.updatedData}
                    {...this.tableProps}
                    noCheckBox
                    noheader
                    noDots
                  />
                </ListingTableWrapper>
              </ListingWrapper>
              <div className="singleStonePlaceOrderDetail mt-20 smallGrayScroll grayScroll d-block applyMemo">
                <div className="d-flex flex-wrap width-100 align-items-start">
                  <PartyBuyerComponent
                    error={(touched?.useAccount || touched?.user) && errors}
                    setUserDetails={(data) => {
                      setValues((oldValues) => ({
                        ...oldValues,
                        userAccount: data?.userAccount,
                        user: data?.user,
                      }));
                    }}
                    initialData={{ ...values }}
                  />
                  <SellerComponent
                    label="Sales Person"
                    placeholder="Select Sales Person"
                    setSellerDetails={(data) => {
                      setValues((oldValues) => ({
                        ...oldValues,
                        seller: data?.seller,
                      }));
                    }}
                    initialData={values}
                  />
                  <InputBlock
                    name="validityDays"
                    label="Memo (Days) *"
                    onKeyPress={(evt) => {
                      if (evt.which < 48 || evt.which > 57) {
                        evt.preventDefault();
                      }
                    }}
                    placeholder="Validity"
                    type="text"
                    value={values?.validityDays}
                    onChange={(e) => setValues((values) => ({ ...values, validityDays: e.target.value }))}
                    onBlur={handleBlur}
                    error={touched?.validityDays && errors?.validityDays}
                  />
                  <InputBlock
                    name="shippingCharge"
                    label="Shipping Charge *"
                    onKeyPress={(evt) => {
                      if (evt.which < 48 || evt.which > 57) {
                        evt.preventDefault();
                      }
                    }}
                    placeholder="Shipping Charge"
                    type="number"
                    value={values?.shippingCharge}
                    onChange={(e) => setValues((values) => ({ ...values, shippingCharge: e.target.value }))}
                    onBlur={handleBlur}
                    error={touched?.shippingCharge && errors?.shippingCharge}
                  />
                  <InputBlock
                    name="trackingNo"
                    label="Tracking No *"
                    // onKeyPress={(evt) => {
                    //   if (evt.which < 48 || evt.which > 57) {
                    //     evt.preventDefault();
                    //   }
                    // }}
                    placeholder="Tracking No"
                    type="text"
                    value={values?.trackNo}
                    onChange={(e) => setValues((values) => ({ ...values, trackNo: e.target.value }))}
                    onBlur={handleBlur}
                    error={touched?.trackNo && errors?.trackNo}
                  />
                  <SelectOption
                    placeholder="Select Logistic"
                    value={values?.logistic}
                    selectValue={this.state.logisticList}
                    label="Logistic *"
                    onChange={(logistic = null) => {
                      setValues((values) => ({ ...values, logistic }));
                    }}
                    onSearch={() => {
                      setValues((values) => ({ ...values, logistic: null }));
                    }}
                    error={touched?.logistic && errors?.logistic}
                  />
                  <TextArea
                    label="Comments"
                    placeholder="Enter Comments"
                    onChange={(e) => {
                      setValues((values) => ({ ...values, comment: e.target.value }));
                    }}
                    value={values?.comment}
                  />
                </div>
              </div>
            </div>
            <div className="sidebarAction">
              <button className="commonButton" type="submit">
                Submit
              </button>
              <button
                className="commonOutine"
                type="reset"
                onClick={() => {
                  this.props.handleCancel();
                  //                  this.props?.unCheckedDiamond();
                }}
              >
                Cancel
              </button>
              {this.state.loader && <CircularProgress className="fixLoader" />}
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default MemoPopup;
