import React from 'react';

import successSvg from 'assets/svg/permission-success.svg';
import Action from './action';

const PermissionListItem = (props) => {
  return (
    <div className={`d-flex align-items-center permissionList ${props.className}`}>
      <div className="d-flex align-items-center permissionLeft">
        <span>{props.name}</span>
        {props.check && <img src={successSvg} alt="" />}
      </div>
      <Action
        moduleName={props.moduleName}
        checked={props.checked}
        module={props.module}
        handleChange={props.handleChange}
      />
    </div>
  );
};
export default PermissionListItem;
