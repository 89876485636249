import { get, groupBy } from 'lodash';

import { TOTAL_COLUMNS } from 'components/Inventory/table-config';
import { getGroupTitle } from 'components/Inventory/table-utils';

import { isEmpty, isFunction } from 'util/utils';

export const GROUP_KEY = '_groupKey_';

/**
 * Add group data for table
 * @param {Array}  array    Records
 * @param {Array}  columns  Array of columns to group ['userDate', 'userId']
 * @param {String} keyToSet group separator key
 */
export function groupArrayByColumns(array = [], columns = ['createdAt'], keyToSet = 'userGroupHold') {
  if (isEmpty(columns)) return array;

  const groups = groupBy(
    array?.map((record) => ({
      ...record,
      [GROUP_KEY]: isFunction(columns) ? columns(record) : columns.map((column) => get(record, column)).join('_'),
    })),
    GROUP_KEY,
  );

  const records = Object.values(groups).map((records) => {
    const length = records.length;
    const lastIndex = length - 1;

    records[0].isHeader = true;
    records[lastIndex].isFooter = true;

    records[0][keyToSet] = true;
    records[lastIndex][keyToSet] = true;
    records[0].totalDiamonds = length;
    records[lastIndex].totalDiamonds = length;
    records[0].groupData = {};
    records[lastIndex].groupData = {};
    records[0]._groupTitle_ = getGroupTitle(records, columns) ?? records[0]?.[GROUP_KEY];

    TOTAL_COLUMNS.forEach((columnId) => {
      records[0].groupData[columnId] = [];
      records[lastIndex].groupData[columnId] = [];

      records.forEach((record) => {
        if (!record[columnId]) return;
        records[0].groupData[columnId].push(record[columnId]);
        if (lastIndex !== 0) records[lastIndex].groupData[columnId].push(record[columnId]);
      });
    });

    return records;
  });

  return records.flat();
}

export function groupingArrayBoth(list, keyToSet) {
  return groupArrayByColumns(list, ['userDate', 'userId'], keyToSet);
}

export const groupingArray = (list, keyToSet, subArray, keyForGroup) => {
  return groupArrayByColumns(list, [keyForGroup], keyToSet);
};
