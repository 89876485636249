import React from 'react';

import sum from 'lodash/sum';
import moment from 'moment';

const OfferWise = (props) => {
  const totalLapAmt = props.sold && sum(props.sold.map((s) => s.buyAmt || 0));
  const totalAmt = props.sold && sum(props.sold.map((s) => s.amt));
  const totalCrt = props.sold && sum(props.sold.map((s) => s.crt));
  const ctPr = totalCrt ? totalAmt / totalCrt : 0;
  const lapctPr = totalCrt ? totalLapAmt / totalCrt : 0;
  const trap = props.sold && totalCrt && sum(props.sold.map((s) => s.rap * s.crt)) / totalCrt;
  const dis = trap ? parseFloat((parseFloat(ctPr) / trap - 1) * 100).toFixed(2) : 0;
  const lapdis = trap && totalLapAmt ? parseFloat((parseFloat(lapctPr) / trap - 1) * 100).toFixed(2) : 0;

  return props.interest ? (
    <td colSpan="1" className="tableInTableBoxWrapper yellow">
      <tbody>
        <tr>
          <th>Interested Parties</th>
        </tr>
        <tr>
          <th>Customer Name</th>
        </tr>
        <tr>
          <td>Coruscate Solutions</td>
        </tr>
      </tbody>
    </td>
  ) : props.showHistory && !props.sold.length ? null : props.showHistory ? (
    <td colSpan={props.offermainpage ? '9' : '13'} className="tableInTableBoxWrapper">
      <tbody>
        <tr>
          <th colSpan={props.offermainpage ? '8' : '10'}>Historical Data</th>
        </tr>
        <tr>
          {props.offermainpage ? null : <th>SRNO</th>}
          {props.offermainpage ? null : <th>Date</th>}
          <th>Customer Name</th>
          <th>
            {props.isFancy ? 'Cash' : 'RAP'}
            Price
          </th>
          <th>Lap%</th>
          <th>LAP Price/CT</th>
          <th>LAP Amount</th>
          <th>Sold%</th>
          <th>Sold Price/CT</th>
          <th>Amount</th>
        </tr>
        {props.sold.map((o, i) => {
          const name = o.user && typeof o.user === 'object' ? `${o.user.firstName} ${o.user.lastName}` : '-';
          return (
            <tr key={i}>
              {props.offermainpage ? null : <td>{i + 1}</td>}
              {props.offermainpage ? null : <td>{moment(o.date).format('DD/MM/YYYY')}</td>}
              <td>{name}</td>
              <td>{parseFloat(o.rap).toFixed(2)}</td>
              <td>{!o.buyBack ? '-' : parseFloat(o.buyBack).toFixed(2)}</td>
              <td>{!o.buyCtPr ? '-' : parseFloat(o.buyCtPr).toFixed(2)}</td>
              <td>{!o.buyAmt ? '-' : parseFloat(o.buyAmt).toFixed(2)}</td>
              <td>{parseFloat(o.back).toFixed(2)}</td>
              <td>{parseFloat(o.ctPr).toFixed(2)}</td>
              <td>{parseFloat(o.amt).toFixed(2)}</td>
            </tr>
          );
        })}
        <tr className="bold-tr">
          {props.offermainpage ? null : <td />}
          {props.offermainpage ? null : <td />}
          <td />
          <td />
          <td>{parseFloat(lapdis).toFixed(2)}</td>
          <td>{parseFloat(lapctPr).toFixed(2)}</td>
          <td>{parseFloat(totalLapAmt).toFixed(2)}</td>
          <td>{parseFloat(dis).toFixed(2)}</td>
          <td>{parseFloat(ctPr).toFixed(2)}</td>
          <td>{parseFloat(totalAmt).toFixed(2)}</td>
        </tr>
      </tbody>
    </td>
  ) : !props.offers.length ? null : (
    <td colSpan={props.offermainpage ? '2' : '13'} className="tableInTableBoxWrapper green">
      <tbody>
        <tr>
          <th colSpan={props.offermainpage ? '2' : '6'}>Active Offer</th>
        </tr>
        <tr>
          {props.offermainpage ? (
            <>
              <th>Customer Name</th>
              <th>Offer% Dis%</th>
            </>
          ) : (
            <>
              <th>SRNO</th>
              <th>Customer Name</th>
              <th>
                {props.isFancy ? 'Cash' : 'RAP'}
                Price
              </th>
              <th>Ask%</th>
              <th>Ask Price/CT</th>
              <th>Ask Amount</th>
            </>
          )}
        </tr>
        {props.offers.map((o, i) => {
          const name = o.user && typeof o.user === 'object' ? `${o.user.firstName} ${o.user.lastName}` : '-';
          return (
            <tr key={i}>
              {props.offermainpage ? (
                <>
                  <td>{name}</td>
                  <td>
                    {parseFloat(o.newDiscount).toFixed(2)} {parseFloat(o.trackDiscount).toFixed(2)}
                  </td>
                </>
              ) : (
                <>
                  <td>{i + 1}</td>
                  <td>{name}</td>
                  <td>{parseFloat(props.rap).toFixed(2)}</td>
                  <td>{parseFloat(o.newDiscount).toFixed(2)}</td>
                  <td>{parseFloat(o.newPricePerCarat).toFixed(2)}</td>
                  <td>{parseFloat(o.newAmount).toFixed(2)}</td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </td>
  );
};
export default OfferWise;
