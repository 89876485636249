import React from 'react';

import loadableFunction from '@loadable/component';

import CircularProgress from 'components/CircularProgress';

const loadableOptions = { fallback: <CircularProgress /> };
const loadable = (func) => loadableFunction(func, loadableOptions);

export default loadable;
