import React from 'react';

function NoDataFoundComponent(props) {
  return (
    <div className={props.className}>
      <img style={{ width: '40px', height: '40px', margin: '20px' }} src={props.src} alt={props.src} />
      <span>{props.title}</span>
    </div>
  );
}

export default NoDataFoundComponent;
