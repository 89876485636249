import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Checkbox } from 'antd';
import { Form, Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import DateRangePicker from 'containers/DateRangePicker';
import FileUpload from 'containers/FileUpload';
import InputBlock from 'containers/InputBlock';
import Heading from 'containers/MainHeading';
import TextArea from 'containers/TextArea';

import BottomStickyButton from 'components/ShortCuts/BottomStickButton';
import UserSelect from 'components/User/UserSelect';

import * as TradeAPI from 'services/TradeApi';

import { Notify } from 'util/notify';
import { isEmpty, isArray, isNotEmpty, objectToQueryString } from 'util/utils';

import { TradeActions } from 'appRedux/reducers/Trade';
import './addtrd.less';

class AddTrade extends Component {
  get validationSchema() {
    return Yup.object().shape({
      name: Yup.string().trim().required('First name is required').typeError('First name must be a string'),
      address: Yup.string().required('Last name is required').typeError('address must be a string'),
      startAt: Yup.date().required('please enter  date & time').nullable(),
      attendees: Yup.array()
        .of(Yup.string().trim())
        .min(1, 'At least one attendee is required')
        .required('At least one attendee is required')
        .typeError('Select attendees'),
    });
  }

  get initialValues() {
    const update = this.props.update;
    const values = {
      name: undefined,
      address: undefined,
      startAt: undefined,
      endAt: undefined,
      startTime: undefined,
      endTime: undefined,
      isActive: true,
      attendees: [],
      attachments: [],
    };
    const newValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, isEmpty(update?.[key]) ? value : update?.[key]]),
    );

    newValues.attendees = newValues?.attendees?.map?.((a) => a?.id)?.filter?.(isNotEmpty);

    return newValues;
  }

  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { update } = this.props;

    const data = {
      ...values,
      timezone: '603e337452eb9812814f8d37',
      countryId: '603e33d052eb9812814f8d3a',
      stateId: '603e33ab52eb9812814f8d39',
      cityId: '603e339c52eb9812814f8d38',
    };

    setSubmitting(true);
    const [err, res] = await (!isEmpty(update?.id)
      ? TradeAPI.updateDetails(update.id, data)
      : TradeAPI.createTrade(data));

    setSubmitting(false);

    if (!err && res && res.code === 'OK') {
      const { tradeData } = this.props;

      const request = { page: tradeData.page, limit: tradeData.limit };
      const [, res] = await TradeAPI.getTradeListPaginate(objectToQueryString(request));

      Notify.success({ message: res.message });
      resetForm();

      const tradeDataSet =
        res?.code === 'OK' && isArray(res?.data?.list) && !isEmpty(res?.data?.list)
          ? { data: res.data?.list, count: res.data?.count, loading: false, tradeData: res.data?.list[0] }
          : { data: [], count: 0, loading: false, tradeData: {} };
      this.props.fetchTradeList({ ...tradeDataSet });
      this.props.closeDrawer();
    }
  };

  render() {
    return (
      <Formik
        validateOnChange
        initialValues={this.initialValues}
        validationSchema={this.validationSchema}
        onSubmit={this.handleSubmit}
      >
        {({ handleChange, handleBlur, setFieldValue, setValues, touched, errors, values }) => (
          <Form>
            <div className="p-15">
              <Heading
                title={this.props?.update?.id ? 'Edit Trade' : 'Create Trade'}
                className="mb-20 smallTitle p-0"
              />
              <div style={{ maxHeight: 'calc(100vh - 127px)', overflow: 'auto' }}>
                <div className="inpBlock">
                  <InputBlock
                    label="Name"
                    id="name"
                    name="name"
                    tabIndex="1"
                    placeholder="First Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched?.name && errors?.name}
                  />
                  <TextArea
                    label="Address *"
                    id="address"
                    name="address"
                    tabIndex="2"
                    placeholder="Address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched?.address && errors?.address}
                  />
                </div>
                <div className="datepicker">
                  <DateRangePicker
                    title="Start Date & Time - End Date & Time"
                    required
                    handleSelect
                    name="from"
                    value={{ from: values?.startAt, to: values?.endAt }}
                    dateFormat="DD-MM-YYYY HH:mm:ss"
                    onChange={(date) => {
                      setFieldValue('startTime', moment(date[0]).format('hh:mm:ss a'));
                      setFieldValue('endTime', moment(date[1]).format('hh:mm:ss a'));
                      setFieldValue('startAt', moment(date[0]).format('YYYY-MM-DD h:mm:ss a'));
                      setFieldValue('endAt', moment(date[1]).format('YYYY-MM-DD h:mm:ss a'));
                    }}
                    error={touched?.startAt && errors?.startAt}
                    showTime
                    isOriginal
                    original
                    noPast
                  />
                  <UserSelect
                    label="Select Attendees"
                    name="attendees"
                    mode="multiple"
                    placeholder="Select Attendees"
                    value={values.attendees}
                    onChange={(value) => setFieldValue('attendees', value)}
                    error={errors?.attendees?.length > 0 && errors?.attendees}
                    required
                  />
                </div>
                <div className="endBlock">
                  <div className="fileuploadblk">
                    <FileUpload
                      name="attachments"
                      multiple
                      label="Choose File"
                      accept="JPG,JPEG,PNG,GIF,MKV,WMV,MP4"
                      size="JPG,JPEG,PNG,GIF,MKV,WMV,MP4 file allowed"
                      files={values?.attachments}
                      onChange={(attachments) => setValues((values) => ({ ...values, attachments: attachments || '' }))}
                      onBlur={handleBlur}
                      error={touched?.attachments && errors?.attachments}
                    />
                  </div>
                  <div className="checkBoxForm">
                    <label>Active</label>
                    <Checkbox
                      onChange={() => setFieldValue('isActive', !values?.isActive)}
                      checked={values?.isActive}
                      disabled={values?.isSpam === true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <BottomStickyButton>
              <button tabIndex="9" className="commonButton" type="submit">
                {this.props?.update?.id ? 'Save' : ' Create Trade Show'}
              </button>
              <button className="commonOutine" type="reset" onClick={() => this.props.closeDrawer()}>
                Cancel
              </button>
            </BottomStickyButton>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(
  ({ trade }) => ({
    tradeData: trade.tradeList,
  }),
  {
    fetchTradeList: TradeActions.fetchTradeList,
    resetList: TradeActions.resetList,
  },
)(AddTrade);
