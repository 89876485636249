import React from 'react';

import { Select } from 'antd';
import PropTypes from 'prop-types';

import classNames from 'util/classNames';
import { isArray, isEmpty } from 'util/utils';

import './leftOption.less';

function LeftMultipleSelect({ label, image, onImageClick, className, selectValue, nameKey, valueKey, ...restProps }) {
  return (
    <div className="leftSelectItem">
      {label && (
        <div className="leftSelectTitle">
          <h2>{label}</h2>
          {image && <img src={image} alt="icon" className="reloadImg" onClick={onImageClick} />}
        </div>
      )}
      <Select className={classNames(['leftMultiSelect', className])} {...restProps}>
        {isArray(selectValue) &&
          selectValue.map((option, index) => (
            <Select.Option key={index} value={isEmpty(option?.[valueKey]) ? `${option?.key}` : `${option?.[valueKey]}`}>
              {isEmpty(option?.[nameKey]) ? `${option?.value}` : `${option?.[nameKey]}`}
            </Select.Option>
          ))}
      </Select>
    </div>
  );
}

LeftMultipleSelect.propTypes = {
  label: PropTypes.string,
  image: PropTypes.any,
  onImageClick: PropTypes.func,
  className: PropTypes.string,
  nameKey: PropTypes.string,
  valueKey: PropTypes.string,
  selectValue: PropTypes.any,
  mode: PropTypes.any,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onDropdownVisibleChange: PropTypes.func,
};

export default React.memo(LeftMultipleSelect);
