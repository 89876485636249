import Cookies from 'js-cookie';
import _find from 'lodash/find';
import moment from 'moment';

import Storage from 'services/storage';

import axios from 'util/Api';
import { history } from 'util/history';
import { Notify } from 'util/notify';
import { isArray, isEmpty, objectToQueryString } from 'util/utils';

import { BASE_SYNC_URL, BASE_URL_FILE_UPLOAD } from 'constants/Common';
import OpenNotification from 'constants/OpenNotifications';

import { resetStore } from 'appRedux/resetStore';

import { PermissionService } from './PermissionService';

export const handleLogout = () => {
  Cookies.remove('token');
  Storage.delete(['user', 'dn-master', 'permission']);
  resetStore();
  PermissionService.clear();
  // history.push(`/signin`);
  window.location.href = `${window.location.origin}/signin`;
};

export const callApi = ({ method, url, request, config, ...params }, cb) => {
  const token = Cookies.get('token');
  config = {
    ...config,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${token}`,
      ...config?.headers,
    },
  };
  if (params?.isSync) config.baseURL = BASE_SYNC_URL;
  if (params?.isFileUploadUrl) config.baseURL = BASE_URL_FILE_UPLOAD;
  if (request?.useCache) request.useCache = true;
  const notify = params?.notify ?? true;

  axios[method](url, request, config)
    .then(({ data }) => {
      if (data?.code === 'E_UNAUTHORIZED') return handleLogout();
      cb(data?.code !== 'OK', data);
    })
    .catch((error) => {
      if (error?.response?.data) {
        if (notify) Notify.error({ message: error.response.data?.message });
        return cb(true, error.response.data);
      }

      if (notify) Notify.error({ message: 'Something bad happend' });
      cb(error, {});
    });
};

export const callApiAsync = (object) =>
  new Promise((resolve) => {
    callApi(object, (...args) => resolve(args));
  });

const UtilService = {
  checkDate: (start, end) => (!isEmpty(start) && !isEmpty(end) ? moment(start).isBefore(moment(end)) : undefined),

  displayDate: (date) => (!isEmpty(date) ? moment(date).format('MMM DD YYYY, hh:mm:ss a') : '-'),

  displyFromNow: (date) => (!isEmpty(date) ? moment(date).fromNow() : '-'),

  getDays: (date) => (!isEmpty(date) ? `${moment().diff(date, 'days')} days ago` : undefined),

  getDefaultsValue: (array, key) => (isArray(array) ? _find(array, { isPrimary: false })?.[key] : undefined),

  getPrimaryValue: (array, key) => (isArray(array) ? _find(array, { isPrimary: true })?.[key] : undefined),

  getSyncValue: () => {
    return false; // Told by CTO
    // const CurrentUserType = (Storage.get('user'));

    // if (CurrentUserType.type === USERS_TYPE.SUPER_ADMIN) return true;

    // const StatusData = (Storage.get('permission'));

    // const SyncStatus = _find(StatusData, object => {
    //   if (object.module === 'sync') return object;
    // });

    // const isSync = SyncStatus && SyncStatus.permissions.view;
    // // localStorage.setItem('isSync', isSync);
    // return isSync;
  },

  callApi: ({ method, url, request, config, ...params }, cb) => {
    const token = Cookies.get('token');
    config = {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
        ...config?.headers,
      },
    };
    if (params?.isSync) config.baseURL = BASE_SYNC_URL;
    if (params?.isFileUploadUrl) config.baseURL = BASE_URL_FILE_UPLOAD;
    if (request?.useCache) request.useCache = true;

    axios[method](url, request, config)
      .then(({ data }) => {
        if (data?.code === 'E_UNAUTHORIZED') {
          handleLogout();
        } else {
          cb(data?.code !== 'OK', data);
        }
      })
      .catch((error) => {
        if (error?.response?.data) {
          OpenNotification({ type: 'error', title: error.response.data?.message });
          cb(true, error.response.data);
        } else {
          OpenNotification({ type: 'error', title: 'Something bad happend' });
          cb(error, {});
        }
      });
  },

  callApiAsync: (object) =>
    new Promise((resolve) => {
      UtilService.callApi(object, (...args) => resolve(args));
    }),

  withUrlParams: (conf, params) => ({ ...conf, url: `${conf.url}/${params.join('/')}`.replace(/[/]+/g, '/') }),

  withQueryString: (conf, params) => ({ ...conf, url: `${conf.url}/${objectToQueryString(params)}` }),
};

export default UtilService;
