import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import classNames from 'util/classNames';

import loaderSvg from 'assets/images/kgk.svg';

function CircularProgress({ className, message, loader, visible, ...props }) {
  return !loader && !visible ? (
    <div className={classNames([`loader`, className])} {...props}>
      {message ? <span>{message}</span> : <img src={loaderSvg} alt="loader" />}
    </div>
  ) : null;
}

CircularProgress.propTypes = {
  message: PropTypes.node,
};

export default connect(
  ({ loader }) => ({
    loader: Boolean(loader?.loading),
    visible: Boolean(loader?.visible),
  }),
  {},
)(React.memo(CircularProgress));
