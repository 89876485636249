import React from 'react';

import shortinglistup from '../../assets/svg/InventoryResult/shortinglistup.svg';
import shortinglistdown from '../../assets/svg/InventoryResult/shortinglistdown.svg';

const SortingRound = (props) => {
  return (
    <div className="shortingRound" onClick={props.onClick}>
      <div className="shortingRoundInner">
        <img src={shortinglistup} alt="icon" />
        <img src={shortinglistdown} alt="icon" />
      </div>
    </div>
  );
};
export default SortingRound;
