import * as ActionTypes from 'constants/ActionTypes';

export const fetchStart = () => ({ type: ActionTypes.FETCH_START });

export const fetchSuccess = () => ({ type: ActionTypes.FETCH_SUCCESS });

export const fetchError = (error) => ({ type: ActionTypes.FETCH_ERROR, payload: error });

export const showMessage = (message) => ({ type: ActionTypes.SHOW_MESSAGE, payload: message });

export const hideMessage = () => ({ type: ActionTypes.HIDE_MESSAGE });

export const currentPageData = (data) => ({ type: ActionTypes.CUURENT_PAGE_DATA, data });

export const resetDiamondSearch = (data) => ({ type: ActionTypes.RESET_SEARCH, data });

export const modifyDiamondSearch = (data) => ({ type: ActionTypes.MODIFY_SEARCH, data });
