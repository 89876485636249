import React, { useEffect, useState } from 'react';

import { DatePicker } from 'antd';
import { Formik, Form } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import InputBlock from 'containers/InputBlock';
import Heading from 'containers/MainHeading';
import TextArea from 'containers/TextArea';

import PartyBuyerComponent from 'components/DiamondListing/PopupComponent/PartyBuyerComponent';

import * as AddNoteAPI from 'services/AddNoteApi';

// import { useCompositeState } from 'util/hooks';
import { isEmpty } from 'util/utils';

import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

const AddNotes = ({ type, checked, onClose, detail = {} }) => {
  // const [state, setState] = useCompositeState({
  //   type: 'NOTES',
  // });

  // React.useEffect(() => {
  //   setState({
  //     subject: detail?.subject ?? '',
  //     dueDateTime: detail?.dueDate ? moment(detail?.dueDateTime) : null,
  //     description: detail?.description ?? null,
  //     type: detail?.type ?? undefined,
  //   });
  // }, [detail?.description, detail?.dueDate, detail?.dueDateTime, detail?.subject, detail?.type, setState]);

  const initialValue = {
    subject: '',
    description: '',
    user: '',
    userAccount: '',
    dueDateTime: undefined,
    type: 'NOTES',
  };

  const tempValue = {
    subject: Yup.string().required(Messages.Todo.subject).nullable(),
    description: Yup.string().required(Messages.Todo.Description).nullable(),
    dueDateTime: Yup.string().required(Messages.Todo.DueDateTime).nullable(),
    userAccount: Yup.string().required(Messages.userAccountSelect).nullable(),
    user: Yup.string().required(Messages.userSelect).nullable(),
  };

  const validate = Yup.object().shape(tempValue);

  const [syncData, setSyncData] = useState(undefined);
  useEffect(() => {
    if (detail) {
      let clientList = [];
      if (detail.partyId && detail.customerId) {
        const client = {
          id: detail?.partyId?.id,
          companyName: detail?.partyId?.companyName,
          user: {
            id: detail?.customerId?.id,
            name: detail?.customerId?.name,
            firstName: detail?.customerId?.firstName,
            lastName: detail?.customerId?.lastName,
          },
        };
        clientList = [client];
      }

      const editValue = {
        subject: detail?.subject ?? undefined,
        description: detail?.description ?? undefined,
        user: detail?.customerId?.id ?? undefined,
        client: clientList,
        userAccount: detail?.partyId?.id ?? undefined,
        dueDateTime: detail?.dueDateTime ? moment(detail?.dueDateTime) : undefined,
        type: 'NOTES',
      };
      setSyncData(editValue);
    }
  }, [detail]);

  useEffect(() => {
    if (checked) {
      // console.log('checked', checked);
      const editValue = {
        ...initialValue,
        subject: checked.map((data) => data.memoNo).toString() ?? undefined,
      };
      setSyncData(editValue);
    }
  }, [checked]);
  const submitValue = async (values) => {
    // setState({ loader: true });
    const request = {
      subject: values.subject,
      customerId: values.user,
      partyId: values.userAccount,
      description: values.description,
      dueDateTime: values.dueDateTime,
      type: values.type,
    };

    const [, res] = isEmpty(detail)
      ? await AddNoteAPI.createAddNote(request)
      : await AddNoteAPI.updateAddNote(detail.id, request);

    if (res?.code === 'OK' && res?.data) {
      // setState({ loader: false });
      OpenNotification({ type: 'success', title: res.message });
      onClose();
    }

    this.props.handleCancel();
    // setState({ loader: false });
  };
  return (
    <Formik
      initialValues={syncData || initialValue}
      enableReinitialize
      validateOnChange
      validationSchema={validate}
      onSubmit={submitValue}
    >
      {({ handleBlur, setValues, touched, errors, values }) => (
        <Form>
          <Heading
            title={`${isEmpty(detail) ? 'Add' : 'Edit'} Notes ${type === '1' ? '' : type === '2' ? '' : ''}`}
            className="mb-20 smallTitle p-0"
          />

          <div style={{ maxHeight: 'calc(100vh - 127px)', overflow: 'auto' }}>
            <PartyBuyerComponent
              error={(touched?.useAccount || touched?.user) && errors}
              setUserDetails={(data) => {
                setValues((oldValues) => ({
                  ...oldValues,
                  userAccount: data?.userAccount,
                  user: data?.user,
                }));
              }}
              initialData={{ ...values }}
            />

            <div className="from-group inputBlock mb-15">
              <label className="commonLabel">Select Due Date & Time *</label>

              <DatePicker
                value={values.dueDateTime}
                format="YYYY-MM-DD HH:mm:ss"
                onChange={(value) =>
                  setValues((oldValues) => ({
                    ...oldValues,
                    dueDateTime: value,
                  }))
                }
                disabledDate={(current) => current && current < moment().startOf('day')}
                showTime={{ defaultValue: moment('HH:mm:ss') }}
                nopast
                onBlur={handleBlur}
                error={touched?.dueDateTime && errors?.dueDateTime}
              />
              {touched?.dueDateTime && <div className="error">{errors?.dueDateTime}</div>}
            </div>

            <InputBlock
              name="subject"
              placeholder="Title"
              label="Title *"
              value={values?.subject}
              onChange={(e) =>
                setValues((oldValues) => ({
                  ...oldValues,
                  subject: e.target.value,
                }))
              }
              onBlur={handleBlur}
              error={touched?.subject && errors?.subject}
            />

            <TextArea
              name="description"
              value={values.description}
              onChange={(e) => {
                setValues((oldValues) => ({
                  ...oldValues,
                  description: e.target.value,
                }));
              }}
              label="Description *"
              placeholder="Description"
              onBlur={handleBlur}
              error={touched?.description && errors?.description}
            />
          </div>

          <div className="sidebarAction">
            <button className="commonButton" type="submit">
              {isEmpty(detail) ? 'Add' : 'Update'} Notes
            </button>
            <button className="commonOutine" type="reset" onClick={onClose}>
              Cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddNotes;
