import React from 'react';
import { useTable } from 'react-table';

import CommonSearch from 'containers/CommonSearch';
import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';

import { LIMIT } from 'components/Inventory/TableBack';
import ListFooter from 'components/List/ListFooter';
import Pagination from 'components/List/Pagination';

import * as UserAPI from 'services/UserApi';

import { isEmpty } from 'util/utils';

import { USERS_TYPE } from 'constants/Common';

class ContactDirectory extends React.Component {
  state = { data: [], count: 0, page: 1, limit: LIMIT };

  types = Object.fromEntries(Object.entries(USERS_TYPE).map(([value, key]) => [key, value]));

  componentDidMount() {
    this.getUsersList();
    this.types = Object.fromEntries(Object.entries(USERS_TYPE).map(([value, key]) => [key, value]));
  }

  getUsersList = async (search = null) => {
    const { page, limit } = this.state;
    const [err, res] = await UserAPI.getUserList({
      page,
      limit,
      sort: [{ createdAt: 'DESC' }],
      isDeleted: false,
      ...(!isEmpty(search)
        ? {
            search: {
              keys: ['name'],
              keyword: search,
            },
          }
        : {}),
      filter: {
        type: [USERS_TYPE.SUPER_ADMIN, USERS_TYPE.ADMIN, USERS_TYPE.SELLER],
      },
    });
    const list = res?.data?.list;
    this.setState(
      !err && res?.code === 'OK' && list?.constructor?.name === 'Array'
        ? { data: list, count: res?.data.count }
        : { data: [], count: 0 },
    );
  };

  handleSearch = (value) => {
    if (value?.length > 2 || isEmpty(value)) this.getUsersList(value);
  };

  render() {
    const columns = [
      {
        Header: 'Sr No.',
        accessor: 'srno',
        Cell: ({ row }) => ++row.id,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }) => row.original.name || '-',
      },
      {
        Header: 'Contact No',
        accessor: 'mobile',
        Cell: ({ row }) => row.original.mobile || '-',
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ row }) => row.original.email || '-',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ row }) => this.types?.[row.original.type] || '-',
      },
      {
        Header: 'Company Name',
        accessor: 'companyname',
        Cell: ({ row }) => row.original.companyName || '-',
      },
      {
        Header: 'Location',
        accessor: 'location',
        Cell: ({ row }) => row.original.city?.name || '-',
      },
      {
        Header: 'Last Active',
        accessor: 'lastactive',
        Cell: ({ row }) => row.original.city?.lastactive || '-',
      },
      // {
      //   Header: 'Social Media',
      //   accessor: 'social',
      //   Cell: () => (
      //     <div className="cardIcon j-flex-start">
      //       <img src={require('assets/svg/facebook.svg')} alt="" />
      //       <img src={require('assets/svg/twitter.svg')} alt="" />
      //       <img src={require('assets/svg/linkdin.svg')} alt="" />
      //     </div>
      //   ),
      // },
      // {
      //   Header: 'Action',
      //   accessor: 'action',
      //   Cell: () => (
      //     <div className="cardIcon j-flex-start">
      //       <img src={require('assets/svg/whatsapp.svg')} alt="" />
      //       <img src={require('assets/svg/mailcard.svg')} alt="" />
      //       <img src={require('assets/svg/message.svg')} alt="" />
      //     </div>
      //   ),
      // },
    ];
    const Table = ({ columns, data }) => {
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
      });

      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, key) => (
              <tr key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, key) => (
                  <th key={key} {...column.getHeaderProps()} width="100px">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, key) => {
                    return (
                      <td key={key} width="100px" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    };
    return (
      <div className="editProfilePage">
        <div className="headeTitle">
          <h2>Contact Directory</h2>
        </div>
        <div className="contactDirectoryWrapper">
          <CommonSearch size="lg-size" placeholder="Search by Name" handlechange={this.handleSearch} />
          <div className="contactListMain">
            <h2 className="settingSecondTitle">Member ({this.state.count})</h2>
            <GridHeightContainer
              subtractFrom={['.headeTitle', '.commonSearch', '.settingSecondTitle', '.botoomStickyBar']}
              className="contactList"
              adjustment={-110}
            >
              <Table columns={columns} data={this.state.data} />
            </GridHeightContainer>
            <ListFooter className="homeSettingfooter">
              <span style={{ margin: 'auto' }}>
                <Pagination
                  page={this.state?.page}
                  limit={this.state?.limit}
                  count={this.state?.count}
                  length={this.state?.data.length}
                  onPaginationChange={(page, limit) => this.setState({ page, limit }, () => this.getUsersList())}
                />
              </span>
            </ListFooter>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactDirectory;
