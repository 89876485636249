import React from 'react';

import CheckBox from 'components/CheckBox';

import { GROUP_KEY } from 'services/groupingArray';

import classNames from 'util/classNames';
import { useBoolean } from 'util/hooks';
import { useGroupSelect } from 'util/useGroupSelect';
import { isNumber, isEmpty } from 'util/utils';

import { COLLAPSIBLE_ROW_HEADERS } from '../table-config';

import leftArrowWhiteSvg from 'assets/svg/left-arrow-white.svg';
import rightArrowWhiteSvg from 'assets/svg/right-arrow-white.svg';

const GroupCheckBox = React.memo((props) => {
  const { rows, currentType } = props;
  const [checked, indeterminate, toggle] = useGroupSelect(rows, currentType);

  const groupKey = React.useMemo(() => rows?.[0]?.[GROUP_KEY], [rows]);
  const tip = React.useMemo(() => (checked ? 'Unselect All' : 'Select All'), [checked]);

  return (
    <div className="checkBoxSelect" title={tip}>
      <CheckBox
        name={groupKey}
        checked={checked}
        indeterminate={indeterminate}
        onChange={toggle}
        disabled={isEmpty(props?.rows)}
      />
    </div>
  );
});

function RowGroupHeader(props) {
  const { currentType, rows, noCheckBox } = props;
  const { title, total, children } = props;

  const [showTitle, setShowTitle] = useBoolean();

  const groupCheckbox = React.useMemo(() => {
    if (noCheckBox) return null;
    return <GroupCheckBox rows={rows} currentType={currentType} />;
  }, [currentType, rows, noCheckBox]);

  const groupTitle = React.useMemo(() => {
    if (isEmpty(title)) return null;
    return <span>{title}</span>;
  }, [title]);

  const groupCollapse = React.useMemo(() => {
    if (!COLLAPSIBLE_ROW_HEADERS) return null;
    const icon = showTitle ? leftArrowWhiteSvg : rightArrowWhiteSvg;
    const tip = showTitle ? 'Hide Title' : 'Show Title';
    return (
      <div className="groupClose" onClick={setShowTitle.toggle}>
        <img title={tip} src={icon} alt={tip} />
      </div>
    );
  }, [setShowTitle.toggle, showTitle]);

  const groupTotal = React.useMemo(() => {
    if (!isNumber(total)) return null;
    return <div className="tableHeaderMain">{`${total} Diamond`}</div>;
  }, [total]);

  return (
    <>
      <div className="tableGropupingBox">
        <div className="tableGroupTitle">
          <span className={classNames([`tableGroupTitleInner`, showTitle && 'collpaseBlock'])}>
            {groupCheckbox}
            {groupTitle}
            {groupCollapse}
          </span>
          {groupTotal}
        </div>
      </div>
      {children ?? (
        <tr className="boxShadowNone">
          <td className="groupingTableAmount">&nbsp;</td>
        </tr>
      )}
    </>
  );
}

export default React.memo(RowGroupHeader);
