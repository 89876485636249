import { getDefaultPermissions } from './_getDefaultPermissions';

export const TradeShowPermissions = [
  {
    mainModule: 'TRADESHOW',
    module: 'APPOINTMENT',
    title: 'Appointment',
    url: '/appointment',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRADESHOW',
    module: 'Details',
    title: 'Details',
    url: '/details',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRADESHOW',
    subModule: 'APPOINTMENT',
    module: 'PENDING',
    title: 'Pending',
    url: '/pending',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRADESHOW',
    subModule: 'APPOINTMENT',
    module: 'COMPLETED',
    title: 'Completed',
    url: '/completed',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRADESHOW',
    subModule: 'APPOINTMENT',
    module: 'CANCEL',
    title: 'Cancel',
    url: '/cancel',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRADESHOW',
    subModule: 'APPOINTMENT',
    module: 'REOPEN',
    title: 'Reopen',
    url: '/reopen',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRADESHOW',
    module: 'DISCUSSION',
    title: 'Discussion',
    url: '/discussion',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'TRADESHOW',
    module: 'VISITOR',
    title: 'Visitor',
    url: '/visitor',
    permissions: getDefaultPermissions(),
  },
];
