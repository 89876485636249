import React from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useTable } from 'react-table';

import { Drawer } from 'antd';
import { groupBy } from 'lodash';

import CheckBox from 'components/CheckBox';
import CircularProgress from 'components/CircularProgress';
import DiamondDetail from 'components/DiamondDetail';
import DiamondSliderImage from 'components/DiamondDetail/DiamondSliderImage';
import IFrame from 'components/DiamondDetail/IFrame';
import MagnifierImage from 'components/DiamondDetail/MagnifierImage';
import NoDataFoundComponent from 'components/DiamondDetail/NoDataFoundComponent';
import ListTableHeaderBack from 'components/DiamondListing/ListTableHeaderBack';
import NoDataShow from 'components/DiamondListing/NoDataShow';
import Status from 'components/DiamondListing/Status';
import TargetSellerRow from 'components/Target/TargetSellerRow';
import OfferDetailsCell from 'components/Transaction/Offer/OfferDetailsCell';
import OrderDetailsCell from 'components/Transaction/Order/OrderDetailsCell';

import { RowSelectService } from 'services/RowSelectService';
import { GROUP_KEY } from 'services/groupingArray';
import Storage from 'services/storage';

import classNames from 'util/classNames';
import * as Hooks from 'util/hooks';
import useFilters from 'util/useFilters';
import useRowSelect from 'util/useRowSelect';
import useSortBy from 'util/useSortBy';
import { isEmpty, isArray, isNotEmpty, isFunction, isString } from 'util/utils';

import { FILE_URLS } from 'constants/Common';
import * as AppConsts from 'constants/Common';
import { COMMON_URL } from 'constants/CommonUrl';
// import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

import TableGrouping from './TableGrouping';
import RowGroupFooter from './TableGrouping/RowGroupFooter';
import RowGroupHeader from './TableGrouping/RowGroupHeader';
import * as TableConfig from './table-config';
import * as TableUtils from './table-utils';

import sortinglightSvg from '../../assets/svg/InventoryResult/sorting-light.svg';
import infoSvg from '../../assets/svg/info.svg';

import iconCamera from 'assets/svg/camera.svg';
import iconCert from 'assets/svg/certi.svg';
import iconVideo from 'assets/svg/video.svg';

export const canSortCommon = TableConfig.SORTABLE_COLUMNS;
export const LIMIT = TableConfig.PAGE_LIMIT;
export const FILTER_COLUMNS = TableConfig.FILTER_COLUMNS;
export const floatkeys = TableConfig.FLOAT_COLUMNS;
export const roundkeys = TableConfig.ROUND_COLUMNS;
export const DISPLAY_TOTAL = TableConfig.TOTAL_COLUMNS;

const getStyles = (props, item, type) => {
  return [
    props,
    {
      style: {
        textAlign: item.cellClass ? item.cellClass.replace('text-', '') : 'center',
        width: (item.width || '100') + 'px',
        fontWeight: type === 'cell' && TableConfig.FONT_WEIGHT_COLUMNS.includes(item.id) ? '600' : '',
        color:
          type === 'cell' && (item.id === 'lbNm' || item.id === 'rptNo' || item.link || item.id === 'dnaa')
            ? '#008cba'
            : '',
        textDecoration: type === 'cell' && item.id === 'dnaa' && 'underline',
      },
    },
  ];
};
const headerProps = (props, { column }) => getStyles(props, column, 'header');
const cellProps = (props, { cell }) => getStyles(props, cell.column, 'cell');

export const NoDataFound = ({ loading, length }) =>
  loading ? <NoDataShow message={<CircularProgress />} /> : length === 0 ? <NoDataShow /> : <></>;

const ActionsCell = React.memo((props) => {
  const { isHeader, rows, disabled, uniqueBy, nostatus, pairStkNo } = props;
  const { areAllChecked, currentType, noCheckBox, liveStatus, historyStatus } = props;

  const row = React.useMemo(() => {
    const output = { ...props?.row };
    output.selectionKey = output.selectionKey ?? output.id;
    return output;
  }, [props?.row]);

  const { isRowChecked, isHeaderChecked } = useRowSelect(currentType, uniqueBy);

  React.useEffect(() => {
    if (!areAllChecked) return;
    if (isHeader) {
      RowSelectService.selectRows(currentType, rows, isHeader);
    } else if (row.isDefaultChecked) {
      RowSelectService.selectRows(currentType, [row]);
    }
  }, [areAllChecked, currentType, rows, isHeader, row]);

  const isChecked = React.useMemo(() => {
    return isHeader ? isHeaderChecked(rows) : isRowChecked(row.selectionKey);
  }, [rows, isHeader, isHeaderChecked, isRowChecked, row.selectionKey]);

  const handleChange = React.useCallback(() => {
    const _rows = isHeader ? rows : [row];
    isChecked
      ? RowSelectService.unSelectRows(currentType, _rows, isHeader)
      : RowSelectService.selectRows(currentType, _rows, isHeader, uniqueBy);
  }, [isHeader, rows, row, isChecked, currentType, uniqueBy]);

  const title = React.useMemo(() => {
    if (isHeader) return isChecked ? 'Unselect All' : 'Select All';
    return isChecked ? 'Unselect Row' : 'Select Row';
  }, [isChecked, isHeader]);

  if (nostatus && noCheckBox) return null;

  return (
    <div className="selectActionIcon">
      {!nostatus && (
        <Status
          status={isHeader ? 'ALL' : row?.wSts}
          bidType={row?.bidType}
          showLive={liveStatus}
          showHistory={historyStatus}
        />
      )}
      {!noCheckBox && (
        <div
          className={classNames(['selectActionIconWrapper', pairStkNo && row?.pairStkNo && 'pairStkNo'])}
          style={{ marginRight: isHeader ? '5px' : undefined }}
          title={title}
        >
          <CheckBox
            disabled={disabled}
            key={`selection_${isHeader ? 'header' : row.id}`}
            checked={isChecked}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
});

const Resource = React.memo(({ extraDetails = [], original }) => {
  const [showImage, setShowImage] = Hooks.useBoolean();
  const [showVideo, setShowVideo] = Hooks.useBoolean();
  const [showCert, setShowCert] = Hooks.useBoolean();

  return (
    <>
      <div className="d-flex width-max-content tableSmallImage">
        <img src={iconCamera} width="15px" alt="" onClick={setShowImage.true} />
        <img src={iconVideo} width="15px" alt="" onClick={setShowVideo.true} />
        <img src={iconCert} width="15px" alt="" onClick={setShowCert.true} />
        {extraDetails === 'Order' && <OrderDetailsCell row={original} />}
        {extraDetails.includes('Offer') && <OfferDetailsCell row={original} />}
      </div>
      <Drawer
        visible={showImage || showVideo || showCert}
        onClose={showImage ? setShowImage.false : showVideo ? setShowVideo.false : showCert ? setShowCert.false : ''}
        wrapClassName="diamondListinSidebar onlyImageSlider"
        destroyOnClose
      >
        {showImage && <DiamondSliderImage image1={AppConsts.FILE_URLS.img.replace('***', original?.vStnId)} />}

        {showVideo && (
          <IFrame video tempSrc={iconVideo} src={AppConsts.FILE_URLS.videoFile.split('***').join(original?.vStnId)} />
        )}
        {showCert && (
          <MagnifierImage src={AppConsts.FILE_URLS.certFile.replace('***', original?.rptNo)}>
            <NoDataFoundComponent className="nodatFoundImage" src={iconCert} title="No Certificate Found" />
          </MagnifierImage>
        )}
      </Drawer>
    </>
  );
});

const MemoizedCell = React.memo((props) => {
  const { cell, clickHandler, row, setDetail, extraDetails, showHistoryFn } = props;
  const handleClick = React.useCallback((e) => clickHandler(e, row, cell), [cell, clickHandler, row]);

  if (cell.column.id === 'shpNm') {
    const masterData = Storage.get(`dn-master`) || {};
    const value = masterData.SHAPE?.find((x) => x.code === cell.value)?.webDisplay;
    return (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        {value}
      </td>
    );
  }

  if (cell.column.id === 'org') {
    return <td {...cell.getCellProps(cellProps)}>{!cell.value || cell.value === ' ' ? '-' : cell.value}</td>;
  }

  return (
    <td {...cell.getCellProps(cellProps)} onClick={handleClick}>
      {cell.column.id !== 'Details' && (
        <div>
          {showHistoryFn && cell?.column?.id === 'vStnId' ? (
            <>
              {row.original.vStnId}
              <img
                alt="info.svg"
                onClick={() => showHistoryFn(row.original.blockId)}
                src={infoSvg}
                style={{
                  marginLeft: '5px',
                  width: '13px',
                }}
              />
            </>
          ) : row.original.altCert && TableConfig.ALT_COLUMNS.includes(cell.column.id) ? (
            <span style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
              <span style={{ width: '100%' }}>{cell.render('Cell')}</span>
              <span
                style={{ width: '100%' }}
                className={
                  floatkeys.includes(cell.column.id) || roundkeys.includes(cell.column.id) ? 'numberValue' : null
                }
              >
                {TableUtils.formatValues(row.original.altCert[cell.column.id], cell.column.id)}
              </span>
            </span>
          ) : (
            cell.render('Cell')
          )}
        </div>
      )}
      {cell.column.id === 'Details' && (
        <Resource original={row.original} setDetail={setDetail} extraDetails={extraDetails} />
      )}
    </td>
  );
});

const TrRow = React.memo((props) => {
  const row = props.row;
  const DND_ITEM_TYPE = 'row';
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // move row
      const r1 = props.data[dragIndex];
      if (props.data[dragIndex]?.sortingSequence) {
        const b = props.data[dragIndex]?.sortingSequence;
        props.data[dragIndex].sortingSequence = props.data[hoverIndex].sortingSequence;
        props.data[hoverIndex].sortingSequence = b;
      }
      let newlist = props.data.map((rec) => ({ ...rec }));

      newlist = [...newlist.slice(0, dragIndex), ...newlist.slice(dragIndex + 1, newlist.length)];
      newlist = [...newlist.slice(0, hoverIndex), r1, ...newlist.slice(hoverIndex, newlist.length)];
      if (props.moverow) props.moverow(newlist);
      // move row
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index: props.index },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });
  const opacity = isDragging ? 0 : 1;
  preview(drop(dropRef));
  drag(dragRef);

  //common functions
  const clickHandler = React.useCallback(
    (e, row, cell) => {
      if (cell.column.id === 'selection') return;
      if (cell.column.id === 'dnaa') {
        window.open(`
         https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/DNA.html?id=${row?.original?.vStnId}`);
      } else if (cell.column.link && typeof cell.column.link === 'string') {
        let field = cell.column.link.slice(cell.column.link.indexOf('$') + 1, cell.column.link.length);
        field = field.slice(0, field.indexOf('$'));
        const link = cell.column.link.replace('$' + field + '$', row.original[field]);
        window.open(link);
      } else if (['shpNm'].includes(cell.column?.id)) {
        // if (isFunction(props.setDetailDrawerVisible)) props.setDetailDrawerVisible(row.original);
        // if (props.setAnalytics) props.setAnalytics(row.original);
      } else if (cell.column.id === 'ftc' && cell.value) {
        window.open(`/ftc/${row.original.id}`);
      } else if (cell.column.id === 'vStnId') {
        window.open(
          `${COMMON_URL.FRONT_SITE_URL}/diamond-detail/${
            row.original?.diamondId ? row.original?.diamondId : row.original?.id ?? row.original.vStnId
          }?actBtn=true`,
        );
      } else if (
        (cell.column.id === 'lbNm' || cell.column.id === 'rptNo') &&
        row.original.lbNm &&
        row.original.rptNo &&
        props?.currentType !== 'NewArrivalDiamondList'
      ) {
        window.open(TableConfig.LAB_LINKS[row.original.lbNm.toUpperCase()].replace('***', row.original.rptNo));
      } else if (!TableConfig.NOCHECK_COLUMNS.includes(cell.column.id)) {
        if (props.nocheck || props.noCheckBox) return;
        const parentNode = e.currentTarget.parentNode;
        if (parentNode) {
          const checkboxRef = parentNode.getElementsByTagName('input');
          if (checkboxRef && checkboxRef.length > 0) {
            checkboxRef[0].click();
          }
        }
      } else if (cell.column.id === 'targetProgress') {
        row.original.targetOpen = !(row.original?.targetOpen || false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.noCheckBox, props.nocheck, props.setDetailDrawerVisible],
  );

  const rowClassNames = React.useMemo(() => {
    const lab = !isEmpty(row.original?.lbNm) && `${row.original.lbNm}`.toUpperCase();
    return classNames([lab]);
  }, [row.original.lbNm]);
  return (
    <>
      {props.dragDrop && (
        <div className="tableSortingTd" ref={dragRef}>
          <img src={sortinglightSvg} />
        </div>
      )}
      <tr
        {...row.getRowProps({ 'table-row': props.currentType + row.original.id })}
        ref={dropRef}
        style={{ opacity }}
        className={row.original.LowerBid === 1 || row.original.isWinnerBid ? 'bid-winner' : rowClassNames}
      >
        {row.cells.map((cell) => (
          <MemoizedCell
            key={cell.column.id + '_cell'}
            cell={cell}
            clickHandler={clickHandler}
            row={row}
            setDetail={props.setDetail}
            showHistoryFn={props.showHistoryFn}
            extraDetails={props.extraDetails}
            setAnalytics={props.setAnalytics}
          />
        ))}
      </tr>
      {!isEmpty(props.targetIndex) && props.targetIndex.includes(props.index) && (
        <tr className="tableInTableBoxWrapper b-none activityTarget">
          <td colSpan="6">
            {!isEmpty(props.targetSeller?.[row.index]) ? (
              <TargetSellerRow data={props.targetSeller?.[row.index]} />
            ) : (
              <NoDataShow message="Detail not exist" />
            )}
          </td>
        </tr>
      )}
    </>
  );
});

function Table(props) {
  const {
    handleSort = () => {},
    nocheck = false,
    nostatus = false,
    noCheckBox = false,
    loading = false,
    nodots = false,
    noGrp,
    currentType,
    areAllChecked = false,
    FilterOption = true,
    canSort = true,
    initialSort = [],
    toggleCollapse,
    pairStkNo,
    disableRowCheck,
    uniqueBy,
  } = props;

  const [detail, setDetail] = React.useState({});
  const [detailDrawerVisible, setDetailDrawerVisible] = React.useState();
  const setAnalytics = () => {
    if (isFunction(props.setAnalytics)) props.setAnalytics();
  };
  const data = React.useMemo(() => {
    if (!isArray(props?.data)) return [];

    const output = props.data.map((record) => ({ ...record }));
    if (output?.[0]?.pairStkNo) {
      output.sort((curr, next) => curr?.pairStkNo - next?.pairStkNo);
    }

    output.forEach((record, index) => {
      if (record?.pairStkNo && index > 0) {
        const prevItem = output?.[index - 1];
        if (prevItem?.pairStkNo === record?.pairStkNo) {
          record.pairStkNo = undefined;
          record.pairStkNoList = prevItem?.pairStkNo;
        }
      }
    });

    return output;
  }, [props.data]);

  const statusColumn = React.useMemo(() => {
    return {
      id: 'selection',
      Header({ rows }) {
        const originalRows = React.useMemo(() => rows.map((row) => row.original), [rows]);

        return (
          <ActionsCell
            currentType={currentType}
            rows={originalRows}
            uniqueBy={uniqueBy}
            areAllChecked={areAllChecked}
            noCheckBox={noCheckBox}
            nostatus={nostatus}
            disabled={!isEmpty(uniqueBy)}
            isHeader
          />
        );
      },
      Cell({ row }) {
        return (
          <ActionsCell
            row={row.original}
            pairStkNo={pairStkNo}
            disabled={disableRowCheck}
            uniqueBy={uniqueBy}
            nostatus={nostatus}
            areAllChecked={areAllChecked}
            currentType={currentType}
            noCheckBox={noCheckBox}
            liveStatus={props.liveStatus}
            historyStatus={props.historyStatus}
          />
        );
      },
    };
  }, [
    areAllChecked,
    currentType,
    disableRowCheck,
    noCheckBox,
    nostatus,
    pairStkNo,
    props.historyStatus,
    props.liveStatus,
    uniqueBy,
  ]);

  const columns = React.useMemo(() => {
    if (!isArray(props?.columns) || isEmpty(props?.columns)) return [];
    let output = props.columns.map((column) => ({ ...column }));

    output = output.filter((column) => {
      return column.id !== 'dnaa';
    });
    if (!nocheck) output.unshift(statusColumn);

    return output;
  }, [props.columns, nocheck, statusColumn]);

  const isMultiSortEvent = React.useCallback(() => true, []);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state: { sortBy, filters },
    headerGroups,
    rows,
    setSortBy,
    toggleSortBy,
  } = useTable(
    {
      data,
      columns,
      initialState: {
        sortBy: initialSort
          .map((item) => {
            return Object.entries(item).map(([id, desc]) => {
              const title = (() => {
                const col =
                  isArray(props.columns) && props.columns.find((col) => [col?.accessor, col?.id].includes(id));
                const output = col?.Header ?? col?.title ?? id;
                return output;
              })();
              desc = `${desc}`.toUpperCase() === 'DESC';
              return { id, desc, title };
            })?.[0];
          })
          .filter(isNotEmpty),
      },
      manualSortBy: true,
      isMultiSortEvent,
      disableSortBy: !canSort,
    },
    useFilters,
    useSortBy,
  );

  React.useEffect(() => {
    handleSort(sortBy.map(({ id, desc }) => ({ [id]: desc ? 'DESC' : 'ASC' })));
  }, [handleSort, sortBy]);

  const getGroupTitle = React.useCallback(
    (data) => {
      const show = {
        user: props.sortUser,
        date: props.sortDate,
        country: props.sortCountry,
        salesPerson: props.sortSalesPerson,
        status: props.sortStatus,
      };

      const country = data?.countryNm || '';
      const salesPerson = data?.seller || '';
      const date = data?.userDate || data?.createdAt;
      const dateTitle = data?.dateTitle || '';
      const user = [
        data?.user?.account?.companyName || data?.user?.companyName,
        data?.userName,
        data?.userMobile,
        data?.userEmail,
      ]
        .filter(isNotEmpty)
        .map((item) => (isEmpty(item) ? '-' : item))
        .join(' | ');

      return show?.user
        ? user
        : show?.date
        ? `${dateTitle ? dateTitle : ''} ${date}`
        : props.groupStoneDate
        ? `${date} - ${data?.vStnId}`
        : props?.stageShow || show?.status
        ? `${data?.sSts ?? data?.blockCode}`
        : show?.country
        ? country
        : show?.salesPerson
        ? salesPerson
        : [`${dateTitle ? dateTitle + ' ' + date : date}`, user].filter(isNotEmpty).join(' | ');
    },
    [
      props.groupStoneDate,
      props.sortCountry,
      props.sortDate,
      props.sortSalesPerson,
      props.sortStatus,
      props.sortUser,
      props?.stageShow,
    ],
  );

  const totalColumns = React.useMemo(() => {
    if (!isArray(props.columns)) return [];
    const fields = !isEmpty(props?.displayTotal) ? props?.displayTotal : TableConfig.TOTAL_COLUMNS;
    return fields.filter((el) => Boolean(props.columns.find((col) => col.id === el)));
  }, [props.columns, props?.displayTotal]);

  const grouppedRows = React.useMemo(() => {
    const originalRows = rows.map((row) => row?.original);
    if (noGrp || !isString(originalRows?.[0]?.[GROUP_KEY])) return rows;
    return groupBy(originalRows, GROUP_KEY);
  }, [noGrp, rows]);

  // React.useEffect(() => {
  //   return () => {
  //     if (areAllChecked) return;
  //     store.dispatch(DiamondActions.resetSelectedRows({ currentType }));
  //   };
  // }, [rows, areAllChecked, currentType]);

  return isArray(rows) && !isEmpty(rows) ? (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, k) => (
                <th key={k} {...column.getHeaderProps(headerProps)} style={{ width: (column.width || 100) + 'px' }}>
                  {column.id === 'selection'
                    ? column.render('Header')
                    : column.id !== 'action' && (
                        <ListTableHeaderBack
                          notooltip={props.notooltip}
                          toggleSortBy={toggleSortBy}
                          setSortBy={setSortBy}
                          sortBy={sortBy}
                          column={column}
                          filters={filters}
                          nodots={nodots}
                          FilterOption={FilterOption}
                        />
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <DndProvider backend={HTML5Backend}>
            {!isArray(rows.length) &&
              rows.map((row) => {
                prepareRow(row);

                const { isHeader, isOfferHeader } = row.original;
                {
                  /* const { isHeader, isFooter, isOfferHeader } = row.original; */
                }
                const groupKey = !noGrp && row.original?.[GROUP_KEY];
                const groupRows = !noGrp && grouppedRows?.[groupKey];

                const extraContent = [
                  Boolean(props.showOffer && row.original.content),
                  Boolean(
                    props.showHistory?.includes?.(row.original.vStnId) ||
                      props.showHistory?.includes?.(row.original.blockId),
                  ) && row.original.content2,
                ];

                return (
                  <>
                    {!noGrp && isHeader && (
                      <RowGroupHeader
                        rows={groupRows}
                        currentType={currentType}
                        noCheckBox={nocheck || noCheckBox}
                        total={row.original.totalDiamonds}
                        title={row.original._groupTitle_ ?? getGroupTitle(row.original)}
                      >
                        <RowGroupFooter row={row.original} columns={columns} totalColumns={totalColumns} />
                      </RowGroupHeader>
                    )}
                    {!noGrp && isOfferHeader && (
                      <TableGrouping
                        groupingTitle={row?.original?.offerGrp}
                        isOfferPopup
                        toggleCollapse={() => toggleCollapse?.(!row.original.collapsed, row.original.offerGrp)}
                        collapsed={row.original.collapsed}
                        calc={row.original.calc}
                        columns={columns}
                        noSerNum
                      />
                    )}
                    <TrRow
                      {...props}
                      row={row}
                      index={row.index}
                      setDetail={setDetail}
                      setDetailDrawerVisible={setDetailDrawerVisible}
                      setAnalytics={setAnalytics}
                    />
                    {extraContent}
                  </>
                );
              })}
          </DndProvider>
        </tbody>
      </table>
      <Drawer
        onClose={() => setDetailDrawerVisible(false)}
        visible={detailDrawerVisible}
        wrapClassName="diamondDetailPopup"
        destroyOnClose
      >
        <DiamondDetail diamondPopup={true} data={detailDrawerVisible} onClose={() => setDetailDrawerVisible(false)} />
      </Drawer>
      <Drawer
        visible={!isEmpty(detail)}
        onClose={() => setDetail(false)}
        wrapClassName="diamondDetailPopup"
        destroyOnClose
      >
        {detail.i && (
          <img
            src={FILE_URLS.img.replace('***', detail.i.vStnId)}
            alt={FILE_URLS.img.replace('***', detail.i.vStnId)}
          />
        )}
        {detail.v && (
          <iframe
            src={FILE_URLS.videoFile.replace('***', detail.v.vStnId)}
            height="100%"
            width="100%"
            title="videoFile"
          />
        )}
        {detail.c && (
          <iframe src={FILE_URLS.certFile.replace('***', detail.c.rptNo)} height="100%" width="100%" title="certFile" />
        )}
      </Drawer>
    </>
  ) : (
    <NoDataFound loading={loading} length={data.length} />
  );
}

export default React.memo(Table);
