export const termData = [
  {
    title: '1. ABOUT KGK GROUP',
    content: [
      {
        desc: [
          `KGK Jewels Online is a part of the KGK Group, having companies and its affiliates worldwide, operating under the brand name(s) KGK, and includes the following companies, that is, KGK Diamonds BV, Belgium Diamond LLC, Sparkle Gems Inc and KGK Diamonds (I) Pvt Ltd. (“KGK Group”). The KGK Group has been in the diamond trade for more than 110 years and has secured a notewothry presence across the globe. KGK’s rough gemstones and diamonds are directly sourced from the mines and the status of De Beers Sightholder and has diamond manufacturing units in India, Russia, South Africa, Angola, Botswana and Namibia. The KGK Group has its offices in USA, UAE, Asia, Africa, China, Japan, Russia, Europe etc. The diamonds listed on the Platform are natural diamonds and sourced in compliance with the United Nations Resolutions and corresponding international laws with certificates issued from Gemological Institute of America (GIA), International Gemological Institute (IGI) and HRD Antwerp (HRD).`,
          `KGK Jewels Online offers, through its Website (as defined hereinbelow), concierge services and acts as a marketplace for registered users and visitors to view a wide range of products and information displayed thereon, and placing a request/enquiry and/or bidding and/or purchasing product(s). It further offers Registered User Services (defined hereinafter) to those users who have registered on the Website, such as bidding, purchasing, tracking orders, viewing the product price, viewing the selection history, and viewing certificates and other product and/or authenticity related documents. The Platform is owned, operated, and edited by KGK Jewels Online DMCC.`,
          `Please read these Terms and Conditions carefully before using the Platform or any material or information therein, or availing Services (as defined hereinafter) from the Company offered through the Website.`,
        ],
      },
    ],
  },
  {
    title: '2. INTERPRETATION',
    content: [
      {
        desc: [
          `The words <strong>“hereof”, “hereto”, “herein”, and “hereunder”</strong> and words of similar import, when used in this Agreement, shall refer to this Agreement as a whole and not to any particular provision of this Agreement.`,
          `Words denoting the singular shall include the plural and words denoting any gender shall include all genders.`,
          `The headings to clauses, sub-clauses and paragraphs of this Agreement shall only serve the purpose of easier orientation and shall not affect the contents and interpretation of this Agreement.`,
          `Reference to days, months and years are to English calendar days, calendar months and calendar years, respectively.`,
          `Any reference to <strong>“writing”</strong> shall include printing, typing, lithography, an electronic record, and other means of reproducing words in a visible form.`,
          `The words <strong>“include”</strong> and <strong>“including”</strong> are to be construed without limitation`,
          `<strong>“Law”</strong> means all statutes, enactments, acts of legislature, laws, ordinances, rules, by-laws, regulations, negotiations, guidelines, policies, directions, directives and orders of the Dubai Multi Commodities Centre or the United Arab Emirates Federal Law.`,
          `<strong>“Person”</strong> includes any individual, partnership, corporation, company, unincorporated organization or association, trust or other entity`,
          `The words <strong>“Your”</strong> or <strong>“your”</strong> or <strong>“You”</strong> or <strong>“you”</strong>, when used in this Agreement, shall refer to and mean <strong>“Website visitor/s”</strong> or <strong>“Registered User/s”</strong>, as the case may be.`,
        ],
      },
    ],
  },
  {
    title: '3. DEFINITIONS',
    content: [
      {
        desc: [
          `The following words and terms, whenever used in this Agreement, unless repugnant to the meaning or context thereof, shall have the respective meanings set forth below.`,
          `<strong>“Registered User Services”</strong> shall mean those services made available and provided only to Registered Users, including but not limited to services such as placing bids to purchase Products, viewing the approximate price of a product, image and 360° view of the diamond, GIA Report, certifications other such documents in respect of the Product(s), details of the diamond, offers, notifications, and viewing a Registered Users selection history, cart, order status, invoice, shipment status, watchlist/wishlist, ‘my diamonds’, orders, saved searches, notes, demands, and ledger reflecting invoices raised, payments received, due dates, and outstanding balances;`,
          `<strong>“Applicable Laws”</strong> or <strong>“Law”</strong> shall mean any applicable laws and regulations of the Dubai Multi Commodities Centre and the applicable federal laws of the United Arab Emirates`,
          `<strong>“Company”</strong> or <strong>“Us”</strong> or <strong>“We”</strong>, shall mean and include KGK Jewels Online DMCC, its Offices, Officers, Directors, Partners, Owners, Administrators, independent Contractors, Employees, Agents, or affiliates, and its/their successors and assigns;`,
          `<strong>“Intellectual Property Rights”</strong>, which may also be referred as IPR, shall mean and include all registered and unregistered trademarks, copyright in all forms including but not limited to the contents of the Website, images, text, illustrations, audio clips, trademarks, logos, labels, video clips, software and coding; industrial designs, patents, inventions, domain names, trade secrets, methodology, processes, features, functionality, User Information and common law rights in the aforesaid, which are associated with the Company, Services or the System;`,
          `<strong>“Person”</strong> shall mean a person, and includes any individual, corporation, firm, partnership, joint venture, association, organisation, trust, Dubai Multi Commodity Centre Authority, federal authority, or other legal entity (in each case, whether or not having separate legal personality);`,
          `<strong>“Privacy Policy”</strong> shall mean the Privacy Policy of the Company published on the Website;`,
          `<strong>“Product Owners”</strong> shall mean the third party, independent owner(s) of a product(s) that is being displayed on the Website;`,
          `<strong>“Registered User”</strong> shall mean any person, and includes any individual, corporation, firm, partnership, joint venture, association, organisation, or trust who has completed the basic KYC procedure on our Website and has been granted a registered account to access the Website;`,
          `<strong>“Services”</strong> shall mean and include Services provided by the Company from time to time through the Website, including services to its Website visitors to view the wide range of products displayed on the Platform as well as Registered User Services provided only to the Registered Users;`,
          `<strong>“Website/Platform”</strong> shall mean and include, whole or in part, the internet resource and website known as www.kgklive.com including but not limited to all its associated sub-domain, mobile application, any accompanying or associated data, applications, utilities or interface`,
          `<strong>“System”</strong> shall mean and include the Website, electronic communication network including all software, all hardware, all files and images and data contained in or generated by the System associated with the Website.`,
          `<strong>“Use”</strong> shall mean and include any use of the Website or Services by a Person, including, without limitation, Website visitors, Registered Users, and any other Persons who accesses or visits the Website and their relative(s), representatives, agents, servants or affiliates, for whom, or on whose behalf, such Website Visitor, Registered User, or Person is using the Website and availing the Services`,
          `<strong>“User”</strong> and <strong>“Users”</strong> shall mean and include a Registered User, Website Visitor or any Person who Uses the Website, Users, including his relative(s), representatives, agents, servants or affiliates, for whom or on whose behalf, the User is using the Website.`,
          `<strong>“User Account Information”</strong> shall mean and include the full name, nationality, residential address (city, state, PIN code), e-mail address, telephone number (mobile or landline and any other information as may be required by the Company in relation of the User Account.`,
          `<strong>“User Information”</strong> shall mean and include User Account Information, User generated content, information generated, directly or indirectly, subject to the privacy policy of the Company, with respect to the User Account, during the process of obtaining Registered User Services by the User, or otherwise. It shall also include the information provided to the Company or collected by the Company in respect of a Website Visitor.`,
          `<strong>“Website Visitor”</strong> shall mean any Person who is not a Registered User and therefore does not have a registered account to access the Website and cannot avail of the Registered User Services, but is visiting the Website in order to view the products displayed thereon.`,
        ],
      },
    ],
  },
  {
    title: '4. ACCEPTANCE OF TERMS',
    content: [
      {
        desc: [
          `This Agreement applies to the Company and User, and governs the provision of access to Users of the Website, and/or the use by Users of any Services provided on or through the Website.`,
          `The Company is, and User accepts that the Company is, the owner, author and publisher of the Website and the operator of the System associated with the Website for providing Services.`,
          `By using the Website or accessing any material, information or services through the Website, the User agrees, admits, confirms and declares that the User have completed 18 years of age as on date of this Agreement, and that the User has fully read and understood the Terms and Conditions (also referred as <strong>“Terms of Use”</strong>) as set forth in this Agreement, without any impairment in judgment resulting from (but not limited to) mental illness, mental handicap, intoxication, medication, or any other health or other problem that could impair judgment.`,
          `In case the User has any issue or difficulty with the understanding of this Agreement or any of its clause or portion, you are advised not to Use the Website and if the User uses the Website, it shall be presumed and signified that User has agreed to be bound by the terms and conditions of the Agreement, without any objection or reservation.`,
          `If you do not wish to be bound by any part of this Agreement, you are not allowed to and shall not use the Website or any of Services offered by the Company or the Website, any such use of the Website or any of the Services shall not be valid and shall not vest any rights or entitlements in you, and shall be a breach of this Agreement by you.`,
          `By mere use of or access to the Website, you shall be contracting with Company, and these terms and conditions including the Privacy Policy, constitute your binding obligation.`,
          `Further, by mere use of or access to the Website, you agree and declare that you are intending to use or using the Services offered by the Company through Website, on your own volition, free will, without any undue influence, force or coercion, while in sound mind, and disposing capacity, and your being legally capable of contracting in law.`,
          `Use of the Website, and/or the Services and/or Registered User Services by any User, shall be solely at the discretion of the Company. The Company reserves its right to add, delete or discontinue its any Service or Website in part or whole.`,
          `The Company reserves, at its sole discretion, the right to refuse any User to access the Website or avail any Services, without assigning any reason`,
          `The Company also reserves, at its sole discretion, the right to block any User from creation of any User Account, or block any Registered User from accessing the Website or Services, without assigning any reason.   `,
        ],
      },
    ],
  },
  {
    title: '5. PLATFORM FOR CONNECTING USERS WITH PRODUCT OWNERS',
    content: [
      {
        desc: [
          `The Website is strictly a technology platform that the Company is providing to enable Users and the Product Owner to interact with each other, without any liability of the Company. The Website is a marketplace that allows for Product Owners to display, list and sell their products. While the Company facilitates transactions that are carried out on the Website, the Company is neither the buyer nor the seller of any product(s).`,
        ],
      },
      {
        title:
          'The Company is not and cannot be a party to or control in any manner any dealing between the Users and Product Owners, and the User hereby understands and accepts as under:',
        desc: [
          `The Company does not own any products displayed on the Website;`,
          `All or any of the Product Owners who may display products through our Website, are independent professionals and/or corporate entities/organisations who are providing services in their professional capacity.`,
          `The Company does not make any representations or warranties with respect to these Product Owners and/or the quality of the products/services they may provide. Accordingly, the Company shall not be held responsible for any interaction or associated issues between the User and the Product Owner.`,
        ],
      },
      {
        desc: [
          `The User hereby understands and agrees that the relationship between the User and the Product Owner, is and shall always be independently governed under Applicable Laws. The Company does not claim any control over any such specific, particular, individual or general rules and regulations and Applicable Laws;`,
        ],
      },
      {
        desc: [
          `There is and shall always be a separate and independent contractual relationship between the User, and the Product Owner, as the case may be, without any involvement or control or interference of the Company. The User shall note that the Company is not privy to any separate or independent Agreements that User has with any Product Owner, which independently govern their inter-se relationship, and shall be on a principal-to-principal basis between them.`,
        ],
      },
      {
        desc: [
          `The Website and its Services are accessible to several international Users and Product Owners. The Company may provide access to certain features and tools to such international buyers or sellers, such as currency conversion, customs duty estimations and other tax tools. It shall be the responsibility of such Users and Product Owners respectively to comply with all laws and regulations applicable to international sale, purchase and shipment of product(s).`,
        ],
      },
    ],
  },
  {
    title: '6. USER OBLIGATIONS, UNDERTAKINGS AND CONSENT WHEN USING THE WEBSITE',
    content: [
      {
        desc: [
          `Prior to availing any Registered User Services on the Website, you will have to register and become a Registered User. If you choose not to register, you shall be considered a Website Visitor and will not be able to avail of any Registered User Services`,
          `When a User registers on the Website, the User shall be required to create an account (“User Account”) by providing User Account Information and certain other information as may be required by the Company.`,
          `The verification of a User’s email shall be compulsory without which the Company will not create a User Account.`,
          `If the User registers on the Website and successfully gets a User Account, User shall be responsible for maintaining the confidentiality of the account, including display name and password of the account, and User shall be responsible for all activities that occur under User’s Account`,
          `The User hereby declares and verifies that all information provided by the User is true, accurate and genuine. User hereby agrees that in case any information provided by the User is not true or accurate or genuine or complete, or the Company has reasonable grounds to suspect that such information is false, misleading, untrue, inaccurate, not current or incomplete, or not in accordance with these Terms of Use, then the Company shall have the right to indefinitely suspend or terminate or block access of the User’s Account on the Website as set out herein`,
          `User hereby agrees, declares and warrants that the User is fully authorized and has taken all requisite approvals from any person on whose behalf (if any) User is acting on the Website`,
          `Users will ensure that the User is in compliance of all Applicable Laws, regulations and conventions.`,
          `User agrees that the User shall be solely responsible for all usage of the User’s Account and password.`,
          `User consents to immediately notify the Company of any actual or suspected unauthorized use or breach of the User’s Account. User hereby agrees that the Company shall not be liable for any direct or indirect damages, losses, costs, charges, expenses, etc. caused to User by any unauthorized use of User Account, and the User shall be liable for all damage and loss caused to the Company or others, due to such unauthorized use.`,
        ],
      },
      {
        title: 'User agrees and undertakes that the User will not or attempt to:',
        desc: [
          `impersonate any other User, person or entity,`,
          `misrepresent his affiliation with the Company (there being no such affiliation);`,
          `use the Website or Services in violation of any Applicable Laws;`,
          `obtain any Services from the Website, using his account for any person, except for himself, his family, and/or his children below 18 years of age.`,
          `use the Services, Website or System in any manner that exceeds the scope of the right to use the Website, System and Services.`,
          `use abusive, inappropriate or derogatory language while communicating with the representative of the Company through the Website.`,
        ],
      },
    ],
  },
  {
    title: '7. USER OBLIGATIONS, UNDERTAKINGS AND CONSENT WHEN BIDDING OR BUYING THE PRODUCT',
    content: [
      {
        desc: [
          `A Registered User may place a bid on a Product for sale/purchase of product(s) through the Registered User Services. The bid placed by the User must be higher than the ask price of the Product Owner at an interval of 0.50`,
          `A Registered User can view his own bid by checking the status. If the Registered Users bid is reflected in green, it would indicate that he is currently the highest bidder. If the Registered Users bid is reflected in black, it would indicate that there are similar bids to the one places by the Registered User and in such an event the winner will be decided on a first save basis, and if the Registered Users bid is reflected in red, it would indicate that there have been higher bids places by other Registered Users`,
          `A bid for product(s) that is/are a new arrival will be closed on the next day after its launch of the Website, at 4:00 pm CET. However, if the product is displayed on a Friday then bidding will be closed on Monday at 4:00 pm CET. Bid to buy will be closed on Monday at 9:00 am CET.`,
          `When a Registered User’s bid is accepted by the Product Owner, he will be notified by the Product Owner and the order will be generated. Accordingly, upon acceptance, a contract for these third party product(s) will be entered into between the Product Owner and Registered User.`,
          `Upon acceptance of a bid, the order is generated and an invoice will be raised by the Product Owner. All sales are final once the bid is accepted, and no refund/revision/cancellation will be permitted. The User will be obligated to purchase the Product for which his bid has been accepted.`,
          `If for any reason whatsoever, a User decides to cancel his order pursuant to the same having been accepted by the concerned Product Owner, the User may contact us and the concerned Product Owner through the Website. The Product Owner and/or KGK Online shall have the sole discretion to decide if the cancellation request should be accepted or not. In the event, the cancellation request is accepted, the User will be charged 10% of the bid amount as a cancellation fee and the remaining amount paid shall be refunded by the concerned Product Owner. Upon acceptance of a cancellation request, you acknowledge that the refund shall be made directly by the concerned Product Owner without any involvement and/or liability of the Company.`,
          `A Registered User may also activate the “buy now” function/feature when intending to bid/purchase product(s). If a Registered User uses the “buy now” feature, the Website will attempt to automatically match such Registered User with the Product Owner, and the Website will furnish the Registered User with a transaction id number and a confirmatory email.`,
          `A Registered User acknowledges that even pursuant to receipt of a confirmation e-mail sent from the Website, the same shall only be in respect of confirming receipt of the Registered User’s order and shall not be considered as an e-mail accepting the order. Basis availability of the selected Product, the order will be considered as accepted by the Product Owner once the order is generated and the invoice is raised`,
          `A Registered User acknowledges that the invoice displayed on the Website is an invoice raised by the Product Owner and not by the Company. The Company has made considerable effort toward providing guidelines to the Product Owner’s in respect of generating an invoice. However, in the event, the Registered User has any queries in respect of the invoice, he can immediately contact the sales representative and/or raise a query through the Website.`,
          `While placing a bid or buying a product(s), a Registered User must enter their preferred mode of payment and shipping company. As KGK Online Jewels cannot estimate the amount of time taken for the product to be delivered, we cannot guarantee a specific delivery timeframe for any items and therefore, a Registered User is not permitted to cancel the purchase if the product(s) does not ship from the Product Owner within a reasonable timeframe. If the Registered User does not receive a product(s) within the timeframe specified in the confirmation email, a Registered User shall promptly report the issue on the Website within 7 days of the latest estimated delivery date. KGK Jewels Online has the right, in our sole and absolute discretion to refuse to accept returns or other refunds and/or to charge restocking fees for returned or abandoned items.`,
        ],
      },
    ],
  },
  {
    title: '8. INFORMATION PROVIDED BY USER, DATA RETENTION, COMMUNICATION AND PRIVACY POLICY',
    content: [
      {
        desc: [
          `While the Company takes utmost care with respect to the security of the information you decide to upload, you understand that any information that you disclose on the Website, is at your own risk. By uploading / sharing / disclosing your information on the Website, you hereby give your consent to the Company to store such information on the Company’s servers.`,
          `User agrees that it is User’s responsibility to ensure and keep a copy of all data pertaining to the User account, in User’s own computer or any other place or in any other form.`,
          `User hereby declares that the User understands and agrees that the Company is not obliged to keep or preserve data of the User, and there may be hardware, System or network failure of the Website, including data storage facility of the Company, which may result in partial or total loss of data, and in case of such loss of data, the Company shall not be obliged or held responsible to retrieve or restore or provide a copy of the data to the User, with or without cost; and further the Company shall not be held responsible for any payment or compensation or damages to the User, for any such dat store or provide a copy of the data to the User, and it shall be the sole and exclusive responsibility of the User to keep a copy of the data, with respect to anything related to the User Account.`,
          `When you use the Website or access any material, information or Services through the Website, you agree and understand that you are communicating with us, through electronic medium and you consent to receive communications, through any mode of communication from Company, time to time, as and when required by Company, for any purpose.`,
          `By verifying the mobile number at the time of creation of the User Account and by registering as a Registered user on the Website, User consents to be contacted by Company via phone, and/or SMS notifications, WhatsApp, email or any other utility or social media platforms such as Facebook, Instagram etc., application, method or mode of communication, and authorizes the Company, to send any information or communication relating to the Services availed by the User from the Company or any concerned product owner, newsletter, promotional material, advertisements, or to contact for feedbacks, reviews, etc. or for any other purpose, including the communications relating to your registration, transactions that you carry out through the Website, reviews, feedbacks, and promotions that are undertaken by the Company. Further, the Company may also send notifications and reminders with respect to other Services that you have subscribed to on the Website. Please note that while the Company endeavours to provide these notifications and reminders to you promptly, the Company does not provide any guarantee and shall not be held liable or responsible for the failure to send such notifications or reminders to you`,
          `The Company is committed to maintaining the privacy of your personal information and has made considerable effort toward ensuring that your personal information is secure. You acknowledge and accept the terms of Our Privacy Policy which sets out the terms on which we retain and process any personal data and any other information we collect from you or that you provide to us. By using the Website, you consent to such processing and you confirm and warrant that all data provided by you is accurate.`,
        ],
      },
    ],
  },
  {
    title: '9. VIOLATIONS OF SYSTEM OR NETWORK SECURITY',
    content: [
      {
        desc: [
          `Subject to the terms of this Agreement, and any other agreement that the Company may have with the User, the User shall act in an honest, bonafide, and ethical manner at all times when the User accesses the Website or Uses any of the Services, and undertakes to provide all co-operation reasonably required by Company in relation to the Website and Services.`,
        ],
      },
      {
        title:
          'Any violation or breach of System or network security is prohibited and actionable, and any such act may result in the User facing criminal and civil liability, and being denied access to the Website. Violations and breach of Website, System or network security may include, but are not limited to, the following:',
        desc: [
          `introduction of any virus, logic bomb, harmful code and/or Trojan horse to the Website or Service;`,
          `flaming, hacking or otherwise obtaining unauthorized access to or use of data, systems or networks, including any attempt to probe, scan or test the vulnerability of a system or network`,
          `unauthorized monitoring of data or traffic on any network or system without express authorization of the Company of the system or network.`,
          `interfering with any User, host or network, including mail bombing, flooding, deliberate attempts to overload a system and broadcast attacks.`,
          `interference with any other users use and enjoyment of the Website or Service, or any other individuals’ use and enjoyment of similar services in any manner.`,
          `placing on the Website or Service, any misleading, defamatory, obscene, offensive or indecent material or material which breaches any intellectual property rights of others`,
          `reverse engineering, disassembling, decompiling, or translating any software or other components of the Website, System or Services;`,
          `any copyright violation of the Website or System;`,
          `transmitting any information (including job posts, messages and hyperlinks) on or through the Website that is disruptive or competitive to the provision of Services by the Company;`,
          `making any unsolicited communications to other Users;`,
          `framing or hot linking or deep linking any content made available by the Company.`,
        ],
      },
      {
        desc: [
          `User agrees not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Website or System, or any transaction being conducted on the Website or System, or with any other person’s use of the Website or System.`,
          `The Company reserves, and shall have all the rights to take necessary actions, at its sole discretion, including claiming damages that may occur due to User’s above acts or involvement/participation in any way on its own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services).`,
        ],
      },
    ],
  },
  {
    title: '10. PRODUCT QUALITY',
    content: [
      {
        desc: [
          `If a Registered User receives a product(s) that it believes to be counterfeit, then such Registered User may notify the Company in writing within 1 day after receiving the product(s), and the Company will commence an independent investigation into the veracity of the product(s). The Registered User shall cooperate with the Company in the investigation and final disposition of the product(s), including providing photographs and other evidence of the item, or delivering the item back to us, at our direction. Any decision made by the Company in this regard, shall be binding and final.`,
          `In the event the Company believes that a User and/or Registered User is attempting to interfere with the free and fair operation of the Website, or creates artificial, false or misleading information, or information appearing to mislead with respect to the quality of the Product upon delivery, then, without limiting any other rights of the Company, the Company reserves the right to do any or all of the following, in its sole discretion: (i) cancel any or all of the Registered User’s orders pending through the Website; (ii) place limits on a Registered User’s buying privileges; (iii) charge Users and/or Registered User for costs, expenses and fees incurred by the Company as a result of the User/Registered User’s actions; (iv) notify law enforcement of the fraudulent activity; (v) temporarily or permanently suspend the Registered User’s account, and (vi) charge the User/Registered User an additional service fee of up to US$ 1,000/-, to cover the Company’s investigation fees and other related costs and expenses.`,
        ],
      },
    ],
  },
  {
    title: '11. DISCLAIMER OF WARRANTIES, GUARANTEES AND REPRESENTATIONS',
    content: [
      {
        desc: [
          `User agrees that use of the Services is entirely at User's own risk, cost and consequences and the use of the Website and the Services by the User, shall be solely at the discretion of the Company. The Services and/or Registered User Services are provided on 'as it is' basis, without warranty, or guarantee of any kind, and/or any responsibility or liability either express or implied, or whether vicarious, or contingent`,
          `Whilst considerable effort has been taken to ensure that product details, descriptions, prices and other information hosted on this Website accurate and kept up to date and representative of the colour, design, cut, etc. of the original products, slight variations, distortions and/or differences may occur when compared to the original product. This may, for example, be due to technical issues such as your browser or computer settings. Accordingly, the Company cannot be held liable for any apparent differences in the product images represented on the Platform and the original products purchased from the Product Owner. Errors may sometimes occur in showcasing the pricing. Prices displayed on the Platform may change from time to time.`,
          `The Company reserves the right, at its sole discretion, to change, modify, add to, or remove any part of the Service or portions of these Terms of Use at any time without any prior written notice to the User, and any such change, modification, addition or removal (hereinafter referred as “Modifications”) shall be considered as a part of these Terms of Use. It is User’s responsibility to review these Terms of Use periodically for updates/changes. User’s continued use of and access to the Website following the Modifications will mean that the User accepts and agrees to the Modifications`,
          `Any right granted to the User to use the Services offered by the Company by its Website is personal, non-exclusive, and non-transferable and a limited and revocable (at the discretion of the Company) permission to use the Website and Services.`,
          `The Company does not make any representations or warranties with respect to any Product Owners or the quality of the product displayed by them, or service they it may provide to the User.`,
          `The Company disclaims all warranties including, but not limited to, the implied warranty of merchantability and suitability for any Services.`,
        ],
      },
      {
        title: 'Company also, does not warrant that',
        desc: [
          `this Website will be constantly available, or available at all;`,
          `any or all the Services or any other Services on the Website will be constantly available, or available at all;`,
          `User will be able to access his or her account at any or all times;`,
          `the information on this Website is complete, true, accurate or non-misleading`,
          `the quality of any Services, information, or other thing obtained by the User through the Website will meet the User’s expectations, need or purpose;`,
          `the User or any other person will be able to use any Services;`,
          `the feedbacks and reviews provided by the User will be published on the Website; and`,
          `the Website, information, content, materials, or Services included on or otherwise made available to the User through the Website; their servers; or electronic communication sent from the Company or the Website, are free of virus’s or any other harmful components.`,
        ],
      },
      {
        desc: [
          `The Company shall not be held liable for any interaction and associated issues, between Users and Product Owners. If you decide to engage with a Product Owner, you do so at your entire risk and liabilities in all respects. The Company shall not be liable for any reason whatsoever for the services provided by such Product Owner, and we bear no liability for the consequences to you, of your use of the System or Services or Registered User Services.`,
          `The Company assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect User’s equipment on account of User’s access to, use of, or browsing the Website or the downloading of any material, data, text, images, video content, or audio content from the Website. If a User is dissatisfied with the Website, User’s sole remedy is to discontinue using the Website.`,
          `To the fullest extent permitted by Applicable Law, the Company shall not be liable for any indirect, incidental, special or consequential damages of any kind arising out of or in connection with the use of information available from the Platform or any liability relating to any loss of use, interruption of business, lost profits or lost data, regardless of the form of action, whether in contract, tort (including negligence) or otherwise.`,
          `The Company shall not be liable for any loss of production, work, data, use, business, goodwill, reputation, revenue or profit, any diminution in value, costs of replacement goods or services, or any consequential, incidental, special or punitive damages or losses, including, but not limited to, lost profits, whether direct or indirect by your use of this Platform.`,
          `The Website acts as a commercial concierge service and marketplace to conclude the sale/purchase on behalf of each Product Owner and User involved in each transaction. Because Product Owner(s) set prices, they may be higher than retail value of the product(s) sold on our Website. Any item valuations displayed through the Website are estimates only. The Company does not guarantee that any item will sell and/or made available for purchase by Product Owner. The Company reserves the right to investigate complaints and violations of these Terms and, to the extent permitted by applicable law, may take any actions we deem appropriate, including suspending a Product Owner or User account and charging such Product Owner and/or User payment method for costs we incur as a result of the violation.”`,
        ],
      },
    ],
  },
  {
    title: '12. INTELLECTUAL PROPERTY',
    content: [
      {
        desc: [
          `The Website is held, controlled and operated by the Company, and all Intellectual Property Rights /IPR including copyright in the Website and Service solely and exclusively belongs to and owned by the Company.`,
          `All IPR protected material on the Website, are the intellectual property of the Company and / or of their respective owner(s). The IPR including the User Information that is collected by the Company from the Users and the Practitioner(s), subject to the privacy policy of the Company, shall be owned and controlled by the Company. Any other use of content on the Website including but not limited to the redistribution, modification, reproduction, transmission, performance, broadcast, publication, uploading, licensing, reverse engineering, transfer or sale of, or the creation of derivative works from, any material, information, software, products or services obtained from the Website, or use of the Website for purposes competitive to the Company and/or KGK Group, of part or all of the contents featured in the Website or Service in any form is prohibited and actionable. You are not permitted to distribute or commercially exploit the IPR or the User Information. Nothing in these Terms of Use grants the User any right in respect of Intellectual Property Rights of Company.`,
          `You may access, copy, download and print the material contained on the Website for your personal and non-commercial use, provided you do not modify or delete any copyright, trademark or other proprietary notice that appears on the material you access, copy, download or print.`,
          `You may not use contact information provided on the Website for unauthorized purposes, including marketing. You may not use any hardware or software intended to damage or interfere, interrupt with the proper working of the Website or to surreptitiously intercept any system, data or personal information from the Website. You agree to abide by all additional restrictions displayed on the Website as it may be updated from time to time.`,
        ],
      },
    ],
  },
  {
    title: '13. LINKS TO OTHER WEBSITES',
    content: [
      {
        title:
          'You have the right to see and/or access the personal information we hold about you. In addition, you have certain rights regarding your personal information, subject to local law. These include the following rights',
        desc: [
          `The Website may contain links to third-party websites or services that are not owned or controlled by Us.`,
          `We have no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.`,
        ],
      },
    ],
  },
  {
    title: '14. TERMINATION',
    content: [
      {
        desc: [
          `The Company reserves its right to add, delete or discontinue its any Service or Website in part or whole. We may limit, restrict, block, suspend or terminate your access or registration to the Website and/or its Services and/or Registered User Services if we believe you are in breach of our Terms and Conditions or applicable law, or for any other reason without notice or liability. We will revoke the Website use privileges of users who are repeat infringers of intellectual property rights. All provisions of the Terms and Conditions which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability. Termination of your access or use will not waive or affect any other right or relief to which the Company may be entitled at law or in equity.`,
          `User may deactivate the User Account and end the registration at any time, for any reason, by writing to us through the Contact Us form on the Website. The Company may suspend or terminate your use of the Website, your Account and/or registration for any reason at any time, at its sole discretion. The Company reserves the right to maintain, delete or destroy all communications and materials posted or uploaded to the Website pursuant to its internal record retention and/or content destruction policies. After such termination, the Company will have no further obligation towards the User.`,
        ],
      },
    ],
  },
  {
    title: '15. INDEMNIFICATION',
    content: [
      {
        desc: [
          `You agree to defend, indemnify, and hold harmless the Company, and its licensors, and service providers, and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms and Conditions or your use of the Website or Services, including, but not limited to, your use or any other person's accessing the Website using your account and/or your personal information, of the Website's content, services, and products other than as expressly authorized in these Terms and Conditions or your use of any information obtained from the Website or Services`,
          `User hereby undertakes to indemnify the Company for all losses, damage, costs, charges and expenses incurred as a result of User’s misrepresentations or fraudulent feedback that has adversely affected the Company or its Users, or has caused any direct or indirect loss to the Company.`,
        ],
      },
    ],
  },
  {
    title: '16. LIMITATION OF LIABILITY',
    content: [
      {
        desc: [
          `This paragraph shall apply to all content and functionality of the Company, Website, System, and Services.`,
          `While the Website helps facilitate transactions and acts as a marketplace, the Company is neither the buyer nor the seller of the Products. The Website provides a platform for Product Owners and Registered Users to interact, negotiate and complete transactions. Accordingly, the contract formed at the completion of a sale for these third party products is solely between the Product Owner and such Registered User. Although the Website might provide historical pricing data to the Product Owner and Registered User, the Company does not set prices for the product(s) and is not an auctioneer.`,
          `All product(s) purchased on the Website from Product Owners are fulfilled by such Product Owner directly and product(s) are kept in the Product Owner’s inventory. It is the responsibility of the Product Owner to assure timely shipping to the Registered User and in this regard, the Website and the Company shall not be liable for any shipping related disputes`,
          `Under no circumstances, including on account of any act, omission, commission or negligence, shall the Company including or anyone else involved in creating, producing or distributing the Services be liable for any direct, indirect, incidental, special or consequential damages that result from the use of or inability to use the Website, Services, or that results from mistakes, omissions, interruptions, deletion of files, errors, defects, delays in operation, or transmission or any failure of performance, whether or not limited to acts of God, communication failure, theft, destruction or unauthorized access to the Company records, programs or the Website, or the Services.`,
          `The Company under no circumstances are liable for any loss of production, work, data, use, business, goodwill, reputation, revenue or profit, any diminution in value, costs of replacement goods or services, or any consequential, incidental, special or punitive damages or losses, including, but not limited to, lost profits, whether direct or indirect by your use of this Website.`,
          `The Company shall not be liable for any breach of service or service deficiency, product related deficiencies by any Product Owner.`,
          `The Company shall not be liable for any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of record, in any way relating to or arising out of the use of the Website or Services.`,
          `Without prejudice to the generality of the above, the Company will never be liable (directly or vicariously) for any act or omission commission or negligence on part of the Product Owner.`,
          `Further, the Company shall not be liable, under any event, for any comments or feedback given by any of the Users in relation to the Services provided by the Product Owner. All such feedback should be made in accordance with applicable law. The option of Users to give feedback remains at the Company’s sole discretion and may be modified or withdrawn at its sole discretion. The Company may moderate such feedback at any time. The Company shall not be obliged to act in any manner to give effect to the content of Users’ feedback, such as suggestions for delisting of a particular Product Owner from the Website.`,
        ],
      },
    ],
  },
  {
    title: '17. GEOGRAPHICAL RESTRICTIONS',
    content: [
      {
        desc: [
          `The Company makes no representation that all products, Services and/or material described on the Website, or the Services available through the Website, are appropriate or available for use in locations.`,
        ],
      },
    ],
  },
  {
    title: '18. SEVERABILITY',
    content: [
      {
        desc: [
          `If any or part of this Agreement is declared invalid, unlawful or unenforceable, then such part will be severed. The remaining parts of this Agreement will continue to be valid and enforceable to the fullest extent permitted by applicable laws and regulations.`,
        ],
      },
    ],
  },
  {
    title: '19. WAIVER',
    content: [
      {
        desc: [
          `Any waiver by the Company of any breach of any term or condition of the Agreement by the User, shall not preclude the Company from enforcing any subsequent breach of that or any other term or condition and shall not be considered as a waiver of the subsequent breach also.`,
        ],
      },
    ],
  },
  {
    title: '20. ASSIGNMENT',
    content: [
      {
        desc: [
          `You hereby agree that the Company may assign this Agreement along with the Company, Website and Services at any time at its sole discretion, without the requirement of any consent from the User and the User also waives the right of any such requirement of consent, to any parent, subsidiary or affiliated company, or as part of the sale to, merger with, or other transfer or license of any kind, of the Company to another entity(s); and any such assignee or transferee or licensee shall automatically step in to the shoes of the Company for the purpose of this Agreement.`,
          `The User shall not be entitled to assign, transfer or sublicense these Terms of Use to anyone else and any attempt to do so in violation of this Agreement, and the same shall be null and void.`,
        ],
      },
    ],
  },
  {
    title: '21. APPLICABLE LAWS AND JURISDICTION',
    content: [
      {
        desc: [
          `These Terms and Conditions shall be governed by and construed in accordance with the laws applicable to Dubai Multi Commodities and Centre. Any dispute, controversy or claim arising out of or in relation to the Terms and Conditions, including the validity, invalidity, breach or termination thereof, shall be governed by laws of Dubai Multi Commodities and Centre.`,
        ],
      },
    ],
  },
  {
    title: '22. CONTACT US',
    content: [
      {
        desc: [
          `If you want to contact us to know more these Terms of Use, please contact us by post.`,
          `Postal Address<br/>
                Unit no. 1222, DMCC Business Centre,
                <br/>
                Level No. 1,
                <br/>
                Jewellery & Gemplex 3,
                <br/>
                Dubai, United Arab Emirates.`,
          `If you would like to contact us through email, please use enquiry@kgklive.com. We will endeavour to send you a response as soon as possible. Your patience is much appreciated during busy periods.`,
        ],
      },
    ],
  },
];
