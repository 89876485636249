import React from 'react';

import Heading from 'containers/Heading';
// import InputBlock from 'containers/InputBlock';
import SelectOption from 'containers/SelectOption';

const Rating = {
  Score: [
    { key: '1', name: '1,00,000 - 5,00,00 = 40%' },
    { key: '2', name: '5,00,000 - 10,00,00 = 60%' },
    { key: '3', name: '10,00,000 - 1,00,000,000 = 100%' },
  ],
  grade: [
    { key: '1', name: '0 - 3 = -AAA' },
    { key: '2', name: '3.1 - 4 = -AA' },
    { key: '3', name: '4.1 - 5 = -A' },
    { key: '4', name: '5.1 - 6 = A' },
    { key: '5', name: '6.1 - 7 = AA' },
    { key: '6', name: '7.1 - + = AAA' },
  ],
};
const AddRating = () => {
  return (
    <div>
      <Heading className="popupInnerTitle" title="Add Rating" />
      <SelectOption
        value="Range = Score"
        // onChange={(value) => setAssignee(value)}
        name="Range = Score"
        label="Inside Sales Volume"
        placeholder="Range = Score"
        selectValue={Rating.Score}
        nameKey="name"
        valueKey="id"
      />
      {/* <div className="d-flex justify-space-between">
                <div className="width-50">
                    <InputBlock title="Range" placeholder="Range"/>
                </div>
                <div className="mlr-20">=</div>
                <div className="width-50">
                    <InputBlock title="Score" placeholder="Score"/>
                </div>
            </div> */}
      {/* <div className="d-flex justify-space-between">
                <div className="width-50">
                    <InputBlock title="Range" placeholder="Range"/>
                </div>
                <div className="mlr-20">=</div>
                <div className="width-50">
                    <InputBlock title="Score" placeholder="Grade"/>
                </div>
            </div> */}
      <SelectOption
        value="Range = Grade"
        // onChange={(value) => setAssignee(value)}
        name="Range = Grade"
        label="Rating Master"
        placeholder="Range = Grade"
        selectValue={Rating.grade}
        nameKey="name"
        valueKey="id"
      />
      <div className="sidebarAction">
        <button className="commonButton">Add</button>
        <button className="commonOutine">Cancel</button>
      </div>
    </div>
  );
};
export default AddRating;
