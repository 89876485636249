import React, { useEffect, useState } from 'react';

import { DatePicker, Checkbox } from 'antd';
import { useFormik } from 'formik';
// import { Formik, Form, useFormik } from 'formik';
// import { cloneDeep, find, pick, values } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';

import FileUpload from 'containers/FileUpload';
import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import InputBlock from 'containers/InputBlock';
import Heading from 'containers/MainHeading';
import PrioritySelect, { PRIORITIES } from 'containers/PrioritySelect';
import SelectOption from 'containers/SelectOption';
import TextArea from 'containers/TextArea';

import PartyBuyerComponent from 'components/DiamondListing/PopupComponent/PartyBuyerComponent';
// import HoldDetailsCell from 'components/Transaction/Hold/HoldDetailsCell';
// import SelectCompany from 'components/User/SelectCompany';
// import SelectUser from 'components/User/SelectUser';

import { AddToDo, updateTask } from 'services/ActivityApi';
import * as SellerAPI from 'services/SellerApi';

import { useCompositeState } from 'util/hooks';
import { isArray, isEmpty } from 'util/utils';

import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

// import pdf from '../../assets/images/pdf.png';
// import { useEffectOnMount } from '../../util/hooks';

import '../../styles/client.less';

// import HoldDetailsCell from 'components/Transaction/Hold/HoldDetailsCell';

const SelectTODO = {
  Reminder: [
    { key: 5, value: '5 min before' },
    { key: 10, value: '10 min before' },
    { key: 60, value: '1 hour before' },
  ],
  RelatedTo: [
    { key: 1, value: 'Sales' },
    { key: 2, value: 'Offer' },
  ],
};

const AddTodo = ({ checked, type, onClose, details = {} }) => {
  // const [priority, setPriority] = React.useState(PRIORITIES.HIGH);
  // const [account, setAccount] = React.useState();

  const [state, setState] = useCompositeState({
    sellers: [],
    isReminder: false,
  });

  const getSalesPerson = React.useCallback(async () => {
    const request = { filter: { isActive: true }, isDeleted: false };
    const [err, res] = await SellerAPI.getSellerList(request);
    const success = !err && res?.code === 'OK';

    if (success && isArray(res.data)) {
      setState({
        sellers: res.data.map(({ id, firstName, lastName }) => ({
          key: id,
          value: `${firstName}${lastName !== '_' ? ' ' + lastName : ''}`,
        })),
      });
    }
  }, [setState]);

  // const updateTaskValue = React.useCallback(
  //   async (id, request) => {
  //     setState({ loading: true });
  //     return await updateTask(id, request);
  //     // const index = state?.data.findIndex((listItem) => listItem?.id === id);
  //     // if (res?.code === 'OK') {
  //     //   const redirectTo =
  //     //     !isEmpty(params?.status) &&
  //     //     state?.data[index]?.status !== params?.status &&
  //     //     ([TodoStatus.PENDING].includes(params?.status)
  //     //       ? 'pending'
  //     //       : [TodoStatus.IN_PROGRESS].includes(params?.status)
  //     //       ? 'inprogress'
  //     //       : [TodoStatus.COMPLETED, TodoStatus.DELETED].includes(params?.status)
  //     //       ? 'closed'
  //     //       : undefined);

  //     //   state.data[index] = { ...state?.data?.[index], ...params };
  //     //   setState({ data: [...state.data], loading: false });

  //     //   if (redirectTo) {
  //     //     history.push(`${PREFIX_URL_WITH_SLASH}/todo/kyc/${redirectTo}`);
  //     //   }
  //     // }
  //   },
  //   [setState, state?.data],
  // );

  React.useEffect(() => {
    getSalesPerson();
  }, [getSalesPerson]);

  React.useEffect(() => {
    setState({
      isReminder: details?.isReminder ?? false,
    });
    // setPriority(details.priority ?? PRIORITIES.HIGH);
  }, [details.priority, details?.isReminder, setState]);

  const initialValue = {
    title: undefined,
    description: undefined,
    userAccount: undefined,
    priority: PRIORITIES.HIGH,
    user: undefined,
    sellers: [],
    path: [],
    related: undefined,
    assign: [],
    dueDate: undefined,
    reminder: undefined,
    share: [],
    images: [],
    type: 'TASK',
  };
  const [syncData, setSyncData] = useState(undefined);

  useEffect(() => {
    if (details) {
      let clientList = [];
      if (details.partyId && details.customerId) {
        const client = {
          id: details?.partyId?.id,
          companyName: details?.partyId?.companyName,
          user: {
            id: details?.customerId?.id,
            name: details?.customerId?.name,
            firstName: details?.customerId?.firstName,
            lastName: details?.customerId?.lastName,
          },
        };
        clientList = [client];
      }
      const editValue = {
        title: details?.subject ?? undefined,
        description: details?.description ?? undefined,
        userAccount: details?.partyId?.id ?? undefined,
        priority: details?.priority ?? PRIORITIES.HIGH,
        user: details?.customerId?.id ?? undefined,
        sellers: [],
        client: clientList,
        path: [],
        related: details?.relatedTo?.toString() ?? undefined,
        assign: details?.assignTo?.map((data) => data.id) ?? [],
        dueDate: details?.dueDateTime ? moment(details?.dueDateTime) : undefined,
        isReminder: details?.isReminder ?? false,
        reminder: details?.reminderDatePeriod === '0' ? undefined : details?.reminderDatePeriod ?? undefined,
        share: details?.shareTo?.map((data) => data.id) ?? [],
        images: details?.attachments ?? [],
        type: 'TASK',
      };
      setSyncData(editValue);
    }
  }, []);

  useEffect(() => {
    if (checked) {
      const editValue = {
        ...initialValue,
        title: checked.map((data) => data.memoNo).toString() ?? undefined,
        related: '1' ?? undefined,
      };
      setSyncData(editValue);
    }
  }, []);
  const tempVal = {
    title: Yup.string().required(Messages.Todo.Title).nullable(),
    userAccount: Yup.string().required(Messages.userAccountSelect).nullable(),
    user: Yup.string().required(Messages.userSelect).nullable(),
    share: Yup.string().required(Messages.Todo.share).nullable(),
    dueDate: Yup.date().required('please enter reminder date & time').nullable(),
    related: Yup.string().required(Messages.Todo.ReltedTo).nullable(),
    assign: Yup.string().required('Please enter assigned member').nullable(),
    description: Yup.string().required(Messages.Todo.Description).nullable(),
    priority: Yup.string().required(Messages.Todo.priority).nullable(),
    images: Yup.array().required('Background Image is required'),
    reminder: Yup.mixed().when('isReminder', {
      is: (v) => v === true,
      then: (s) => s.required('please enter Remind  & Time'),
      otherwise: (s) => s.nullable(),
    }),
  };
  const validationSchema = Yup.object().shape(tempVal);
  const submitValue = async (values) => {
    setState({ loader: true });
    const request = {
      subject: values.title,
      partyId: values.userAccount,
      description: values.description,
      shareTo: values.share,
      relatedTo: values.related,
      isReminder: values.isReminder,
      reminderDateTime: values.reminder,
      reminderDatePeriod: values.isReminder ? parseInt(values.reminder) : 0,
      dueDateTime: values.dueDate,
      assignTo: values.assign,
      priority: values.priority,
      customerId: values.user,
      attachments: values.images,
      type: values.type,
    };

    const [, res] = isEmpty(details) ? await AddToDo(request) : await updateTask(details.id, request);
    if (res?.code === 'OK' && res?.data) {
      setState({ loader: false });
      OpenNotification({ type: 'success', title: res.message });
      onClose();
    }
    this.props.handleCancel();
    setState({ loader: false });
  };

  const formik = useFormik({
    initialValues: syncData || initialValue,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: submitValue,
  });

  const { handleBlur, handleSubmit, handleReset, setValues, touched, errors, values } = formik;

  // const [options] = React.useMemo(() => {
  //   const options = {
  //     userAccount: isEmpty(values?.user) ? undefined : !isEmpty(details.partyId) ? [details.partyId] : undefined,
  //     user: isEmpty(values?.userAccount)
  //       ? undefined
  //       : !isEmpty(account?.user?.id)
  //       ? [account?.user?.id]
  //       : !isEmpty(details.customerId)
  //       ? [details.customerId]
  //       : undefined,
  //   };
  //   return [options];
  // }, [account?.user?.id, details.customerId, details.partyId]);

  // useEffect(() => {
  //   setFieldValue('user', account?.user?.id);
  //   setFieldValue('userAccount', account?.userAccount?.id);
  // }, [account, setFieldValue]);
  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Heading
        title={`${isEmpty(details) ? 'Add' : 'Edit'} Task ${type === '1' ? '' : type === '2' ? '' : ''}`}
        className="mb-20 smallTitle p-0"
      />
      <GridHeightContainer subtractFrom=".mainHeadingTitle,.sidebarAction" adjustment={-38}>
        {/* <SelectCompany
          value={values.userAccount}
          onChange={(value, _account) => {
            setFieldValue('userAccount', value);
            setAccount(_account);
          }}
          error={touched?.userAccount && errors?.userAccount}
          options={options?.userAccount}
        />
        <SelectUser
          account={values?.userAccount}
          value={values.user}
          onChange={(value, _account) => {
            setFieldValue('user', value);
            setAccount(_account);
          }}
          error={touched?.user && errors?.user}
          options={options?.user}
        /> */}
        <PartyBuyerComponent
          // onBlur={handleBlur}
          setUserDetails={(data) => {
            setValues((oldValues) => ({
              ...oldValues,
              userAccount: data?.userAccount,
              user: data?.user,
            }));
          }}
          error={(touched?.useAccount || touched?.user) && errors}
          initialData={{ ...values }}
        />
        <InputBlock
          name="title"
          placeholder="Title"
          label="Title *"
          value={values?.title}
          onChange={(e) =>
            setValues((oldValues) => ({
              ...oldValues,
              title: e.target.value,
            }))
          }
          onBlur={handleBlur}
          error={touched?.title && errors?.title}
        />
        <SelectOption
          value={values.related}
          onChange={(value) => {
            setValues((oldValues) => ({
              ...oldValues,
              related: value,
            }));
            // setState({ share: value });
          }}
          name="related"
          label="Select Related To *"
          placeholder="Select Member"
          selectValue={SelectTODO.RelatedTo}
          // onBlur={handleBlur}
          error={touched?.related && errors?.related}
          // nameKey="name"
          // valueKey="id"
          showSearch
          // className="multiSelect w-100"
        />
        <SelectOption
          value={values.share}
          onChange={(value) => {
            setValues((oldValues) => ({
              ...oldValues,
              share: value,
            }));
            // setState({ share: value });
          }}
          name="share"
          label="Share To *"
          placeholder="Select Member"
          selectValue={state.sellers}
          // onBlur={handleBlur}
          error={touched?.share && errors?.share}
          // nameKey="name"
          // valueKey="id"
          showSearch
          mode="multiple"
          className="multiSelect w-100"
        />
        <SelectOption
          value={values.assign}
          onChange={(value) => {
            setValues((oldValues) => ({
              ...oldValues,
              assign: value,
            }));
            // setState({ assign: value });
          }}
          name="assign"
          label="Assign To *"
          placeholder="Select Member"
          selectValue={state.sellers}
          // onBlur={handleBlur}
          error={touched?.assign && errors?.assign}
          // nameKey="name"
          // valueKey="id"
          showSearch
          mode="multiple"
          className="multiSelect w-100"
        />
        <TextArea
          name="description"
          value={values.description}
          onChange={(e) => {
            setValues((oldValues) => ({
              ...oldValues,
              description: e.target.value,
            }));
          }}
          label="Description *"
          placeholder="Description"
          // onBlur={handleBlur}
          error={touched?.description && errors?.description}
        />
        <div className="from-group inputBlock mb-15">
          <label className="commonLabel">Select Due Date & Time *</label>
          <DatePicker
            value={values.dueDate}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={(value) =>
              setValues((oldValues) => ({
                ...oldValues,
                dueDate: value,
              }))
            }
            disabledDate={(current) => current && current < moment().startOf('day')}
            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            // onBlur={handleBlur}
            error={touched?.dueDate && errors?.dueDate}
          />
          {touched?.dueDate && <div className="error">{errors?.dueDate}</div>}
        </div>
        <div className="from-group setPriorityBox mb-15">
          <label className="commonLabel">Set Priority</label>
          <PrioritySelect
            priority={values?.priority}
            // setPriority={(value) => {
            //   setValues((oldValues) => ({
            //     ...oldValues,
            //     priority: value,
            //   }));
            // }}
            // onBlur={handleBlur}
            error={touched?.priority && errors?.priority}
          />
        </div>
        <div className="mb-10">
          <Checkbox
            checked={values.isReminder}
            onChange={(e) => {
              setValues((values) => ({ ...values, isReminder: e.target.checked }));
              setState({ isReminder: e.target.checked });
            }}
          />
          <span className="ml-5">Set Reminder</span>
        </div>
        <div>
          {values.isReminder && (
            <>
              <SelectOption
                value={values.reminder}
                onChange={(value) => {
                  setValues((oldValues) => ({
                    ...oldValues,
                    reminder: value,
                  }));
                  // setState({ share: value });
                }}
                name="reminder"
                label="Select Reminder Time *"
                placeholder="Select Member"
                selectValue={SelectTODO.Reminder}
                // onBlur={handleBlur}
                showSearch
                // error={touched?.reminder && <div className="error">{errors?.reminder}</div>}
                error={touched?.reminder && errors?.reminder}
              />
            </>
          )}
        </div>

        <FileUpload
          fullWidth="width-auto"
          name="images"
          isValidate
          multiple
          label="Choose File"
          accept="Attachments JPG JPEG"
          size="JPG,JPEG file allowed"
          getData={(value) =>
            setValues((oldValues) => ({
              ...oldValues,
              path: value.absolutePath,
            }))
          }
          files={values?.images}
          onChange={(images) => setValues((values) => ({ ...values, images: images || '' }))}
          // onBlur={handleBlur}
          error={touched?.images && errors?.images}
        />
      </GridHeightContainer>
      <div className="sidebarAction">
        <button className="commonButton" type="submit">
          {isEmpty(details) ? 'Create' : 'Update'} Task
        </button>
        <button className="commonOutine" type="reset" onClick={onClose}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddTodo;
