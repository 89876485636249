import React from 'react';
import { TabList, Tab, Tabs } from 'react-tabs';

import { Select } from 'antd';
import find from 'lodash/find';

import SearchWithSelect from 'containers/SearchWithSelect';

import Permission from 'components/User/Permission';

import { getMastersList } from 'services/SubMasterApi';

import { isEmpty } from 'util/utils';

class RolePermission extends React.Component {
  state = {
    list: [],
    parentId: null,
    loading: false,
    role: '',
    tab: 0,
    selectedCode: null,
    selectedGroup: null,
    selectedIndex: 0,
  };

  componentDidMount() {
    this.getMastersList();
  }

  getMastersList = async () => {
    const [err, res] = await getMastersList({
      isOnlyParents: true,
      isDeleted: false,
    });
    if (!err && res?.code === 'OK' && res?.data?.length) {
      this.setState(
        {
          parentId: find(res.data, { code: 'USER_ROLES' }) ? find(res.data, { code: 'USER_ROLES' }).id : null,
        },
        () => this.getSubMastersList(),
      );
    }
  };

  getSubMastersList = async (search = '') => {
    if ((search?.length > 2 || !search) && !isEmpty(this.state.parentId)) {
      this.setState({ loading: true });
      const obj = {
        parentId: this.state.parentId,
        isDeleted: false,
        filter: {},
      };
      if (search) {
        obj.search = { keyword: search, keys: ['name', 'code'] };
      }
      const [err, res] = await getMastersList(obj);

      if (!err && res?.code === 'OK' && res?.data?.length) {
        this.setState({ list: res.data, loading: false, role: res.data?.[0]?.id ?? '' }, () =>
          this.handleRole(res.data?.[0]?.id ?? ''),
        );
      } else {
        this.setState({ list: [], loading: false });
      }
    }
  };

  handleClickRet = (tab) => () => this.setState({ tab });

  handleRole = (role = '') => {
    const selected = find(this.state.list, { id: role });
    this.setState({
      role,
      selectedCode: selected?.code,
      selectedGroup: selected?.group,
      selectedIndex: 0,
      tab: 0,
    });
  };

  render() {
    const tabs = [''].includes(this.state.selectedGroup);
    return (
      <div className="configurationInnerSubTb submasterTab">
        <div className="subMasterHeader">
          <SearchWithSelect placeholder="Search User Role" value={this.state.role} onChange={this.handleRole}>
            {this.state?.list?.map((item) => (
              <Select.Option value={item.id} key={item.id} selected>
                {item.name}
              </Select.Option>
            ))}
          </SearchWithSelect>
        </div>
        {tabs && (
          <Tabs
            className="rightStickyTab"
            selectedIndex={['ADMIN', 'SELLER'].includes(this.state.selectedGroup) ? this.state.tab : 0}
          >
            <TabList className="customerMaintab">
              <Tab onClick={this.handleClickRet(0)}>Admin Website</Tab>
              <Tab onClick={this.handleClickRet(1)}>Customer Website</Tab>
              {/* {['ADMIN', 'SELLER'].includes(this.state.selectedCode) && (
              )}
              {['SELLER', 'INTERNATIONAL_REG', 'INDIVIDUAL_REG', 'CUSTOMER', 'GUEST'].includes(
                this.state.selectedCode,
              ) &&
              } */}
            </TabList>
          </Tabs>
        )}
        <div className="configurationWrapper pt-0">
          <Permission
            rolePermission
            role={this.state.role}
            tabs={['CUSTOMER', 'SELLER'].includes(this.state.selectedGroup) ? true : tabs}
            tab={['ADMIN', 'SELLER'].includes(this.state.selectedGroup) ? 0 : 1}
          />
        </div>
      </div>
    );
  }
}

export default RolePermission;
