import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Checkbox } from 'antd';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import InputBlock from 'containers/InputBlock';
import MainHeading from 'containers/MainHeading';
import SelectOption from 'containers/SelectOption';

import { showNotification } from 'services/NotificationService';
import * as MasterApi from 'services/SubMasterApi';
import * as UserApi from 'services/UserApi';

import { isEmpty, isFunction } from 'util/utils';

import { USERS_TYPE } from 'constants/Common';

import { TabActions } from 'appRedux/reducers/TabIssueReducer/TabReducer';

import BottomStickyButton from './BottomStickButton';

export const user = {
  Role: [
    { key: 1, name: 'Dashboard' },
    { key: 2, name: 'Diamond Search' },
    { key: 3, name: 'Dashboard' },
    { key: 4, name: 'Dashboard' },
    { key: 5, name: 'Dashboard' },
    { key: 6, name: 'Dashboard' },
    { key: 7, name: 'Dashboard' },
    { key: 8, name: 'Dashboard' },
    { key: 9, name: 'Dashboard' },
  ],
};
class UserDrawer extends Component {
  state = {
    roleList: [],
    errors: {},
  };

  initialValues = {
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    email: '',
    mobile: '',
    type: undefined,
    isActive: true,
    roles: [],
    adminRoles: [],
  };
  getInitialValues = () => {
    const { update } = this.props;
    if (update?.id) this.initialValues.password = undefined;
    return Object.fromEntries(
      Object.entries(this.initialValues).map(([key, value]) => [key, isEmpty(update?.[key]) ? value : update?.[key]]),
    );
  };

  componentDidMount() {
    this.masterList();
  }

  async masterList() {
    const [, res] = await MasterApi.getMastersList({
      filter: {
        parentCode: 'USER_ROLES',
        group: ['ADMIN', 'SELLER', 'CUSTOMER'],
        isActive: true,
      },
    });

    if (res && res.data && res.data.length) {
      this.setState({
        roleList: res.data,
      });
    }
  }

  /**
   * Function used to handel submit
   * @param {*} values
   * @param {*} param1
   */
  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { update } = this.props;

    const data = {
      ...values,
    };
    setSubmitting(true);

    const [err, res] = await (!isEmpty(update?.id)
      ? UserApi.updateUserDetail(update.id, data)
      : UserApi.storeUserDetail(data));

    setSubmitting(false);

    if (!err && res && res.code === 'OK') {
      resetForm();
      this.closeDrawer();
    }

    if (!isEmpty(update)) this.userStoreUpdate(res?.data?.[0]);

    !err && showNotification(res);
  };

  userStoreUpdate = (user) => {
    let list = this.props?.userData?.data;
    list = list?.map((item) => {
      if (item?.id === user?.id) {
        item = user;
      }
      return item;
    });

    this.props.setUserList({ ...this.props.userData, data: list, userData: user });
  };

  closeDrawer = () => {
    isFunction(this.props.closeDrawer) && this.props.closeDrawer();
  };

  render() {
    return (
      <>
        <MainHeading className="mb-10" title={this.props?.update?.id ? 'Edit User' : 'Add User'} />

        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange
          validationSchema={Yup.object().shape({
            firstName: Yup.string().trim().required('First name is required').typeError('First name must be a string'),
            lastName: Yup.string().required('Last name is required').typeError('Last name must be a string'),
            username: Yup.string().required('Username is required'),
            email: Yup.string().email('Invalid email').required('Email is required'),
            password: Yup.string()
              .when('id', {
                is: () => isEmpty(this.props.update),
                then: Yup.string().required('Password is required'),
              })
              .min(6, 'At least six character required'),
            mobile: Yup.string().required('Mobile is required').matches(/^\d+$/, 'Invalid mobile number'),
            // type: Yup.number()
            //   .oneOf([USERS_TYPE.ADMIN, USERS_TYPE.SELLER, USERS_TYPE.MASTER_SALES])
            //   .required('Please select type'),
          })}
          onSubmit={this.handleSubmit}
        >
          {({ handleChange, handleBlur, setFieldValue, setFieldTouched, touched, errors, values }) => (
            <Form>
              <div className="p-15">
                <div className="userListBlockWrapper">
                  <InputBlock
                    label="First Name *"
                    id="firstName"
                    name="firstName"
                    tabIndex="1"
                    placeholder="First Name"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched?.firstName && errors?.firstName}
                  />
                  <InputBlock
                    label="Last Name *"
                    placeholder="Last Name"
                    id="lastName"
                    name="lastName"
                    tabIndex="2"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched?.lastName && errors?.lastName}
                  />
                  <InputBlock
                    label="Username *"
                    placeholder="Username"
                    id="username"
                    name="username"
                    tabIndex="3"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched?.username && errors?.username}
                  />
                  {isEmpty(this.props.update?.id) && (
                    <InputBlock
                      type="password"
                      label="Password *"
                      placeholder="Password"
                      id="password"
                      name="password"
                      tabIndex="4"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched?.password && errors?.password}
                    />
                  )}
                  <InputBlock
                    label="Mobile *"
                    placeholder="Mobile"
                    id="mobile"
                    name="mobile"
                    tabIndex="5"
                    onKeyPress={(evt) => {
                      if (
                        evt.which !== 8 &&
                        evt.which !== 0 &&
                        (evt.which < 48 || evt.which > 57) &&
                        evt.which !== 45
                      ) {
                        evt.preventDefault();
                      }
                    }}
                    value={values.mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched?.mobile && errors?.mobile}
                  />
                  <InputBlock
                    label="Email *"
                    placeholder="Email"
                    id="email"
                    type="email"
                    name="email"
                    tabIndex="6"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched?.email && errors?.email}
                  />
                  <SelectOption
                    placeholder="Select Type"
                    label="Type"
                    tabIndex="7"
                    selectValue={Object.entries(USERS_TYPE)
                      .filter((item) => item[0] === 'ADMIN' || item[0] === 'SELLER')
                      .map(([value, key]) => ({
                        key,
                        value: value === 'SELLER' ? 'SALES PERSON' : value,
                      }))}
                    value={
                      [USERS_TYPE.ADMIN, USERS_TYPE.SELLER].filter((item) => item === values.type)?.length
                        ? `${values.type}`
                        : undefined
                    }
                    onChange={(value) => {
                      let valueInNumeric = parseInt(value, 10);
                      setFieldValue('type', valueInNumeric);
                      valueInNumeric === USERS_TYPE.ADMIN && setFieldValue('roles', []);
                    }}
                    onBlur={() => setFieldTouched('type', true)}
                    error={touched?.type && errors?.type}
                  />
                  <SelectOption
                    placeholder="Select User Role"
                    label="User Role (Customer Website)"
                    tabIndex="8"
                    selectValue={this.state.roleList.filter((item) => {
                      const roleList = values?.type === USERS_TYPE.ADMIN ? ['ADMIN'] : ['CUSTOMER'];
                      return roleList.includes(item.group);
                    })}
                    value={values.roles[0]}
                    valueKey="id"
                    nameKey="name"
                    onChange={(value) => {
                      setFieldValue('roles', value.split());
                    }}
                    onBlur={() => setFieldTouched('roles', true)}
                    error={touched?.roles && errors?.roles}
                    disabled={values?.type === USERS_TYPE.ADMIN}
                  />
                  <SelectOption
                    placeholder="Select User Roles"
                    label="User Role (Admin Website)"
                    tabIndex="9"
                    className="multiSelect"
                    selectValue={this.state.roleList.filter((item) => {
                      const roleList = values?.type === USERS_TYPE.ADMIN ? ['ADMIN'] : ['SELLER'];
                      return roleList.includes(item.group);
                    })}
                    value={values.adminRoles[0]}
                    valueKey="id"
                    nameKey="name"
                    onChange={(value) => {
                      setFieldValue('adminRoles', value.split());
                    }}
                    onBlur={() => setFieldTouched('adminRoles', true)}
                    error={touched?.adminRoles && errors?.adminRoles}
                  />

                  <div className="checkBoxForm">
                    <label>Active</label>
                    <Checkbox
                      tabIndex="10"
                      checked={Boolean(values.isActive)}
                      onChange={() => setFieldValue('isActive', !values.isActive)}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <SelectOption
                    // value={assignee}
                    // onChange={(value) => setAssignee(value)}
                    name="role"
                    label="Add Role"
                    placeholder="Select Role"
                    selectValue={user.Role}
                    nameKey="name"
                    valueKey="id"
                    showSearch
                    mode="multiple"
                    className="multiSelect"
                  />
                  <a href="/kgk/setting/permission" className="commonButton ml-10">
                    Add Custom Role
                  </a>
                </div>
                {/* <SelectOption defaultValue="Select Country" selectValue={ManageParameters.country} label="Country"/> */}
              </div>
              <BottomStickyButton>
                <button tabIndex="9" className="commonButton" type="submit">
                  Save
                </button>
                {this.props?.update?.id ? (
                  <button tabIndex="10" className="commonOutine" onClick={this.closeDrawer} type="button">
                    Cancel
                  </button>
                ) : (
                  <button tabIndex="10" className="commonOutine" type="reset">
                    Reset
                  </button>
                )}
              </BottomStickyButton>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default connect(
  ({ tab }) => ({
    userData: tab.userList,
  }),
  {
    setUserList: TabActions.setUserList,
  },
)(UserDrawer);
