import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Checkbox } from 'antd';
import { Formik, Form } from 'formik';
import _findIndex from 'lodash/findIndex';
import { DateTime } from 'luxon';
import * as Yup from 'yup';

// import DisableBlock from 'containers/DisableBlock';
// import Heading from 'containers/MainHeading';
// import InputBlock from 'containers/InputBlock';
import { InputNumber } from 'containers/InputBlock';
import SelectOption from 'containers/SelectOption';

import BottomStickyButton from 'components/ShortCuts/BottomStickButton';
// import VolumeDiscount from './VolumeDiscount';
import AccountDropdown from 'components/User/AccountDropdown';

// import * as SellerAPI from 'services/SellerApi';
import Storage from 'services/storage';

import { isArray, isEmpty, isObject, isString } from 'util/utils';

import { KYC_STATUS } from 'constants/Common';

let groups = Storage.get('dn-master')?.COMPANY_GROUP?.map((item) => ({ ...item, id: item.id[0] }));

class Setting extends Component {
  state = { add: false, add1: false, disable: false };

  componentDidMount() {
    groups = Storage.get('dn-master')?.COMPANY_GROUP?.map((item) => ({ ...item, id: item.id[0] }));
    this.getCompanyGroups();
    // this.getSalesPerson();
  }

  getCompanyGroups = async () => {};

  // getSalesPerson = async () => {
  //   let request = { filter: { isActive: true }, isDeleted: false };
  //   const [err, res] = await SellerAPI.getSellerList(request);
  //   const success = !err && res?.code === 'OK';

  //   if (success && isArray(res.data)) {
  //     this.setState({
  //       sellers: res.data.map(({ id, firstName, lastName }) => ({
  //         id,
  //         name: `${firstName}${lastName !== '_' ? ' ' + lastName : ''}`,
  //       })),
  //     });
  //   }
  // };

  AddBlock = () => this.setState({ add: true });

  AddBlock1 = () => this.setState({ add1: true });

  hidePrice = () => this.setState({ disable: !this.state.disable });

  initialValues = {
    isActive: false,
    isSpam: false,
    isApproved: 1,
    isVerified: false,
    // seller: undefined,
    broker: undefined,
    group: undefined,
    crdLmt: undefined,
  };

  getInitialValues = () => {
    const account = { ...this.props.account };

    account.isVerified = account.isVerified === 2 ? true : false;
    const groups = account?.group || [];
    const groupIndex = _findIndex(groups, { isActive: true });
    account.group = groupIndex > -1 ? groups[groupIndex].id : '';
    // assign Ids to prepopulated fields
    const populatedFieldNames = ['country', 'state', 'city'];
    populatedFieldNames.forEach((fieldName) => (account[fieldName] = account?.[fieldName]?.id));

    return Object.fromEntries(
      Object.entries(this.initialValues).map(([key, value]) => [key, isEmpty(account?.[key]) ? value : account?.[key]]),
    );
  };

  submitForm = async (values, { setSubmitting, resetForm }) => {
    const { account } = this.props;

    const groups = account?.group || [];
    const groupIndex = _findIndex(groups, { isActive: true });
    const groupId = groupIndex > -1 ? groups[groupIndex].id : '';

    let group = [];
    if (values?.group !== groupId) {
      group = (!isString(account?.group) && !isEmpty(account?.group) ? account?.group : [])?.map((item) => ({
        ...item,
        isActive: false,
      }));
      group.push({ id: values?.group, time: DateTime.local(), isActive: true });
    } else {
      group = groups;
    }
    if (account.isApproved !== Number(values?.isApproved) && isArray(account?.kyc) && !isEmpty(account?.kyc)) {
      values.kyc = account.kyc.map((item) => {
        item.status = Number(values?.isApproved);
        return item;
      });
    }

    if (values.isSpam === true) values.isActive = false;

    values.isVerified = values.isVerified ? 2 : 1;
    values.broker = isObject(values.broker) ? values?.broker?.id : values.broker;

    setSubmitting(true);
    const [err, res] = await (isEmpty(account?.id)
      ? this.props.createAccount({ ...values, group })
      : this.props.updateAccount(account?.id, { ...values, group }));
    setSubmitting(false);

    if (!isEmpty(account?.id) && this.props?.filter?.isActive === 'spam' && values.isSpam !== account.isSpam) {
      this.props.getClientList();
    }

    if (!err && res?.code === 'OK')
      if (this.state.saveContinue) {
        this.props.updateSubTabIndex();
      } else {
        resetForm();
        this.props.onCancel();
      }
  };

  validationSchema = () =>
    Yup.object().shape({
      isActive: Yup.boolean(),
      isSpam: Yup.boolean(),
      isVerified: Yup.boolean(),
      // seller: Yup.string().required('Please select sales person'),
      group: Yup.string().required('Please select group'),
    });

  render() {
    // const { sellers } = this.state;

    return (
      <Formik
        enableReinitialize
        initialValues={this.getInitialValues()}
        validationSchema={this.validationSchema}
        validateOnChange
        onSubmit={this.submitForm}
      >
        {({ submitForm, setFieldValue, setFieldTouched, setValues, touched, errors, values }) => (
          <Form>
            <div className="kycFormMainBlock">
              <div className="d-flex align-items-normal">
                <div className="KycSettingLeft">
                  <div className="kycBlockWrapper twoBlock mb-20">
                    {/* <SelectOption
                      value={sellers?.filter((item) => item.id === values?.seller).length ? values?.seller : undefined}
                      onChange={(value) => setFieldValue('seller', value)}
                      onBlur={() => setFieldTouched('seller', true)}
                      name="seller"
                      error={errors?.seller}
                      label="Sales Person *"
                      placeholder="Select Sales Person"
                      selectValue={sellers}
                      nameKey="name"
                      valueKey="id"
                      showSearch
                    /> */}
                    <SelectOption
                      value={groups?.filter((item) => item.id === values?.group).length ? values?.group : undefined}
                      onChange={(value) => {
                        setFieldValue('group', value);
                      }}
                      onBlur={() => setFieldTouched('group', true)}
                      name="group"
                      error={errors?.group}
                      label="Group *"
                      placeholder="Select Group"
                      selectValue={groups}
                      nameKey="name"
                      valueKey="id"
                      showSearch
                    />
                    <AccountDropdown
                      initialValue={values?.broker}
                      value={values?.broker?.id}
                      placeholder="Broker"
                      label={`Broker ${
                        this.props?.account.hasBroker ? '(' + this.props?.account?.brokerInfo?.brokerName + ')' : ''
                      }`}
                      name="broker"
                      onChange={(broker) => setValues((values) => ({ ...values, broker }))}
                      ledgerType="broker"
                    />
                    <SelectOption
                      value={
                        KYC_STATUS?.filter((item) => item.key === values?.isApproved).length
                          ? `${values?.isApproved}`
                          : undefined
                      }
                      onChange={(value) => setFieldValue('isApproved', value || '1')}
                      onBlur={() => setFieldTouched('isApproved', true)}
                      name="isApproved"
                      error={touched?.isApproved && errors?.isApproved}
                      label="KYC Approve"
                      selectValue={KYC_STATUS}
                      disabled={values?.isSpam === true}
                      showSearch
                    />
                    <InputNumber
                      name="crdLmt"
                      label="Credit Limit"
                      placeholder="Credit Limit"
                      type="text"
                      value={values?.crdLmt}
                      onChange={(value) => setFieldValue('crdLmt', value ?? 0)}
                      onBlur={() => setFieldTouched('crdLmt', true)}
                      error={touched?.crdLmt && errors?.crdLmt}
                    />
                    <div className="checkBoxForm">
                      <label>Spam</label>
                      <Checkbox
                        onChange={(e) => {
                          e.target.checked
                            ? setValues((values) => ({ ...values, isActive: false, isSpam: !values?.isSpam }))
                            : setValues((values) => ({ ...values, isSpam: !values?.isSpam }));
                        }}
                        checked={values?.isSpam}
                      />
                    </div>
                    <div className="checkBoxForm">
                      <label>Verified</label>
                      <Checkbox
                        onChange={() => setFieldValue('isVerified', !values?.isVerified)}
                        checked={values?.isVerified}
                        disabled={values?.isSpam === true}
                      />
                    </div>
                    <div className="checkBoxForm">
                      <label>Active</label>
                      <Checkbox
                        onChange={() => setFieldValue('isActive', !values?.isActive)}
                        checked={values?.isActive}
                        disabled={values?.isSpam === true}
                      />
                    </div>
                    {/* {this.state.disable ? (
                      <DisableBlock className="width-50">
                        <InputBlock className="width-100" label="Mark Up %" placeholder="Mark Up %" tag="%" plusMin />
                      </DisableBlock>
                    ) : (
                      <InputBlock label="Mark Up %" placeholder="Mark Up %" tag="%" plusMin />
                    )}
                    <SelectOption defaultValue="Select Terms" selectValue={setting.terms} label="Terms" />
                    <InputBlock label="Credit Limit" placeholder="Credit Limit" tag="$" /> */}
                  </div>
                  {/* <div className="kycBlockWrapper twoBlock formMainSubBlock mb-20 pb-0">
                    <SelectOption
                      defaultValue="Select Broker Name"
                      selectValue={setting.brokername}
                      label="Broker Name"
                    />
                    <InputBlock label="Broker %" placeholder="Broker %" />
                    {this.state.add && (
                      <>
                        <SelectOption
                          defaultValue="Select Broker Name"
                          selectValue={setting.brokername}
                          label="Broker Name"
                        />
                        <InputBlock label="Brokerage %" placeholder="Brokerage %" />
                      </>
                    )}
                  </div> */}

                  {/* <div className="d-flex j-flex-end align-items-end kycNewBlockAdd">
                    <button className="commonButton" onClick={this.AddBlock}>
                      Add
                    </button>
                  </div> */}
                  {/* <div className="kycBlockWrapper twoBlock formMainSubBlock mb-20 pb-0">
                    <SelectOption
                      defaultValue="Select Aadat Party"
                      selectValue={setting.brokername}
                      label="Aadat Party"
                    />
                    <InputBlock label="Aadat %" placeholder="Aadat %" />
                    {this.state.add1 && (
                      <>
                        <SelectOption
                          defaultValue="Select Aadat Party"
                          selectValue={setting.brokername}
                          label="Aadat Party"
                        />
                        <InputBlock label="Aadat %" placeholder="Aadat %" />
                      </>
                    )}
                  </div> */}
                  {/* <div className="d-flex j-flex-end align-items-end kycNewBlockAdd">
                    <button className="commonButton" onClick={this.AddBlock1}>
                      Add
                    </button>
                  </div> */}
                </div>
                <div className="KycSettingRight">
                  {/* <div className="kycSettingRightSticky">
                    <div className="mb-20">
                      <Checkbox onClick={this.hidePrice}>Hide Price For a Customer</Checkbox>
                    </div>
                    {this.state.disable ? (
                      <DisableBlock>
                        <Heading className="smallTitle mb-10 p-0" title="volume Discount" />
                        <VolumeDiscount />
                      </DisableBlock>
                    ) : (
                      <>
                        <Heading className="smallTitle mb-10 p-0" title="volume Discount" />
                        <VolumeDiscount />
                      </>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
            <BottomStickyButton className={!this.props?.account?.id && 'disableBlock'}>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: true }, submitForm)}
              >
                Save & Continue
              </button>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: false }, submitForm)}
              >
                Save
              </button>
              <button className="commonOutine" type="reset" onClick={this.props.onCancel}>
                Cancel
              </button>
            </BottomStickyButton>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(
  ({ accountFilter }) => ({
    filter: accountFilter,
  }),
  {},
)(Setting);
