import React from 'react';

import DiamondTotalCard from 'components/DiamondListing/DiamondTotalCard';

import { isArray, isEmpty } from 'util/utils';

const RowGroupTotal = React.memo(({ row, column, total }) => {
  if (!total.includes(column.id)) return <span>&nbsp;</span>;
  return <DiamondTotalCard row={row} col={column?.id} />;
});

function RowGroupFooter(props) {
  const { columns, row, totalColumns = [] } = props;

  const groupTotal = React.useMemo(() => {
    if (!isArray(columns) || isEmpty(columns)) return <td className="groupingTableAmount">&nbsp;</td>;

    return columns.map((column, index) => (
      <td className="groupingTableAmount" key={column?.id ?? index}>
        <RowGroupTotal row={row} column={column} total={totalColumns} />
      </td>
    ));
  }, [columns, totalColumns, row]);

  return <tr className="boxShadowNone">{groupTotal}</tr>;
}

export default RowGroupFooter;
