import React, { useState } from 'react';
import { useTable } from 'react-table';

import { Drawer } from 'antd';

import ListingTableWrapper from 'containers/ListingTableWrapper';
import ListingWrapper from 'containers/ListingWrapper';

import AddRating from './AddRating';

const data = [
  {
    key: '1',
    value: 'Sales Volume',
    from: '3',
  },
  {
    key: '2',
    value: 'Sales Frequency',
    from: '2',
  },
  {
    key: '3',
    value: 'Online and offline sales volume',
    from: '3',
  },
  {
    key: '4',
    value: 'Total',
    from: '8',
  },
];

const Rating = () => {
  const [isRating, setIsRating] = useState(false);
  const showRating = () => {
    setIsRating(true);
  };
  const handleClose = () => {
    setIsRating(false);
  };
  const columns = [
    {
      Header: 'Parameter',
      accessor: 'value',
      Cell: ({ row }) => <span onClick={showRating}>{row.original.value}</span>,
    },
    {
      Header: 'Weightage',
      accessor: 'Weightage',
      Cell: ({ row }) => (
        <div className="tableInput width-fix">
          <form>
            <input type="text" name="discount" placeholder={row.original.from} />
            <span className="signStatic">/10</span>
          </form>
        </div>
      ),
    },
  ];
  const Table = ({ columns, data }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
      columns,
      data,
    });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={key}>
              {headerGroup.headers.map((column, key) => (
                <th {...column.getHeaderProps()} width="100px" key={key}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.slice(0, 10).map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, key) => {
                  return (
                    <td width="100px" {...cell.getCellProps()} key={key}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  return (
    <div className="menuAccessWrapper settingPage">
      <div className="headeTitle d-flex justify-space-between align-items-center width-100 mb-20">
        <h2 className="mb-0">Rating</h2>
      </div>
      <ListingWrapper>
        <ListingTableWrapper className="whiteBg smallGrayScroll">
          <Table columns={columns} data={data} />
        </ListingTableWrapper>
      </ListingWrapper>
      <Drawer visible={isRating} onClose={handleClose} wrapClassName="diamondListinSidebar sm-size">
        <AddRating />
      </Drawer>
    </div>
  );
};
export default Rating;
