import React, { Component } from 'react';

import axios from 'axios';
// import $ from 'jquery';

import CircularProgress from 'components/CircularProgress';

import NoDataFoundComponent from './NoDataFoundComponent';

export default class IFrame extends Component {
  state = {
    src: this.props.src,
    error: false,
    loader: true,
  };
  componentDidMount() {
    // if (this.props.detail) {
    //   if (this.props.video) {
    //     this.setState({ error: this.props.error });
    //     if (!this.props.videoDone) {
    //       this.getData();
    //     }
    //   } else {
    //     this.setState({ error: this.props.certiError });
    //     if (!this.props.certiDone) {
    //       this.getData();
    //     }
    //   }
    // } else {
    //   this.getData();
    // }
  }
  getData = () => {
    this.setState({ loader: true });
    axios
      .get(this.props.src, {
        headers: {
          'Cache-Control': 'no-cache',
        },
        responseType: 'blob',
      })
      .then((resp) => {
        if (resp.status === 200 && resp.statusText === 'OK') {
          this.setState({ error: false, loader: false });
          this.setOnLoadData(false);
        } else {
          this.setState({ error: true, loader: false });
          this.setOnLoadData(true);
        }
      })
      .catch((error) => {
        if (error) {
          this.setOnLoadData(true);
          this.setState({ error: true, loader: false });
        }
      });
  };
  setOnLoadData = (error) => {
    if (this.props.detail) {
      if (this.props.video) {
        this.props.doneVideo();
        this.props.setError(error);
      } else {
        this.props.setErrorCerti(error);

        this.props.doneCerti();
      }
    }
  };
  render() {
    const { error } = this.state;
    return (
      <>
        {this.state.loader && <CircularProgress />}
        {error ? (
          this.props.detail ? (
            <div className="diamondDetailImageBox htmlViewImage">
              <NoDataFoundComponent
                className="nodatFoundImage"
                src={this.props.tempSrc}
                title={`No ${this.props.video ? 'Video' : 'Certificate'}Found`}
              />
            </div>
          ) : (
            <div style={{ marginLeft: '465px' }} className="diamondDetailImageBox htmlViewImage">
              <NoDataFoundComponent
                className="nodatFoundImage"
                src={this.props.tempSrc}
                title={`No ${this.props.video ? 'Video' : 'Certificate'}Found`}
              />
            </div>
          )
        ) : this.props.detail && !this.state.loader ? (
          <iframe
            id="iframe-id"
            src={this.state.src}
            onLoad={() => {
              this.setState({ loader: false });
            }}
            title="iframe"
          />
        ) : (
          <iframe
            id="iframe-id"
            src={this.state.src}
            height="100%"
            width="100%"
            // loading="lazy"
            onLoad={() => {
              this.setState({ loader: false });
            }}
            title="iframe"
          />
        )}
      </>
    );
  }
}
