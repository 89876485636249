import { memoize, forOwn, cloneDeep } from 'lodash';
import { DateTime, Duration } from 'luxon';

import * as REGEX from 'util/regex';

import { LOCALE, CURRENCY, FLOAT_PRECISION, TIMEZONE_IANA, LUXON_FORMAT } from 'constants/Common';

export const typeOf = (input, type) => input?.constructor?.name === type ?? null;

export const isArray = (input) => typeOf(input, 'Array');

export const isObject = (input) => typeOf(input, 'Object');

export const isBoolean = (input) => typeOf(input, 'Boolean');

export const isString = (input) => typeOf(input, 'String');

export const isNumber = (input) => typeOf(input, 'Number') && !Number.isNaN(input);

export const isNumeric = (input, strict = false) =>
  new RegExp(strict ? REGEX.NUMERIC.STRICT : REGEX.NUMERIC.LOOSE).test(input);

export const isAlphaNumeric = (input) => new RegExp(REGEX.ALPHA_NUMERIC).test(input);

export const isFunction = (input) => typeOf(input, 'Function');

export const callFunction = (func, ...args) => isFunction(func) && func(...args);

export const forEach = (instance, callback) => Array.prototype.forEach.call(instance, callback);

export const isHTMLElement = (input) => input instanceof HTMLElement;

export const isEmpty = (input) => {
  const type = input?.constructor?.name;
  if ([undefined, null].includes(input)) return true;
  if (type === 'Array') return !input.length;
  if (type === 'Number') return Number.isNaN(input);
  if (type === 'Object') return !Object.keys(input).length;
  if (type === 'String') return !input.trim().length;
  return false;
};

export const isNotEmpty = (...args) => !isEmpty(...args);

export const pruneEmpty = (obj) => {
  const prune = (current) => {
    forOwn(current, (value, key) => {
      if (isEmpty(value) || ((isObject(value) || isArray(value)) && isEmpty(prune(value)))) delete current[key];
    });
    if (isArray(current)) current = current.filter(isNotEmpty);
    return current;
  };
  return prune(cloneDeep(obj));
};

export const returnIfNotEmpty = (value, replaceWith) => (isEmpty(value) ? replaceWith : value);

export const hasKey = (object, key) => isObject(object) && !isEmpty(object) && Object.keys(object).includes(key);

// might need to refactor this later
export const runInDevelopment = (callback) =>
  (isEmpty(process.env.REACT_APP_ENV) || process.env.REACT_APP_ENV === 'development') && callback();
//

export const logInfo = (...args) => runInDevelopment(() => console.info(...args)); // eslint-disable-line no-console
export const logWarn = (...args) => runInDevelopment(() => console.warn(...args)); // eslint-disable-line no-console
export const logTable = (...args) => runInDevelopment(() => console.table(...args)); // eslint-disable-line no-console

export const sortArrayByKey = (key = 'id', desc = false) => {
  if (!isString(key)) return undefined;
  const n = { less: desc ? 1 : -1, more: desc ? -1 : 1 };
  return (curr, next) => (curr?.[key] < next?.[key] ? n.less : curr?.[key] > next?.[key] ? n.more : 0);
};

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  if (isEmpty(array)) return initialValue;
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const getCurrentTime = () => DateTime.local().setZone(TIMEZONE_IANA);

export const formatDateTime = memoize(
  (isoDate, format = LUXON_FORMAT.DATE_TIME) => {
    const dateTime = DateTime.fromISO(isoDate);
    return dateTime.isValid ? dateTime.toFormat(format) : undefined;
  },
  (...args) => JSON.stringify(args.join('_')),
);

export const formatDate = (isoDate, format = LUXON_FORMAT.DATE) => formatDateTime(isoDate, format);

export const formatTime = (isoDate, format = LUXON_FORMAT.TIME) => formatDateTime(isoDate, format);

export const formatDuration = (duration, format = LUXON_FORMAT.DURATION) =>
  Duration.isDuration(duration) && duration?.isValid ? duration.toFormat(format) : undefined;

export const getDateTimeDiff = (startISO, endISO) => DateTime.fromISO(endISO).diff(DateTime.fromISO(startISO));

export const getFormattedDateTimeDiff = (startISO, endISO, format = LUXON_FORMAT.DURATION) =>
  formatDuration(getDateTimeDiff(startISO, endISO), format);

export const formatNumber = (input, options = {}) => {
  if (!isNumber(Number(input))) return input;
  const { locale, trimFractions, ...rest } = { locale: LOCALE, trimFractions: false, ...options };
  const fractionLength = trimFractions ? rest?.fractionLength : `${input}`.split('.')?.[1]?.length;
  const defaults = { maximumFractionDigits: fractionLength, minimumFractionDigits: fractionLength };
  return new Intl.NumberFormat(locale, { ...defaults, ...rest }).format(input);
};

export const formatCurrency = (number, options = {}) => {
  const { locale, ...rest } = { locale: LOCALE, currency: CURRENCY, ...options };
  return new Intl.NumberFormat(locale, { style: 'currency', ...rest }).format(number);
};

export const formatDecimal = (input, fractionLength = FLOAT_PRECISION) =>
  formatNumber(input, { trimFractions: true, fractionLength });

export const parseDecimal = (input, fractionLength = FLOAT_PRECISION) =>
  Number(parseFloat(input).toFixed(fractionLength));

export const formatFloat = (input, fractionLength = FLOAT_PRECISION) => parseFloat(input).toFixed(fractionLength);

export const formatInlineList = (value, options = {}) => {
  if (!isString(value)) return value;
  const { separator, allowAppend } = { separator: ',', allowAppend: false, ...options };
  const valueList = `${value}`.replace(/[\s,]+/gm, separator).split(separator);
  return valueList
    .filter((value, index) => !isEmpty(value) || (allowAppend && index && valueList?.length === index + 1))
    .join(separator);
};

export const classNames = (list) => list.filter(isString).join(' ');

export const objectToQueryString = (object) =>
  `?${Object.keys(object)
    .map((key) => `${key}=${object?.[key]?.toString ? object[key].toString() : ''}`)
    .join('&')}`;

export const queryStringToObject = (location = window.location) =>
  !isEmpty(location.search)
    ? JSON.parse(
        `{"${decodeURI(location.search.substr(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`,
      )
    : {};

export const getUserName = memoize((user, replace = '-') => {
  user = pruneEmpty(user);
  const name = [user?.firstName, user?.lastName].filter(isNotEmpty);
  return !isEmpty(name) ? name.join(' ') : user?.name ?? user?.username ?? user?.email ?? replace;
});

export const validateNumberInput = (evt) => {
  if ((evt.which < 48 || evt.which > 57) && evt.which != 46 && evt.which != 45) {
    evt.preventDefault();
  }
};

export const capitalize = (input) =>
  input?.replace(/_/g, ' ')?.replace(/(\w+)/g, (x) => x[0].toUpperCase() + x.substring(1));
