import React from 'react';

import SelectOption from 'containers/SelectOption';

import Storage from 'services/storage';

import { useCompositeState } from 'util/hooks';

function PreferShipCompany(props) {
  const [state, setState] = useCompositeState(() => ({
    preShipCompany: '',
    preShipCompanyList: Storage.get(`dn-master`)?.PRE_SHIP_COMPANY,
  }));
  React.useEffect(() => {
    //initialPreferShipComp it's when by default fill data and it' not editAble
    setState({ preShipCompany: props.initialPreferShipComp });
  }, [props.initialPreferShipComp]);
  return (
    <SelectOption
      valueKey="id"
      className={props.initialPreferShipComp ? 'disableBlock' : ''}
      nameKey="webDisplay"
      placeholder={props?.placeholder || `Select Prefer Shipping Company`}
      value={state.preShipCompany}
      onChange={(preShipCompany = null) => {
        setState({ preShipCompany });
        props.setPreShipCompany(preShipCompany);
      }}
      selectValue={state.preShipCompanyList}
      label={props?.label || 'Prefer Shipping Company'}
      onSearch={() => {}}
    />
  );
}

export default React.memo(PreferShipCompany);
