import React from 'react';

import { Dropdown, Tooltip } from 'antd';
import sum from 'lodash/sum';

import DiamondTotalCard from 'components/DiamondListing/DiamondTotalCard';

import classNames from 'util/classNames';
import { useBoolean } from 'util/hooks';
import { isEmpty } from 'util/utils';

import MoreOptionItem from './MoreOptionItem';

import excelWhiteSvg from 'assets/svg/InventoryResult/excel-white.svg';
import mailWhiteSvg from 'assets/svg/InventoryResult/mail-white.svg';
import moreSvg from 'assets/svg/InventoryResult/more.svg';
import printSvg from 'assets/svg/InventoryResult/print.svg';
import checkboxSvg from 'assets/svg/checkbox.svg';
import leftArrowWhiteSvg from 'assets/svg/left-arrow-white.svg';
import rightArrowWhiteSvg from 'assets/svg/right-arrow-white.svg';

function TableGrouping({ displayTotal = [], ...props }) {
  const [showOption, setShowOption] = useBoolean(false);
  const [titleExpanded, setTitleExpanded] = useBoolean(false);

  return (
    <>
      {!props.tableHide && (
        <div className="tableGropupingBox">
          <div className="tableGroupTitle">
            {(props.Collapse || props.multipleCheckBox || props.groupingTitle || props.MoreOption) && (
              <span
                className={classNames([
                  `tableGroupTitleInner`,
                  titleExpanded && 'collpaseBlock',
                  showOption && 'moreOptionShow',
                  props.MoreOption && 'moreOptionShow',
                ])}
              >
                {props.Collapse && (
                  <div className="tableCollapse" onClick={props.onClick}>
                    <img src={props.img} alt={props.img} />
                  </div>
                )}
                {props.multipleCheckBox && (
                  <Tooltip title="Select All">
                    <div className="checkBoxSelect">
                      <img src={checkboxSvg} alt={checkboxSvg} />
                    </div>
                  </Tooltip>
                )}
                {props.groupingTitle && <span>{props.groupingTitle}</span>}
                {/* {props.isOfferPopup && (
                  <div className="tableCollapseBox" onClick={props.toggleCollapse}>
                    <img
                      src={
                        !isExpanded
                          ? require('assets/svg/InventoryResult/substraction.svg')
                          : require('assets/svg/plus-color.svg')
                      }
                      alt=""
                    />
                  </div>
                )} */}

                {props.Action && (
                  <div className="d-flex tableListIconMain">
                    {props.pending && (
                      <>
                        <span className="tableListIcon" onClick={props.approveClick}>
                          Approve
                        </span>
                        {props.reject ? null : <span className="tableListIcon">Reject</span>}
                      </>
                    )}
                    <span className="tableListIcon">
                      <img src={mailWhiteSvg} alt="" />
                    </span>
                    <span className="tableListIcon">
                      <img src={excelWhiteSvg} alt="" />
                    </span>
                    <span className="tableListIcon">
                      <img src={printSvg} alt="" />
                    </span>
                  </div>
                )}
                <div className="groupClose" onClick={setTitleExpanded.toggle}>
                  {titleExpanded ? <img src={leftArrowWhiteSvg} alt="" /> : <img src={rightArrowWhiteSvg} alt="" />}
                </div>
                {props.MoreOption ? (
                  <Dropdown
                    overlayClassName="groupingDrodown"
                    overlay={
                      <div onClick={setShowOption.toggle}>
                        {props.data.map((d, index) => (
                          <MoreOptionItem
                            key={index}
                            Link={d.Link}
                            image={d.image}
                            title={d.title}
                            className={d.className}
                          />
                        ))}
                      </div>
                    }
                    trigger="click"
                  >
                    <span className="moreOption" onClick={setShowOption.toggle}>
                      <img src={moreSvg} alt="more" />
                    </span>
                  </Dropdown>
                ) : (
                  <span className="moreOption">
                    <img src={moreSvg} alt="more" />
                  </span>
                )}
              </span>
            )}
            <div className="tableHeaderMain">{props.totalDiamonds ? props.totalDiamonds + ' Diamond' : ''}</div>
          </div>
        </div>
      )}
      <tr className="boxShadowNone">
        {/* ## td for srno column ## */}
        {!props.noSerNum && (
          <td className="groupingTableAmount" style={{ color: 'transparent' }}>
            0
          </td>
        )}
        {props.isOfferPopup ? (
          props.calc &&
          props.calc.map((x, i) => (
            <td className="groupingTableAmount" key={i}>
              {x}
            </td>
          ))
        ) : !props.columns ? (
          <td className="groupingTableAmount" style={{ color: 'transparent' }}>
            0
          </td>
        ) : (
          props.columns &&
          props.columns.map((column, index) => {
            {
              /* let content; */
            }
            if (column.id === 'latestOfferAmt' && props?.row?.groupData?.latestOfferAmt) {
              {
                /* const summation = '$' + parseFloat(sum(props?.row.groupData.latestOfferAmt)).toFixed(2); */
              }
              let differCalc = parseFloat(
                sum(props?.row.groupData.newAmount) - sum(props?.row.groupData.latestOfferAmt),
              ).toFixed(2);
              if (!parseFloat(differCalc)) {
                differCalc = parseFloat(
                  sum(props?.row.groupData.latestOfferAmt) - sum(props?.row.groupData.buyAmt),
                ).toFixed(2);
              }
              {
                /* content = (
                <span>
                  {summation}
                  <span className={parseFloat(differCalc) > 0 ? 'greenColor' : 'redColor'}> ({differCalc})</span>
                </span>
              ); */
              }
            }
            if (column.id === 'newPricePerCarat' && props?.row?.groupData?.newPricePerCarat) {
              {
                /* const summation = '$' + parseFloat(sum(props?.row.groupData.newPricePerCarat)).toFixed(2);
              const sum2 =
                '$' +
                parseFloat(
                  sum(props?.row.bargainsCalc ? props?.row.bargainsCalc.map((f) => parseFloat(f.amt)) : 0),
                ).toFixed(2); */
              }
              {
                /* content = (
                <span>
                  <span className="greenColor">{summation}</span>
                  {sum2 && props?.row.bargainsCalc?.length > 1 ? ' | ' + sum2 : null}
                </span>
              ); */
              }
            }

            return (
              <td className="groupingTableAmount" key={isEmpty(column.id) ? index : column.id}>
                {displayTotal.includes(column.id) && (
                  <DiamondTotalCard content={props.content} row={props.row} col={column.id} />
                )}
              </td>
            );
          })
        )}
      </tr>
    </>
  );
}

export default TableGrouping;
