import React, { Component } from 'react';

// import { Input } from 'antd';
import { find, groupBy, keys } from 'lodash';
import _ from 'lodash';

import Heading from 'containers/MainHeading';

import CircularProgress from 'components/CircularProgress';
import ShareAction from 'components/DiamondListing/Actions/ShareAction';

import { downloadZip, downloadPdfFile } from 'services/DiamondApi';
// import { diamondHitCounter, downloadZip } from 'services/DiamondApi';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import UtilService from 'services/util';

import { isEmpty, isNotEmpty } from 'util/utils';

import CommonApi from 'constants/CommonApi';
import { PREFIX_URL_WITH_SLASH as PREFIX_URL } from 'constants/CommonUrl';

import DetailView from './DetailView';
import DiamondDetailCompare from './DiamondDetailCompare';
import DiamondDetailLeft from './LeftSection';
import RecentlyView from './RecentView';

import shareSvg from '../../assets/svg/diamondDetail/share.svg';
import downloadzipSvg from '../../assets/svg/diamondDetail/download-zip.svg';
import print from '../../assets/svg/diamondDetail/print.svg';
// const { Search } = Input;
export const DiamondDetailRoute = {
  root: `${PREFIX_URL}/diamond-detail/:id?direct-share`,
};
class DiamondDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      share: false,
      downloadCon: { mp4Video: true },
    };
  }

  async componentDidMount() {
    const id = _.last(_.split(window.location.pathname, '/'));
    if (id !== 'diamond-details' && id !== 'diamond-detail' && !this.props.inDiamondPopup) {
      const objData = {
        method: CommonApi.Diamond.view.method,
        url: CommonApi.Diamond.view.url + '/' + id,
        request: {
          // showRecent: true,
          compareDiamonds: true,
        },
      };
      UtilService.callApi(objData, async (err, data) => {
        if (err) throw err;
        if (data && data.code === 'OK') {
          this.setState({ data: data?.data, id: data?.data?.id });
        }
      });
      //await diamondHitCounter({ id, isHitCounter: true });
    }

    const columnName = ['dna', 'action'];
    const nextCol = this.props.columns?.filter((el) => !find(columnName, (name) => name === el.id)) || [];
    const columns = [...nextCol];
    this.setState({ columns });
  }

  onVerifyCertificate = () => {
    if (this.props.data?.rptNo) window.open(`https://gia.edu/report-check?reportno=${this.props.data.rptNo}`);
  };

  actionButtons = (data) => {
    const Details = {
      Length: data?.length,
      Width: data?.width,
      Depth: data?.depth,
      'Table%': data?.tblPer ? parseFloat(data?.tblPer).toFixed(2) : '-',
      'Depth%': data?.depPer ? parseFloat(data?.depPer).toFixed(2) : '-',
    };
    return !data || data.wSts === 'U' ? null : (
      <div className="diamondDetailBox">
        <div className="detailTopBasic">
          <div className="detailBasic">
            <div className="">
              <ul className="basicDetailFull">
                {['shpNm', 'crt', 'colNm', 'clrNm'].map((key, index) => (
                  <li key={index}>{data[key] || '-'}</li>
                ))}
              </ul>
            </div>
            <div className="basicSmallDetail">
              <span>{[data.cultNm, data.polNm, data.symNm].filter(isNotEmpty).join(' | ')}</span>
            </div>
          </div>
          <div className="detailPriceBlock">
            {isNotEmpty(data.amt) && <span>{parseFloat(data.amt).toFixed(2)}</span>}
            {isNotEmpty(data.back) && (
              <span className="offerPrice">( {`${parseFloat(data.back).toFixed(2)}`.replace('-', '')}% off )</span>
            )}
          </div>
        </div>
        <div className="diamondDetailBottomAction">
          <div className="diamonddetailActionwrap">
            <div className="diamondDetailShare d-flex flex-wrap">
              <div className="diamondDetailShareItem" onClick={() => this.setState({ share: true })}>
                <span>Share</span>
                <img src={shareSvg} alt="" />
              </div>
              <div
                className="diamondDetailShareItem"
                onClick={() => {
                  this.setState({ loader: true });
                  downloadZip(
                    {
                      filter: {
                        vStnId: [data.vStnId],
                        isFcCol: false,
                      },
                      zip: false,
                      ...this.state.downloadCon,
                    },
                    () => {
                      this.setState({ loader: false });
                    },
                  );
                }}
              >
                <span>Download</span>
                <img src={downloadzipSvg} alt="" />
              </div>
              <div
                className="diamondDetailShareItem"
                onClick={() =>
                  downloadPdfFile([data], {}, {}, {}, (loader) => {
                    this.setState({ loader });
                  })
                }
              >
                <span>Print</span>
                <img src={print} alt="" />
              </div>
            </div>
          </div>
          {this.state.share && <ShareAction checked={[data]} onClose={() => this.setState({ share: false })} />}

          <div className="diamondetailTable">
            <div className="diamondDetailbasicWrap">
              {Object.keys(Details).map((key, index) => (
                <div className="diamondDetailBasic" key={index}>
                  <div className="diamondDetailLebl">{key}</div>
                  <div className="diamondDetailValue">{Details[key] || '-'}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { data } = this.state || {};
    return (
      <>
        {this.state.loader && <CircularProgress className="fixLoader" />}

        <div className="diamondDetailWrapper diamondDetailVersion2">
          <div className="diamondDetailInner">
            <div className="diamondDetailMainTab">
              {!isEmpty(data) && (
                <div className="diamondDetailLeft">
                  <div className="diamondDetailInnerDetail">
                    {data ? (
                      <DiamondDetailLeft
                        rptNo={data.rptNo}
                        vStnId={data.vStnId}
                        setDownload={(downloadCon) => {
                          this.setState({ downloadCon });
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              )}
              <div className={`diamondDetailRight ${!this.props.diamondPopup && 'diamondDetailPage'}`}>
                {data && this.actionButtons(data)}
              </div>
            </div>

            {!isEmpty(this.state.data?.alternativeDiamonds) && (
              <div className="detailCompareBlock">
                <Heading title="Compare Alternate Diamonds" className="p-0" />
                <DiamondDetailCompare
                  diamondDetails={this.state.data?.alternativeDiamonds}
                  mainDiamondAmt={parseFloat(data?.amt).toFixed(2)}
                />
              </div>
            )}
            {this.state.data && (
              <div className={`diamondDetailBlock ${this.state.more && 'active'}`}>
                <Heading title="Diamond Details" className="p-0" />
                <div className="mt-10 d-flex flex-wrap align-items-start">
                  {keys(DetailView).map((k, index) => (
                    <div className="diamondDetailMore" key={index}>
                      <h2 className="detailSmallTitle">{DetailView[k]['name']}</h2>
                      <div className="diamondDetailMoreHalf">
                        {keys(groupBy(DetailView[k]['data'], 'part')).map((k1) => {
                          const source = groupBy(DetailView[k]['data'], 'part');
                          return source[k1].map((k2, index) => {
                            return (
                              <ul key={index}>
                                <li>
                                  <span className="detailLabel">{k2.label}</span>
                                  <span className="detailvalue">
                                    {k2['isFormat'] && data[k2['key']]
                                      ? parseFloat(data[k2['key']]).toFixed(2)
                                      : k2['key'] === 'colNm' && data['isFcCol'] && data[k2['key']]
                                      ? [data['intenNm'], data['ovrtnNm']].filter((el) => el && el).join(' ')
                                      : data[k2['key']]
                                      ? data[k2['key']]
                                      : '-'}
                                  </span>
                                </li>
                              </ul>
                            );
                          });
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!isEmpty(this.state.data?.similarStones) && (
              <div className="recentlyViewProduct">
                <Heading title="Similar Diamonds" className="p-0" />
                <div>
                  <RecentlyView diamondList={this.state.data?.similarStones} />
                </div>
              </div>
            )}
            {/* //FIXME:Recentview list */}
            {/* {!isEmpty(this.state.data?.recentlyViewed) && (
              <div className="recentlyViewProduct">
                <Heading title="Recently Viewed" className="p-0" />
                <div>
                  <RecentlyView diamondList={this.state.data?.recentlyViewed} />
                </div>
              </div>
            )} */}
          </div>
        </div>
      </>
    );
  }
}
export default DiamondDetail;
