import React, { useEffect, useState, useMemo } from 'react';
import 'antd/dist/antd.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button, Dropdown, Menu, Table, Tag } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { InventoryUserApi, showInventoryUser } from 'services/InventoryUserApi';

import './InventoryUser.css';
import OpenNotification from 'constants/OpenNotifications';

export const InventoryUser = () => {
  const [res, setRes] = useState({});
  const [err, setErr] = useState(false);
  const [data, setData] = useState({
    ipAddress: [],
    city: '',
    country: '',
  });
  const [loc, setLoc] = useState('');
  const [city, setCity] = useState('');
  const [cityArr, setCityArr] = useState();
  const [copy, setCopy] = useState({
    value: '',
    copied: false,
  });
  const [showCreate, setShowCreate] = useState(true);
  const [showUserList, setShowUserList] = useState(false);
  const [allInvData, setAllInvData] = useState([]);
  const [ipAddress, setIpAddress] = useState([]);
  const [ips, setIps] = useState('');

  const handleCopyFromTable = (data) => {
    const value = `userId: ${data.userId} password: ${data.password}`;
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(value);
      setLoc('');
      OpenNotification({
        type: 'success',
        title: 'userId and Password are copied.',
      });
    } else {
      OpenNotification({
        type: 'error',
        title: 'userId and Password are not copied.',
      });
    }
  };
  const items = [
    {
      location: 'INDIA',
      key: '1',
    },
    {
      location: 'BELGIUM',
      key: '2',
    },
    {
      location: 'USA',
      key: '3',
    },
    {
      location: 'UAE',
      key: '4',
    },
  ];

  const UserCity = [
    {
      city: 'ANTWERP',
      key: '1',
      country: 'BELGIUM',
    },
    {
      city: 'INDORE',
      key: '2',
      country: 'INDIA',
    },
    {
      city: 'MUMBAI',
      key: '3',
      country: 'INDIA',
    },
    {
      city: 'SURAT',
      key: '4',
      country: 'INDIA',
    },
    {
      city: 'NEW YORK',
      key: '5',
      country: 'USA',
    },
    {
      city: 'DUBAI',
      key: '6',
      country: 'UAE',
    },
  ];

  const columns = [
    {
      title: 'Copy',
      dataIndex: 'userId',
      key: 'userId',
      render: (_, record) => (
        <button className="showInventoryCopy_btn" onClick={() => handleCopyFromTable(record)}>
          Copy
        </button>
      ),
    },
    {
      title: 'UserId',
      dataIndex: 'userId',
      key: 'userIds',
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
  ];

  const handleCreate = async () => {
    if (!data.city || !data.country || !data.ipAddress || !data.ipAddress.length) {
      OpenNotification({
        type: 'error',
        title: 'Please enter IP address as well as select location.',
      });
    } else {
      const request = data;
      const apiRes = await InventoryUserApi(request);
      if (Object.keys(apiRes).length > 0) {
        setRes(apiRes && apiRes.data && apiRes.data.user);
        const user = apiRes.data.user;
        const value = `userId: ${user.userId} password: ${user.password}`;
        setCopy({ ...copy, value });
        setLoc('');
        setIpAddress([]);
        setIps('');
      }
    }
  };

  const toggleTab = async (type) => {
    if (type === 'create') {
      setShowCreate(true);
      setShowUserList(false);
    } else if (type === 'usersList') {
      setShowCreate(false);
      setShowUserList(true);
      const allInventoryData = await showInventoryUser();
      setAllInvData(allInventoryData);
    }
  };

  const handleIp = (e) => {
    //IP address validation regex
    const isIPv4 =
      /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])/;

    const val = e.target.value.trim();
    if (val === '') {
      setErr(false);
      setIps('');
    } else {
      if (val.match(isIPv4)) {
        setErr(false);
      } else {
        setErr(true);
      }
      setIps(val);
    }
  };

  const handleCountry = (e, item) => {
    const val = item.location.toString().toUpperCase();

    e.preventDefault();
    setLoc(val);
  };

  const showAllIpAddress = () => {
    if (ips === '') {
      OpenNotification({
        type: 'error',
        title: 'Please enter IP Adress first.',
      });
    } else {
      setIpAddress((prev) => [...prev, ips]);
      setIps('');
    }
  };

  const removeIp = (i) => {
    const newArr = ipAddress.filter((ip) => ip !== i);
    setIpAddress(newArr);
  };

  useEffect(() => {
    setCity('');
    let newArr = UserCity;
    if (loc === '') {
      setCityArr(UserCity);
    } else {
      newArr = newArr.filter((i) => i.country === loc);
      setCityArr(newArr);
    }
  }, [loc]);

  useMemo(() => setData({ ...data, ipAddress }), [ipAddress]);
  useMemo(() => setData({ ...data, city }), [city]);
  useMemo(() => setData({ ...data, country: loc }), [loc]);

  return (
    <>
      <div className="userInventory_main">
        <div className="userInventory_box">
          <div className="userInv_header">
            <h2>User Inventory</h2>
            <span style={{ color: showCreate ? '#003365' : '#6e6e6e' }} onClick={() => toggleTab('create')}>
              CREATE USER
            </span>
            <span style={{ color: showUserList ? '#003365' : '#6e6e6e' }} onClick={() => toggleTab('usersList')}>
              INVENTORY USERS
            </span>
          </div>
          {showCreate && (
            <>
              <div className="main_heading">
                <p>Dashboard to create an Inventory User</p>
              </div>

              <div className="form_box">
                {/* IP Address */}
                <div className="form_inputs">
                  <p>Add IP Address</p>
                  <input
                    placeholder="Enter IP Address"
                    type="string"
                    onChange={(e) => handleIp(e)}
                    required
                    value={ips}
                  />
                  <button className="add_btn" onClick={() => showAllIpAddress()}>
                    Add
                  </button>
                </div>
                {err && <h3 style={{ color: '#cc0000' }}>Error : Invalid IPv4 Address !!!</h3>}

                <div>
                  {ipAddress?.length
                    ? ipAddress.map((item) => {
                        return (
                          <Tag closable onClose={() => removeIp(item)} key={item}>
                            {item}
                          </Tag>
                        );
                      })
                    : null}
                </div>
                {/* Country */}
                <div className="form_inputs">
                  <p>Select Country</p>
                  <Dropdown
                    overlay={
                      <Menu>
                        {items.map((item) => (
                          <Menu.Item key={item.key}>
                            <a
                              href="!#"
                              onClick={(e) => {
                                handleCountry(e, item);
                              }}
                            >
                              {item.location}
                            </a>
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    <Button>
                      {loc.length > 0 ? loc : 'Select location'}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>

                {/* City */}
                {cityArr?.length && (
                  <div className="form_inputs">
                    <p>Select City</p>
                    <Dropdown
                      overlay={
                        <Menu>
                          {cityArr.map((item) => (
                            <Menu.Item key={item.key}>
                              <a
                                href="!#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setCity(item.city.toString().toUpperCase());
                                }}
                              >
                                {item.city}
                              </a>
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                    >
                      <Button>
                        {city.length > 0 ? city : 'Select City'}
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                )}

                <button className="create_btn" onClick={() => handleCreate()}>
                  Create
                </button>
              </div>

              <div className="main_box">
                {Object.keys(res).length > 0 && (
                  <>
                    <div className="response_box">
                      <div className="box_heading">
                        <p>Inventory user created successfully</p>
                      </div>
                      <div className="box_content">
                        <p>userId : {res?.userId}</p>
                        <p>Password : {res?.password}</p>
                        <p>Country : {res?.country}</p>
                        <p>City : {res?.city}</p>
                        <div className="copy_clipboard_box">
                          <CopyToClipboard text={copy.value}>
                            <button
                              onClick={() => {
                                OpenNotification({
                                  type: 'success',
                                  title: 'userId and Password are copied.',
                                });
                              }}
                              className="copy_clipboard"
                            >
                              Copy userId and Password
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="note_box">
                <div className="note_box_heading">
                  <p>NOTE</p>
                </div>
                <div className="note_box_content">
                  <div className="note_element">
                    <li>
                      To acquire the token for authentication the user must login with the credentials lirovided by
                      kgk.live before syncing in the inventory.
                    </li>
                    <li>KGK Live allows only a single user to sync the inventory at a time from the same country.</li>
                  </div>
                  <div>
                    <p className="note_url">Documentation URL</p>
                    <a
                      className="note_link"
                      href="https://docs.google.com/document/d/1PJy7Cf4ggymla8-84uOFE81uogeHI2z6fLI0AXiVmn8/edit?usp=sharing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      KGK Sync API Detail Architect
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}

          {showUserList && (
            <>
              <div className="main_heading">
                <p>List of all Inventory Users</p>
              </div>

              <div className="showInventoryTable">
                <Table key={Math.random()} dataSource={allInvData} columns={columns} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
