import React from 'react';

import { Form, Formik } from 'formik';
import { cloneDeep, find } from 'lodash';
// import moment from 'moment';
import * as Yup from 'yup';

import CommonModal from 'containers/CommonModal';
// import SelectOption from '../../containers/SelectOption';
// import DatePickerInput from 'containers/DatePickerInput';
// import DisableBlock from 'containers/DisableBlock';
import InputBlock from 'containers/InputBlock';
// import InputBlock, { InputField } from 'containers/InputBlock';
import TextArea from 'containers/TextArea';

import CircularProgress from 'components/CircularProgress';

import { InsertDiamondBlock } from 'services/DiamondBlockApi';

import { isEmpty } from 'util/utils';

import { DIAMOND_BLOCK, HOLD_MEMO_BLOCK_SETTING } from 'constants/Common';
// import { getUserList } from 'services/UserApi';
import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

// import { fetchPartyRelatedInfo } from './CommonPopupFunction';
import PartyBuyerComponent from './PopupComponent/PartyBuyerComponent';
import SellerComponent from './PopupComponent/SellerComponent';
import { newDiamondPrice } from './SelectStone';

export const holdPopup = {
  type: [{ value: 'Sales Rep. Hold' }, { value: 'Client Hold' }],
  Duration: [{ value: '20 Minutes' }, { value: '30 Minutes' }],
};
const HoldTime = {
  min: 1,
  max: 12,
};
class HoldPopup extends React.Component {
  state = {
    clientList: [],
    client: null,
    error: {},
    typeList: [],
    holdDurationList: [],
    holdDuration: null,
    checked: cloneDeep(this.props.checked),
    userTerms: this.props.userTerms,
    isTimeEditable: false,
    isClientShow: false,
    isTimeShow: false,
    loader: false,
    userAccount: undefined,
    user: undefined,
    seller: undefined,
    validTillDate: null,
    remarks: '',
  };

  initialState = cloneDeep(this.state);
  componentDidMount() {
    if (!isEmpty(this.props.client)) {
      let clientData = {};
      if (!isEmpty(this.props.client)) {
        const client = this.props.client;
        clientData = {
          client: [client],
          user: client?.user?.id,
          userAccount: client?.id,
          seller: client?.seller?.id,
        };
      }
      this.setState({ ...clientData }, () => {
        this.calculateDiamond();
      });
    }
  }

  removeError = (filed) => {
    const error = this.state.error;
    delete error[filed];
    this.setState({ error });
  };

  isValue = (value) => {
    //for set place holder value
    if (value) {
      return value;
    } else {
      return undefined;
    }
  };

  onSaveHold = async (values) => {
    const { remarks, user, userAccount, seller, holdDuration } = values;
    //FIXME:Hold duration pending

    const diamonds = this.state.checked.map((d) => ({
      diamond: d.id,
      blockPricePerCarat: d.calcPricePerCarat ? d.calcPricePerCarat : 0,
      blockAmount: d.calcAmount ? d.calcAmount : 0,
      vnd: d.vnd,
      vStnId: d.vStnId,
    }));
    this.setState({ loader: true });

    // let validTillDate = moment().add(holdDuration, 'hours').toISOString();
    const request = {
      ...(remarks && { remarks }),
      user,
      userAccount,
      ...(seller && { seller }),
      holdDuration,
      blockType: DIAMOND_BLOCK.TYPE.HOLD,
      diamonds,
      blockSetting: HOLD_MEMO_BLOCK_SETTING.HOLD,
    };
    const [, res] = await InsertDiamondBlock(request);
    if (res?.code === 'OK' && res?.data) {
      this.setState({ loader: false });
      OpenNotification({ type: 'success', title: res.message });

      this.props.handleCancel();
      this.props.changesStatus();
    } else {
      this.setState({ loader: false });
      this.props.handleCancel();
    }
  };

  calculateDiamond = () => {
    const client = find(this.state.clientList, (d) => d.key === this.state.client);
    const newPricedRows = this.state.checked.map((x) => newDiamondPrice(x, client?.accountTerms));
    this.setState({ checked: newPricedRows });
  };

  userTermsDiamondCal = (userTerms = null) => {
    const newPricedRows = this.state.checked.map((x) => newDiamondPrice(x, userTerms !== null ? userTerms : {}));
    this.setState({ checked: newPricedRows });
  };

  initialValues = {
    userAccount: this.props.client?.id,
    user: this.props.client?.user?.id,
    holdDuration: null,
    seller: this.props.client?.seller?.id,
    remarks: '',
    ...(this.props.client && { client: [this.props.client] }),
  };

  validationSchema = Yup.object().shape({
    userAccount: Yup.string().required(Messages.userAccountSelect).nullable(),
    user: Yup.string().required(Messages.userSelect).nullable(),
    // offerValidDate: Yup.string().required(Messages.ExpireDateRequire).nullable(),
    holdDuration: Yup.number()
      .required(Messages.holdTimeRequire)
      .min(HoldTime.min, `Minimum Hold Time : ${HoldTime.min} Hour | Maximum Hold Time : ${HoldTime.max} Hours.`)
      .max(HoldTime.max, `Minimum Hold Time : ${HoldTime.min} Hour | Maximum Hold Time : ${HoldTime.max} Hours.`)
      .nullable(),
  });
  render() {
    return (
      <>
        <CommonModal
          visible={this.props.holdPopup}
          handleCancel={() => {
            this.props.handleCancel();
          }}
          title="Hold Stone"
          // footerShow
        >
          <Formik
            enableReinitialize
            initialValues={this.initialValues}
            validateOnBlur
            validationSchema={this.validationSchema}
            onSubmit={this.onSaveHold}
          >
            {({ handleBlur, setValues, touched, errors, values }) => (
              <Form>
                <div className="searchPopupCommon">
                  <div className="holdPopupBlock">
                    <PartyBuyerComponent
                      error={(touched?.useAccount || touched?.user) && errors}
                      //Do not change
                      setUserDetails={(data) => {
                        this.userTermsDiamondCal(data?.userTerms);
                        setValues((oldValues) => ({
                          ...oldValues,
                          ...data,
                        }));
                      }}
                      initialData={{ ...values }}
                    />
                    <SellerComponent
                      label="Sales Person"
                      placeholder="Select Sales Person"
                      setSellerDetails={(data) => {
                        setValues((oldValues) => ({
                          ...oldValues,
                          seller: data?.seller,
                        }));
                      }}
                      initialData={values}
                    />
                    {/* <DatePickerInput
                noPast
                onChange={(value) => {
                  if (value) this.errorHandel({ fields: ['validTillDate'] });
                  this.setState({ validTillDate: value });
                }}
                label="Hold Date Time *"
                format="MMM DD YYYY, hh:mm:ss a "
                value={this.state?.validTillDate}
                error={this.state.error?.validTillDate}
                placeholder="Select Hold Date Time"
                showTime
              /> */}
                    <InputBlock
                      name="holdDuration"
                      label="Enter Hold Time (Hours) *"
                      onKeyPress={(evt) => {
                        if (evt.which < 48 || evt.which > 57) {
                          evt.preventDefault();
                        }
                      }}
                      placeholder="Hold Time"
                      type="number"
                      value={values?.holdDuration}
                      onChange={(e) => setValues((values) => ({ ...values, holdDuration: e.target.value }))}
                      onBlur={handleBlur}
                      error={touched?.holdDuration && errors?.holdDuration}
                    />
                  </div>
                  <TextArea
                    name="remarks"
                    label="Comments"
                    placeholder="Enter Comments"
                    onChange={(e) => {
                      setValues((values) => ({ ...values, remarks: e.target.value }));
                    }}
                    value={values?.remarks}
                  />
                  <div className="commonModalButton ant-modal-footer">
                    <button className="fillButton" type="submit">
                      Submit
                    </button>
                    <button
                      className="outLineButton"
                      type="reset"
                      onClick={() => {
                        this.props.handleCancel();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {this.state.loader && <CircularProgress className="fixLoader" />}
        </CommonModal>
      </>
    );
  }
}

export default HoldPopup;
