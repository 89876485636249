import React from 'react';
import { Link } from 'react-router-dom';

// import { Popconfirm } from 'antd';
import { DateTime } from 'luxon';

import CommonSearch from 'containers/CommonSearch';
import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import ListingWrapper from 'containers/ListingWrapper';
import PlusIcon from 'containers/PlusIcon';
import StatusBlock from 'containers/StatusBlock';

import Table from 'components/Inventory/TableBack';
import { getState } from 'components/Inventory/UpcomingDiamond';

import { showNotification } from 'services/NotificationService';
import * as SubMasterAPI from 'services/SubMasterApi';

import { useCompositeState } from 'util/hooks';
import { isArray, isEmpty } from 'util/utils';

import { getModuleFromUrl } from 'constants/permission';

import Form from './Form';

import editSvg from '../../../assets/svg/edit.svg';

const initialSort = [{ sortingSequence: 'ASC' }];

const Master = () => {
  const [form, setForm] = React.useState(true);
  const [state, setState] = useCompositeState(
    getState({
      tab: false,
      checked: [],
      data: [],
      sort: initialSort,
      loading: true,
    }),
  );

  const getMastersList = React.useCallback(
    async (search = null) => {
      setState({ loading: true });
      const requestConf = {
        isOnlyParents: true,
        isDeleted: false,
        sort: state.sort,
        filter: {},
      };
      if (search) requestConf.search = { keyword: search, keys: ['name', 'code'] };
      const [, res] = await SubMasterAPI.getMastersList(requestConf);

      setState(
        res?.code === 'OK' && isArray(res?.data) && !isEmpty(res?.data)
          ? { data: res.data, count: res.data.length, loading: false }
          : { data: [], count: 0, loading: false },
      );
    },
    [setState, state.sort],
  );
  React.useEffect(() => {
    getMastersList();
  }, [getMastersList, state.sort]);

  const openForm = () => setForm(false);

  const closeForm = () => {
    setState({ detail: {} });
    setForm(true);
    getMastersList();
  };

  const editMasterDetail = React.useCallback(
    async (editId) => {
      const [, res] = await SubMasterAPI.findMasterDetail(editId);
      if (res?.code === 'OK') {
        setState({ detail: res.data });
        setForm(false);
      }
    },
    [setState],
  );

  const deleteMasterDetail = React.useCallback(
    async (updateId) => {
      const [err, res] = await SubMasterAPI.updateMasterDetail({ updateId, isDeleted: true });
      !err && showNotification(res);

      if (res?.code === 'OK') {
        getMastersList();
      }
    },
    [getMastersList],
  );

  const handleSearch = (value) => {
    if (value?.length > 2 || isEmpty(value)) getMastersList(value);
  };

  const updateActiveStatus = async (id, status) => {
    const [err, res] = await SubMasterAPI.updateMasterDetail({ updateId: id, isActive: status });
    !err && showNotification(res);
    return !err && res.code === 'OK';
  };

  const columns = React.useMemo(
    () => [
      { Header: 'Name', accessor: 'name', sort: 'name' },
      { Header: 'Code', accessor: 'code' },
      { Header: 'Sequence', accessor: 'sortingSequence', sort: 'sortingSequence' },
      {
        Header: 'Status',
        accessor: 'active',
        id: 'active',
        Cell({ row }) {
          const [isActive, setIsActive] = React.useState(row?.original?.isActive);

          return (
            <div
              onClick={async () => {
                const newIsActive = !isActive;
                row.original.isActive = newIsActive;
                const success = await updateActiveStatus(row.original.id, newIsActive);
                success && setIsActive(newIsActive);
              }}
            >
              <StatusBlock status={isActive ? 'active' : 'InActive'} title={isActive ? 'Active' : 'Inactive'} />
            </div>
          );
        },
      },
      {
        Header: 'Created By',
        accessor: 'createdby',
        Cell({ row }) {
          return (
            <div className="lastEditUser">
              <div className="lastEditUserName">
                <span className="userName">{row.original?.createdBy?.name}</span>
              </div>
              <div className="userName mt-5">{DateTime.fromISO(row.original.createdAt).toLocaleString()}</div>
            </div>
          );
        },
      },
      {
        Header: 'Sub Master',
        accessor: 'submaster',
        id: 'submaster',
        Cell({ row }) {
          return (
            <Link
              to={{ pathname: 'submaster', parentId: row.original.id, parentCode: row.original.code }}
              className="tableLink"
            >
              View
            </Link>
          );
        },
      },
      {
        Header: 'Action',
        Cell({ row }) {
          return (
            <div className="actionButton">
              {getModuleFromUrl().update && (
                <div className="actionAction" onClick={() => editMasterDetail(row.original.id)}>
                  <img src={editSvg} />
                </div>
              )}
              {/* <Popconfirm
                placement="left"
                title={`Are you sure you want to delete this Master?`}
                okText={`Delete`}
                onConfirm={() => deleteMasterDetail(row.original.id)}
              >
                <div className="actionAction">
                  <img src={require('assets/svg/delet.svg')} />
                </div>
              </Popconfirm> */}
            </div>
          );
        },
      },
    ],
    [deleteMasterDetail, editMasterDetail],
  );

  const handleSort = React.useCallback(
    (sort) => {
      if (JSON.stringify(state.sort) !== JSON.stringify(sort)) {
        setState({ sort }, () => getMastersList());
      }
    },
    [getMastersList, setState, state.sort],
  );

  return (
    <div className="configurationInnerSubTb">
      <div className="d-flex j-space-between p-15 align-items-center masterTopBlock">
        <div className="headeTitle">
          <h2 className="mb-0">Master</h2>
        </div>
        {form && (
          <div>
            <CommonSearch placeholder="Search" handlechange={handleSearch} />
          </div>
        )}
      </div>
      {form ? (
        <ListingWrapper>
          {getModuleFromUrl().insert && <PlusIcon onClick={openForm} />}
          <GridHeightContainer
            id="MasterTable"
            className="searchResultTable"
            subtractFrom=".masterTopBlock"
            style={{ height: 'auto', overflow: 'auto' }}
          >
            <Table
              data={state.data}
              loading={state.loading}
              columns={columns}
              initialSort={initialSort}
              handleSort={handleSort}
              FilterOption={false}
              nocheck
            />
          </GridHeightContainer>
        </ListingWrapper>
      ) : (
        <Form onClick={closeForm} detail={state.detail} />
      )}
    </div>
  );
};

export default Master;
