import React from 'react';

import PropTypes from 'prop-types';

// import BidService from 'services/BidService';

// import classNames from 'util/classNames';

export const STATUS_INDICATION = {
  A: { name: 'Available', color: '#6bc950', hover: 'Available' },
  M: { name: 'Memo', color: '#307bea', hover: 'Memo' },
  H: { name: 'Hold', color: '#db1c1c', hover: 'Hold' },
  B: { name: 'BID', color: '#1cdbc1', hover: 'BID' },
  U: { name: 'Upcoming', color: '#acdb1c', hover: 'Upcoming' },
  I: { name: 'Exclusive', color: '#737571', hover: 'Exclusive' },
  C: { name: 'Confirm', color: '#457c22', hover: 'Confirm' },
  N: { name: 'New Arrival', color: '#457c22', hover: 'New Arrival' },
};

// const STATUS_LIST = { A: 'Available', M: 'On Memo', H: 'Hold', U: 'Upcoming', B: 'Bid', ALL: 'All' };
// const STATUS_COLOR = { A: 'green', M: 'blue', H: 'red' };

const Status = React.memo((props) => {
  // const [status, _setStatus] = React.useState();
  // const setStatus = React.useCallback(() => {
  //   let status = STATUS_LIST[`${props?.status}`.toUpperCase()];
  //   // if (props?.showLive) {
  //   //   if (status === STATUS_LIST.B) status = BidService.getBidStatus();
  //   // }
  //   _setStatus(status);
  // }, [props?.status]);

  // const colorClass = React.useMemo(() => {
  //   return classNames(['SelectcolorWiseList', STATUS_COLOR?.[props.status]]);
  // }, [props.status]);

  // React.useEffect(() => {
  //   setStatus();
  // }, [setStatus]);

  return (
    <div className="showSatusBlock">
      {props.status !== 'ALL' && (
        <div style={{ backgroundColor: STATUS_INDICATION[props.status]?.color }} className="SelectcolorWiseList" />
      )}
      <span>{STATUS_INDICATION[props.status]?.name}</span>
    </div>
  );
});

Status.propTypes = {
  status: PropTypes.string,
  showLive: PropTypes.bool,
};

export default Status;
