const DetailView = {
  section1: {
    name: 'Basic Details',
    data: [
      { part: 1, label: 'PacketNo', key: 'vStnId' },
      { part: 1, label: 'ReportNo', key: 'rptNo' },
      { part: 1, label: 'Lab', key: 'lbNm' },
      { part: 1, label: 'Rap.($)', key: 'rap', isFormat: true },
      { part: 1, label: 'Shape', key: 'shpNm' },
      { part: 1, label: 'Carat', key: 'crt' },
      { part: 1, label: 'Color', key: 'colNm' },
      { part: 1, label: 'Clarity', key: 'clrNm' },
      { part: 1, label: 'Tinge', key: 'shdNm' },
      { part: 1, label: 'Cut', key: 'cutNm' },
      { part: 1, label: 'Polish', key: 'polNm' },
      { part: 1, label: 'Symmetry', key: 'symNm' },
      { part: 1, label: 'Fluorescence', key: 'fluNm' },
      { part: 1, label: 'Location', key: 'locNm' },
    ],
  },
  section2: {
    name: 'Measurements',
    data: [
      { label: 'Table (%)', key: 'tblPer', isFormat: true },
      { label: 'Depth(%)', key: 'depPer', isFormat: true },
      { label: 'Length', key: 'length', isFormat: true },
      { label: 'Width', key: 'width', isFormat: true },
      { label: 'Depth', key: 'height', isFormat: true },
      { label: 'Ratio', key: 'ratio', isFormat: true },
      { label: 'Crown Angle', key: 'cAng', isFormat: true },
      { label: 'Crown Height', key: 'cHgt', isFormat: true },
      { label: 'Pavilion Angle', key: 'pAng', isFormat: true },
      { label: 'Pavilion Height', key: 'pHgt', isFormat: true },
      { label: 'Girdle', key: 'girdleStr' },
      { label: 'Culet', key: 'cultNm' },
      { label: 'Laser Inscription', key: 'lsrInc' },
    ],
  },
  section3: {
    name: 'Inclusion Details',
    data: [
      { label: 'Table Black', key: 'blkCrnNm' },
      { label: 'Side Black', key: 'blkSdNm' },
      { label: 'Table Inclusion', key: 'wCrnNm' },
      { label: 'Side inclusion', key: 'wSdNm' },
      { label: 'Table Open', key: 'opTblNm' },
      { label: 'Crown Open', key: 'opCrwnNm' },
      { label: 'Pavilion Open', key: 'opPavNm' },
      { label: 'Eye Clean', key: 'eClnNm' },
      { label: 'Heart Arrow', key: 'hANm' },
      { label: 'Brilliancy', key: 'brlncyNm' },
      { label: 'Type 2 Cert', key: 'type2Nm' },
      { label: 'Country Of Origin', key: 'org' },
      { label: 'Rough Mine', key: '' },
      { label: 'Natural Girdle', key: 'nrlGrdlNm' },
      { label: 'Natural Crown', key: 'nrlCrwnNm' },
      { label: 'Natural Pavilion', key: 'nrlPavNm' },
      { label: 'Internal Graining', key: 'intrnlGrningNm' },
      { label: 'Surface Graining', key: 'srfcGrningNm' },
    ],
  },
  section4: {
    name: 'Other Information',
    data: [
      { label: 'Key to Symbol', key: 'kToSStr' },
      { label: 'Report Comments', key: 'lbCmt' },
    ],
  },
};

export default DetailView;
