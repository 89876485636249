import React, { useState } from 'react';

import './action.less';
// import SendEmailPopup from './SendEmail';
// import { isArray } from 'lodash';
// import last from 'lodash/last';

import CommonModal from 'containers/CommonModal';

import OpenNotification from 'constants/OpenNotifications';
import { FORNT_SIDE_URL } from 'constants/PageUrl';

// import DownloadAction from './DownloadAction';
import SendEmailPopup from './SendEmailPopup';

const URL_ID = 'vStnId';

const ShareAction = (props) => {
  const [sendEmail, setSendEmail] = useState(false);
  // const checkedIds = React.useMemo(
  //   () => isArray(props?.checked) && props.checked.map((item) => item?.id),
  //   [props?.checked],
  // );
  const WhatsAppShare = () => {
    if (props.checked && props.checked.length === 0) {
      OpenNotification({
        type: 'error',
        title: 'No stone(stones) to share.',
      });
    } else {
      if (props.checked.length === 1) {
        const path = `${FORNT_SIDE_URL.diamondDetail}/${props.checked[0][URL_ID]}${
          props.btnName === 'drshare' ? '?direct-share' : ''
        }`;
        window.open(`https://web.whatsapp.com/send?text=${path}`);
      } else if (props.checked.length >= 2) {
        let msg = 'KGK : Diamonds Detail%0A%0A';

        props.checked.forEach((element) => {
          const path = `${FORNT_SIDE_URL.diamondDetail}/${element[URL_ID]}`;
          msg += `${path}%0A%0A`;
        });
        window.open(`https://web.whatsapp.com/send?text=${msg}`);
      }
      props.onClose();
    }
  };

  const SkypeShare = () => {
    if (props.checked && props.checked.length === 0) {
      OpenNotification({
        type: 'error',
        title: 'No stone(stones) to share.',
      });
    } else {
      if (props.checked.length === 1) {
        const path = `${FORNT_SIDE_URL.diamondDetail}/${props.checked[0][URL_ID]}`;
        window.open(`https://web.skype.com/share?url=${path}${props.btnName === 'drshare' ? '?direct-share' : ''}`);
      } else if (props.checked.length >= 2) {
        let msg = 'KGK : Diamonds Detail%0A%0A';

        props.checked.forEach((element) => {
          const path = `${FORNT_SIDE_URL.diamondDetail}/${element[URL_ID]}`;
          msg += `${path}%0A%0A`;
        });
        window.open(`https://web.skype.com/share?url=${msg}`);
      }
      props.onClose();
    }
  };

  const Wechat = () => {
    if (props.checked && props.checked.length === 0) {
      OpenNotification({
        type: 'error',
        title: 'No stone(stones) to share.',
      });
    } else {
      if (props.checked.length === 1) {
        const path = `${FORNT_SIDE_URL.diamondDetail}/${props.checked[0][URL_ID]}`;
        window.open(`https://web.wechat.com/send?text=${path}${props.btnName === 'drshare' ? '?direct-share' : ''}`);
      } else if (props.checked.length >= 2) {
        let msg = 'KGK : Diamonds Detail%0A%0A';

        props.checked.forEach(() => {
          const path = `${FORNT_SIDE_URL.diamondDetail}/${props.checked[0][URL_ID]}`;
          msg += `${path}%0A%0A`;
        });
        window.open(`https://web.wechat.com/send?text=${msg}`);
      }
      props.onClose();
    }
  };

  // const shareOption = () => {
  //   return (
  //     <button
  //       className="fillButton"
  //       onClick={}
  //     >
  //       Email
  //     </button>
  //   );
  // };
  const ShareOption = (props) => {
    return (
      <li onClick={props.onClick} className={props.activeClass}>
        <div className="dropdownBottomItem">
          <span className="shareOptionLabel">{props.title}</span>
        </div>
      </li>
    );
  };
  return (
    <>
      {/* handleCancel={onClose} */}
      <CommonModal title="Share Stone" handleCancel={props.onClose} visible className="sm-size">
        <div className="searchPopupCommon">
          <div className="shareOptionSet d-flex">
            <div className="shareOptionSetItem">
              <ul>
                <ShareOption onClick={() => setSendEmail(true)} title="Email" />
                <ShareOption onClick={() => WhatsAppShare()} title="Whats App" />
                <ShareOption onClick={() => SkypeShare()} title="Skype" />
                {/* <ShareOption onClick={() => Wechat()} title="WeChat" /> */}
              </ul>
            </div>
          </div>
        </div>
      </CommonModal>
      <SendEmailPopup
        sendEmail={sendEmail}
        ids={props.checked.map((item) => item.id)}
        onCancel={() => {
          setSendEmail(!sendEmail);
          props.onClose();
        }}
        checked={props.checked}

        // certFile={false}
        // img={false}
        // isPlt={false}
        // videoFile={false}
        // btnName={props.btnName === 'drshare' ? '?direct-share' : ''}
      />
    </>
  );
};

export default ShareAction;
