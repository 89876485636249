import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

// import classNames from 'util/classNames';
import { isFunction } from 'util/utils';

function BottomAction({ link, children, title, onClick, ...rest }) {
  const handleClick = React.useCallback(
    (event) => {
      if (isFunction(event?.preventDefault)) event.preventDefault();
      if (!isFunction(onClick)) return;
      onClick();
    },
    [onClick],
  );

  return link ? (
    <Link to={link} className="bottomStickyButton" {...rest}>
      <span>{children ?? title}</span>
    </Link>
  ) : (
    <a className="bottomStickyButton" href="#!" onClick={handleClick} {...rest}>
      <span>{children ?? title}</span>
    </a>
  );
}

BottomAction.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default React.memo(BottomAction);
