import React from 'react';

import { Popconfirm } from 'antd';
import PropTypes from 'prop-types';

import { isEmpty, isFunction } from 'util/utils';

import svgDelete from 'assets/svg/savedSearch/delete.svg';
import svgEdit from 'assets/svg/savedSearch/edit.svg';
import svgSearch from 'assets/svg/savedSearch/search.svg';

export const LiveStatus = (props) => <span className={`liveStatus ${props.status || 'offline'}`} />;

export const ListItemAction = ({ icon, width, ...props }) => (
  <div className="list-item-action savedSearchActionItem" {...props}>
    <img src={icon} alt="edit" width={width} />
  </div>
);
ListItemAction.propTypes = {
  icon: PropTypes.any,
};

export const ListItemTitle = ({ title, secondaryTitle }) => (
  <>
    <strong>{title}</strong> <span>{secondaryTitle}</span>
  </>
);
ListItemTitle.propTypes = {
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
};

const ListItemBody = (props) => {
  const { body, onEdit, onDelete, onSearch, entityName } = props;
  const name = React.useMemo(() => (!isEmpty(entityName) ? entityName : 'Entry'), [entityName]);

  return (
    <div className="list-item savedSearchItem">
      {/* {header && <div className="list-item-header savedSearchTop"></div>} */}
      {body && (
        <div className="list-item-body savedSearchBottom">
          {body}
          {(isFunction(onEdit) || isFunction(onDelete) || isFunction(onSearch)) && (
            <span className="list-item-actions savedSearchAction">
              {isFunction(onEdit) && <ListItemAction icon={svgEdit} width="30" alt="edit" onClick={onEdit} />}
              {isFunction(onDelete) && (
                <Popconfirm
                  placement="left"
                  title={`Are you sure you want to delete this ${name}?`}
                  okText={`Delete`}
                  onConfirm={onDelete}
                >
                  <span>
                    <ListItemAction icon={svgDelete} alt="delete" width="30" />
                  </span>
                </Popconfirm>
              )}
              {isFunction(onSearch) && <ListItemAction icon={svgSearch} alt="search" onClick={onSearch} width="30" />}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

ListItemBody.propTypes = {
  header: PropTypes.object,
  body: PropTypes.object,
  entityName: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSearch: PropTypes.func,
};

export default ListItemBody;
