import React, { Component } from 'react';

import MainHeading from '../../containers/MainHeading';
// import CustomTab from '../../containers/Tab';

class ClientDrawer extends Component {
  render() {
    return (
      <>
        <MainHeading title="Add Client" />
        {/* <CustomTab data={TabData.diamondDetailtab} tabHeaderClass="customerMaintab diamondDetailTopTab">

            </CustomTab> */}
      </>
    );
  }
}

export default ClientDrawer;
