import { getDefaultPermissions } from './_getDefaultPermissions';

export const ShortcutsPermissions = [
  {
    mainModule: 'SHORTCUTS',
    module: 'CLIENT_KYC',
    url: '/user/configuration/user-infomation',
    title: 'Clients / KYC',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'SHORTCUTS',
    module: 'USER',
    url: '/user/configuration/user-infomation',
    title: 'User',
    permissions: getDefaultPermissions(),
  },
];
