import React from 'react';

import { Checkbox } from 'antd';
import { Formik, Form } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import DatePickerInput from 'containers/DatePickerInput';
import FileUpload from 'containers/FileUpload';
import InputBlock from 'containers/InputBlock';
import TextArea from 'containers/TextArea';

import CircularProgress from 'components/CircularProgress';

import * as NewsAPI from 'services/NewsApi';
import { showNotification } from 'services/NotificationService';

import { isEmpty } from 'util/utils';

const initialValues = {
  title: '',
  fullDescription: '',
  shortDescription: '',
  fromDateTime: '',
  toDateTime: '',
  logoImage: '',
  mobileImage: '',
  isActive: true,
};

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required('Title is required').typeError('Title must be a string'),
  // fromdateTime: Yup.date().required('Start Date is required'),
  toDateTime: Yup.date().min(Yup.ref('fromDateTime'), 'End date must be grater than Start Date'),

  // images: Yup.array().required('Background Image is required'),
});

function NewsForm({ update, closeForm }) {
  const saveContinue = React.useRef(false);
  const [loader, setLoader] = React.useState(false);
  const getInitialValues = React.useCallback(() => {
    if (!isEmpty(update)) {
      update.fromDateTime = update?.fromDateTime && moment(update?.fromDateTime);
      update.toDateTime = update?.toDateTime && moment(update?.toDateTime);
    }
    return Object.fromEntries(
      Object.entries(initialValues).map(([key, value]) => [key, isEmpty(update?.[key]) ? value : update?.[key]]),
    );
  }, [update]);

  const submitForm = React.useCallback(
    async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      setLoader(true);
      const [err, res] = await (!isEmpty(update?.id)
        ? NewsAPI.updateNews(update?.id, { ...update, ...values })
        : NewsAPI.storeNews(values));
      setSubmitting(false);

      if (!err) {
        showNotification(res);
        if (isEmpty(update?.id)) resetForm();
        if (!saveContinue.current) closeForm();
      }
      setLoader(false);
    },
    [update, closeForm],
  );

  return (
    <Formik
      initialValues={getInitialValues()}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({ handleChange, handleBlur, setValues, setFieldTouched, touched, errors, values }) => (
        <Form>
          <div className="d-flex flex-wrap homeSettingWrapper mt-20">
            <InputBlock
              name="title"
              label="Title *"
              placeholder="Title"
              value={values.title}
              error={touched?.title && errors?.title}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <DatePickerInput
              label="Start Date"
              value={values.fromDateTime}
              onOpenChange={(open) => !open && setFieldTouched('fromDateTime')}
              onChange={(fromDateTime) => setValues((values) => ({ ...values, fromDateTime }))}
              // error={touched?.fromdateTime && errors?.fromdateTime}
              showTime
              noPast
            />
            <DatePickerInput
              label="End Date"
              value={values.toDateTime}
              onOpenChange={(open) => !open && setFieldTouched('toDateTime')}
              onChange={(toDateTime) => setValues((values) => ({ ...values, toDateTime }))}
              error={touched?.toDateTime && errors?.toDateTime}
              showTime
              noPast
            />
            <TextArea
              name="fullDescription"
              label="Full Description"
              placeholder="Description"
              value={values.fullDescription}
              onBlur={handleBlur}
              onChange={handleChange}
              // error={touched?.description && errors?.description}
            />
            <TextArea
              name="shortDescription"
              label="Short Description"
              placeholder="Description"
              value={values.shortDescription}
              onBlur={handleBlur}
              onChange={handleChange}
              // error={touched?.description && errors?.description}
            />
            <div className="d-flex from-group">
              <FileUpload
                fileType="image/png,image/jpeg,image/gif,image/jpg,video/x-matroska,video/mp4,video/x-ms-wmv"
                name="mobileImage"
                label="Upload Mobile Image"
                accept="JPG,JPEG,PNG,GIF,MKV,WMV,MP4"
                size="JPG,JPEG,PNG,GIF,MKV,WMV,MP4 file allowed"
                isValidate
                files={[values?.mobileImage]}
                onBlur={handleBlur}
                onChange={(mobileImage) => setValues((values) => ({ ...values, mobileImage }))}
                // error={touched?.mobileImage && errors?.mobileImage}
              />
            </div>
            <div className="d-flex from-group width-100">
              <FileUpload
                fileType="image/png,image/jpeg,image/gif,image/jpg,video/x-matroska,video/mp4,video/x-ms-wmv,video/*"
                fullWidth="width-auto"
                name="logoImage"
                label="Upload Images"
                isValidate
                //multiple
                accept="JPG,JPEG,PNG,GIF,MKV,WMV,MP4"
                size="JPG,JPEG,PNG,GIF,MKV,WMV,MP4 file allowed"
                files={[values?.logoImage]}
                onChange={(logoImage) => setValues((values) => ({ ...values, logoImage }))}
                onBlur={handleBlur}
              />
            </div>
            <div className="from-group mt-20">
              <Checkbox
                name="isActive"
                checked={values.isActive}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched?.isActive && errors?.isActive}
              >
                Is Active
              </Checkbox>
            </div>
            <div className="width-100 d-flex mt-40">
              <button className="commonButton mr-10" type="submit" value="Submit">
                {isEmpty(update?.id) ? 'Save' : 'Update'}
              </button>
              <button className="commonOutine" type="reset" onClick={closeForm}>
                Back
              </button>
            </div>
            {loader && <CircularProgress className="fixLoader" />}
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default NewsForm;
