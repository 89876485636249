import Cookies from 'js-cookie';

import Storage from 'services/storage';
import { handleLogout } from 'services/util';

import axios from 'util/Api';
import { isEmpty } from 'util/utils';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_DEFAULT_PASSWORD_SET,
  USERNAME_PASSWORD,
  VERIFY_OTP,
  REMOVE_USERNAME_PASSWORD,
} from 'constants/ActionTypes';
import { USERS_TYPE } from 'constants/Common';

import { store } from 'appRedux/store';

export const saveLoginData = async (res, dispatch = store.dispatch) => {
  const permissions =
    res?.data?.user?.type !== USERS_TYPE.SUPER_ADMIN ? res?.data?.userPermissions?.adminPermission : [];

  // set session data
  Storage.set('user', res?.data?.user); // set user
  Cookies.set('token', res?.data?.token?.jwt, { expires: 365, sameSite: 'strict' }); // set token
  Storage.set('dn-master', res?.data?.masters); // set masters
  Storage.set('permission', permissions); // set permissions

  // set axios access-token header
  axios.defaults.headers.common['access-token'] = 'JWT ' + res?.data?.token?.jwt;

  // set token and user in reducer
  dispatch({ type: USER_TOKEN_SET, payload: res?.data?.token?.jwt });
  dispatch({ type: USER_DATA, payload: res?.data?.user });

  // extra data
  Storage.set('default-client', !isEmpty(res?.data?.defaultAccount) ? res?.data?.defaultAccount : {});
  Storage.set('default-broker', !isEmpty(res?.data?.defaultBroker) ? res?.data?.defaultBroker : {});
};

export const setInitUrl = (url) => ({ type: INIT_URL, payload: url });

export const userSignUp =
  ({ email, password, name }) =>
  (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post('auth/register', { email, password, name })
      .then(({ data }) => {
        if (data.result) {
          Cookies.set('token', data?.token?.access_token, { expires: 365, sameSite: 'strict' });
          axios.defaults.headers.common['access-token'] = 'JWT ' + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

export const userGetOTP = (values, cb) => (dispatch) => {
  const { username, password } = values;
  dispatch({ type: FETCH_START });
  axios
    .post('admin/v1/auth/send-login-otp', {
      username,
      password,
      masters: [
        'COLOR',
        'CLARITY',
        'SHAPE',
        'CUT',
        'FLUORESCENCE',
        'SHADE',
        'LAB',
        'POLISH',
        'SYMMETRY',
        'LOCATION',
        'COUNTRYOFORIGIN',
        'BLACK_INCLUSION',
        'OPEN_INCLUSION_TABLE',
        'MILKEY',
        'WHITE_INCLUSION_CROWN',
        'OPEN_INCLUSION_CROWN',
        'EYECLEAN',
        'OPEN_INCLUSION_PAVILION',
        'ORIGIN',
        'BLACK_INCLUSION_CROWN',
        'H_AND_A',
        'WHITE_INCLUSION_TABLE',
        'FANCY_COLOR',
        'INTENSITY',
        'OVERTONE',
        'KEY_TO_SYMBOLS',
        'GIRDLE',
        'CULET',
        'GIRDLE_COND',
        'CULET_COND',
        'COMPANY_SIZE',
        'BLACK_INCLUSION_TABLE',
        'BLACK_INCLUSION_SIDE',
        'WHITE_INCLUSION_SIDE',
        'BRILLIANCY',
        'EYECLEAN',
        'DAY_TERM',
        'CURRENCY',
        'COMPANY_GROUP',
        'BLOCK_STAGE',
        'NATURE_OF_ORG',
        'BUSINESS_TYPE',
        'PROFORMA_INSTRUCTION_TYPES',
        'DOC_TYPE_PERSONAL',
        'DOC_TYPE_BUSINESS',
        'BILL_TYPE',
        'LOGISTIC',
        'LUSTER',
        'PRE_SHIP_COMPANY',
        'USER_ROLES',

        'NATURAL_GIRDLE',
        'NATURAL_CROWN',
        'NATURAL_PAVILION',
        'SURFACE_GRAINING',
        'INTERNAL_GRAINING',
        'TINGE_INTENSITY',
        'TABLE_BLACK',
        'SIDE_BLACK',
        'TABLE_INCLUSION',
        'SIDE_INCLUSION',
      ],
    })
    .then(({ data }) => {
      if (data.code === 'OK') {
        if (data.data.token && data.data.user) {
          const user = data.data.user;

          if (user.defaultPassword) {
            dispatch({
              type: USER_DEFAULT_PASSWORD_SET,
              payload: {
                userId: user.id,
                defaultPassword: user.defaultPassword,
                token: 'JWT ' + data.data.token.jwt,
              },
            });
          } else {
            saveLoginData(data, dispatch);
          }
          dispatch({ type: VERIFY_OTP, payload: true });
          dispatch({ type: REMOVE_USERNAME_PASSWORD });
          dispatch({ type: FETCH_SUCCESS });
          cb(true);
        } else {
          dispatch({ type: USERNAME_PASSWORD, payload: { username, password } });
          cb(false);
        }
        dispatch({ type: FETCH_SUCCESS });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    })
    .catch((error) => {
      const message = error?.response?.data?.message || 'Something went wrong';
      dispatch({ type: FETCH_ERROR, payload: message });
      cb({ error: message });
    });
};

export const userSignIn = (request, cb) => (dispatch) => {
  dispatch({ type: FETCH_START });
  axios
    .post('admin/v1/auth/login', { ...request })
    .then(({ data }) => {
      if (data.code === 'OK') {
        const user = data.data.user;
        if (user.defaultPassword) {
          dispatch({
            type: USER_DEFAULT_PASSWORD_SET,
            payload: {
              userId: user.id,
              defaultPassword: user.defaultPassword,
              token: 'JWT ' + data.data.token.jwt,
            },
          });
        } else {
          saveLoginData(data, dispatch);
        }

        dispatch({ type: VERIFY_OTP, payload: true });
        dispatch({ type: REMOVE_USERNAME_PASSWORD });
        dispatch({ type: FETCH_SUCCESS });
        cb(true);
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
        dispatch({ type: VERIFY_OTP, payload: false });
        cb(false);
      }
    })
    .catch((error) => {
      const message = error?.response?.data?.message || 'Something went wrong';
      dispatch({ type: FETCH_ERROR, payload: message });
      cb({ error: message });
    });
};

export const getUser = () => (dispatch) => {
  dispatch({ type: FETCH_START });
};

export const signoutUserSuccess = () => ({ type: SIGNOUT_USER_SUCCESS });

export const userSignOut = () => {
  document.body.classList.add('showLoading');
  handleLogout();
  setTimeout(() => document.body.classList.remove('showLoading'), 2000);
};
