import React from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import InputBlock from 'containers/InputBlock';

import { getSetting, settingUpsert } from 'services/CommonApi';
import { showNotification } from 'services/NotificationService';

import { isEmpty, isObject } from 'util/utils';

import { PROJECT_SETTINGS } from 'constants/Common';

const defaultValues = {
  minBuynowDiscount: 0,
  maxBuynowDiscount: 0,
  days: 0,
};
const DiscountSettings = () => {
  const [setting, setSetting] = React.useState({});

  const initialValues = React.useMemo(() => {
    const updatedEntries = Object.entries(defaultValues).map(([key, value]) => {
      return [key, isEmpty(setting?.[key]) ? value : setting?.[key]];
    });
    return Object.fromEntries(updatedEntries);
  }, [setting]);

  const getList = React.useCallback(async () => {
    const [err, res] = await getSetting({ type: PROJECT_SETTINGS.TYPE.MIN_MAX });
    if (!err && isObject(res?.data?.settings)) setSetting(res?.data?.settings);
  }, []);

  React.useEffect(() => {
    getList();
  }, [getList]);

  const onSubmit = React.useCallback(async (values, { setSubmitting }) => {
    setSubmitting(true);
    const [err, res] = await settingUpsert({ type: PROJECT_SETTINGS.TYPE.MIN_MAX, settings: values });
    setSubmitting(false);
    if (!err) {
      showNotification(res);
    }
  }, []);

  const validationSchema = Yup.object().shape({
    minBuynowDiscount: Yup.number()
      .typeError('Please Insert Min Discount')
      .min(-100, 'Min Discount must be greated than or equal to -100')
      .max(0, 'Min Discount must be less than or equal to 0')
      .required('Please Insert Min Discount'),
    maxBuynowDiscount: Yup.number()
      .typeError('Please Insert Max Discount')
      .min(0, 'Min Discount must be greated than or equal to 0')
      .max(100, 'Min Discount must be less than or equal to 100')
      .required('Please Insert Max Discount'),
    days: Yup.number()
      .typeError('Please Insert Days.')
      .min(0, 'Min valid days must be greated than or equal to 0')
      .max(100, 'Min valid days must be less than or equal to 100'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const { handleSubmit, handleReset, handleChange, handleBlur } = formik;
  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <div className="headeTitle">
        <h2>Discount Settings</h2>
      </div>
      <div className="d-flex flex-wrap discountSetting mt-20">
        <InputBlock
          type="number"
          name="minBuynowDiscount"
          label="Min Discount"
          placeholder="Min Discount"
          min={-100}
          max={0}
          value={formik.values.minBuynowDiscount}
          error={formik.touched?.minBuynowDiscount && formik.errors?.minBuynowDiscount}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <InputBlock
          type="number"
          name="maxBuynowDiscount"
          label="Max Discount"
          placeholder="Max Discount"
          min={0}
          max={100}
          value={formik.values.maxBuynowDiscount}
          error={formik.touched?.maxBuynowDiscount && formik.errors?.maxBuynowDiscount}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <InputBlock
          type="number"
          name="days"
          label="Offer Validity days"
          placeholder="Offer Validity days"
          min={0}
          max={100}
          value={formik.values.days}
          error={formik.touched?.days && formik.errors?.days}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <div className="width-100 d-flex mt-40">
          <button className="commonButton mr-10" type="submit" value="Submit">
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default DiscountSettings;
