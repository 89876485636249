import React from 'react';
import { useSelector } from 'react-redux';

import { fetchcolumnAsyncMemo } from 'components/Inventory/inventoryFunctions';
import { PAGE_LIMIT } from 'components/Inventory/table-config';

import { LoaderService } from 'services/LoaderService';
import { PermissionService } from 'services/PermissionService';

import { isArray, isFunction, isNotEmpty, isNumber, isObject, isString } from './utils';

export const useAutoRef = (value) => {
  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};

export const useBoolean = (initialValue = false) => {
  const [state, _setState] = React.useState(Boolean(initialValue));
  const setState = React.useMemo(
    () => ({
      true: () => _setState(true),
      false: () => _setState(false),
      toggle: () => _setState((v) => !v),
    }),
    [],
  );
  return [state, setState];
};

export const useCompositeState = (initialState) => {
  const [state, _setState] = React.useState(initialState);
  const initialStateRef = useAutoRef(initialState);

  const setState = React.useCallback((objectOrCallback, spread = true) => {
    const callback = isFunction(objectOrCallback) ? objectOrCallback : undefined;
    const object = isObject(objectOrCallback) ? objectOrCallback : {};

    _setState((state) => {
      const _object = callback ? callback(state) : object;
      return spread ? { ...state, ..._object } : { ..._object };
    });
  }, []);

  const resetState = React.useCallback(() => {
    _setState(initialStateRef.current);
  }, [initialStateRef]);

  React.useDebugValue(state);
  return [state, setState, resetState];
};

export const useRefEffect = (ref, value) => {
  React.useEffect(() => void (ref.current = value), [ref, value]);
};

export const useCustomRef = (initialValue) => {
  const ref = React.useRef(initialValue);
  const refProxy = React.useMemo(
    () => ({
      set: (value) => void (ref.current = value),
      get: () => ref?.current,
    }),
    [],
  );
  return refProxy;
};

export const useCounter = (init = 0) => {
  const [count, set] = React.useState(init);
  const inc = React.useCallback((cb = () => {}) => set((n) => ((n = n > 0 ? ++n : 1), cb(n), n)), []);
  const dec = React.useCallback((cb = () => {}) => set((n) => ((n = n > 0 ? --n : 0), cb(n), n)), []);
  return [count, inc, dec];
};

export const useLoading = (init = false, disp = false) => {
  const [loading, inc, dec] = useCounter(init ? 1 : 0);
  const countRef = React.useRef(loading);
  React.useEffect(() => void (countRef.current = loading), [loading]);

  const start = React.useCallback(() => inc(() => disp && LoaderService.startLoading()), [inc, disp]);
  const stop = React.useCallback(() => dec(() => disp && LoaderService.stopLoading()), [dec, disp]);

  React.useEffect(() => {
    return () => {
      if (disp && isNumber(countRef.current) && countRef.current > 0) {
        LoaderService.adjustCount(-Math.abs(countRef.current));
      }
    };
  }, [disp]);

  return [Boolean(loading), start, stop];
};

export const usePagination = (page = 1, limit = PAGE_LIMIT) => {
  const [state, _setState] = React.useState({ page, limit });
  const setPagination = React.useCallback(
    (page, limit) => _setState((state) => ({ page: page ?? state?.page, limit: limit ?? state?.limit })),
    [],
  );
  return [state?.page, state?.limit, setPagination];
};

export const useColumns = (setDefault = false, options = {}) => {
  const [columns, _setColumns] = React.useState([]);
  const defaultOptions = React.useRef(options);
  React.useEffect(() => {
    defaultOptions.current = options;
  }, [options]);

  const setColumns = React.useCallback(async (params) => {
    const { type, transform, beforeSet, afterSet, ...rest } = { ...defaultOptions.current, ...params };
    if (isFunction(beforeSet)) beforeSet();
    const columns = await fetchcolumnAsyncMemo(type, rest);
    isFunction(transform) ? _setColumns(transform(columns)) : _setColumns(columns);
    if (isFunction(afterSet)) afterSet();
  }, []);

  React.useEffect(() => {
    if (setDefault) setColumns();
  }, [setDefault, setColumns]);

  return [columns, setColumns];
};

export const useList = (list = [], count = 0) => {
  const [state, _setState] = React.useState({ list, count });
  const setList = React.useCallback((list, count) => _setState((state) => ({ ...state, list, count })), []);
  const resetList = React.useCallback(() => _setState(() => ({ list: [], count: 0 })), []);
  return [state?.list, state?.count, setList, resetList];
};

export const useSelectedRows = (currentType, mapFields = []) => {
  const rowList = useSelector((state) => state?.diamondData?.selectedRows?.[currentType] ?? []);
  const rowMap = React.useMemo(
    () => Object.fromEntries(mapFields.map((field) => [field, rowList.map((row) => row?.[field])])),
    [mapFields, rowList],
  );
  return [rowList, rowMap];
};

export const useCurrentType = (...args) => {
  const currentType = React.useMemo(
    () => (isArray(args) ? args.filter(isString).filter(isNotEmpty).join('.') : `${args}`),
    [args],
  );
  return [currentType];
};

export const usePermissions = (path) => {
  const [module, _setModule] = React.useState();
  React.useDebugValue(module);

  const setModule = React.useCallback(() => {
    _setModule(PermissionService.getPermission(path));
  }, [path]);

  React.useEffect(() => {
    setModule();
    const unlisten = PermissionService.events.updateTree.listen(() => setModule());
    return () => void unlisten();
  }, [setModule]);

  return module;
};

export const useEffectOnMount = (callback) => {
  const didMount = React.useRef(true);
  React.useEffect(() => {
    if (didMount.current) {
      callback();
      didMount.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useIsMounted = () => {
  const isMounted = React.useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    return () => void (isMounted.current = false);
  }, []);
  return isMounted;
};
