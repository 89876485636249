import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import moment from 'moment';

import CommonBackButton from 'containers/CommonBackButton';
import ListingTableWrapper from 'containers/ListingTableWrapper';
import ListingWrapper from 'containers/ListingWrapper';

import Table from 'components/Inventory/TableBack';
import { searchFn } from 'components/Inventory/inventoryFunctions';
import { filterList } from 'components/Inventory/table-utils';
import OfferWise from 'components/Transaction/Offer/OfferWise';

import { createDiamondTrack } from 'services/DiamondApi';
import { historyDataPaginate } from 'services/DiamondBlockApi';
import { RowSelectService } from 'services/RowSelectService';
import Storage from 'services/storage';
import UtilService from 'services/util';

import { isEmpty, isArray, validateNumberInput } from 'util/utils';

import { TRACK_TYPES } from 'constants/Common';
import CommonApi from 'constants/CommonApi';
import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

// import { getOfficeConfig } from 'services/officeApi';

import CellInputHeader from './CellInputHeader';

const caratRange = [
  '0.30-0.33',
  '0.34-0.36',
  '0.37-0.39',

  '0.40-0.44',
  '0.45-0.49',

  '0.50-0.54',
  '0.55-0.59',
  '0.60-0.64',
  '0.65-0.69',
  '0.70-0.73',

  '0.74-0.76',
  '0.77-0.79',

  '0.80-0.83',
  '0.84-0.87',
  '0.88-0.89',

  '0.90-0.95',
  '0.96-0.99',

  '1.00-1.05',
  '1.06-1.09',
  '1.10-1.14',
  '1.15-1.19',
  '1.20-1.24',
  '1.25-1.29',
  '1.30-1.34',
  '1.35-1.39',
  '1.40-1.44',
  '1.45-1.49',
  '1.50-1.54',
  '1.55-1.59',
  '1.60-1.64',
  '1.65-1.69',
  '1.70-1.74',
  '1.75-1.79',
  '1.80-1.84',
  '1.85-1.89',
  '1.90-1.94',
  '1.95-1.99',

  '2.00-2.19',
  '2.20-2.39',
  '2.40-2.49',
  '2.50-2.69',
  '2.70-2.89',
  '2.90-2.99',

  '3.00-3.49',
  '3.50-3.99',

  '4.00-4.49',
  '4.50-4.99',

  '5.00-100',
];

export function getSoldDiamondWithFilter(list = [], stone = {}) {
  if (isEmpty(list)) return [];

  const MASTER = Storage.get('dn-master');
  const SHAPE_LIST = find(MASTER?.SHAPE ?? [], { code: stone.shpNm })?.likeKeyWords ?? null;

  const crtRange = (() => {
    const output = isArray(MASTER.SIZE) ? MASTER.SIZE?.map((size) => size.group) : caratRange;
    return uniq(output);
  })();

  const group = crtRange?.filter((el) => {
    return parseFloat(el.split('-')[0]) <= stone.crt && parseFloat(el.split('-')[1]) >= stone.crt;
  });
  const from = parseFloat(group[0]?.split('-')?.[0]);
  const to = parseFloat(group[0]?.split('-')?.[1]);

  const output = (() => {
    const _list = list?.filter(
      (_stone) =>
        _stone.crt >= from &&
        _stone.crt <= to &&
        _stone.colNm &&
        _stone.colNm === stone.colNm &&
        _stone.clrNm === stone.clrNm &&
        _stone.cutNm === stone.cutNm &&
        _stone.polNm === stone.polNm &&
        _stone.symNm === stone.symNm &&
        _stone.fluNm === stone.fluNm,
    );
    return !SHAPE_LIST
      ? _list?.filter((_stone) => _stone.shpNm === stone.shpNm)
      : _list?.filter((_stone) => _stone.shpNm === stone.shpNm || SHAPE_LIST.includes(_stone.shpNm));
  })();

  return output;
}

const offerCaratGrp = [
  '0.01-0.09',
  '0.10-0.19',
  '0.20-0.29',
  '0.30-0.39',
  '0.40-0.49',
  '0.50-0.59',
  '0.60-0.69',
  '0.70-0.79',
  '0.80-0.89',
  '0.90-0.99',
  '1.00-1.49',
  '1.50-1.99',
  '2.00-2.99',
  '3.00-3.99',
  '4.00-4.99',
  '5.00-100.00',
];

class Offer extends Component {
  state = {
    data: [],
    checked: [],
    columns: [],
    collapseAll: false,
    hideRange: false,
    tracks: [],
    showOffer: false,
    historyData: [],
    showHistory: [],
    VALUES: {},
    checkedData: [],
    client: this.props.client ?? {},
  };

  getHistoryData = async () => {
    const request = {
      from: moment().add(-1, 'month').toISOString(),
      to: moment().toISOString(),
    };

    this.setState({ historyLoading: true });
    const [err, res] = await historyDataPaginate(request);

    if (!err && res?.code === 'OK' && res?.data?.list.length) {
      let historyData = [];
      res.data.list.forEach((x) => {
        x.memoDetails.forEach((m) => {
          m.user = x.user;
          m.date = x.updatedAt;
        });
        historyData = [...historyData, ...x.memoDetails];
      });
      this.setState({ historyData }, () => this.fetchData(this.state.data));
      this.setState({ historyLoading: false });
    }
  };

  componentDidMount() {
    this.setState({ tracks: isArray(this.props.tracks) ? this.props.tracks : [] });
    this.getColumns();
    this.setData();
  }

  // getConfig = async () => {
  //   const [err, res] = await getOfficeConfig({});
  // };

  getColumns = () => {
    const columns = [
      {
        Header: 'SID',
        id: 'vStnId',
        accessor: 'vStnId',
        Cell: ({ row }) => (
          <a href="!#" className="tableLink">
            {row.original.vStnId}
          </a>
        ),
      },
      {
        Header: 'CT.',
        accessor: 'crt',
        id: 'crt',
        Cell: ({ cell }) => <>{parseFloat(cell.value || 0).toFixed(2)}</>,
      },
      {
        Header: 'Shape',
        accessor: 'shpNm',
        id: 'shpNm',
      },
      {
        Header: 'Color',
        accessor: 'colNm',
        id: 'colNm',
      },
      {
        Header: 'Clarity',
        accessor: 'clrNm',
        id: 'clrNm',
      },
      {
        Header: 'Flu',
        accessor: 'fluNm',
        id: 'fluNm',
      },
      {
        Header: 'Cut',
        accessor: 'cutNm',
        id: 'cutNm',
      },
      {
        Header: 'Pol',
        accessor: 'polNm',
        id: 'polNm',
      },
      {
        Header: 'Syn',
        accessor: 'symNm',
        id: 'symNm',
      },
      {
        Header: 'Rap',
        accessor: 'rap',
        id: 'rap',
        Cell: ({ cell }) => <>{parseFloat(cell.value || 0).toFixed(0)}</>,
      },
      // {
      //   Header: 'LAP %',
      //   id: 'buyBack',
      //   accessor: 'buyBack',
      //   Cell: ({ cell }) => <a className="rapPrice">{parseFloat(cell.value || 0).toFixed(2)}</a>,
      // },
      // {
      //   Header: 'Price',
      //   accessor: 'buyCtPr',
      //   id: 'buyCtPr',
      //   Cell: ({ cell, row }) => (
      //     <a className="rapPrice">
      //       {parseFloat(cell.value || (row.original.buyAmt || 0) / row.original.crt).toFixed(2)}
      //     </a>
      //   ),
      // },
      // {
      //   Header: 'Amount',
      //   accessor: 'buyAmt',
      //   id: 'buyAmt',
      //   Cell: ({ cell }) => <a className="rapPrice">{parseFloat(cell.value || 0).toFixed(2)}</a>,
      // },
      {
        Header: 'Ask%',
        accessor: 'back',
        id: 'back',
        Cell: ({ cell }) => (
          <a href="!#" className="askingPrice">
            {parseFloat(cell.value || 0).toFixed(2)}
          </a>
        ),
      },
      {
        Header: 'Price/Ct',
        accessor: 'ctPr',
        id: 'ctPr',
        Cell: ({ cell }) => (
          <a href="!#" className="askingPrice">
            {parseFloat(cell.value || 0).toFixed(2)}
          </a>
        ),
      },
      {
        Header: 'Sale Amt',
        accessor: 'amt',
        id: 'amt',
        Cell: ({ cell }) => (
          <a href="!#" className="askingPrice">
            {parseFloat(cell.value || 0).toFixed(2)}
          </a>
        ),
      },
      {
        Header: 'Disc. Diff.',
        id: 'diff',
        accessor: 'diff',
        Cell: ({ row }) => {
          const inputRef = React.useRef();
          const prevValueRef = React.useRef();

          React.useEffect(() => {
            if (prevValueRef.current !== row.original.diff) {
              inputRef.current.value = row.original.diff;
              prevValueRef.current = row.original.diff;
            }
          }, [row.original.diff]);

          return (
            <div className="tableInput">
              <input
                ref={inputRef}
                disabled={true}
                onKeyPress={validateNumberInput}
                onBlur={(e) => this.onChangeDiff(e.target.value, row.original.id)}
              />
            </div>
          );
        },
      },
      {
        Header: 'Offer Dis%',
        id: 'discount',
        accessor: 'discount',
        Cell: ({ row }) => {
          const inputRef = React.useRef();
          const prevValueRef = React.useRef();

          React.useEffect(() => {
            if (prevValueRef.current !== row.original.discount) {
              inputRef.current.value = row.original.discount;
              prevValueRef.current = row.original.discount;
            }
          }, [row.original.discount, row.original.isFcCol]);

          return (
            <div className="tableInput">
              <input
                ref={inputRef}
                onKeyPress={validateNumberInput}
                disabled={row.original.isFcCol}
                onBlur={(e) => this.onChangeDiscount(e.target.value, row.original.id)}
              />
            </div>
          );
        },
      },
      {
        Header: 'Offer Price/Ct',
        id: 'offerCtPr',
        accessor: 'offerCtPr',
        Cell: ({ row }) => {
          const inputRef = React.useRef();
          const prevValueRef = React.useRef();

          React.useEffect(() => {
            if (prevValueRef.current !== row.original.offerCtPr) {
              inputRef.current.value = row.original.offerCtPr;
              prevValueRef.current = row.original.offerCtPr;
            }
          }, [row.original.offerCtPr]);

          return (
            <div className="tableInput">
              <input
                ref={inputRef}
                onKeyPress={validateNumberInput}
                onBlur={(e) => this.onChangePricePerCarat(e.target.value, row.original.id)}
              />
            </div>
          );
        },
      },
      {
        Header: 'Final',
        id: 'final',
        accessor: 'final',
      },
    ]?.filter((el) => el && el);

    this.setState({ columns }, () => this.fetchData());
  };

  showHistory = (vStnId) => {
    let showHistory = this.state.showHistory;
    if (showHistory.includes(vStnId)) showHistory = showHistory?.filter((el) => el !== vStnId);
    else showHistory.push(vStnId);
    this.setState({ showHistory });
  };

  fetchData = (list) => {
    const twoCall = uniq(this.props.checked?.map((x) => (x.newDiscount ? 'update' : 'add')));
    if (twoCall.length > 1) this.setState({ twoCall: true });
    let offerCrtGroup = this.props.checked
      ?.map((x) => (x.sizeGrp ? x.sizeGrp.replace(/' '/g, '') : ''))
      ?.filter((el) => el && el);
    if (!offerCrtGroup?.length) offerCrtGroup = offerCaratGrp;

    const data = list
      ? list
      : [...this.props.checked]
          ?.map((x) => {
            const grp = offerCrtGroup?.filter((el) => el.split('-')[0] <= x.crt && el.split('-')[1] >= x.crt)[0];
            const discount = parseFloat((this.props.inOffer ? x.newDiscount : x.back) || 0).toFixed(2);
            const offerCtPr = parseFloat(
              (this.props.inOffer ? x.newPricePerCarat : x.rap + (x.rap * discount) / 100) || 0,
            ).toFixed(2);
            return {
              ...x,
              discount,
              diff: parseFloat(this.props.inOffer ? x.newDiscount - (x.back ?? 0) : 0).toFixed(2),
              offerCtPr,
              final: parseFloat(offerCtPr * x.crt || 0).toFixed(2),
              offerGrp: grp ?? offerCrtGroup[offerCrtGroup.length - 1],
            };
          })
          ?.sort((b, a) => a.crt - b.crt);

    data?.length &&
      [...data].forEach((val, i) => {
        if (!data[i - 1] || data[i - 1].offerGrp !== val.offerGrp) {
          val.isOfferHeader = true;
          // val.diffIndex = findIndex(this.state.columns, { id: 'diff' });
          val.calc = [''];
          const rapAvg = sum(data?.filter((el) => el.offerGrp === val.offerGrp).map((d) => d.crt * d.rap));
          const calcCrt = sum(data?.filter((el) => el.offerGrp === val.offerGrp).map((d) => d.crt));
          const calcAmt = sum(data?.filter((el) => el.offerGrp === val.offerGrp).map((d) => d.buyAmt || 0));
          const calcPr = calcCrt ? calcAmt / calcCrt : 0;
          const calcBack = calcCrt ? (calcPr / (rapAvg / calcCrt) - 1) * 100 : 0;
          const amt = sum(data?.filter((el) => el.offerGrp === val.offerGrp).map((d) => d.amt));
          const ctPr = calcCrt ? amt / calcCrt : 0;
          let back = calcCrt ? (ctPr / (rapAvg / calcCrt) - 1) * 100 : 0;
          if (isNaN(back)) back = 0;

          // let finalCalc = sum(
          //   data?.filter((el) => el.offerGrp === val.offerGrp).map((d) => (d.discount / 100 + 1) * d.rap * d.crt),
          // );
          // let differCalc = parseFloat(finalCalc - calcAmt || 0).toFixed(2);
          return this.state.columns.forEach((col) => {
            if (col.id === 'crt') val.calc.push(parseFloat(calcCrt).toFixed(2));
            else if (col.id === 'diff')
              val.calc.push(
                <CellInputHeader disabled={true} onChange={this.diffChange} args={val?.offerGrp} inputOnly />,
              );
            else if (col.id === 'discount')
              val.calc.push(<CellInputHeader onChange={this.discChange} args={val?.offerGrp} inputOnly />);
            else if (col.id === 'offerCtPr')
              val.calc.push(<CellInputHeader onChange={this.pricePerCaratChange} args={val?.offerGrp} inputOnly />);
            else if (col.id === 'buyBack') val.calc.push(parseFloat(calcBack).toFixed(2));
            else if (col.id === 'buyCtPr') val.calc.push(parseFloat(calcPr).toFixed(2));
            else if (col.id === 'buyAmt') val.calc.push(parseFloat(calcAmt).toFixed(2));
            else if (col.id === 'back') val.calc.push(parseFloat(back * -1).toFixed(2));
            else if (col.id === 'ctPr') val.calc.push(parseFloat(ctPr).toFixed(2));
            else if (col.id === 'amt') val.calc.push(parseFloat(amt).toFixed(2));
            else if (col.id === 'final')
              val.calc.push(
                <>
                  <span>{parseFloat(val.final || 0).toFixed(2)}</span>
                  {/* <span className={differCalc > 0 ? 'greenColor' : 'redColor'}> ({differCalc})</span> */}
                </>,
              );
            else val.calc.push('');
          });
        }
        const sold = getSoldDiamondWithFilter(this.state.historyData, val);
        val.content2 = <OfferWise showHistory sold={sold} isFancy={val.isFcCol} />;
        val.content = (
          <OfferWise
            rap={val.rap}
            isFancy={val.isFcCol}
            offers={this.state.tracks?.filter((el) => el.diamond === val.id)}
          />
        );

        val = { ...val };
      });

    this.setState({ data, checked: list ? this.state.checked : data?.map((x) => x.id) }, () => {
      // if (!list) this.getHistoryData();
    });
  };

  onChangeDiff = (val, id) => {
    const data = [...this.state.data];
    const index = findIndex(data, { id });
    data[index].diff = parseFloat(!val || isNaN(val) ? 0 : val).toFixed(2);
    data[index].discount = parseFloat(parseFloat(data[index].diff) + data[index].back).toFixed(2);
    data[index].offerCtPr = parseFloat(data[index].rap + (data[index].rap * data[index].discount) / 100 || 0).toFixed(
      2,
    );
    data[index].final = parseFloat(data[index].offerCtPr * data[index].crt || 0).toFixed(2);
    if (isNaN(data[index].discount)) data[index].discount = parseFloat(0).toFixed(2);
    data[index] = { ...data[index] };
    this.fetchData(data);
  };

  onChangeDiscount = (val, id) => {
    const data = [...this.state.data];
    const index = findIndex(data, { id });
    data[index].discount = parseFloat(!val || isNaN(val) ? data[index].discount : val).toFixed(2);
    data[index].diff = parseFloat(data[index].discount - data[index].back).toFixed(2);
    data[index].offerCtPr = parseFloat(data[index].rap + (data[index].rap * data[index].discount) / 100 || 0).toFixed(
      2,
    );
    data[index].final = parseFloat(data[index].offerCtPr * data[index].crt || 0).toFixed(2);
    if (isNaN(data[index].diff)) data[index].diff = parseFloat(0).toFixed(2);
    data[index] = { ...data[index] };
    this.fetchData(data);
  };

  onChangePricePerCarat = (val, id) => {
    const data = [...this.state.data];
    const index = findIndex(data, { id });

    if (!data[index].isFcCol) {
      data[index].discount = parseFloat(
        !val || isNaN(val) ? data[index].discount : (1 - val / data[index].rap) * -100,
      ).toFixed(2);
      data[index].diff = parseFloat(data[index].discount - data[index].back).toFixed(2);
    }
    data[index].offerCtPr = parseFloat(!val || isNaN(val) ? data[index].offerCtPr : val).toFixed(2);
    data[index].final = parseFloat(data[index].offerCtPr * data[index].crt || 0).toFixed(2);

    data[index] = { ...data[index] };
    this.fetchData(data);
  };

  diffChange = (val, grp) => {
    const data = this.state.data.map((row) => {
      if ((!grp || row.offerGrp === grp) && !row.isFcCol) {
        row.diff = parseFloat(!val || isNaN(val) ? 0 : val).toFixed(2);
        row.discount = parseFloat(parseFloat(row.diff) + row.back).toFixed(2);
        row.offerCtPr = parseFloat(
          (this.props.inOffer ? row.rap + (row.rap * row.discount) / 100 : row.ctPr) || 0,
        ).toFixed(2);
        row.final = parseFloat(row.offerCtPr * row.crt || 0).toFixed(2);
        if (isNaN(row.discount)) row.discount = parseFloat(0).toFixed(2);
        return { ...row };
      }
      return row;
    });
    this.fetchData(data);
  };

  discChange = (val, grp) => {
    const data = this.state.data.map((row) => {
      if ((!grp || row.offerGrp === grp) && !row.isFcCol) {
        row.discount = parseFloat(!val || isNaN(val) ? row.discount : val).toFixed(2);
        row.diff = parseFloat(row.discount - row.back).toFixed(2);
        row.offerCtPr = parseFloat(row.rap + (row.rap * row.discount) / 100 || 0).toFixed(2);
        row.final = parseFloat(row.offerCtPr * row.crt || 0).toFixed(2);
        if (isNaN(row.diff)) row.diff = parseFloat(0).toFixed(2);
        return { ...row };
      }
      return row;
    });
    this.fetchData(data);
  };

  pricePerCaratChange = (val, grp) => {
    const data = this.state.data.map((row) => {
      if (!grp || row.offerGrp === grp) {
        if (!row.isFcCol) {
          row.discount = parseFloat(!val || isNaN(val) ? row.discount : (1 - val / row.rap) * -100).toFixed(2);
          row.diff = parseFloat(row.discount - row.back).toFixed(2);
        }
        row.offerCtPr = parseFloat(!val || isNaN(val) ? row.offerCtPr : val).toFixed(2);
        row.final = parseFloat(row.offerCtPr * row.crt || 0).toFixed(2);
        return { ...row };
      }
      return row;
    });
    this.fetchData(data);
  };

  applyOffer = async () => {
    // let values={}
    // ['userAccount', 'user', 'seller', 'broker'].forEach((field) => {
    //   values[field] = values[field]?.id;
    // });
    const arr = this.state.partAdd || this.state.data;
    const checkedData = arr?.filter((el) => this.state.checked.includes(el.id));
    if (!checkedData.length) {
      OpenNotification({ type: 'error', title: Messages.DiamondSelectRequire });
      return;
    }
    const request = {
      trackType: TRACK_TYPES.Offer,
      userAccount: this.state.twoCall ? null : this.props.client && this.props.client.id,
      user: this.state.twoCall
        ? this.props.client && this.props.client.id
        : this.props.client && this.props.client.user && this.props.client.user.id,
      diamonds: checkedData?.map((d) => ({
        diamond: d.id,
        newPricePerCarat: d.offerCtPr || d.ctPr,
        newAmount: (d.discount / 100 + 1) * d.rap * d.crt || d.amt,
        newDiscount: d.discount || d.back,
        vnd: d.vnd,
        vStnId: d.vStnId,
        // offerValidDate: moment().add(d.hours, 'hours').toISOString(),
      })),
    };
    const [, res] = await createDiamondTrack(request);
    if (res?.code === 'OK') {
      // this.props.fetch()
      OpenNotification({ type: 'success', title: res.message });
      this.props.onClose();
      this.props.fetch();
      RowSelectService.resetSelectedRows({ currentType: 'transactionOffer' });
    }
  };

  updateOffer = async () => {
    const arr = filterList(this.state.partUp || this.state.data);
    const checkedData = arr?.filter((el) => this.state.checked.includes(el.id));
    if (!checkedData.length) {
      OpenNotification({ type: 'error', title: Messages.DiamondSelectRequire });
      return;
    }
    const obj = CommonApi.Offer.Counter;
    obj.request = {
      tracks: checkedData?.map((d) => ({
        id: d.blockId,
        diamond: d.id,
        newPricePerCarat: d.offerCtPr || d.ctPr,
        newAmount: (d.discount / 100 + 1) * d.rap * d.crt || d.amt,
        newDiscount: parseFloat(d.discount || d.back),
        offerValidDate: d.offerValidDate,
        userAccount: d.userAccount,
        user: d?.user?.id, // this.props.client && this.props.client.id,
      })),
    };
    const [, res] = await UtilService.callApiAsync(obj);

    if (res?.code === 'OK') {
      this.props.fetch();
      RowSelectService.resetSelectedRows({ currentType: 'transactionOffer' });
      if (!this.state.twoCall) OpenNotification({ type: 'success', title: res.message });
      this.props.onClose();
    }
  };

  addDiamond = () => {
    const data = this.state.data;
    Storage.set('addDiamond', data);
    searchFn({}, (err, cb) => {
      Storage.set(
        'cli',
        this.props.client.user
          ? { ...this.props.client.user, companyName: this.props.client.companyName }
          : this.props.client,
      );
      if (cb && cb.searchIds)
        this.props.history.push(`/mohit-diamond/inventory/single-stone/search?result=${cb.searchIds.join(',')}`, {
          customer: this.props.client,
        });
    });
  };

  twoCall = () => {
    const partAdd = this.state.data?.filter((el) => !el.newDiscount);
    const partUp = this.state.data?.filter((el) => el.newDiscount);
    this.setState({ partAdd, partUp }, () => {
      this.updateOffer();
      this.applyOffer();
    });
  };

  setData = () => {
    const checkedData = this.state.data?.filter((el) => this.state.checked.includes(el.id));
    const totalRapAvg = sum(checkedData?.map((d) => d.crt * d.rap));
    const totalCt = sum(checkedData?.map((d) => d.crt));
    const totalLapAmt = sum(checkedData?.map((d) => d.buyAmt || 0));
    const totalLapPr = totalCt ? totalLapAmt / totalCt : 0;
    const totalLap = totalCt ? (totalLapPr / (totalRapAvg / totalCt) - 1) * 100 : 0;
    const totalAmt = sum(checkedData?.map((d) => d.amt));
    const totalCtPr = totalCt ? totalAmt / totalCt : 0;
    const totalBack = totalCt ? (totalCtPr / (totalRapAvg / totalCt) - 1) * 100 : 0;
    const totalFinalCalc = sum(checkedData?.map((d) => (d.discount / 100 + 1) * d.rap * d.crt));
    const totalOfferRate = totalCt ? totalFinalCalc / totalCt : 0;
    const totalOffer = totalCt ? (totalOfferRate / (totalRapAvg / totalCt) - 1) * 100 : 0;
    const totalDifferCalc = totalOffer - totalBack;

    const VALUES = {
      'CT.': totalCt,
      'Rap%': totalLap,
      'Rap Rate': totalLapPr,
      'Rap value': totalLapAmt,
      'Ask%': totalBack,
      'Asking Rate': totalCtPr,
      'Asking Value': totalAmt,
      'Offer%': totalOffer,
      'Offer Rate': totalOfferRate,
      'Offer Value': totalFinalCalc,
      'Discount Different': totalDifferCalc,
      'P/L Amt': totalFinalCalc - totalLapAmt,
      'P/L %': totalLapAmt ? ((totalFinalCalc - totalLapAmt) * 100) / totalLapAmt : 0,
    };

    this.setState({
      checkedData: [...this.state.checkedData, ...checkedData],
      VALUES: { ...this.state.VALUES, ...VALUES },
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      this.setData();
    }
  }

  handleCheck = (checked) => this.setState({ checked: checked?.map((x) => x.id) });

  handleToggle = (collapsed, grp) => {
    const data = this.state.data.map((row) => {
      if (row.offerGrp === grp) {
        row.collapsed = collapsed;
        return { ...row };
      }
      return row;
    });
    this.setState({ data });
  };

  render() {
    const { client } = this.state;

    return (
      <div className="offerMainPage">
        <div className="d-flex align-items-center justify-space-between  offerTopBlock">
          <div className="d-flex align-items-center">
            <CommonBackButton onClick={this.props.onClose} />
            <div className="offerTitleWithClient">
              <h2>Apply Offer For</h2>
              <div className="clientDetail">
                <div className="listdetailBox listClientName">
                  <div className="d-flex align-items-center">
                    <span className="listClientNameBox">{client.companyName || ''}</span>
                    <span className="liveStatus  offline"></span>
                  </div>
                </div>
                <div className="listdetailBox listUserList">
                  <div className="d-flex align-items-center">
                    <span>
                      {(client.user && client.user.firstName) || client.firstName || ''}
                      {(client.user && client.user.lastName) || client.lastName || ''}
                    </span>
                    <span> | {(client.user && client.user.mobile) || client.mobile || ''}</span>
                    <span className="kycStatus active">Active</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="offerTopCollpaseOption">
            {/* <div>
              <Checkbox
                value={this.state.showOffer}
                onChange={() => this.setState((prev) => ({ showOffer: !prev.showOffer }))}
              >
                Show offer on stone
              </Checkbox>
            </div> */}
            {/* <CollpaseBox
              title1="Collapse All"
              title2="Expand All"
              collpase={!this.state.collapseAll && 'active'}
              collpase1={this.state.collapseAll && 'active'}
              collpaseAllOption={() => {
                this.setState({ collapseAll: !this.state.collapseAll }, () => {
                  let data = this.state.data;
                  data.forEach((val) => (val.collapsed = this.state.collapseAll));
                  this.setState({ data, hideRange: this.state.collapseAll ? false : this.state.hideRange });
                });
              }}
            />
            <CollpaseBox
              title1="Add Range"
              title2="Hide Range"
              collpase={!this.state.hideRange && 'active'}
              collpase1={this.state.hideRange && 'active'}
              collpaseAllOption={() => {
                this.setState(
                  {
                    hideRange: !this.state.hideRange,
                    collapseAll: !this.state.hideRange ? false : this.state.collapseAll,
                  },
                  () => {
                    let data = this.state.data;
                    data.forEach((val) => (val.collapsed = this.state.collapseAll));
                    this.setState({ data });
                  },
                );
              }}
            /> */}
            {/* <button className="commonButton smallButton" onClick={this.addDiamond}>
              Add Diamond
            </button> */}
            <button
              className="commonButton smallButton ml-5"
              onClick={this.state.twoCall ? this.twoCall : this.props.inOffer ? this.updateOffer : this.applyOffer}
            >
              Apply Offer
            </button>
          </div>
        </div>
        {/* <ListingWrapper className="offerTable"> */}
        <ListingWrapper className="">
          <ListingTableWrapper
            className="tabInnerTableScroll"
            subtractFrom=".offerTopBlock,.botoomStickyBar"
            adjustment={-10}
          >
            <Table
              noDnd
              noDots
              columns={this.state.columns}
              colspan={1}
              data={this.state.data}
              handleCheck={this.handleCheck}
              checked={this.state.checkedData}
              noGrp={this.state.hideRange ? true : false}
              toggleCollapse={this.handleToggle}
              showOffer={this.state.showOffer}
              showHistory={this.state.showHistory}
              isOfferPopup
            />
          </ListingTableWrapper>
          <div className="botoomStickyBar align-items-center flex-wrap">
            {Object.keys(this.state.VALUES)?.map((x, index) => {
              return (
                <div key={index} className="bottomValue">
                  <span>{x}</span>
                  <span>{parseFloat(this.state.VALUES[x]).toFixed(2)}</span>
                </div>
              );
            })}
          </div>
        </ListingWrapper>
      </div>
    );
  }
}

export default withRouter(Offer);
