import { PermissionService } from 'services/PermissionService';

import { PREFIX_URL_WITH_SLASH as URL } from 'constants/CommonUrl';

export const MENU = [
  { path: `${URL}/dashboard`, name: 'Home', svg: 'Dashboard', permit: 'DASHBOARD' },
  { path: `${URL}/inventory`, name: 'Inventory', svg: 'Master', permit: 'INVENTORY' },
  { path: `${URL}/inventory-quick-search`, name: 'Quick Search', svg: 'QuickSearch', permit: 'INVENTORY' },
  { path: `${URL}/client`, name: 'Clients', svg: 'Account', id: 72, permit: 'CLIENT' },
  { path: `${URL}/transaction`, name: 'Transactions', svg: 'Transactions', permit: 'TRANSACTION' },
  { path: `${URL}/notification`, name: 'Notifications', svg: 'Notification', permit: 'NOTIFICATION' },
  { path: `${URL}/activity`, name: 'Activity', svg: 'Todo', id: 79, permit: 'ACTIVITIES' },
  // { path: `${URL}/trade`, name: 'Trade', svg: 'Trade', icon: 'widgets', permit: 'TRADESHOW' },
  // { path: `${URL}/bid`, name: 'Bid', svg: 'Bid', id: 82, permit: 'BIDSHOW' },
  { path: `${URL}/report`, name: 'Report', svg: 'Reports', id: 83, permit: 'REPORT' },
  { path: `${URL}/demand`, name: 'Supplier', svg: 'Todo', id: 83, permit: 'DEMANDMAIN' },
  { path: `${URL}/user`, name: 'Users', svg: 'Account', id: 81, permit: 'USER' },
];

export function getMenuLinks() {
  const permissionTree = PermissionService.getTree() ?? [];
  const mainModuleList = Object.keys(permissionTree).filter((module) => {
    const modulePermissions = PermissionService.getPermission(module);
    return modulePermissions?.allow;
  });
  return MENU.filter(({ permit }) => mainModuleList.includes(permit));
}
