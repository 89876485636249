import React, { Component } from 'react';
import { connect } from 'react-redux';

import { cloneDeep, pick } from 'lodash';
import moment from 'moment';

import DatePickerInput from 'containers/DatePickerInput';
import DisableBlock from 'containers/DisableBlock';
import ListingTableWrapper from 'containers/ListingTableWrapper';
import ListingWrapper from 'containers/ListingWrapper';
import MainHeading from 'containers/MainHeading';
import SelectOption from 'containers/SelectOption';

import CircularProgress from 'components/CircularProgress';
import Table from 'components/Inventory/TableBack';
import * as inventoryFunctions from 'components/Inventory/inventoryFunctions';

import { createShowSelection } from 'services/CommonApi';
import { getMasterDetailsByCode } from 'services/SubMasterApi';
import Storage from 'services/storage';

import { isArray, isEmpty, isObject } from 'util/utils';

import { INVOICE_DATE, REFERENCE_BY, SHOW_SELECTION, USERS_TYPE } from 'constants/Common';
import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

import { fetchPartyRelatedInfo, isValue, HeadingCalc } from './CommonPopupFunction';
import ClientComponent from './PopupComponent/ClientComponent';
import CourierAccountNo from './PopupComponent/CourierAccountNo';
import PartyBuyerComponent from './PopupComponent/PartyBuyerComponent';
import PaymentTermsComponent from './PopupComponent/PaymentTermsComponent';
import PreferShipCompany from './PopupComponent/PreferShipCompany';
import SellerComponent from './PopupComponent/SellerComponent';

import TextArea from '../../containers/TextArea';

const currentType = 'SingleStonePlaceOrderPopup';

class SingleStonePlaceOrderPopup extends Component {
  state = {
    show: true,
    stoneblock: false,
    checked: [],
    data: cloneDeep(this.props.checked),
    loader: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.checked.length !== this.state.checked.length && this.state.checked.length < 1) {
      this.props.onClose();
    }
  }

  static getDerivedStateFromProps(props) {
    if (props.visible && isArray(props.checkedData)) return { checked: [...props.checkedData] };
    return null;
  }

  //FIXME:Only for order tab****************
  async componentDidMount() {
    if (
      ['order', 'quoteApprove', 'finalCalculation', 'orderApprove', 'showStone', 'officeApprove'].includes(
        this.props.tab,
      )
    ) {
      const data = {
        user: null,
        userAccount: null,
        seller: null,
        sellerList: [],
        // loading: false,
        countryList: [],
        shippingCountry: null,
        cityList: [],
        shippingCity: null,
        stageList: [],
        stage: null,
        assistantSeller: null,
        error: {},
        broker: null,
        brokerList: [],
        shipDirect: false,
        client: [],
        billType: null,
        billTypeList: [],
        courierNameList: [],
        courierName: null,
        sourceOfSaleList: [],
        sourceOfSale: null,
        termsList: [],
        terms: null,
        comment: '',
        invoiceDate: null,
        invoiceDateType: undefined,
        bankRateList: [],
        referenceBy: '1',
        showName: null,
        showNameList: [],
        inquirySourceList: [],
        inquirySource: null,
        preferShippingCompany: '',
        courierAccountNo: '',
      };
      const tab = this.props.tab;
      const client = this.props.client;

      if (!isEmpty(client)) {
        data.client = [client];
        data.user = client?.user?.id;
        data.userAccount = client?.id;
        data.broker = isObject(client?.broker) ? client.broker?.id : null;
        data.brokerList = isObject(client?.broker)
          ? [
              {
                ...client?.broker,
                value: client?.broker?.companyName,
                key: client?.broker?.id,
              },
            ]
          : [];
        data.seller = isObject(client?.seller) ? client.seller?.id : null;
        data.shippingCountry = client?.country;
        data.shippingCity = client?.city;
      }
      if (['quoteApprove', 'orderApprove', 'officeApprove'].includes(tab)) {
        const checkedDiamond = this.props.checked?.[0];
        data.client = [checkedDiamond?.user];
        data.user = checkedDiamond?.user?.id;
        data.userAccount = checkedDiamond?.user?.account?.id;
        if (isObject(checkedDiamond?.broker)) {
          data.broker = checkedDiamond?.broker?.id;
          data.brokerList = isObject(checkedDiamond?.broker)
            ? [checkedDiamond?.broker].map((d) => ({ value: d.companyName, key: d.id }))
            : [];
        }
        // data.initialPaymentTerms = data.terms = checkedDiamond?.terms?.id;
        // data.initialPreShipCompany = data.preferShippingCompany = checkedDiamond?.preferShippingCompany?.id;
        // data.initialCourierAccountNo = data.courierAccountNo = checkedDiamond?.courierAccountNo;
        data.initialPaymentTerms = checkedDiamond?.terms?.id;
        data.terms = checkedDiamond?.terms?.id;

        data.initialPreShipCompany = checkedDiamond?.preferShippingCompany?.id;
        data.preferShippingCompany = checkedDiamond?.preferShippingCompany?.id;

        data.initialCourierAccountNo = checkedDiamond?.courierAccountNo;
        data.courierAccountNo = checkedDiamond?.courierAccountNo;
        if (isObject(checkedDiamond?.seller)) data.seller = checkedDiamond?.seller?.id;
      }

      if (!isEmpty(client) && this.props.tab === 'showStone') {
        data.client = [
          {
            key: client.user?.id,
            value: `${client?.companyName} ${client?.user?.firstName || client?.user?.lastName ? `|` : ''} ${
              client?.user?.firstName ? client.user.firstName : ''
            } ${client?.user?.lastName ? client.user.lastName : ''}`,
            ...client,
          },
        ];
        data.user = client.user?.id;
      }

      this.setState({ ...data }, () => {
        //  !isEmpty(this.state.client) && this.fetchClientOtherData();
      });
      if (['orderApprove'].includes(tab)) {
        this.setState({
          invoiceDateType: '0',
          invoiceDate: moment(),
        });
      }
      if (['quoteApprove'].includes(tab)) {
        this.setState({
          invoiceDateType: '0',
          invoiceDate: moment(),
        });
      }
      this.fetchMasterData();
    }
  }

  errorHandel = (param) => {
    const { fields, isConfirmStone } = param;
    const error = this.state.error;
    if (isConfirmStone) {
      const data = pick(this.state, [
        'userAccount',
        'user',
        'seller',
        'invoiceDateType',
        'terms',
        'invoiceDate',
        'terms',
      ]);
      let showStoneData = [];
      if (this.props.tab === 'showStone') {
        data.showName = this.state.showName;
        data.referenceBy = this.state.referenceBy;
        showStoneData = [
          'broker',
          'invoiceDate',
          'userAccount',
          'billType',
          this.state.referenceBy === 1 ? 'seller' : 'user',
        ];
      }
      if (['orderApprove', 'quoteApprove', 'showStone', 'officeApprove'].includes(this.props.tab)) {
        ['shippingCountry', 'shippingCity', 'sourceOfSale', ...showStoneData].forEach((d) => delete data[d]);
      }
      // if (['orderApprove'].includes(this.props.tab)) {
      //   // delete data['invoiceDate'];
      //   delete data['invoiceDateType'];
      // }
      if (this.state.shipDirect) {
        data.courierName = this.state.courierName;
      }
      Object.keys(data).forEach((d) => {
        if (isEmpty(data?.[d])) error[d] = Messages[`${d}Select`];
      });
      if (this.state.error?.user && this.props.tab === 'showStone') {
        error.user = Messages.clientNameSelect;
      }
    } else {
      fields.forEach((filed) => this.state[filed] && delete error[filed]);
    }
    this.setState({ error });
  };

  fetchClientOtherData = () => {
    const clientOtherData = fetchPartyRelatedInfo(this.state.client, this.state.user);
    Object.keys(clientOtherData).forEach((key) => {
      if (clientOtherData[key]) this.errorHandel({ fields: [key] });
    });
    this.setState({ ...this.state, ...clientOtherData });
  };

  onClickFn = async () => {
    if (this.props.tab !== 'officeApprove') this.errorHandel({ isConfirmStone: true });
    if (['order', 'quoteApprove', 'orderApprove', 'officeApprove'].includes(this.props.tab)) {
      if (isEmpty(this.state.error)) {
        const request = pick(this.state, ['user', 'terms', 'seller', 'bankRate', 'invoiceDate', 'comment']);
        Object.keys(request).forEach((key) => {
          if (isEmpty(request[key])) {
            delete request[key];
          }
        });
        if (['order', 'orderApprove'].includes(this.props.tab)) {
          ['preferShippingCompany', 'courierAccountNo'].forEach((key) => {
            if (!isEmpty(this.state[key])) request[key] = this.state[key];
          });
        }

        request.companyName = this.state.userAccount;
        request.account = this.state.userAccount;
        request.invoiceDate = !isEmpty(request.invoiceDate) ? request.invoiceDate.toISOString() : null;
        request.diamonds = this.state.checked.map((x) => x.id);
        if (this.props.tab === 'order') {
          request.diamonds = this.state.data.map((x) => x.id);
          inventoryFunctions.handlePlaceOrder(request, this.props.onClose, (loader) => {
            this.setState({ loader });
          });
          // inventoryFunctions.handlePlaceOrder(request, this.props.onClose, this.props.removeDiamond, (loader) => {
          //   this.setState({ loader });
          // });
        } else {
          if (this.props.tab === 'orderApprove') {
            [
              'shippingCity',
              'assistantSeller',
              'shippingCountry',
              'companyName',
              'courierName',
              'sourceOfSale',
            ].forEach((key) => delete request[key]);
            request.account = this.state.userAccount;
            request.inquirySource = this.state.inquirySource;
          }
          this.props.approveClick((loader) => this.setState({ loader }), this.state.checked, request);
        }
      }
    }
    if (this.props.tab === 'showStone' && isEmpty(this.state.error)) {
      const request = {
        vStnIds: this.props.checked.map((d) => d.vStnId),
        type: SHOW_SELECTION.SHOW,
        referenceType: this.state.referenceBy,
        show: this.state.showName,
        remarks: this.state.comment,
      };
      if (request.referenceType === 1) {
        request.userAccount = this.state.user;
      } else {
        request.seller = this.state.seller;
      }

      this.setState({ loader: true });
      const [, res] = await createShowSelection(request);
      if (res.code === 'OK') {
        OpenNotification({ type: 'success', title: res.message });
        this.props.unCheckedDiamond();
      }
      this.setState({ loader: false });
      this.props.onClose();
    }
  };

  fetchMasterData = async () => {
    const masters =
      this.props.tab === 'showStone'
        ? ['SHOW_TYPE']
        : ['BILL_TYPE', 'COURIER_NAME', 'SOURCE_OF_SALE', 'DAY_TERM', 'INQUIRY_SOURCE'];
    const [, res] = await getMasterDetailsByCode({
      masters,
      isWebVisible: 'true',
    });
    const data = {};
    if (res.code === 'OK' && res.data) {
      if (this.props.tab === 'showStone') {
        data.showNameList = res.data.SHOW_TYPE.map((d) => ({ value: d.name, key: d.id[0] }));
      } else {
        data.billTypeList = res.data.BILL_TYPE.map((d) => ({ value: d.name, key: d.id[0] }));
        data.billType = data.billTypeList[0]?.key;
        data.courierNameList = res.data.COURIER_NAME.map((d) => ({ value: d.name, key: d.id[0] }));
        data.courierName = data.courierNameList[0]?.key;
        data.sourceOfSaleList = res.data.SOURCE_OF_SALE.map((d) => ({ value: d.name, key: d.id[0] }));
        data.sourceOfSale = data.sourceOfSaleList[0]?.key;
        // data.termsList = res.data.DAY_TERM.map((d) => ({ value: d.name, key: d.id[0] }));
        // data.terms = data.termsList[0]?.key;
        // data.bankRate = find(this.state.bankRateList, (d) => data.terms === d?.dayTermId)?.exchangeRate;
        data.inquirySourceList = res.data.INQUIRY_SOURCE.map((d) => ({ value: d.name, key: d.id[0] }));
      }
      this.setState({ ...this.state, ...data });
    }
  };

  titleList = {
    order: 'Order',
    quoteApprove: 'Approve',
    finalCalculation: 'Final Calculation',
    orderApprove: 'Approve',
    showStone: 'Show Stone',
    officeApprove: 'Approve',
  };
  render() {
    const state = this.state;
    const tab = this.props.tab;

    return (
      <>
        <div className="d-flex align-items-center oneLineTotal">
          <MainHeading
            className={tab === 'quoteApprove' ? 'popupInnerTitle mb-0 mr-40 mt-0' : 'popupInnerTitle mb-0 mr-40 mt-0'}
            title={`${this.titleList[tab]} (${tab !== 'order' ? this.state.checked.length : this.state.data?.length}) `}
          />
          {HeadingCalc(tab !== 'order' ? this.state.checked : this.state.data, tab)}
        </div>
        <div className="popupHalfShow">
          <ListingWrapper>
            <ListingTableWrapper classList={['.mainHeadingTitle', '.sidebarAction']} initial={30}>
              <Table
                data={this.state.data}
                columns={this.props.columns}
                canSort={false}
                currentType={currentType}
                FilterOption={false}
                areAllChecked={tab !== 'order'}
                noheader
                noCheckBox
                noDots
                noGrp
                extraDetails={tab === 'orderApprove' ? 'Order' : tab === 'quoteApprove' ? 'Offer' : ''}
              />
            </ListingTableWrapper>
          </ListingWrapper>
          {['order', 'quoteApprove', 'orderApprove', 'showStone', 'officeApprove'].includes(tab) && (
            <>
              <div className="singleStonePlaceOrderDetail mt-20 smallGrayScroll grayScroll">
                {['order'].includes(tab) && (
                  <PartyBuyerComponent
                    error={this.state.error}
                    setUserDetails={(data) => {
                      this.setState({ ...data }, () => {
                        this.errorHandel({ fields: Object.keys(data) });
                      });
                    }}
                    initialData={pick(this.state, ['client', 'userAccount', 'user'])}
                  />
                )}

                {tab === 'showStone' && (
                  <>
                    <SelectOption
                      placeholder="Show Name"
                      value={isValue(this.state.showName)}
                      onChange={(showName = null) => {
                        this.setState({ showName }, () => {
                          this.errorHandel({ fields: ['showName'] });
                        });
                      }}
                      selectValue={this.state.showNameList}
                      label="Show Name *"
                      error={this.state.error?.showName}
                      onSearch={() => {
                        this.setState({ showName: null });
                      }}
                    />
                    {/* <InputField
                      placeholder="Show Name"
                      label="Show Name *"
                      onChange={(e) => {
                        this.setState({ showName: e.target.value });
                      }}
                      value={isValue(this.state.showName)}
                      error={state.error?.showName}
                    /> */}
                    <SelectOption
                      placeholder="Reference By"
                      value={isValue(this.state.referenceBy)}
                      onChange={(referenceBy = null) => {
                        const data = referenceBy
                          ? { user: null, client: [], seller: null }
                          : { user: state.user, client: state.client, seller: state.seller };
                        this.setState({ referenceBy, ...data }, () => {
                          this.errorHandel({ fields: ['referenceBy', 'user', 'seller'] });
                        });
                      }}
                      selectValue={REFERENCE_BY}
                      label="Reference By *"
                      onSearch={() => this.setState({ referenceBy: null })}
                      error={state.error?.referenceBy}
                    />
                    {this.state.referenceBy === 1 && (
                      <ClientComponent
                        error={{ client: state.error?.user }}
                        setClientData={(data) => {
                          this.setState({ client: data.clientList, user: data.client }, () => {
                            this.errorHandel({ fields: ['user'] });
                          });
                        }}
                        initialData={{ clientList: state.client, client: state.user }}
                      />
                    )}
                    {this.state.referenceBy === 2 && (
                      <SellerComponent
                        error={this.state.error}
                        setSellerDetails={(data) => {
                          this.setState({ ...data }, () => {
                            this.errorHandel({ fields: ['seller'] });
                          });
                        }}
                        initialData={pick(this.state, ['seller', 'sellerList'])}
                      />
                    )}
                  </>
                )}
                {['quoteApprove', 'orderApprove'].includes(tab) && (
                  <>
                    <DisableBlock className="from-group d-flex width-66">
                      <PartyBuyerComponent
                        error={this.state.error}
                        setUserDetails={(data) =>
                          this.setState({ ...data }, () => {
                            this.errorHandel({ fields: ['userAccount', 'user'] });
                          })
                        }
                        initialData={pick(this.state, ['client', 'userAccount', 'user'])}
                        disable
                      />
                    </DisableBlock>
                  </>
                )}

                {['order', 'orderApprove'].includes(tab) && (
                  <SellerComponent
                    label="Salesman *"
                    error={this.state.error}
                    setSellerDetails={(data) => {
                      this.setState({ ...data }, () => {
                        this.errorHandel({ fields: ['seller'] });
                      });
                    }}
                    initialData={pick(this.state, ['seller'])}
                  />
                )}
                {['quoteApprove'].includes(tab) && (
                  <>
                    <SellerComponent
                      setSellerDetails={(data) => {
                        this.setState({ ...data }, () => {
                          this.errorHandel({ fields: ['seller'] });
                        });
                      }}
                      initialData={pick(this.state, ['seller'])}
                      error={this.state.error}
                    />
                  </>
                )}

                {['order', 'quoteApprove'].includes(tab) && (
                  <>
                    {['quoteApprove'].includes(tab) ? (
                      ''
                    ) : (
                      <SelectOption
                        placeholder="Select Invoice Date"
                        value={isValue(this.state.invoiceDateType)}
                        onChange={(invoiceDateType = null) => {
                          const invoiceDate = ['0', '1'].includes(invoiceDateType)
                            ? moment().add(Number(invoiceDateType), 'd')
                            : moment();

                          this.setState({ invoiceDateType, invoiceDate }, () => {
                            this.errorHandel({ fields: ['invoiceDateType', 'invoiceDate'] });
                          });
                        }}
                        disabled={['quoteApprove'].includes(tab)}
                        selectValue={INVOICE_DATE}
                        label="Invoice Date *"
                        error={this.state.error?.invoiceDateType}
                        onSearch={() => {
                          this.setState({ invoiceDateType: null });
                        }}
                      />
                    )}

                    {this.state.invoiceDateType === '2' && (
                      <DatePickerInput
                        label="Select Date *"
                        placeholder="Select Date"
                        name="offerValidDate"
                        format="DD-MM-Y"
                        value={this.state.invoiceDate}
                        onChange={(invoiceDate) => {
                          this.setState({ invoiceDate }, () => {
                            this.errorHandel({ fields: ['invoiceDate'] });
                          });
                        }}
                        onOpenChange={(open) => !open && this.errorHandel({ fields: ['invoiceDate'] })}
                        error={this.state.error?.invoiceDate}
                        noPast
                      />
                    )}
                  </>
                )}

                {['quoteApprove', 'order', 'orderApprove'].includes(tab) && (
                  <>
                    <PaymentTermsComponent
                      initialPaymentTerms={this.state.initialPaymentTerms}
                      setTermsDetails={(data) =>
                        this.setState({ ...data }, () => {
                          this.errorHandel({ fields: ['terms'] });
                        })
                      }
                      error={state.error?.terms}
                      disabled={
                        tab === 'quoteApprove' &&
                        ![USERS_TYPE.ADMIN, USERS_TYPE.SUPER_ADMIN].includes(Storage.get('user')?.type)
                      }
                    />
                  </>
                )}
                {['order', 'orderApprove', 'quoteApprove'].includes(tab) && (
                  <>
                    <PreferShipCompany
                      initialPreferShipComp={this.state.initialPreShipCompany}
                      setPreShipCompany={(preferShippingCompany) => {
                        this.setState({ preferShippingCompany });
                      }}
                    />
                    <CourierAccountNo
                      initialCourierAccountNo={this.state.initialCourierAccountNo}
                      setCourierAccNo={(courierAccountNo) => {
                        this.setState({ courierAccountNo });
                      }}
                    />
                  </>
                )}
                <TextArea
                  label="Comments"
                  placeholder="Enter Comments"
                  onChange={(e) => {
                    this.setState({ comment: e.target.value });
                  }}
                  value={this.state.comment}
                />
              </div>
            </>
          )}
          {this.state.loader && <CircularProgress className="fixLoader" />}
        </div>
        <div className="sidebarAction">
          <button className="commonButton" onClick={this.onClickFn}>
            {this.titleList[tab]}
          </button>
          <button className="commonOutine" onClick={() => this.props.onClose()}>
            Cancel
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ diamondData }) => {
  return {
    checkedData: isArray(diamondData?.selectedRows?.[currentType]) ? [...diamondData?.selectedRows?.[currentType]] : [],
  };
};

export default connect(mapStateToProps, null)(SingleStonePlaceOrderPopup);
