import React, { Component } from 'react';
// import { Accordion } from 'react-accessible-accordion';

// import { Checkbox, Button } from 'antd';

import PermissionList from './list';

import ManageParameters from '../../components/User/Permission';
// import CommonCollapse from '../../containers/CommonCollapse';

class MenuAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: true,
    };
  }
  toggle = () => {
    this.setState({
      form: !this.state.form,
    });
  };
  render() {
    return (
      <div className="menuAccessWrapper settingPage">
        <div className="headeTitle d-flex justify-space-between align-items-center width-100">
          <h2 className="mb-0">Menu Permission</h2>
          {this.state.form === false && (
            <button className="commonButton" onClick={this.toggle}>
              List
            </button>
          )}
        </div>
        {this.state.form ? <PermissionList onClick={this.toggle} /> : <ManageParameters />}
      </div>
    );
  }
}

export default MenuAccess;
