import { getDefaultPermissions } from './_getDefaultPermissions';

export const ActivitiesPermissions = [
  {
    mainModule: 'ACTIVITIES',
    module: 'TODO',
    title: 'ToDo',
    url: '/todo',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'ACTIVITIES',
    module: 'CALLLOG',
    title: 'Call Log',
    url: '/call-log',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'ACTIVITIES',
    module: 'EMAIL',
    title: 'Email',
    url: '/email',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'ACTIVITIES',
    module: 'TARGET',
    title: 'Target',
    url: '/target',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'ACTIVITIES',
    module: 'APPOINTMENT',
    title: 'Appointment',
    url: '/appointment',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'ACTIVITIES',
    subModule: 'NOTES',
    module: 'DUE',
    title: 'Due',
    url: '/due',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'ACTIVITIES',
    subModule: 'NOTES',
    module: 'UPCOMING',
    title: 'Upcoming',
    url: '/upcoming',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'ACTIVITIES',
    subModule: 'NOTES',
    module: 'ARCHIVED',
    title: 'Archived',
    url: '/archived',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'ACTIVITIES',
    subModule: 'NOTES',
    module: 'DUE',
    title: 'Due',
    url: '/due',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'ACTIVITIES',
    subModule: 'APPOINTMENT',
    module: 'PENDING',
    title: 'Pending',
    url: '/pending',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'ACTIVITIES',
    subModule: 'APPOINTMENT',
    module: 'COMPLETE',
    title: 'Complete',
    url: '/complete',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'ACTIVITIES',
    subModule: 'APPOINTMENT',
    module: 'CANCEL',
    title: 'Cancel',
    url: '/cancel',
    permissions: getDefaultPermissions(),
  },
  {
    mainModule: 'ACTIVITIES',
    subModule: 'APPOINTMENT',
    module: 'REOPEN',
    title: 'Reopen',
    url: '/reopen',
    permissions: getDefaultPermissions(),
  },
];
