import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Drawer, Dropdown } from 'antd';
import { map, uniq, compact } from 'lodash';

import OfferPagePopup from 'routes/Offer';

import BottomAction from 'containers/BottomAction';

import AddCallLog from 'components/CallLog/AddCallLog';
import CircularProgress from 'components/CircularProgress';
import FinalCalculations from 'components/DiamondListing/FinalCalculations';
import Pagination from 'components/List/Pagination';
import AddNotes from 'components/Notes/AddNotes';
import AddTodo from 'components/Todo/AddTodo';
import OrderApprove from 'components/Transaction/Order/OrderApprove';

// import BottomMailAction from './Actions/BottomMailAction';
import { downloadDiamondExcel, downloadPdfFile } from 'services/DiamondApi';
import { showNotification } from 'services/NotificationService';
import { PermissionService } from 'services/PermissionService';
import UtilService from 'services/util';

import { useBoolean, useCompositeState, usePermissions } from 'util/hooks';
import { Notify } from 'util/notify';
import { isArray, isEmpty, isFunction, queryStringToObject } from 'util/utils';

import { TRACK_TYPES } from 'constants/Common';
import CommonApi from 'constants/CommonApi';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';
import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';
import {
  getPermittedInsertListFirst,
  MODULES,
  MAINMODULE,
  getPermittedInsertList,
  downloadPermit,
  printPermit,
} from 'constants/permission';

import { DiamondActions } from 'appRedux/reducers/diamond';

import BottomCompareStone, { BottomCompareStoneButton, CompareStoneTable } from './Actions/BottomCompareStone';
import BottomInstruct from './Actions/BottomInstruct';
import DownloadAction from './Actions/DownloadAction';
import SendEmailPopup from './Actions/SendEmailPopup';
import ShareAction from './Actions/ShareAction';
import BidPopup from './BidPopup';
// import BottomAppointmentCompleteAction from './Actions/BottomAppointmentCompleteAction';
// import BottomAppointmentReopenAction from './Actions/BottomAppointmentReopenAction';
import CommentPopup from './CommentPopup';
import { checkClientName } from './CommonPopupFunction';
import HoldPopup from './HoldPopup';
import MemoPopup from './MemoPopup';
// import OfferPopup from './OfferPopup';
import OfficeSidebar from './OfficeSidebar';
import QuoteDayPopup from './QuoteDayPopup';
import SingleStonePlaceOrderPopup from './SingleStonePlaceOrderPopup';

// import { getuserTerms } from 'services/CommonApi';

// import { getNewStatus } from 'components/Inventory/inventoryFunctions';
// import { handlePrint } from './CommonPopupFunction';

const isAction = (main, sub, model) => !isEmpty(getPermittedInsertListFirst(main, sub, model));

const DiamondListingAction = (props) => {
  const module = usePermissions(PermissionService.getPathFromUrl(window?.location?.pathname));

  const actionPermission = React.useMemo(
    () => ({
      quote: isAction(MAINMODULE.TRANSACTION, MODULES.TRANSACTION.OFFER.NAME),
      hold: isAction(MAINMODULE.TRANSACTION, MODULES.TRANSACTION.HOLD.NAME),
      memo: isAction(MAINMODULE.TRANSACTION, MODULES.TRANSACTION.MEMO.NAME),
      confirm: isAction(MAINMODULE.TRANSACTION, MODULES.TRANSACTION.ORDER.NAME),
      quoteDay: isAction(MAINMODULE.INVENTORY, null, MODULES.INVENTORY.SINGLE_STONE.MODULE.QUOTEDAY),
      searchPermit: getPermittedInsertList(MAINMODULE.INVENTORY, MODULES.INVENTORY.SINGLE_STONE.NAME).includes(
        MODULES.INVENTORY.SINGLE_STONE.MODULE.SEARCH,
      ),
      cart: isAction(MAINMODULE.CLIENT, null, MODULES.CLIENT.TRANSACTION.MODULE.CART),
      watch: isAction(MAINMODULE.CLIENT, null, MODULES.CLIENT.TRANSACTION.MODULE.WATCHLIST),
      notes: isAction(MAINMODULE.CLIENT, null, MODULES.CLIENT.TRANSACTION.MODULE.NOTES),
      office: isAction(MAINMODULE.CLIENT, null, MODULES.CLIENT.TRANSACTION.MODULE.OFFICE_VIEW),
      downloadExcel: downloadPermit(),
      print: printPermit(),
    }),
    [],
  );

  const [compareStones, setCompareStones] = useBoolean(false);
  const [loader, setLoader] = React.useState(false);

  const [state, setState] = useCompositeState({ singleStonePalaceOrder: false, commentPopup: false, tab: '' });
  const [userTerms] = React.useState(null);
  const [officeDrawer, setOfficeDrawer] = React.useState(false);
  const [visibleType, setVisibleType] = React.useState('');
  const [isTask, setIsTask] = React.useState(false);
  const [isCallLog, setIsCallLog] = React.useState(false);
  const [isNotes, setIsNotes] = React.useState(false);

  const chk = useSelector((state) => state?.diamondData?.selectedRows?.[props?.currentType]);
  const checked = React.useMemo(() => props?.checkedData || chk || [], [chk, props?.checkedData]);

  const dispatch = useDispatch();
  const clearAllSelectedRows = React.useCallback(() => {
    if (isFunction(props.clearAllSelectedRows)) props.clearAllSelectedRows();
    dispatch(DiamondActions.resetSelectedRows());
  }, [dispatch, props]);

  const toggleMemo = React.useCallback(() => setState({ memoPopup: !state.memoPopup }), [state.memoPopup, setState]);
  const toggleHold = React.useCallback(() => setState({ holdPopup: !state.holdPopup }), [state.holdPopup, setState]);
  const toggleOfficeDrawer = React.useCallback(() => setOfficeDrawer((value) => !value), []);
  const toggleOffer = React.useCallback(
    () => setState({ OfferPopup: !state.OfferPopup }),
    [state.OfferPopup, setState],
  );
  const toggleApprove = React.useCallback(
    () => setState({ approvePopup: !state.approvePopup }),
    [state.approvePopup, setState],
  );
  const toggleFinalCalculation = React.useCallback(
    () => setState({ finalCalculation: !state.finalCalculation }),
    [state.finalCalculation, setState],
  );
  const toggleQuoteDay = React.useCallback(() => setState({ quoteDay: !state.quoteDay }), [state.quoteDay, setState]);
  const toggleBid = React.useCallback(() => setState({ bid: !state.bid }), [state.bid, setState]);
  const toggleCompareEmail = React.useCallback(
    () => setState({ compareEmail: !state.compareEmail }),
    [state.compareEmail, setState],
  );

  const showTask = () => setIsTask(true);

  const showCallLog = () => setIsCallLog(true);

  const showNotes = () => setIsNotes(true);

  const handleTaskUnchecked = React.useCallback(() => {
    setIsTask(false);
    clearAllSelectedRows();
  }, [clearAllSelectedRows]);

  const handleNotesUnchecked = React.useCallback(() => {
    setIsNotes(false);
    clearAllSelectedRows();
  }, [clearAllSelectedRows]);

  const handleCallLogUnchecked = React.useCallback(() => {
    setIsCallLog(false);
    clearAllSelectedRows();
  }, [clearAllSelectedRows]);

  const toggleTab = React.useCallback(
    (tab) => {
      switch (tab) {
        case 'memo':
          toggleMemo();
          break;

        case 'hold':
          toggleHold();
          break;

        case 'offer':
          toggleOffer();
          break;

        case 'finalCalculation':
          toggleFinalCalculation();
          break;
        case 'quoteDay':
          toggleQuoteDay();
          break;
        case 'bid':
          toggleBid();
          break;
        default:
          break;
      }
    },
    [toggleBid, toggleFinalCalculation, toggleHold, toggleMemo, toggleOffer, toggleQuoteDay],
  );

  const validateClient = React.useCallback(() => {
    if (!isEmpty(props.client)) return true;
    OpenNotification({ type: 'error', title: Messages.Error.Required.Company });
    return false;
  }, [props.client]);

  const validateCheckedItems = React.useCallback(
    (tab) => {
      const status = checked.map((diamond) => diamond.wSts);
      if (props.inOffer) {
        if (!isEmpty(checked)) {
          if (status.includes('C') && tab === 'offerReject') {
            return Notify.error({
              message: 'You are not allowed to reject confirm stone.',
            });
          }
          if (status.includes('H')) {
            return Notify.error({
              message: 'You are not allowed to reject hold stone.',
            });
          }
        }
      }
      if (!isEmpty(checked)) return true;
      OpenNotification({ type: 'error', title: Messages.Error.Required.Stones });
      return false;
    },
    [checked, props.inOffer],
  );

  const checkDiamondStatus = React.useCallback(
    (tab) => {
      const diamondStatus = checked.map((d) => d.wSts);

      if (diamondStatus.includes('M') || diamondStatus.includes('H')) {
        if (!['hold', 'memo'].includes(tab)) {
          if (validateClient()) {
            const checkecId = checked?.map((d) => d?.id);
            const users = props?.inBlockDiamonds?.length
              ? compact(
                  uniq(
                    props.inBlockDiamonds.map((d) => {
                      if (checkecId.includes(d?.blockDetails?.[0]?.diamond)) return d?.blockDetails?.[0]?.user?.id;
                      return;
                    }),
                  ),
                )
              : [];

            if (users?.includes(props?.client?.user?.id) && users?.length === 1) return true;
            OpenNotification({
              type: 'error',
              title: Messages.Error.Required.MemoAndHoldStone,
            });
          }
        } else {
          OpenNotification({
            type: 'error',
            title: Messages.Error.Required.MemoAndHoldStone,
          });
        }
      } else {
        return true;
      }
      return false;
    },
    [checked, props?.client?.user?.id, props.inBlockDiamonds, validateClient],
  );

  const popupShow = React.useCallback(
    (tab) => {
      const status = checked.map((diamond) => diamond.wSts);
      if (props.inOffer) {
        if (status.includes('C') && tab === 'offer')
          return Notify.error({
            message: 'You are not allowed to modify confirm stone.',
          });
      }
      if (props.inOffer && ['quoteApprove', 'offer'].includes(tab)) {
        if (status.includes('H'))
          return Notify.error({
            message:
              tab === 'quoteApprove'
                ? 'You are not allowed to approve Hold stone.'
                : 'You are not allowed to modify Hold stone.',
          });
      }

      if (tab === 'offer') {
        const itemsAreValid = validateCheckedItems();
        if (itemsAreValid) {
          if (props.inOffer) {
            const clients = uniq(checked?.map((x) => (x.user ? x.user.id : null))?.filter((el) => el && el));
            if (clients.length > 1) {
              Notify.error({ message: 'Please select diamonds for only one company.' });
            } else toggleTab(tab);
          } else if (validateClient()) {
            toggleTab(tab);
          }
        }
        return;
      }

      if (
        ['enquiry', 'comment', 'finalCalculation', 'bid'].includes(tab) &&
        validateClient() &&
        validateCheckedItems()
      ) {
        // const isClientValid = true;
        if (tab === 'enquiry') setState({ singleStonePalaceOrder: !state.singleStonePalaceOrder, tab });
        if (tab === 'comment') setState({ commentPopup: !state.commentPopup });

        if (['finalCalculation', 'bid'].includes(tab)) toggleTab(tab);
      }

      if (
        [
          'hold',
          'memo',
          'order',
          'approve',
          'quoteApprove',
          'office',
          'orderApprove',
          'quoteDay',
          'showStone',
          'emailStock',
          'emailPic',
          'officeApprove',
        ].includes(tab) &&
        validateCheckedItems()
      ) {
        // if (['offer', 'quoteDay'].includes(tab)) {
        //   let fm = checked.filter((el) => getNewStatus(el) && el.crt <= 3);
        //   if (fm.length !== 0) {
        //     OpenNotification({
        //       type: 'error',
        //       title:
        //         'Packet No. ' +
        //         checked.map((x) => x.vStnId).join(', ') +
        //         (fm.length === 1 ? ' is' : ' are') +
        //         ' not eligible for quote.',
        //     });
        //     return;
        //   }
        // }
        if (['hold', 'memo', 'quoteDay'].includes(tab) && checkDiamondStatus(tab)) {
          toggleTab(tab);
        }
        if (['emailStock', 'emailPic'].includes(tab)) {
          setState({ tab });
          toggleCompareEmail();
        }
        if (['order', 'quoteApprove', 'orderApprove', 'showStone', 'officeApprove'].includes(tab)) {
          const isStateUpdate = ['quoteApprove', 'orderApprove', 'officeApprove'].includes(tab)
            ? checkClientName(checked)
            : true;
          const isDiamondStatusCheck = ['order', 'showStone'].includes(tab)
            ? validateClient() && checkDiamondStatus(tab)
            : true;
          if (isStateUpdate && isDiamondStatusCheck) {
            setState({ singleStonePalaceOrder: !state.singleStonePalaceOrder, tab });
          }
        }
        // if (tab === 'approve') {
        //   toggleApprove();
        // }
        if (tab === 'office' && checkDiamondStatus(tab)) {
          toggleOfficeDrawer();
        }
      }
    },
    [
      checkDiamondStatus,
      checked,
      props.inOffer,
      setState,
      state.commentPopup,
      state.singleStonePalaceOrder,
      toggleCompareEmail,
      toggleOfficeDrawer,
      toggleTab,
      validateCheckedItems,
      validateClient,
    ],
  );

  React.useEffect(() => {
    if (props.checked && props.inOffer) {
      popupShow('quoteApprove');
    }
  }, [popupShow, props.checked, props.inOffer]);

  /**
   * Function used to insert track
   * @param {*} trackType
   * @param {*} checkedData
   */
  const handleInsertTrack = React.useCallback(
    async (trackType, checkedData) => {
      const isClientValid = validateClient();
      if (!isClientValid) return;

      const isDiamondValid = validateCheckedItems();
      if (!isDiamondValid) return;

      const diamonds =
        isArray(checkedData) &&
        checkedData.map((item) => ({
          diamond: item.id,
          trackPricePerCarat: item.ctPr,
          trackAmount: item.amt,
        }));
      setLoader((loader) => !loader);

      const [err, res] = await UtilService.callApiAsync({
        ...CommonApi.DiamondTrack.create,
        request: {
          trackType,
          diamonds,
          ...(!isEmpty(props.client?.user?.id) && { user: props.client?.user?.id }),
          ...(!isEmpty(props.client?.id) && { userAccount: props.client?.id }),
        },
      });
      if (!err && res?.code === 'OK') {
        if (props.fetch) props.fetch();
        clearAllSelectedRows();
        showNotification(res);
      }
      setLoader((loader) => !loader);
    },
    [clearAllSelectedRows, props, validateCheckedItems, validateClient],
  );

  const visibleTypeButtonHandler = (type) => {
    return () => {
      isEmpty(checked)
        ? OpenNotification({
            type: 'error',
            title: `Please select stone(s).`,
          })
        : setVisibleType(type);
    };
  };

  const handlePrintClick = React.useCallback(() => {
    downloadPdfFile(
      checked,
      props.requestUrl,
      props.seachFilterTag ? props.seachFilterTag.searchData : props.filter ? props.filter : {},
      props?.tabPrintExcel,
      setLoader,
    );
    clearAllSelectedRows();
  }, [checked, clearAllSelectedRows, props.filter, props.requestUrl, props.seachFilterTag, props?.tabPrintExcel]);

  const handleExportExcelClick = React.useCallback(() => {
    isEmpty(checked)
      ? OpenNotification({
          type: 'error',
          title: `Please select stone(s).`,
        })
      : downloadDiamondExcel(
          checked,
          props.requestUrl,
          props.seachFilterTag ? props.seachFilterTag.searchData : props.filter ? props.filter : {},
          props?.tabPrintExcel,
          setLoader,
          props.inOffer ? props.inOffer : false,
        );
    clearAllSelectedRows();
  }, [checked, clearAllSelectedRows, props.filter, props.requestUrl, props.seachFilterTag, props?.tabPrintExcel]);

  const visibleTypeModelClose = React.useCallback(() => setVisibleType(''), []);

  const modifySearch = React.useCallback(() => {
    const queryString = queryStringToObject();
    props.history.push(`${PREFIX_URL_WITH_SLASH}/inventory/single-stone/search`, {
      searchKey: queryString.result,
      stoneIds: props?.stoneIds,
      customer: props?.client,
    });
  }, [props?.client, props.history, props?.stoneIds]);

  const actions = (
    <>
      <div className="d-flex">
        {props.guestExcelDownload && (
          <div className="d-flex listingAction">
            <BottomAction title="Download Excel" onClick={props.guestExcelDownload} />
          </div>
        )}
        {props.ActionFalse === false ||
        props.columnSetting ||
        props.isOfficeView ||
        props.todolist ||
        props.userPermission ||
        props.isInQuoteDayList ||
        props.inCheckPacket ? null : (
          <div className="d-flex listingAction">
            {props.isSearchTab && !window.location.search.includes('?similar') && actionPermission.searchPermit && (
              <BottomAction title="Modify Search" onClick={modifySearch} />
            )}
            {actionPermission.confirm && <BottomAction title="Order" onClick={() => popupShow('order')} />}
            {/* {actionPermission.hold && <BottomAction title="Hold" onClick={() => popupShow('hold')} {...props} />}
            {actionPermission.memo && <BottomAction title="Memo" onClick={() => popupShow('memo')} {...props} />} */}
            {actionPermission.quote && <BottomAction title="Offer" onClick={() => popupShow('offer')} />}
            {/* <BottomAction title="Enquiry" onClick={() => popupShow('enquiry')} /> */}
            {/* <BottomAction title="Final Calculation" onClick={() => popupShow('finalCalculation')} /> */}

            {actionPermission.cart && (
              <BottomAction
                title="Cart"
                onClick={() => checkDiamondStatus('cart') && handleInsertTrack(TRACK_TYPES.Cart, checked)}
              />
            )}
            {!props.priceRevice && actionPermission.watch && (
              <BottomAction
                title="Watchlist"
                onClick={() => checkDiamondStatus() && handleInsertTrack(TRACK_TYPES.Watchlist, checked)}
              />
            )}
            {actionPermission.office && <BottomAction title="View Diamonds" onClick={() => popupShow('office')} />}

            {/* <BottomCompareStone list={props?.checked} /> */}
            <Dropdown
              trigger={['click']}
              overlay={
                <div className="moreOptionDropdown">
                  <BottomCompareStoneButton onClick={setCompareStones.true} />
                  <BottomAction title="Share" onClick={visibleTypeButtonHandler('share')} />
                  {actionPermission.downloadExcel && (
                    <BottomAction title="Download" onClick={visibleTypeButtonHandler('download')} />
                  )}
                  {actionPermission.print && <BottomAction title="Print List" onClick={handlePrintClick} />}

                  {actionPermission.downloadExcel && (
                    <BottomAction title="Export Excel" onClick={handleExportExcelClick} />
                  )}
                  {!props.priceRevice && actionPermission.notes && (
                    <BottomAction title="Notes" onClick={() => popupShow('comment')} />
                  )}
                  {/* <BottomAction title="Reminder" /> */}
                  {/* {!props.priceRevice && <BottomAction title="Export Excel" onClick={onExportExcel} />} */}
                  {/* {!props.priceRevice && (
                    <BottomMailAction
                      list={checked}
                      client={props.client}
                      clearAllSelectedRows={clearAllSelectedRows}
                    />
                  )} */}
                  {/* {!props.priceRevice && actionPermission.quoteDay && (
                    <BottomAction title="Quote Day" onClick={() => popupShow('quoteDay')} {...props} />
                  )}
                  {!props.priceRevice && <BottomAction title="Show Stone" onClick={() => popupShow('showStone')} />} */}
                </div>
              }
            >
              <a className="bottomStickyButton">
                <span>More</span>
              </a>
            </Dropdown>
          </div>
        )}
        {props.email && (
          <div className="d-flex listingAction">
            <BottomAction title="Reply" />
            <BottomAction title="Reply All" />
            <BottomAction title="Forward" />
          </div>
        )}
        {props.isInQuoteDayList && (
          <div className="d-flex listingAction">
            <BottomAction title="Delete" onClick={props.onDeleteClick} />
            {actionPermission.searchPermit && <BottomAction title="Modify Search" onClick={props.searchResult} />}
          </div>
        )}
        {props.onDelete && <BottomAction title="Delete" onClick={() => validateCheckedItems() && props.onDelete()} />}
        {props.removeStone && (
          <BottomAction title="Remove Stone" onClick={() => validateCheckedItems() && props.removeStone()} />
        )}
        {props.confimEnquiry && actionPermission.downloadExcel && <BottomAction title="Download Excel" />}

        {props.orderList && (
          <div className="d-flex listingAction">
            {!['approved', 'rejected', 'cancelled'].includes(`${props.tab}`.toLowerCase()) && (
              <>
                {module?.permissions?.approve && (
                  <BottomAction
                    title="Approve"
                    onClick={() => (props.orderList ? popupShow('orderApprove') : popupShow('quoteApprove'))}
                    checked={checked}
                  />
                )}
                {module?.permissions?.reject && (
                  <BottomAction
                    title="Reject"
                    onClick={() => validateCheckedItems() && checkClientName(checked) && props.rejectClick(setLoader)}
                  />
                )}
              </>
            )}

            {['approved'].includes(`${props.tab}`.toLowerCase()) && module?.permissions?.cancel && (
              <BottomAction title="Cancel" onClick={() => validateCheckedItems() && props.cancelClick(setLoader)} />
            )}
            <BottomAction title="Share" onClick={visibleTypeButtonHandler('share')} />
            {actionPermission.downloadExcel && (
              <>
                <BottomAction title="Download" onClick={visibleTypeButtonHandler('download')} />
                <BottomAction title="Export Excel" onClick={handleExportExcelClick} />
              </>
            )}
            {actionPermission.print && <BottomAction title="Print List" onClick={handlePrintClick} />}
          </div>
        )}
        {props.inOffer && (
          <div className="d-flex listingAction">
            {['approved', 'rejected', 'cancelled'].includes(`${props.tab}`.toLowerCase()) ? null : (
              <>
                {module?.permissions?.approve && (
                  <BottomAction
                    title="Approve"
                    onClick={() => (props.orderList ? popupShow('orderApprove') : popupShow('quoteApprove'))}
                    checked={checked}
                  />
                )}
                {module?.permissions?.reject && (
                  <BottomAction
                    title="Reject"
                    onClick={() =>
                      validateCheckedItems('offerReject') && checkClientName(checked) && props.rejectClick(setLoader)
                    }
                  />
                )}
                {module?.permissions?.modify && <BottomAction title="Modify" onClick={() => popupShow('offer')} />}
              </>
            )}
            <BottomAction title="Share" onClick={visibleTypeButtonHandler('share')} />
            {actionPermission.downloadExcel && (
              <>
                <BottomAction title="Download" onClick={visibleTypeButtonHandler('download')} />
                <BottomAction title="Export Excel" onClick={handleExportExcelClick} />
              </>
            )}
            {actionPermission.print && <BottomAction title="Print List" onClick={handlePrintClick} />}
          </div>
        )}
        {/* {props.tranOfficeView && (
          <div className="d-flex">
            <BottomAction title="Remove" onClick={props.removeHandle} />
          </div>
        )} */}
        {/* {props.unHold && module?.permissions?.unHold && <BottomAction title="UnHold" onClick={props.unHoldFn} />} */}
        {/* {props.memoReturn && <BottomAction title="Memo Return" onClick={props.unHoldFn} />} */}

        {props.inCheckPacket && (
          <>
            <BottomAction title="Get Stock" onClick={() => props.fetchData('stock')} />
            <BottomAction title="Get Sale" onClick={() => props.fetchData('sale')} />
            {/* <BottomAction title="Get Hold" onClick={() => props.fetchData('hold')} /> */}
          </>
        )}
        {props.isOfficeView && (
          <div className="listingAction d-flex">
            {/* <BottomAction
              title="Delete"
              onClick={() => {
                const isDiamondValid = validateCheckedItems();
                isDiamondValid &&
                  Modal.confirm({
                    title: 'Are you sure delete this selected entries?',
                    okText: 'Delete',
                    onOk: props.removeHandle,
                  });
              }}
            /> */}
            {/* {actionPermission.memo && <BottomAction title="Memo" onClick={() => popupShow('memo')} {...props} />} */}
            {props.isApprove && (
              <BottomAction title="Approve" onClick={() => popupShow('officeApprove')} checked={checked} />
            )}
            {props.isReject && (
              <BottomAction
                title="Reject"
                onClick={() => validateCheckedItems() && checkClientName(checked) && props.rejectClick(setLoader)}
              />
            )}
            <BottomAction title="Share" onClick={visibleTypeButtonHandler('share')} />
            {actionPermission.downloadExcel && (
              <>
                <BottomAction title="Download" onClick={visibleTypeButtonHandler('download')} />
                <BottomAction title="Export Excel" onClick={handleExportExcelClick} />
              </>
            )}
            {actionPermission.print && <BottomAction title="Print List" onClick={handlePrintClick} />}
          </div>
        )}
        {/* {props.watchlistType && (
          <div className="watchListPrice d-flex">
            <div className="watchListPriceItem d-flex align-items-center">
              <span className="priceRound increase" />
              <span>Price Increase</span>
            </div>
            <div className="watchListPriceItem d-flex align-items-center">
              <span className="priceRound decrease" />
              <span>Price Decrease</span>
            </div>
          </div>
        )} */}
        {props.instructions && <BottomInstruct list={checked} onSuccess={props.reload} />}
        {props.exportClickImageExcel && <BottomAction title="Export Excel" onClick={props?.excelExportHandel} />}
        {props.modifySearch && <BottomAction title="Modify Search" onClick={modifySearch} />}
        {props.FinalCalculations && (
          <BottomAction title="Final Calculation" onClick={() => popupShow('finalCalculation')} />
        )}
        {props.bidStone && <BottomAction title="Bid Stone" onClick={() => popupShow('bid')} />}
        {props.compareStone && <BottomCompareStone list={checked} />}
        {props.share && <BottomAction title="Share" onClick={visibleTypeButtonHandler('share')} />}
        {props.download && actionPermission.downloadExcel && (
          <BottomAction title="Download" onClick={visibleTypeButtonHandler('download')} />
        )}
        {props.printList && actionPermission.print && <BottomAction title="Print List" onClick={handlePrintClick} />}
        {props.exportExcel && actionPermission.downloadExcel && (
          <BottomAction title="Export Excel" onClick={handleExportExcelClick} />
        )}
        {/* {props.mailList && (
          <BottomMailAction list={checked} client={props.client} clearAllSelectedRows={clearAllSelectedRows} />
        )} */}
        {props.emailStock && <BottomAction title="Email Stock" onClick={() => popupShow('emailStock')} />}
        {props.emailPic && <BottomAction title="Email Pic" onClick={() => popupShow('emailPic')} />}
        {props.save && <BottomAction title="Save" onClick={props.onSave} />}
        {(props.tab === 'APPROVED' || props.tab === 'PENDING' || props.task) && (
          <BottomAction onClick={showTask} title="Add Task" />
        )}
        {(props.tab === 'APPROVED' || props.tab === 'PENDING' || props.call) && (
          <BottomAction onClick={showCallLog} title="Add Call" />
        )}
        {(props.tab === 'APPROVED' || props.tab === 'PENDING' || props.note) && (
          <BottomAction onClick={showNotes} title="Add Note" />
        )}
        {window.location.pathname === '/kgk/trade/appointement/pending' && (
          <BottomAction title="Cancel" onClick={props.pendingCancle} />
        )}
        {window.location.pathname === '/kgk/trade/appointement/pending' && (
          <BottomAction title="Complete" onClick={props.clickComplete} />
        )}
        {window.location.pathname === '/kgk/trade/appointement/complete' && (
          <BottomAction title="Reopen" onClick={props.completeReopen} />
        )}
        {window.location.pathname === '/kgk/trade/appointement/reopen' && (
          <BottomAction title="Cancel" onClick={props.reopenCancel} />
        )}
        {window.location.pathname === '/kgk/trade/appointement/reopen' && (
          <BottomAction title="Complete" onClick={props.reopenComplete} />
        )}
        {window.location.pathname === '/kgk/activity/appointment/pending' && <BottomAction title="Cancel" />}
        {window.location.pathname === '/kgk/activity/appointment/pending' && <BottomAction title="Complete" />}
        {window.location.pathname === '/kgk/activity/appointment/reopen' && <BottomAction title="Complete" />}
        {window.location.pathname === '/kgk/activity/appointment/complete' && <BottomAction title="Reopen" />}
        {window.location.pathname === '/kgk/activity/notes/due' && <BottomAction title="Archived" />}
      </div>

      <div className="d-flex">{isFunction(props.onPaginationChange) && <Pagination {...props} />}</div>
    </>
  );

  return (
    <>
      {loader && <CircularProgress className="fixLoader" />}
      {props.noContainer ? (
        actions
      ) : (
        <div
          className={`botoomStickyBar diamondListingAction
          ${(props.columnSetting || props.userPermission) && 'j-flex-start'}
           ${
             props.ActionFalse === false &&
             !props.guestExcelDownload &&
             !props.tranCart &&
             !props.inCheckPacket &&
             !props.tranEnquiry &&
             !props.watchlistType &&
             !props.tranOfficeView &&
             !props.orderList &&
             !props.inOffer &&
             !props.exportExcel &&
             !props.exportClickImageExcel &&
             !props.isCompare
               ? 'justify-content-center'
               : ''
           }`}
        >
          {actions}
        </div>
      )}
      <CompareStoneTable isOpen={compareStones} list={checked} handleClose={setCompareStones.false} />
      <Drawer
        visible={isTask}
        onClose={handleTaskUnchecked}
        wrapClassName="diamondListinSidebar sm-size"
        destroyOnClose
      >
        <AddTodo checked={checked} onClose={handleTaskUnchecked} />
      </Drawer>
      <Drawer
        visible={isCallLog}
        onClose={handleCallLogUnchecked}
        wrapClassName="diamondListinSidebar sm-size"
        destroyOnClose
      >
        <AddCallLog checked={checked} onClose={handleCallLogUnchecked} />
      </Drawer>
      <Drawer
        visible={isNotes}
        onClose={handleNotesUnchecked}
        wrapClassName="diamondListinSidebar sm-size"
        destroyOnClose
      >
        <AddNotes checked={checked} onClose={handleNotesUnchecked} />
      </Drawer>
      <Drawer
        onClose={() => {
          clearAllSelectedRows();
          toggleOfficeDrawer();
        }}
        visible={officeDrawer}
        wrapClassName="diamondDetailPopup xl-size OfficeViewPopup"
        destroyOnClose
      >
        <OfficeSidebar
          onClose={() => {
            clearAllSelectedRows();
            toggleOfficeDrawer();
          }}
          checked={checked}
          unCheckedDiamond={props.unCheckedDiamond}
          client={props.client}
        />
      </Drawer>
      {/* {state.OfferPopup && (
        <Drawer
          onClose={() => setState({ ...state, OfferPopup: false })}
          visible={true}
          wrapClassName="diamondListinSidebar lg-size"
          destroyOnClose
        >
          <OfferPagePopup
            checked={checked}
            inOffer={props.inOffer}
            onClose={() => setState({ ...state, OfferPopup: false })}
            fetch={() => {
              if (props.fetch) props.fetch();
            }}
            tracks={props.tracks}
            client={!props.inOffer ? props.client : props.checked && props.checked.length ? props.checked[0].user : {}}
          />
        </Drawer>
      )} */}
      {state.OfferPopup && (
        <OfferPagePopup
          checked={checked}
          inOffer={props.inOffer}
          onClose={() => setState({ OfferPopup: false })}
          fetch={props.fetch}
          tracks={props.tracks}
          client={!props.inOffer ? props.client : checked && checked.length ? checked[0].user : {}}
        />
      )}
      {/* {state.OfferPopup && (
        <Drawer
          onClose={toggleOffer}
          visible={state.OfferPopup}
          wrapClassName="diamondListinSidebar xl-size"
          columns={props.columns}
          destroyOnClose
        >
          <OfferPopup
            inOffer={props.inOffer}
            onClose={() => {
              clearAllSelectedRows();
              toggleOffer();
            }}
            fetch={() => {
              if (props.fetch) props.fetch();
            }}
            tracks={props.tracks}
            checked={checked}
            client={props.client}
          />
        </Drawer>
      )} */}

      <Drawer
        onClose={toggleApprove}
        visible={state.approvePopup}
        wrapClassName="diamondListinSidebar lg-size"
        destroyOnClose
      >
        <OrderApprove {...props} checked={checked} />
      </Drawer>

      <Drawer
        onClose={() => {
          setState({ singleStonePalaceOrder: false, finalCalculation: false });
          clearAllSelectedRows();
        }}
        visible={state.singleStonePalaceOrder}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        <SingleStonePlaceOrderPopup
          {...props}
          checked={checked}
          tab={state.tab}
          visible={state.singleStonePalaceOrder}
          onClose={(e) => {
            setState({ singleStonePalaceOrder: false, finalCalculation: false });
            if (e && props.fetch) props.fetch();
            clearAllSelectedRows();
          }}
          handleInsertTrack={(data) => handleInsertTrack(TRACK_TYPES.Enquiry, data)}
          removeDiamond={props.removeDiamond}
          approveClick={(loadingFn, checkedData, request) =>
            props.approveClick(
              (loader) => {
                loadingFn(loader);
                if (!loader) setState({ singleStonePalaceOrder: !state.singleStonePalaceOrder });
              },
              checkedData,
              request,
            )
          }
          loadingFn={props.loadingFn}
        />
      </Drawer>
      <Drawer
        onClose={() => {
          setState({ commentPopup: !state.commentPopup });
          clearAllSelectedRows();
        }}
        visible={state.commentPopup}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        <CommentPopup
          {...props}
          checked={checked}
          unCheckedDiamond={props.unCheckedDiamond}
          onClose={() => {
            clearAllSelectedRows();
            setState({ commentPopup: !state.commentPopup });
          }}
        />
      </Drawer>
      {state.holdPopup && (
        <HoldPopup
          holdPopup={state.holdPopup}
          handleCancel={() => {
            toggleHold();
            clearAllSelectedRows();
          }}
          {...props}
          checked={checked}
          clearAllSelectedRows={clearAllSelectedRows}
          userTerms={userTerms}
        />
      )}

      <Drawer
        onClose={toggleBid}
        visible={state.bid}
        wrapClassName="diamondListinSidebar offerPopup xl-size"
        destroyOnClose
      >
        <BidPopup
          {...props}
          checked={checked}
          onClose={() => {
            clearAllSelectedRows();
            toggleBid();
          }}
        />
      </Drawer>
      {state.memoPopup && (
        <Drawer
          onClose={() => {
            setState({ memoPopup: !state.memoPopup });
            clearAllSelectedRows();
          }}
          visible={state.memoPopup}
          wrapClassName="diamondListinSidebar xl-size"
          destroyOnClose
        >
          <MemoPopup
            memoPopup={state.memoPopup}
            handleCancel={() => {
              toggleMemo();
              clearAllSelectedRows();
            }}
            userTerms={userTerms}
            {...props}
            checked={checked}
            clearAllSelectedRows={clearAllSelectedRows}
          />
        </Drawer>
      )}
      {visibleType === 'share' && (
        <ShareAction
          {...props}
          checked={checked}
          onClose={() => {
            clearAllSelectedRows();
            visibleTypeModelClose();
          }}
        />
      )}
      {state.compareEmail && (
        <SendEmailPopup
          sendEmail={state.compareEmail}
          onCancel={() => {
            toggleCompareEmail();
            clearAllSelectedRows();
          }}
          ids={map(checked, 'id')}
          img={state.tab === 'emailPic'}
          isStock={state.tab === 'emailStock'}
        />
      )}

      {state.quoteDay && (
        <QuoteDayPopup
          quoteDay={state.quoteDay}
          handleCancel={() => {
            clearAllSelectedRows();
            toggleQuoteDay();
          }}
          {...props}
          checked={checked}
        />
      )}
      {visibleType === 'download' && actionPermission.downloadExcel && (
        <DownloadAction
          {...props}
          checked={checked}
          onClose={() => {
            clearAllSelectedRows();
            visibleTypeModelClose();
          }}
        />
      )}

      <Drawer
        onClose={toggleFinalCalculation}
        visible={state.finalCalculation}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        <FinalCalculations
          checked={checked}
          client={props.client}
          {...props}
          onClose={() => {
            clearAllSelectedRows();
            toggleFinalCalculation();
          }}
          // userTerms={userTerms}
          onConfirmStone={() => popupShow('order')}
          isShowConfirmStone={props.isShowConfirmStone}
          checkDiamondStatus={checkDiamondStatus}
        />
      </Drawer>
    </>
  );
};

export default React.memo(withRouter(DiamondListingAction));
