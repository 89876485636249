export const getDefaultPermissions = (override = {}) => ({
  all: true,
  view: true,
  insert: true,
  update: true,
  delete: true,
  printPDF: true,
  mailExcel: true,
  uploadExcel: true,
  downloadExcel: true,
  ...override,
});
