import { groupBy, memoize } from 'lodash';

import { formatNumber } from 'components/DiamondListing/SelectStone';

import { getUserName, isArray, isEmpty, isNotEmpty, isObject } from 'util/utils';

import { FLOAT_COLUMNS, ROUND_COLUMNS } from './table-config';

export const updateRecord = (record, options = {}) => {
  if (!isObject(record)) return;
  // options.srNo
  return { ...record, ...options };
};

export const updateList = (list, inBlockList = [], options = {}) => {
  if (!isArray(list)) return;
  return list.forEach((record) => updateRecord(record, inBlockList, options));
};

export const filterRecord = (record, remove = []) => {
  if (!isObject(record)) return;
  record = { ...record };
  const byUpdater = ['srNo'];
  const byGroupping = ['isFooter', 'isHeader', 'groupData', 'totalDiamonds', '_groupTitle_'];
  const byGroupType = ['isHoldHeader', 'isMatchHeader', 'userGroupHold'];
  const keysToDelete = [...byUpdater, ...byGroupping, ...byGroupType, ...remove];
  keysToDelete.forEach((key) => delete record[key]);
  return record;
};

export const filterList = (list, remove = []) => {
  if (!isArray(list)) return;
  return list.map((record) => filterRecord({ ...record }, remove));
};

export const getStickyStyle = memoize((noStatus = false) => {
  const stickyHeader = { position: 'sticky', zIndex: 2000 };
  const stickyCell = { position: 'sticky', backgroundColor: '#fff', zIndex: 1000 };
  const firstLeft = -10;
  const firstWidth = noStatus ? 40 : 140;
  const secondLeft = firstWidth + firstLeft - 5;
  const secondWidth = 160;
  const thirdLeft = firstWidth + secondWidth + firstLeft - 10;

  return {
    head: {
      first: { left: firstLeft + 'px', ...stickyHeader, minWidth: firstWidth + 'px' },
      second: { left: secondLeft + 'px', ...stickyHeader, minWidth: secondWidth + 'px' },
      third: { left: thirdLeft + 'px', ...stickyHeader },
    },
    cell: {
      first: { left: firstLeft + 'px', ...stickyCell, minWidth: firstWidth + 'px' },
      second: { left: secondLeft + 'px', ...stickyCell, minWidth: secondWidth + 'px' },
      third: { left: thirdLeft + 'px', ...stickyCell },
    },
  };
});

export const formatValues = (value, key) => {
  if (!value) return '-';

  return FLOAT_COLUMNS.includes(key)
    ? formatNumber(parseFloat(value).toFixed(2))
    : ROUND_COLUMNS.includes(key)
    ? formatNumber(Math.round(value))
    : value;
};

export const getUserTitle = (user, replace = '-') => {
  const companyName = user?.account?.companyName ?? user?.companyName;
  const name = [companyName, getUserName(user), user?.mobile, user?.email].filter(isNotEmpty);
  return !isEmpty(name) ? name.join(' | ') : replace;
};

export const getGroupTitle = (records = [], columns = []) => {
  const record = records?.[0];
  if (!isArray(columns)) return;

  const options = {
    user: columns.includes('user.id') || columns.includes('userId'),
    date: columns.includes('userDate') || columns.includes('createdAt'),
    country: columns.includes('countryNm'),
    seller: columns.includes('seller.id') || columns.includes('sellerNm'),
    stage: columns.includes('sSts') || columns.includes('blockCode'),
    vStnId: columns.includes('vStnId'),
    memoNo: columns.includes('memoNo'),
    groupNo: columns.includes('groupNo'),
  };

  if (options.user || options.date) {
    const dateTitle = record?.dateTitle ?? '';
    const date = record?.userDate ?? record?.createdAt ?? '-';

    if (options.user && options.date) return `${dateTitle} ${date} | ${getUserTitle(record?.user)}`;
    if (options.date) return `${dateTitle} ${date}`;
    if (options.user) return `${getUserTitle(record?.user)}`;
  }

  if (options.country) return record?.countryNm ?? '-';
  if (options.memoNo) return record?.memoNo ?? '-';
  if (options.seller) return getUserTitle(record?.seller, '-');
  if (options.stage) return record?.blockCode ?? record?.sSts ?? '-';
  if (options.vStnId) return record?.vStnId ?? '-';

  if (options.groupNo) {
    const paramsList = records.map((stone) => ({
      shp: stone.shpNm,
      crt: parseFloat(stone.crt).toFixed(2),
      col: stone.isFcCol ? stone.fcColNm : stone.colNm,
      clr: stone.clrNm,
      cut: stone.cutNm,
      pol: stone.polNm,
      sym: stone.symNm,
    }));
    const fields = ['shp', 'crt', 'col', 'clr', 'cut', 'pol', 'sym'];
    const title = fields
      .map((field) => {
        const subGroup = groupBy(paramsList, field);
        if (Object.keys(subGroup)?.length === 1) {
          return paramsList?.[0]?.[field];
        } else {
          return null;
        }
      })
      .filter(isNotEmpty)
      .join(' ');

    return title;
  }

  return '-';
};
export const memoizedGetGroupTitle = memoize(getGroupTitle, (...args) => JSON.stringify(args));

export const filterGridColumns = (columns = [], options = {}) => {
  columns =
    options?.dateList && options?.customerList
      ? columns.filter((el) => el.id !== 'createdAt' && el.id !== 'user')
      : options?.dateList
      ? columns.filter((el) => el.id !== 'createdAt')
      : options?.customerList
      ? columns.filter((el) => el.id !== 'user')
      : columns;

  if (options?.inClient) columns = columns.filter((el) => el.id !== 'user');

  return columns;
};

export const updateRowClassList = (accessor, rowId, select = false) => {
  const tableRows = document.querySelectorAll(accessor ? `[table-row="${accessor}${rowId}"]` : `tr[table-row]`);
  if (!isEmpty(tableRows)) for (const row of tableRows) row.classList[select ? 'add' : 'remove']('selectTr');
};
