import React from 'react';

import closeSvg from '../../assets/svg/InventoryResult/close.svg';

const SortingClose = (props) => {
  return (
    <div className="shortingClose" onClick={props.onClick}>
      <img src={closeSvg} alt="icon" />
    </div>
  );
};
export default SortingClose;
