import React from 'react';

import _divide from 'lodash/divide';
import _sum from 'lodash/sum';

import { floatkeys } from '../Inventory/TableBack';

function DiamondTotalCard(props) {
  const { row, col, content } = props;

  const newDisc =
    _sum(row?.groupData.rapAvg) && _sum(row?.groupData.crt)
      ? (_divide(_sum(row?.groupData.newAmount), _sum(row?.groupData.crt)) /
          _divide(_sum(row?.groupData.rapAvg), _sum(row?.groupData.crt)) -
          1) *
        100
      : 0;

  const prefix = React.useMemo(() => (col !== 'crt' && col !== 'newDiscount' ? '$' : ''), [col]);

  const result = React.useMemo(() => {
    const sum = parseFloat(
      col !== 'ctPr' && col !== 'newPricePerCarat' && col !== 'newDiscount'
        ? _sum(row?.groupData[col])
        : col === 'newDiscount'
        ? newDisc
        : _divide(_sum(col === 'ctPr' ? row?.groupData.amt : row?.groupData.newAmount), _sum(row?.groupData.crt)),
    ).toFixed(floatkeys.includes(col) ? (col === 'crt ' ? 3 : 2) : 0);
    return `${prefix}${sum}`;
  }, [col, row?.groupData, newDisc, prefix]);

  return <span className="selectAmount">{content ? content : result}</span>;
}
export default DiamondTotalCard;
