import UtilService from 'services/util';

// import { isEmpty } from 'util/utils';

import ConstantApi from 'constants/CommonApi';

export const AddToDo = (request = {}) => UtilService.callApiAsync({ ...ConstantApi.Activity.Insert, request });

export const getListTrade = (request = {}) => UtilService.callApiAsync({ ...ConstantApi.Activity.paginate, request });

export const destroyTask = (request = {}) => UtilService.callApiAsync({ ...ConstantApi.Activity.destroy, request });

export const updateTask = (id, request) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Activity.Update,
    url: ConstantApi.Activity.Update.url + `/${id}`,
    id,
    request,
  });
};

export const findTask = (request = {}) =>
  UtilService.callApiAsync({
    ...ConstantApi.Activity.view,
    url: ConstantApi.Activity.view.url + '/' + request,
  });

export const AddCallLogActivity = (request = {}) =>
  UtilService.callApiAsync({ ...ConstantApi.Activity.InsertCallLog, request });

export const getListCallLog = (request = {}) =>
  UtilService.callApiAsync({ ...ConstantApi.Activity.paginateCallLog, request });

export const destroyCallLog = (request = {}) =>
  UtilService.callApiAsync({ ...ConstantApi.Activity.destroyCallLog, request });

export const updateCallLog = (id, request) =>
  UtilService.callApiAsync({
    ...ConstantApi.Activity.UpdateCallLog,
    url: ConstantApi.Activity.UpdateCallLog.url + '/' + id,
    id,
    request,
  });

export const findCallLog = (request = {}) =>
  UtilService.callApiAsync({
    ...ConstantApi.Activity.viewCallLog,
    url: ConstantApi.Activity.viewCallLog.url + '/' + request,
  });
// export const changeStatusDiamondBlock = (request = {}, config) =>
//   UtilService.callApiAsync({ ...ConstantApi.Memo.changeStatus, request, ...(!isEmpty(config) && { config }) });

// export const getDiamondBlockMaster = (request = {}, config) =>
//   UtilService.callApiAsync({
//     ...ConstantApi.DiamondBlockMaster.paginate,
//     request,
//     ...(!isEmpty(config) && { config }),
//   });

// export const memoHoldPaginate = (request = {}, config) =>
//   UtilService.callApiAsync({ ...ConstantApi.Memo.paginate, request, ...(!isEmpty(config) && { config }) });

// export const historyDataPaginate = (request = {}) => {
//   return UtilService.callApiAsync({
//     ...ConstantApi.Order.paginate,
//     request,
//   });
// };
